import React from "react";
import * as PropTypes from "prop-types";
import { Checkbox } from "@material-ui/core";
import { camelToTitle, underscoreToCamel } from "../../../tools/stringTools";
import { Typography } from "@material-ui/core";
//import  from '@mui/material/FormControlLabel';

const WorxCheckbox = (props) => {
  let {
    formik,
    fieldKey,
    disabled = false,
    label = camelToTitle(underscoreToCamel(fieldKey)),
  } = props;

  return (
    <div>
      <Typography variant={"body1"} gutterBottom>
        {label}
      </Typography>
      <Checkbox
        id={fieldKey}
        checked={formik.values[fieldKey]}
        disabled={disabled}
        onChange={(event) => {
          formik.setFieldValue(fieldKey, event.target.checked);
        }}
        color="primary"
      />
      {formik.touched[fieldKey] && formik.errors[fieldKey] ? (
        <div style={{ color: "red" }}>{formik.errors[fieldKey]}</div>
      ) : null}
    </div>
  );
};

WorxCheckbox.propTypes = {
  fieldKey: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  formik: PropTypes.object.isRequired,
};

export default WorxCheckbox;
