import React, { useEffect } from "react";
import * as PropTypes from "prop-types";
import { camelToTitle, underscoreToCamel } from "../../../tools/stringTools";
import { makeStyles } from "@mui/styles";
import WorxDialogContainer from "../../../Container/WorxDialogContainer";
import Button from "@material-ui/core/Button";
import WorxList from "./WorxList";

const buttonUseStyles = makeStyles({
  alignHead: {
    display: "tableHeaderGroup",
  },
});

const WorxListCreate = (props) => {
  /* eslint-disable no-unused-vars */
  let {
    fieldKey,
    disabled = false,
    label = camelToTitle(underscoreToCamel(fieldKey)),
    options,
    setInitValues,
    formik,
  } = props;
  /* eslint-disable no-unused-vars */

  let listValues = formik.values;

  for (let el of fieldKey.split(".")) {
    if (listValues && listValues?.[el]) {
      listValues = listValues?.[el];
    } else {
      listValues = [];
    }
  }

  const buttonClasses = buttonUseStyles();

  useEffect(() => {
    if (listValues.length === 0) {
      formik.setFieldValue(fieldKey, []);
    }
  }, []);

  return (
    <React.Fragment>
      <WorxList
        fieldKey={fieldKey}
        columns={options?.columns}
        options={options}
        setInitValues={setInitValues}
        formik={formik}
      />
      {!disabled && (
        <React.Fragment>
          <WorxDialogContainer
            open={options?.open.get}
            onClose={() => {
              setInitValues({});
              options?.open.set(false);
            }}
            content={options?.display}
            title={
              <div>{`Create ${camelToTitle(underscoreToCamel(fieldKey))}`}</div>
            }
          />
          <Button
            key={"submit"}
            variant={"contained"}
            color="secondary"
            margin="normal"
            onClick={() => {
              options?.open.set(!options?.open?.get);
            }}
            classes={{ root: buttonClasses.root }}
          >
            Add {camelToTitle(underscoreToCamel(fieldKey))}
          </Button>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

WorxListCreate.propTypes = {
  fieldKey: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  options: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  setInitValues: PropTypes.func,
  formik: PropTypes.object.isRequired,
};

export default WorxListCreate;
