import React, { useContext } from "react";
import {
  getJuniorAnalystClaimedQueueItems,
  getJuniorAnalystQueueItemsShort,
} from "../../Queries/Queries";
import {
  claimJuniorAnalystQueueItem,
  juniorAnalystReleaseQueueItem,
} from "../../Queries/Mutations";
import { useHistory } from "react-router";
import HeaderTitleContext from "../../Context/HeaderTitleContext";
import Button from "@material-ui/core/Button";
import QueueContainer from "../Queue/QueueContainer";

// eslint-disable-next-line no-unused-vars
const JuniorAnalystQueueContainer = (props) => {
  // eslint-disable-next-line no-unused-vars
  const history = useHistory();

  // eslint-disable-next-line no-unused-vars
  const [headerTitle, setHeaderTitle] = useContext(HeaderTitleContext);
  setHeaderTitle("Junior Analyst");

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 100,
      renderCell: Object.assign(
        (params) => {
          return (
            <Button aria-label={`row-id-${params.value}`}
              onClick={() => {
                history.push({
                  pathname:
                    "/junior_analyst/junior_analyst_workflow/" + params.row.id,
                  state: { data: params.row },
                });
              }}
            >
              {params.row.id}
            </Button>
          );
        },
        { displayName: "renderCell" }
      ),
    },
    {
      field: "type",
      headerName: "Type",
      width: 150,
      renderCell: Object.assign(
        (params) => {
          return <div>{params.row.document_type?.[0]?.name}</div>;
        },
        { displayName: "renderCell" }
      ),
    },
    {
      field: "name",
      headerName: "Name",
      width: 150,
      renderCell: Object.assign(
        (params) => {
          return <div>{params.row.name}</div>;
        },
        { displayName: "renderCell" }
      ),
    },
    {
      field: "order",
      headerName: "Client",
      width: 230,
      valueGetter: (value) => {
        return value.row.order_item_reference?.[0]?.order?.[0]?.client[0].name;
      },
    },
    {
      field: "portfolio_name",
      headerName: "Portfolio",
      width: 230,
      valueGetter: (value) => {
        return value.row.order_item_reference?.[0]?.order?.[0]?.portfolio_name;
      },
    },
    {
      field: "Address",
      headerName: "Address",
      width: 230,
      valueGetter: (value) => {
        return value?.row?.property?.[0]?.address?.address_line1;
      },
    },
    {
      field: "City",
      headerName: "City",
      width: 230,
      valueGetter: (value) => {
        return value?.row?.property?.[0]?.address?.locality;
      },
    },
    {
      field: "State",
      headerName: "State",
      width: 230,
      valueGetter: (value) => {
        return value?.row?.property?.[0]?.address?.administrative_area;
      },
    },
    {
      field: "report_order_items",
      headerName: "Jurisdiction",
      width: 230,
      valueGetter: (value) => {
        return value?.row?.jurisdiction?.[0]?.name;
      },
    },
    {
      field: "state",
      headerName: "Status",
      width: 230,
      valueGetter: (value) => {
        return value.row.order_item_reference?.[0]?.order?.[0]?.state;
      },
    },
  ];

  return (
    <QueueContainer
      columns={columns}
      type={"Item"}
      itemsQuery={getJuniorAnalystQueueItemsShort}
      queryKey={"juniorAnalystQueueItems"}
      claimedItemsQuery={getJuniorAnalystClaimedQueueItems}
      claimedItemsQueryKey={"juniorAnalystClaimedQueueItems"}
      claimKey={"juniorAnalystClaimQueueItem.claimed_item.media_document.id"}
      claimQuery={claimJuniorAnalystQueueItem}
      claimRoute={"/junior_analyst/junior_analyst_workflow"}
      release={juniorAnalystReleaseQueueItem}
    />
  );
};

JuniorAnalystQueueContainer.propTypes = {};

export default JuniorAnalystQueueContainer;
