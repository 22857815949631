import React, { useState } from "react";
import * as PropTypes from "prop-types";
import { camelToTitle, underscoreToCamel } from "../../../tools/stringTools";
import Button from "@material-ui/core/Button";
import WorxListCreate from "../../../Display/Forms/Inputs/WorxListCreate";
import JuniorAnalystTenantFormContainer from "../../JuniorAnalyst/Forms/JuniorAnalystTenantFormContainer";

const DefaultListCreateContainer = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [open, setOpen] = useState(false);
  const [initValues, setInitValues] = useState(null);

  let {
    fieldKey,
    type,
    disabled = false,
    label = camelToTitle(underscoreToCamel(fieldKey)),
    formik,
  } = props;

  let localOptions = {
    open: { get: open, set: setOpen },
    display: (
      <JuniorAnalystTenantFormContainer
        jurisdiction={formik.values.jurisdiction}
        initValues={initValues}
        callback={(value) => {
          setOpen(false);
          let newList = [...formik.values[fieldKey]];
          let index = newList.findIndex((item) => item.id === value.id);
          if (index >= 0) {
            newList[index] = value;
          } else {
            newList = [...newList, value];
          }
          setInitValues({});
          formik.setFieldValue(fieldKey, newList);
        }}
      />
    ),
    columns: [
      { field: "id", headerName: "ID", width: 230 },
      { field: "name", headerName: "Name", width: 230 },
      {
        field: "action",
        headerName: "Action",
        width: 230,
        renderCell: Object.assign(
          (params) => {
            return (
              <React.Fragment>
                <Button
                  onClick={() => {
                    setInitValues(params.row);
                    setOpen(true);
                  }}
                  color="primary"
                >
                  Edit
                </Button>
                <Button
                  onClick={() => {
                    let newValue = [...formik.values[fieldKey]];
                    let index = newValue.findIndex(
                      (val) => val.id === params.row.id
                    );
                    if (index >= 0) {
                      newValue.splice(index, 1);
                    }
                    formik.setFieldValue(fieldKey, newValue);
                  }}
                  color="primary"
                >
                  Remove
                </Button>
              </React.Fragment>
            );
          },
          { displayName: "renderCell" }
        ),
      },
    ],
  };

  return (
    <WorxListCreate
      fieldKey={fieldKey}
      initValues={initValues}
      options={localOptions}
      formik={formik}
      label={label}
      type={type}
      disabled={disabled}
      setInitValues={setInitValues}
    />
  );
};

DefaultListCreateContainer.propTypes = {
  fieldKey: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  options: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  formik: PropTypes.object.isRequired,
};

export default DefaultListCreateContainer;
