import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  getCategoryOptions,
  getZonesForJurisdiction,
} from "../../../../Queries/Queries";
import {
  addZone,
  checkZoneDuplicate,
  editZone,
  uploadFile,
} from "../../../../Queries/Mutations";
import JurisdictionFormThreeDisplay from "../../../../Display/DataEntry/DataEntryOne_MainWorkflow/Forms/JurisdictionFormThreeDisplay";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "@material-ui/core/Button";
import WorxDeleteButton from "../../../Forms/Inputs/WorxDeleteButton";

const JurisdictionFormThreeContainer = (props) => {
  let { data } = props;
  let [addZonesDistricts, setAddZonesDistricts] = useState(null);
  let [initValues, setInitValues] = useState({});
  let [categoryOpen, setCategoryOpen] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const pageSize = 25;
  const [currentPage, setCurrentPage] = useState(0);
  const [filters, setFilters] = useState({ limit: 50, offset: 0 });
  const [filterZones, { data: zonesQuery }] = useLazyQuery(
    getZonesForJurisdiction(undefined, data.get.id, 50),
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const [checkZoneDesignation] = useMutation(checkZoneDuplicate());
  const validateDesignation = async (variables) => {
    // eslint-disable-next-line no-unused-vars
    const thing = await checkZoneDesignation({
      variables: { data: variables },
    });
    if (thing) {
      return thing;
    } else {
      return null;
    }
  };

  const [filterCategoryOptions, { data: categoryOptionsQuery }] = useLazyQuery(
    getCategoryOptions(data.get.id, "zone_list")
  );

  //mutations
  const [doAddZone] = useMutation(addZone("Zone"), {
    refetchQueries: [
      {
        query: getZonesForJurisdiction(undefined, data.get.id, 50),
        variables: filters,
      },
    ],
  });
  const [doEditZone] = useMutation(editZone("Zone"), {
    refetchQueries: [
      {
        query: getZonesForJurisdiction(undefined, data.get.id, 50),
        variables: filters,
      },
    ],
  });
  const [doAddOverlay] = useMutation(addZone("Overlay"), {
    refetchQueries: [
      {
        query: getZonesForJurisdiction(undefined, data.get.id, 50),
        variables: filters,
      },
    ],
  });
  const [doEditOverlay] = useMutation(editZone("Overlay"), {
    refetchQueries: [
      {
        query: getZonesForJurisdiction(undefined, data.get.id, 50),
        variables: filters,
      },
    ],
  });
  const [doAddPd] = useMutation(addZone("PD"), {
    refetchQueries: [
      {
        query: getZonesForJurisdiction(undefined, data.get.id, 50),
        variables: filters,
      },
    ],
  });
  const [doEditPd] = useMutation(editZone("PD"), {
    refetchQueries: [
      {
        query: getZonesForJurisdiction(undefined, data.get.id, 50),
        variables: filters,
      },
    ],
  });
  const [doUploadFile] = useMutation(uploadFile());

  useEffect(() => {
    //first fetch needed in lazy case
    filterZones();
  }, []);

  useEffect(() => {
    if (zonesQuery) {
      setRowCount(zonesQuery.zones.count);
    }
  }, [zonesQuery]);

  let defaultZone = {
    name: "",
    designation: "",
    description: "",
    category: [{ id: 0, name: "-Select-" }],
    code_date: "",
    weight: 0,
    button: null,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...defaultZone, ...initValues, button: null },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      //designation: Yup.string().required('Required'),
      designation: Yup.string().test(
        "designation",
        "This Designation is already in use.",
        async (value) => {
          if (initValues.id && value === initValues.designation) {
            return true;
          } else {
            const rValue = await validateDesignation({
              designation: value ?? "",
              type: "zone",
              jurisdiction: [{ id: data.get.id }],
            });
            if (rValue?.data?.checkZoneDuplicate?.exists) {
              return false;
            } else {
              return true;
            }
          }
        }
      ),
    }),
    onSubmit: (values, formikTools) => {
      // eslint-disable-next-line no-unused-vars
      let { button, ...zone } = values;
      switch (button) {
        case "normal":
          //doUploadFile({variables: { file: code_file }});
          mutateZone({ ...zone, type: addZonesDistricts });
          setAddZonesDistricts(null);
          break;
        case "repeat":
          mutateZone({ ...zone, type: addZonesDistricts });
          break;
        case "cancel":
          setAddZonesDistricts(null);
          break;
      }
      setInitValues({});
      formikTools.resetForm();
    },
  });

  let mutateZone = (values) => {
    // Do this to get the payload in the right shape
    let { type, ...payload } = values;
    payload.jurisdiction = [
      {
        id: data.get.id,
      },
    ];
    if (payload.id) {
      switch (type) {
        case "zone":
          doEditZone({ variables: { data: payload } });
          break;
        case "overlay":
          doEditOverlay({ variables: { data: payload } });
          break;
        case "pd":
          doEditPd({ variables: { data: payload } });
          break;
      }
    } else {
      switch (type) {
        case "zone":
          doAddZone({ variables: { data: payload } });
          break;
        case "overlay":
          doAddOverlay({ variables: { data: payload } });
          break;
        case "pd":
          doAddPd({ variables: { data: payload } });
          break;
      }
    }
  };

  const columns = [
    { field: "name", headerName: "Name", width: 350 },
    { field: "designation", headerName: "Designation", width: 200 },
    { field: "type", headerName: "Type", width: 200 },
    {
      field: "Operation",
      headerName: "Operation",
      width: 200,
      renderCell: Object.assign(
        (params) => {
          return (
            <React.Fragment>
              <WorxDeleteButton
                callback={(response) => {
                  if (response?.deleteEntity?.errors?.length <= 0) {
                    filterZones({ variables: filters });
                  }
                }}
                type={"jur_zone"}
                id={params.row.id}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setAddZonesDistricts(params.row.type);
                  setInitValues(params.row);
                }}
              >
                edit
              </Button>
            </React.Fragment>
          );
        },
        { displayName: "renderCell" }
      ),
    },
  ];

  return (
    <JurisdictionFormThreeDisplay
      addZonesDistricts={{ get: addZonesDistricts, set: setAddZonesDistricts }}
      columns={columns}
      zones={{ list: zonesQuery?.zones?.items, filter: filterZones }}
      categoryOptions={{
        open: { get: categoryOpen, set: setCategoryOpen },
        options: {
          list: categoryOptionsQuery?.categories?.items
            ? categoryOptionsQuery.categories.items
            : [],
          filter: filterCategoryOptions,
        },
      }}
      initValues={{ get: initValues, set: setInitValues }}
      currentPage={{ get: currentPage, set: setCurrentPage }}
      filters={{ get: filters, set: setFilters }}
      rowCount={rowCount}
      pageSize={pageSize}
      formik={formik}
      doUploadFile={doUploadFile}
    />
  );
};

JurisdictionFormThreeContainer.propTypes = {
  data: PropTypes.any.isRequired,
};

export default JurisdictionFormThreeContainer;
