import React, { useContext, useEffect, useState } from "react";
// eslint-disable-next-line no-unused-vars
import * as PropTypes from "prop-types";
import AnalystConformanceReportDisplay from "../../Display/Analyst/AnalystConformanceReportDisplay";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  getAnalystReport,
  getDocumentTypes,
  getMedia,
} from "../../Queries/Queries";
import { generateConformanceReport } from "../../Queries/Mutations";
import { testMode } from "../../Constants/Values";
import MessageContext from "../../Context/MessageContext";

const AnalystConformanceReportContainer = (props) => {
  const { id } = props;

  const [filterDocumentTypes, { data: documentTypes }] = useLazyQuery(
    getDocumentTypes()
  );
  const [getReport, { data: report }] = useLazyQuery(getAnalystReport(id));
  const [filterDocuments, { data: documentsQuery }] = useLazyQuery(
    getMedia("document")
  );

  // eslint-disable-next-line no-unused-vars
  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowTotal, setRowTotal] = useState(0);
  const [filters, setFilters] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [message, setMessage] = useContext(MessageContext);

  const [doGenerateReport] = useMutation(generateConformanceReport(), {
    refetchQueries: [{ query: getMedia("document"), variables: filters }],
  });

  useEffect(() => {
    getReport();
    filterDocumentTypes();
    setMessage(null);
  }, []);

  useEffect(() => {
    if (
      report?.analystReport?.conformance_report_order_item?.[0]?.property?.[0]
        ?.id &&
      documentTypes
    ) {
      let newFilters = filters;
      newFilters.property =
        report.analystReport.conformance_report_order_item[0].property[0].id;
      newFilters.document_type = [
        documentTypes.documentTypes[
          documentTypes.documentTypes.findIndex(
            (type) => type.name === "Conformance Report"
          )
        ].id,
      ]; //119 is the hard coded value of the conformance report document types
      newFilters.limit = pageSize;
      filterDocuments({
        variables: newFilters,
      });
      setFilters(newFilters);
    }
  }, [report, documentTypes]);

  useEffect(() => {
    if (documentsQuery) {
      setRowTotal(documentsQuery.mediaDocuments.total);
    }
  }, [documentsQuery]);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      valueGetter: (value) => {
        return value.id;
      },
      width: 100,
    },
    {
      field: "name",
      headerName: "Name",
      renderCell: Object.assign(
        (params) => {
          return (
            <a
              href={params.row.document[0].filepath}
              target="_blank"
              rel="noreferrer"
            >
              {params.row.document[0].filename}
            </a>
          );
        },
        { displayName: "renderCell" }
      ),
      width: 330,
    },
  ];

  const generateReport = () => {
    let variables = { analyst_report: +id };
    if (testMode) {
      variables.test = true;
    }
    doGenerateReport({ variables });
    setMessage("✓ Conformance Saved");
  };

  return (
    <AnalystConformanceReportDisplay
      documents={{
        list: documentsQuery?.mediaDocuments?.items ?? [],
        filter: filterDocuments,
      }}
      currentPage={{ get: currentPage, set: setCurrentPage }}
      filters={{ get: filters, set: setFilters }}
      rowTotal={rowTotal}
      pageSize={pageSize}
      columns={columns}
      doGenerateReport={generateReport}
    />
  );
};

AnalystConformanceReportContainer.propTypes = {
  id: PropTypes.number.isRequired,
  page: PropTypes.string,
};

export default AnalystConformanceReportContainer;
