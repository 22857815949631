import { Route, Switch, useRouteMatch } from "react-router-dom";
import React from "react";
import ClientRelationsContainer from "../Container/ClientRelations/ClientRelationsContainer";

const ClientRelationsRouter = () => {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route
        path={`${match.url}/`}
        render={() => {
          return <ClientRelationsContainer />;
        }}
      />
    </Switch>
  );
};

export default ClientRelationsRouter;
