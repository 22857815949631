import React from "react";
import LoginFormDisplay from "../../Display/Forms/LoginFormDisplay";
import { getAccessToken } from "../../tools/authentication";
import { useFormik } from "formik";
import { GetSet } from "../../Constants/Jurisdiction";
import { useHistory } from "react-router";

const LoginFormContainer = ({ isAuthorized }) => {
  //const [doRedirect, setDoRedirect] = useState(false);
  //isAuthorized.set(false);
  const history = useHistory();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { username: "", password: "" },
    onSubmit: async (values, { setSubmitting, setStatus }) => {
      let response = await getAccessToken(values.username, values.password);

      if (
        response.data &&
        response.data.error &&
        response.data.error === "invalid_credentials"
      ) {
        setStatus({ error: response.data.message });
        setSubmitting(false);
      } else {
        setSubmitting(false);
        localStorage.setItem("access_token", response.data.access_token);
        localStorage.setItem("refresh_token", response.data.refresh_token);
        isAuthorized.set(true);
        history.push("/");
      }
    },
  });

  return <LoginFormDisplay formik={formik} />;
};

LoginFormContainer.propTypes = {
  isAuthorized: GetSet,
};

export default LoginFormContainer;
