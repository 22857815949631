import React, { useContext, useState } from "react";
import {
  getCommercialClaimedQueueItems, getCommercialQueueItems
} from "../../Queries/Queries";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@material-ui/core";
import MainContentWidthContext from "../../Context/MainContentWidthContext";
import HeaderTitleContext from "../../Context/HeaderTitleContext";
import QueueContainer from "../Queue/QueueContainer";
import {
  CommercialClaimQueueItem, CommercialReleaseQueueItem
} from "../../Queries/Mutations";

const CommercialQueueContainer = () => {
  // eslint-disable-next-line no-unused-vars
  let [header, setHeader] = useContext(HeaderTitleContext);
  setHeader("Commercial Reports");

  // eslint-disable-next-line no-unused-vars
  const [columns, setColumns] = useState([
    {
      field: "id",
      headerName: "ID",
      width: 230,
      renderCell: Object.assign(
        (params) => {
          return (
            <Link aria-label={`row-id-${params.value}`} component={RouterLink} to={`commercial/order/${params.row.id}`}>
              {params.value}
            </Link>
          );
        },
        { displayName: "renderCell" }
      ),
    },
    {
      field: "order",
      headerName: "Client",
      width: 230,
      valueGetter: (value) => {
        return value.row.client[0].name;
      },
    },
    /*{
      field: "portfolio_name",
      headerName: "Portfolio",
      width: 230,
      valueGetter: (value) => {
        console.log(value);
        return value.value;
      },
    },*/
    {
      field: "name",
      headerName: "Name",
      width: 230,
      valueGetter: (value) => {
        return value?.row?.report_order_items?.[0]?.name;
      },
    },
    {
      field: "Address",
      headerName: "Address",
      width: 230,
      valueGetter: (value) => {
        return value?.row?.report_order_items?.[0]?.address?.address_line1;
      },
    },
    {
      field: "City",
      headerName: "City",
      width: 230,
      valueGetter: (value) => {
        return value?.row?.report_order_items?.[0]?.address?.locality;
      },
    },
    {
      field: "State",
      headerName: "State",
      width: 230,
      valueGetter: (value) => {
        return value?.row?.report_order_items?.[0]?.address?.administrative_area;
      },
    },
    {
      field: "report_order_items",
      headerName: "Jurisdiction",
      width: 230,
      valueGetter: (value) => {
        return value?.value?.[0]?.jurisdiction?.[0]?.name;
      },
    },
    { field: "state", headerName: "Status", width: 230 },
    /*{ field: 'client', headerName: 'Client', width: 230,
      renderCell: Object.assign(params => {
        delete params.row.issues;
        return renderButton(params);
      }, { displayName: 'renderCell' })
    },*/
  ]);

  // eslint-disable-next-line no-unused-vars
  const [mainContentWidth, setMainContentWidth] = useContext(
    MainContentWidthContext
  );
  setMainContentWidth("80");

  return (
    <QueueContainer
      columns={columns}
      type={"Item"}
      itemsQuery={getCommercialQueueItems}
      queryKey={"commercialQueueItems"}
      claimedItemsQuery={getCommercialClaimedQueueItems}
      claimedItemsQueryKey={"commercialClaimedQueueItems"}
      claimQuery={CommercialClaimQueueItem}
      claimKey={"commercialClaimQueueItem.claimed_item.order.id"}
      claimRoute={"/commercial/order"}
      release={CommercialReleaseQueueItem}
      getReleaseKey={(params) => {
        return { variables: { data: { order: [{ id: params.row.id }] } } };
      }}
    />
  );
};

CommercialQueueContainer.propTypes = {};

export default CommercialQueueContainer;
