import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import * as PropTypes from "prop-types";
import WorxAddressField from "../../../Container/Forms/Inputs/WorxAddressField";
import Card from "@material-ui/core/Card";
import WorxTextField from "../../Forms/Inputs/WorxTextField";
import WorxDatePicker from "../../Forms/Inputs/WorxDatePicker";
import {
  AutoCompleteFilter,
  GetSet,
  ListFilter,
} from "../../../Constants/Jurisdiction";
import Button from "@material-ui/core/Button";
import { DataGrid } from "@mui/x-data-grid";
import WorxAutocompleteFormik from "../../Forms/Inputs/WorxAutocompleteFormik";
import OrderItemManagementContainer from "../../../Container/Orders/Forms/OrderItemManagementContainer";
import WorxDialogContainer from "../../../Container/WorxDialogContainer";
import WorxAutocomplete from "../../Forms/Inputs/WorxAutocomplete";

const buttonUseStyles = makeStyles({
  alignHead: {
    display: "tableHeaderGroup",
  },
});

const cardUseStyles = makeStyles({
  worxCardStyleOverride: {
    width: (props) => (props.width > "80" ? "80%" : props.width + "%"),
    padding: "2em",
    marginBottom: "2em",
  },
});

const topCardUseStyles = makeStyles({
  root: {
    "& .MuiDataGrid-cell": {
      whiteSpace: "normal !important",
    },
    marginBottom: "2em",
  },
});

const useStyles = makeStyles({
  worxRadioGroupStyleOverride: {
    marginBottom: "1em",
  },
  worxSpaceBelow: {
    marginBottom: "1em",
  },
  worxSpaceLeft: {
    marginLeft: "1em",
  },
  worxBold: {
    fontWeight: "bold",
  },
  textfieldWrapper: {
    marginBottom: "1em",
  },
  buttonWrapper: {
    marginTop: "1em",
    textAlign: "right",
  },
  orderEditWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  orderEditLeftWrapper: {
    flex: "0 0 75%",
    marginRight: "2em",
  },
  orderTotalRight: {
    textAlign: "right",
  },
  createOrderEditWrapper: {
    display: "flex",
    alignItems: "center",
  },
});

const OrderDisplay = (props) => {
  const {
    columns,
    rows,
    billingProfiles,
    contactProfiles,
    preparedForProfiles,
    adjustmentColumns,
    pageSize,
    rowTotal,
    createOrderOptions,
    edit,
    editOrderItem,
    availableReports,
    initOrderItemValues,
    adjustmentTypeOptions,
    stateTransitionOptions,
    formik,
  } = props;
  const buttonClasses = buttonUseStyles();
  const cardClasses = cardUseStyles();
  const classes = useStyles();
  const topCardClasses = topCardUseStyles();

  return (
    <div className={classes.orderEditWrapper}>
      <div className={classes.orderEditLeftWrapper}>
        <Card className={cardClasses.worxCardStyleOverride}>
          <DataGrid
            classes={{ root: topCardClasses.root }}
            key={rows.length}
            pagination
            rowCount={rowTotal}
            autoHeight={true}
            getRowHeight={() => "auto"}
            columns={columns}
            rows={rows}
            pageSize={pageSize}
            loading={!rows}
            className={classes.worxSpaceBelow}
          />

          {edit.get && (
            <React.Fragment>
              <div className={classes.createOrderEditWrapper}>
                <WorxAutocompleteFormik
                  fieldKey={"create_order_item"}
                  options={createOrderOptions}
                  formik={formik}
                />
                <div className={classes.worxSpaceLeft}>
                  <Button
                    variant={"contained"}
                    color="primary"
                    margin="normal"
                    onClick={() => {
                      editOrderItem.set(true);
                      initOrderItemValues.set({
                        order: [
                          { id: formik.values.id, name: formik.values.name },
                        ],
                        __typename: formik?.values?.create_order_item?.[0]?.id,
                      });
                    }}
                    classes={{ root: buttonClasses.root }}
                  >
                    Add Order Item
                  </Button>
                </div>
              </div>

              {formik.values.state !== "draft" && (
                <React.Fragment>
                  <DataGrid
                    key={
                      formik?.values?.adjustments?.length
                        ? formik?.values?.adjustments?.length
                        : 0
                    }
                    pagination
                    autoHeight={true}
                    getRowHeight={() => "auto"}
                    columns={adjustmentColumns}
                    rows={
                      formik?.values?.adjustments?.length
                        ? formik?.values?.adjustments
                        : []
                    }
                    className={classes.worxSpaceAround}
                  />

                  <div className={classes.createOrderEditWrapper}>
                    <WorxAutocompleteFormik
                      fieldKey={"create_adjustment_item"}
                      options={adjustmentTypeOptions}
                      formik={formik}
                    />
                    <div className={classes.worxSpaceLeft}>
                      <Button
                        variant={"contained"}
                        color="primary"
                        margin="normal"
                        onClick={() => {
                          editOrderItem.set(true);
                          initOrderItemValues.set({
                            update: (data) => {
                              let adj = [...formik.values.adjustments];
                              data.id =
                                "" + data.type + data.title + data.amount;
                              adj.push(data);
                              formik.setFieldValue("adjustments", adj);
                              formik.setFieldValue(
                                "create_adjustment_item",
                                null
                              );
                            },
                            type: formik?.values?.create_adjustment_item?.[0]
                              ?.id,
                            __typename: formik?.values
                              ?.create_adjustment_item?.[0]
                              ? "Adjustment"
                              : "",
                          });
                        }}
                        classes={{ root: buttonClasses.root }}
                      >
                        Add Adjustment
                      </Button>
                    </div>
                  </div>
                </React.Fragment>
              )}

              <WorxTextField
                fieldKey={"client_reference_number"}
                type={"number"}
                formik={formik}
              />
              <WorxTextField fieldKey={"portfolio_name"} formik={formik} />
              <WorxTextField
                fieldKey={"total_orders"}
                label={"Total Orders in Portfolio"}
                type={"number"}
                formik={formik}
              />
              <WorxDatePicker fieldKey={"report_due_date"} formik={formik} />
            </React.Fragment>
          )}

          {!edit.get && (
            <div className={classes.orderTotalRight}>
              <Typography
                variant={"subtitle1"}
                gutterBottom
              >{`Subtotal: ${(+formik.values.subtotal_price).toFixed(
                2
              )}`}</Typography>
              {formik?.values?.adjustments &&
                formik?.values?.adjustments.map((adj) => {
                  return (
                    <Typography
                      key={adj.id}
                      variant={"subtitle1"}
                      gutterBottom
                    >{`${adj.label}: ${(+adj.amount).toFixed(2)}`}</Typography>
                  );
                })}
              <Typography
                variant={"subtitle1"}
                gutterBottom
                className={classes.worxBold}
              >{`Total: ${(+formik.values.total_price).toFixed(
                2
              )}`}</Typography>
            </div>
          )}

          <WorxDialogContainer
            title={
              initOrderItemValues.get.label
                ? initOrderItemValues.get.label
                : initOrderItemValues.get.name
            }
            content={
              <OrderItemManagementContainer
                referenceData={formik.values}
                initValues={initOrderItemValues}
                availableReports={availableReports}
                edit={editOrderItem}
              />
            }
            id={"addLinkForm"}
            open={editOrderItem.get}
            onClose={() => editOrderItem.set(false)}
            onKeyDown={(e) => e.stopPropagation()}
          />
        </Card>
      </div>

      <div>
        <Card className={cardClasses.worxCardStyleOverride}>
          {!edit.get && (
            <React.Fragment>
              <Button
                variant={"contained"}
                color="primary"
                margin="normal"
                onClick={() => {
                  edit.set(true);
                }}
                classes={{ root: buttonClasses.root }}
              >
                Edit
              </Button>

              {stateTransitionOptions.options.list.map((state) => {
                return (
                  <Button
                    key={state.id}
                    variant={"contained"}
                    color="primary"
                    margin="normal"
                    onClick={() => {
                      formik.setFieldValue("state_transition", state.id);
                      formik.handleSubmit();
                    }}
                    classes={{ root: buttonClasses.root }}
                    className={classes.worxSpaceLeft}
                  >
                    {state.label}
                  </Button>
                );
              })}
            </React.Fragment>
          )}
          {edit.get && (
            <React.Fragment>
              <Button
                variant={"contained"}
                color="primary"
                margin="normal"
                onClick={() => {
                  formik.handleSubmit();
                }}
                classes={{ root: buttonClasses.root }}
                className={classes.worxSpaceLeft}
              >
                Submit
              </Button>
              <Button
                variant={"contained"}
                color="primary"
                margin="normal"
                onClick={() => {
                  edit.set(false);
                  formik.handleReset();
                }}
                classes={{ root: buttonClasses.root }}
                className={classes.worxSpaceLeft}
              >
                Cancel
              </Button>
            </React.Fragment>
          )}
          <WorxTextField fieldKey={"state"} disabled={true} formik={formik} />
          <WorxDatePicker
            fieldKey={"placed"}
            disabled={!edit.get}
            formik={formik}
          />
          <WorxTextField
            fieldKey={"last_activity"}
            disabled={true}
            formik={formik}
          />
          <WorxTextField
            fieldKey={"order_balance"}
            disabled={true}
            formik={formik}
          />
        </Card>

        <Card className={cardClasses.worxCardStyleOverride}>
          <WorxTextField
            fieldKey={"client.0.name"}
            disabled={!edit.get}
            label={"Client"}
            formik={formik}
          />
          <WorxTextField
            fieldKey={"company"}
            label={"Company"}
            disabled={!edit.get}
            formik={formik}
          />
          <WorxTextField
            fieldKey={"client.0.mail"}
            label={"Email"}
            disabled={!edit.get}
            formik={formik}
          />
          <WorxTextField
            fieldKey={"contact_information.0.phone"}
            label={"Phone"}
            formik={formik}
            disabled={!edit.get}
          />
        </Card>

        <Card className={cardClasses.worxCardStyleOverride}>
          <Typography variant={"h5"} gutterBottom>
            {"Billing Info"}
          </Typography>
          {edit.get && (
            <WorxAutocomplete
              fieldKey={"billing_information"}
              options={billingProfiles}
              onChange={(event, value) => {
                if (value) {
                  let profileList = billingProfiles.variables.profiles;
                  let profileIndex = profileList.findIndex((profile) => {
                    return profile.id === value.id;
                  });
                  formik.setFieldValue("billing_information", [
                    profileList[profileIndex],
                  ]);
                }
              }}
            />
          )}
          <WorxAddressField
            fieldKey={"billing_information.0.address"}
            disabled={!edit.get}
            formik={formik}
          />
        </Card>

        <Card className={cardClasses.worxCardStyleOverride}>
          <Typography variant={"h5"} gutterBottom>
            {"Contact Info"}
          </Typography>
          {edit.get && (
            <WorxAutocomplete
              fieldKey={"contact_information"}
              options={contactProfiles}
              onChange={(event, value) => {
                if (value) {
                  let profileList = contactProfiles.variables.profiles;
                  let profileIndex = profileList.findIndex((profile) => {
                    return profile.id === value.id;
                  });
                  formik.setFieldValue("contact_information", [
                    profileList[profileIndex],
                  ]);
                }
              }}
            />
          )}
          <WorxAddressField
            fieldKey={"contact_information.0.address"}
            disabled={!edit.get}
            formik={formik}
          />
        </Card>

        <Card className={cardClasses.worxCardStyleOverride}>
          <Typography variant={"h5"} gutterBottom>
            {"Prepared For"}
          </Typography>
          {edit.get && (
            <WorxAutocomplete
              fieldKey={"prepared_for"}
              options={preparedForProfiles}
              onChange={(event, value) => {
                if (value) {
                  let profileList = preparedForProfiles.variables.profiles;
                  let profileIndex = profileList.findIndex((profile) => {
                    return profile.id === value.id;
                  });
                  formik.setFieldValue("prepared_for", [
                    profileList[profileIndex],
                  ]);
                }
              }}
            />
          )}
          <WorxAddressField
            fieldKey={"prepared_for.0.address"}
            disabled={!edit.get}
            formik={formik}
          />
        </Card>
      </div>
    </div>
  );
};

/*
{open: contact, options: {
            list: formik?.values?.client?.[0]?.id && clients.options.list.findIndex(client => {
              return client.id === formik?.values?.client?.[0]?.id
            }) >= 0 ? clients.options.list[clients.options.list.findIndex(client => {
              return client.id === formik?.values?.client?.[0]?.id
            })].contact_information.map(contact => {
              return {id: contact.id, name: contact?.address?.address_line1 ? contact?.address?.address_line1 : contact.phone};
            }) : [], filter: () => {}}}}
                          onChange={(event, value) => {
                            let clientList = clients.options.list;
                            let clientIndex = clientList.findIndex(client => {
                              return client.id === formik?.values?.client?.[0]?.id
                            });
                            let profileList = clientList[clientIndex].contact_information;
                            let profileIndex = profileList.findIndex(profile => {
                              return profile.id === value.id;
                            });
                            formik.setFieldValue('billing_information', [profileList[profileIndex]]);
                          }
 */

OrderDisplay.propTypes = {
  columns: PropTypes.object.isRequired,
  rows: PropTypes.object.isRequired,
  billingProfiles: ListFilter,
  contactProfiles: ListFilter,
  preparedForProfiles: ListFilter,
  adjustmentColumns: PropTypes.object.isRequired,
  pageFilters: GetSet,
  pageSize: GetSet,
  rowTotal: PropTypes.number,
  edit: GetSet,
  createOrderOptions: AutoCompleteFilter,
  editOrderItem: GetSet,
  availableReports: GetSet,
  adjustmentTypeOptions: AutoCompleteFilter,
  stateTransitionOptions: AutoCompleteFilter,
  initOrderItemValues: GetSet,
  formik: PropTypes.object.isRequired,
};

export default OrderDisplay;
