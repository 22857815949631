import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import JurisdictionFormFiveDisplay from "../../../../Display/DataEntry/DataEntryOne_MainWorkflow/Forms/JurisdictionFormFiveDisplay";
import { GetSet } from "../../../../Constants/Jurisdiction";
import {
  getCategoryOptions,
  getUsesForJurisdiction,
} from "../../../../Queries/Queries";
import {
  checkUseDuplicate,
  createUse,
  editUse,
} from "../../../../Queries/Mutations";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "@material-ui/core/Button";
import WorxDeleteButton from "../../../Forms/Inputs/WorxDeleteButton";

const JurisdictionFormFiveContainer = (props) => {
  const { data } = props;
  const [addUses, setAddUses] = useState(false);
  const [initValues, setInitValues] = useState({});
  const [categoryOpen, setCategoryOpen] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const id = data.get.id;
  const pageSize = 25;
  const [filters, setFilters] = useState({
    limit: pageSize,
    offset: 0,
    operator: "CONTAINS",
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [filterUses, { data: usesQuery }] = useLazyQuery(
    getUsesForJurisdiction(data.get.id, pageSize),
    {
      fetchPolicy: "cache-and-network",
    }
  );
  const [filterCategoryOptions, { data: categoryOptionsQuery }] = useLazyQuery(
    getCategoryOptions(data.get.id, "use_list", "", 50)
  );
  /*const [UseValidation, {data: validationQuery}] = useLazyQuery(getUsesForJurisdiction(data.get.id), {
    fetchPolicy: 'no-cache',
  });*/
  const [checkUseName] = useMutation(checkUseDuplicate());
  const validateName = async (variables) => {
    // eslint-disable-next-line no-unused-vars
    const thing = await checkUseName({ variables: { data: variables } });
    if (thing) {
      return thing;
    } else {
      return null;
    }
  };

  const [doCreateUse] = useMutation(createUse(), {
    refetchQueries: [
      { query: getUsesForJurisdiction(id, pageSize), variables: filters },
    ],
  });
  const [doEditUse] = useMutation(editUse(), {
    refetchQueries: [
      { query: getUsesForJurisdiction(id, pageSize), variables: filters },
    ],
  });

  useEffect(() => {
    filterUses();
  }, []);

  useEffect(() => {
    if (usesQuery) {
      setRowCount(usesQuery.uses.count);
    }
  }, [usesQuery]);

  let defaultUse = {
    name: "",
    description: "",
    category: [{ id: 0, name: "-Select-" }],
    certificate_required: true,
    code_location: "",
    weight: 0,
    button: null,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...defaultUse, ...initValues, button: null },
    validationSchema: Yup.object({
      name: Yup.string().test(
        "name",
        "This name is already in use.",
        async (value) => {
          if (initValues.id && value === initValues.name) {
            return true;
          } else {
            const rValue = await validateName({
              name: value ?? "",
              jurisdiction: [{ id: data.get.id }],
            });
            if (rValue?.data?.checkUseDuplicate?.exists) {
              return false;
            } else {
              return true;
            }
          }
        }
      ),
    }),
    onSubmit: (values, formikTools) => {
      // eslint-disable-next-line no-unused-vars
      let { button, code_file, parking, ...use } = values;
      switch (button) {
        case "normal":
          //doUploadFile({variables: { file: code_file }});
          mutateUse(use);
          setAddUses(false);
          break;
        case "repeat":
          mutateUse(use);
          break;
        case "cancel":
          setAddUses(false);
          break;
      }
      setInitValues({});
      formikTools.resetForm();
    },
  });

  const mutateUse = (use) => {
    // Do this to get the payload in the right shape
    // eslint-disable-next-line no-unused-vars
    let { new: isNew, edit, type, rowIndex, ...payload } = use;
    payload.jurisdiction = [
      {
        id: data.get.id,
      },
    ];
    if (payload.id) {
      doEditUse({ variables: { data: payload } });
    } else {
      // take the temp id out of the payload
      // eslint-disable-next-line no-unused-vars
      let { id, ...createPayload } = payload;
      doCreateUse({ variables: { data: createPayload } }).then();
      setRowCount(rowCount + 1);
    }
  };

  const columns = [
    { field: "name", headerName: "Name", width: 400 },
    { field: "certificate_required", headerName: "Certificate", width: 200 },
    { field: "code_location", headerName: "Location", width: 200 },
    {
      field: "Operation",
      headerName: "Operation",
      width: 200,
      renderCell: Object.assign(
        (params) => {
          return (
            <React.Fragment>
              <WorxDeleteButton
                callback={(response) => {
                  if (response?.deleteEntity?.errors?.length <= 0) {
                    filterUses({ variables: filters });
                  }
                }}
                type={"jur_use"}
                id={params.row.id}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setAddUses(true);
                  setInitValues(params.row);
                }}
              >
                edit
              </Button>
            </React.Fragment>
          );
        },
        { displayName: "renderCell" }
      ),
    },
  ];

  return (
    <JurisdictionFormFiveDisplay
      addUses={{ get: addUses, set: setAddUses }}
      initValues={{ get: initValues, set: setInitValues }}
      uses={{
        list: usesQuery?.uses?.items,
        filter: filterUses,
      }}
      columns={columns}
      categoryOptions={{
        open: { get: categoryOpen, set: setCategoryOpen },
        options: {
          list: categoryOptionsQuery?.categories?.items
            ? categoryOptionsQuery.categories.items
            : [],
          filter: filterCategoryOptions,
        },
      }}
      pageSize={pageSize}
      rowCount={rowCount}
      filters={{ get: filters, set: setFilters }}
      currentPage={{ get: currentPage, set: setCurrentPage }}
      formik={formik}
    />
  );
};

JurisdictionFormFiveContainer.propTypes = {
  data: GetSet,
};

export default JurisdictionFormFiveContainer;
