import React, { useEffect } from "react";
import * as PropTypes from "prop-types";
import WorxFormContainer from "../../Forms/Generic/WorxFormContainer";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { createSurveyField, editSurveyField } from "../../../Queries/Mutations";

const JuniorAnalystSurveyFieldFormContainer = (props) => {
  const { options, initValues = {}, callback } = props;
  // eslint-disable-next-line no-unused-vars
  const [doCreateSurveyField, { data: surveyResponse }] = useMutation(
    createSurveyField()
  );
  const [doEditSurveyField, { data: editSurveyResponse }] = useMutation(
    editSurveyField()
  );

  let defaultValues = { __typename: "SurveyField" };

  useEffect(() => {
    if (surveyResponse) {
      callback(surveyResponse.createSurveyField.survey_field);
    }
  }, [surveyResponse]);

  useEffect(() => {
    if (editSurveyResponse) {
      callback(editSurveyResponse.editSurveyField.survey_field);
    }
  }, [editSurveyResponse]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...defaultValues, ...initValues },
    validationSchema: Yup.object({}),
    // eslint-disable-next-line no-unused-vars
    onSubmit: (values, formikTools) => {
      if (values.id) {
        doEditSurveyField({ variables: { data: values } });
      } else {
        doCreateSurveyField({ variables: { data: values } });
      }
    },
  });

  return <WorxFormContainer options={options} formik={formik} />;
};

JuniorAnalystSurveyFieldFormContainer.propTypes = {
  options: PropTypes.object.isRequired,
  initValues: PropTypes.object,
  callback: PropTypes.func,
};

export default JuniorAnalystSurveyFieldFormContainer;
