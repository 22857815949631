import React, { useContext, useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  getJurisdictionOptions,
  getOrders,
  getProperties,
  getUsers,
} from "../../../Queries/Queries";
import AddNewOrderDisplay from "../../../Display/Orders/Forms/AddNewOrderDisplay";
import HeaderTitleContext from "../../../Context/HeaderTitleContext";
import { createOrder } from "../../../Queries/Mutations";

const AddNewOrderContainer = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { data } = props;
  // eslint-disable-next-line no-unused-vars
  let [header, setHeader] = useContext(HeaderTitleContext);
  setHeader("Add New Order");

  const [initValues, setInitValues] = useState({});

  const [jurisdictionOpen, setJurisdictionOpen] = useState(false);
  const [filterJurisdictions, { data: jurisdictionsQuery }] = useLazyQuery(
    getJurisdictionOptions(undefined, "", 50)
  );
  const [jurisdictions, setJurisdictions] = useState([]);

  const [propertyOpen, setPropertyOpen] = useState(false);
  const [filterProperties, { data: propertiesQuery }] = useLazyQuery(
    getProperties()
  );
  const [properties, setProperties] = useState([]);

  const [usersOpen, setUsersOpen] = useState(false);
  const [filterUsers, { data: usersQuery }] = useLazyQuery(
    getUsers("customer")
  );
  const [users, setUsers] = useState([]);

  const [contactOpen, setContactOpen] = useState(false);
  const [billingOpen, setBillingOpen] = useState(false);
  const [prepForOpen, setPrepForOpen] = useState(false);

  const [fixedPropertyFilters, setFixedPropertyFilters] = useState({});

  const siteInfoOptions = [
    { id: "order", name: "Order" },
    { id: "proposal", name: "Proposal" },
  ];

  const productSelections = [
    {
      id: "zoning_conformance_report",
      name: "Zoning Conformance Report",
      info: "$800",
    },
    {
      id: "zoning_conformance_summary",
      name: "Zoning Conformance Summary",
      info: "$575",
    },
    { id: "appraisal_report", name: "Appraisal Report", info: "$xxx" },
  ];

  const billingSelections = [
    {
      id: "contact",
      name: "Billing Information is Same as Contact Information",
    },
  ];

  const preparedForSelections = [
    { id: "contact", name: "Prepared For is Same as Contact Information" },
    { id: "billing", name: "Prepared For is Same as Billing Information" },
  ];

  //mutations
  // eslint-disable-next-line no-unused-vars
  const [doCreateOrder] = useMutation(createOrder(), {
    refetchQueries: [{ query: getOrders() }],
  });

  let defaultValues = {};

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...defaultValues, ...initValues },
    validationSchema: Yup.object({}),
    onSubmit: (values, formikTools) => {
      // eslint-disable-next-line no-unused-vars
      /* eslint-disable no-unused-vars */
      const {
        billing_selections,
        prepared_for_selections,
        product_selections,
        site_information,
        site_information_options,
        ...payload
      } = values;
      /* eslint-disable no-unused-vars */
      doCreateOrder({ variables: { data: payload } });
      setInitValues({});
      formikTools.resetForm();
    },
  });

  useEffect(() => {
    filterJurisdictions();
    filterUsers();
  }, []);

  useEffect(() => {
    if (jurisdictionsQuery?.jurisdictions?.items) {
      setJurisdictions(jurisdictionsQuery?.jurisdictions?.items);
    }
  }, [jurisdictionsQuery]);

  useEffect(() => {
    if (usersQuery?.users?.items) {
      setUsers(usersQuery?.users?.items);
    }
  }, [usersQuery]);

  useEffect(() => {
    if (formik?.values?.report_order_items?.[0]?.jurisdiction) {
      setFixedPropertyFilters({
        jurisdiction:
          formik?.values?.report_order_items?.[0]?.jurisdiction?.[0]?.id,
      });
      filterProperties({
        variables: { offset: 0, limit: 50, ...fixedPropertyFilters },
      });
    }
  }, [formik?.values?.report_order_items?.[0]?.jurisdiction]);

  useEffect(() => {
    if (propertiesQuery?.properties?.items) {
      setProperties(propertiesQuery?.properties?.items);
    }
  }, [propertiesQuery]);

  useEffect(() => {
    if (formik.values.billing_selections) {
      // eslint-disable-next-line no-unused-vars
      let { id, type, ...info } = formik.values.contact_information[0];
      formik.setFieldValue("billing_information", [
        { ...info, type: "customer" },
      ]);
    }
  }, [formik.values.billing_selections]);

  useEffect(() => {
    if (formik.values.prepared_for_selections === "contact") {
      // eslint-disable-next-line no-unused-vars
      let { id, type, ...info } = formik.values.contact_information[0];
      formik.setFieldValue("prepared_for", [{ ...info, type: "prepared_for" }]);
    } else if (formik.values.prepared_for_selections === "billing") {
      // eslint-disable-next-line no-unused-vars
      let { id, ...info } = formik.values.billing_information;
      formik.setFieldValue("prepared_for", [{ ...info, type: "prepared_for" }]);
    }
  }, [formik.values.prepared_for_selections]);

  useEffect(() => {
    if (formik.values.client) {
      formik.setFieldValue("contact_information", [
        {
          ...formik.values?.contact_information?.[0],
          user: formik.values.client,
        },
      ]);
      formik.setFieldValue("billing_information", [
        {
          ...formik.values?.billing_information?.[0],
          user: formik.values.client,
        },
      ]);
      formik.setFieldValue("prepared_for", [
        { ...formik.values?.prepared_for?.[0], user: formik.values.client },
      ]);
    }
  }, [formik.values.client]);

  return (
    <AddNewOrderDisplay
      clients={{
        open: { get: usersOpen, set: setUsersOpen },
        options: {
          list: users,
          filter: filterUsers,
        },
      }}
      jurisdictions={{
        open: { get: jurisdictionOpen, set: setJurisdictionOpen },
        options: {
          list: jurisdictions,
          filter: filterJurisdictions,
        },
      }}
      properties={{
        open: { get: propertyOpen, set: setPropertyOpen },
        options: {
          list: properties,
          filter: filterProperties,
        },
        variables: {
          filters: { get: fixedPropertyFilters, set: setFixedPropertyFilters },
        },
      }}
      contact={{ get: contactOpen, set: setContactOpen }}
      billing={{ get: billingOpen, set: setBillingOpen }}
      prepForOpen={{ get: prepForOpen, set: setPrepForOpen }}
      siteInfoOptions={siteInfoOptions}
      productSelections={productSelections}
      billingSelections={billingSelections}
      preparedForSelections={preparedForSelections}
      initValues={{ get: initValues, set: setInitValues }}
      formik={formik}
    />
  );
};

AddNewOrderContainer.propTypes = {
  data: PropTypes.any.isRequired,
};

export default AddNewOrderContainer;
