/* @todo make enum */
/*
  string
  number
  decimal
  address
  textArea
  select //entity reference
  stringSelect //string array
  list
  list_create
  multi_select
  grid
  label
*/

export const FormMaps = {
  Order: {
    client_reference_number: "number",
    id: "number",
    order_balance: "decimal",
    portfolio_name: "string",
  },
  EmailTemplate: {
    id: "number",
    name: "string",
    check_payable: "address",
    contact: "select",
    cost_per: "decimal",
    estimate_of_time: "decimal",
    instructions: "textArea",
    jurisdiction: "select",
    research_documents: "list",
    email_subject: "string",
    email_body: "textArea",
  },
  FaxTemplate: {
    id: "number",
    name: "string",
    check_payable: "address",
    contact: "select",
    cost_per: "decimal",
    estimate_of_time: "decimal",
    instructions: "textArea",
    jurisdiction: "select",
    research_documents: "list",
    email_subject: "string",
    email_body: "textArea",
  },
  OnlineTemplate: {
    id: "number",
    name: "string",
    cost_per: "decimal",
    estimate_of_time: "decimal",
    instructions: "textArea",
    jurisdiction: "select",
    research_documents: "list",
  },
  PhoneTemplate: {
    id: "number",
    name: "string",
    cost_per: "decimal",
    estimate_of_time: "decimal",
    instructions: "textArea",
    jurisdiction: "select",
    research_documents: "list",
    contact: "select",
  },
  RunnerTemplate: {
    id: "number",
    name: "string",
    check_payable: "address",
    runner_payable: "address",
    contact: "select",
    runner_contact: "select",
    cost_per: "decimal",
    estimate_of_time: "decimal",
    instructions: "textArea",
    runner_instructions: "textArea",
    jurisdiction: "select",
    research_documents: "list",
  },
  USPSTemplate: {
    id: "number",
    name: "string",
    check_payable: "address",
    contact: "select",
    cost_per: "decimal",
    estimate_of_time: "decimal",
    instructions: "textArea",
    jurisdiction: "select",
    research_documents: "list",
    letter_body: "textArea",
  },
  document_uploader: {
    document: "document",
  },
  building_code_violation: {
    bv_violations: "select",
    bv_violations_other: "string",
    document: "document",
    letter_date: "date",
    notes: "textArea",
    property: "select",
  },
  business_license: {
    document: "document",
    letter_date: "date",
    notes: "textArea",
    property: "select",
  },
  certificate_of_occupancy: {
    document: "document",
    co_number: "string",
    co_date: "date",
    notes: "textArea",
    parking_required: "boolean",
    square_footage: "number",
    tenant: "select",
    property: "select",
    use: "select",
  },
  condemnation: {
    cn_plans: "select",
    cn_plans_other: "string",
    document: "document",
    letter_date: "date",
    notes: "textArea",
    property: "select",
  },
  conditional_use_permit: {
    document: "document",
    letter_date: "date",
    notes: "textArea",
    property: "select",
    permit_number: "string",
  },
  fire_code_violation: {
    fv_violations: "select",
    fv_violations_other: "string",
    document: "document",
    letter_date: "date",
    notes: "textArea",
    property: "select",
    permit_number: "string",
  },
  "historical_/_landmark": {
    historical_landmark: "select",
    document: "document",
    letter_date: "date",
    notes: "textArea",
    property: "select",
  },
  special_use_permit: {
    document: "document",
    letter_date: "date",
    notes: "textArea",
    property: "select",
    permit_number: "string",
  },
  variance: {
    document: "document",
    letter_date: "date",
    notes: "textArea",
    property: "select",
    permit_number: "string",
  },
  zoning_code_violation: {
    zv_violations: "select",
    zv_violations_other: "string",
    document: "document",
    letter_date: "date",
    notes: "textArea",
    property: "select",
  },
  zoning_letter: {
    document: "document",
    letter_date: "date",
    notes: "textArea",
    property: "select",
  },
  requirementLink: {
    use: "select",
    code_location: "textArea",
    footnote: "multi_select",
    minimum: "number",
    maximum: "number",
    weight: "number",
  },
  useLink: {
    status: "select",
    footnote: "multi_select",
  },
  unit_mix: {
    name: "string",
    //property: 'select',
    unit_mix_document: "document",
    provided_by: "string",
    number_of_dwelling_units_per_building: "number",
    number_of_dwelling_units: "number",
    floor_area: "decimal",
    studio: "number",
    studio_sqft: "decimal",
    studio_handicap: "number",
    studio_handicap_sqft: "decimal",
    studio_affordable: "number",
    studio_affordable_sqft: "decimal",
    one_bedroom: "number",
    one_bedroom_sqft: "decimal",
    one_bedroom_handicap: "number",
    one_bedroom_handicap_sqft: "decimal",
    one_bedroom_affordable: "number",
    one_bedroom_affordable_sqft: "decimal",
    two_bedroom: "number",
    two_bedroom_sqft: "decimal",
    two_bedroom_handicap: "number",
    two_bedroom_handicap_sqft: "decimal",
    two_bedroom_affordable: "number",
    two_bedroom_affordable_sqft: "decimal",
    three_bedroom: "number",
    three_bedroom_sqft: "decimal",
    three_bedroom_handicap: "number",
    three_bedroom_handicap_sqft: "decimal",
    three_bedroom_affordable: "number",
    three_bedroom_affordable_sqft: "decimal",
    four_bedroom: "number",
    four_bedroom_sqft: "decimal",
    four_bedroom_handicap: "number",
    four_bedroom_handicap_sqft: "decimal",
    four_bedroom_affordable: "number",
    four_bedroom_affordable_sqft: "decimal",
    five_bedroom: "number",
    five_bedroom_sqft: "decimal",
    five_bedroom_handicap: "number",
    five_bedroom_handicap_sqft: "decimal",
    five_bedroom_affordable: "number",
    five_bedroom_affordable_sqft: "decimal",
  },
  tenant_list: {
    name: "string",
    //property: 'select',
    tenant_list_document: "document",
    provided_by: "string",
    commercial_use_area: "decimal",
    ground_floor_non_residential_area: "decimal",
    ground_floor_area: "decimal",
    residential_floor_area: "decimal",
    tenants: "list_create",
  },
  tenant: {
    name: "string",
  },
  SurveyField: {
    label: "string",
    value: "number",
  },
  analyst_report: {
    status: "select",
    name: "string",
    "property.0.address": "address",
    "order.0.prepared_for": "select",
    "order.0.client.0.name": "string",
    reliance_language: "textArea",
  },
  Property: {
    parcel_tax_id: "string",
    property_type: "select",
    zone: "multi_select",
  },
  AnalystConformanceForm: {
    use_conformance: "select",
    lot_size_density_conformance: "select",
    structure_conformance: "select",
    parking_conformance: "select",
    conformance_notes: "textArea",
    deficiencies: "textArea",
  },
  AnalystReconstructionForm: {
    reconstruction_clause: "textArea",
    reconstruction_notes: "textArea",
  },
  AnalystRequirementsForm: {
    "requirements_answers.requirements": "grid",
    requirements_notes: "textArea",
  },
  AnalystCOfOForm: {
    cofo_answers: "grid",
    "cofo_answers.0.unit_mix_aux.rows": "grid",
    cofo_notes: "textArea",
  },
  AnalystMunicipalDocumentsForm: {
    "municipal_documents.zoning_violations.title": "label",
    "municipal_documents.zoning_violations.conformance": "stringSelect",
    "municipal_documents.zoning_violations.other": "string",
    "municipal_documents.zoning_violations.document_link": "document",
    "municipal_documents.zoning_violations.notes": "textArea",

    "municipal_documents.building_violations.title": "label",
    "municipal_documents.building_violations.conformance": "stringSelect",
    "municipal_documents.building_violations.other": "string",
    "municipal_documents.building_violations.document_link": "document",
    "municipal_documents.building_violations.notes": "textArea",

    "municipal_documents.fire_code_violations.title": "label",
    "municipal_documents.fire_code_violations.conformance": "stringSelect",
    "municipal_documents.fire_code_violations.other": "string",
    "municipal_documents.fire_code_violations.document_link": "document",
    "municipal_documents.fire_code_violations.notes": "textArea",

    "municipal_documents.condemnation.title": "label",
    "municipal_documents.condemnation.conformance": "stringSelect",
    "municipal_documents.condemnation.other": "string",
    "municipal_documents.condemnation.document_link": "document",
    "municipal_documents.condemnation.notes": "textArea",

    "municipal_documents.business_license.title": "label",
    "municipal_documents.business_license.conformance": "stringSelect",
    "municipal_documents.business_license.other": "string",
    "municipal_documents.business_license.document_link": "document",
    "municipal_documents.business_license.notes": "textArea",

    "municipal_documents.historical_landmark.title": "label",
    "municipal_documents.historical_landmark.conformance": "stringSelect",
    "municipal_documents.historical_landmark.other": "string",
    "municipal_documents.historical_landmark.document_link": "document",
    "municipal_documents.historical_landmark.notes": "textArea",
  },
  AnalystParkingForm: {
    "parking_answers.regular_spaces": "grid",
    "parking_answers.handicap_spaces": "grid",
    parking_notes: "textArea",
  },
  requirementsMatrix: {
    use: "select",
    code_location: "string",
    footnote: "multi_select",
    minimum: "number",
    maximum: "number",
  },
  useMatrix: {
    status: "select",
    footnote: "multi_select",
  },
  fannie: {
    lenderInformation: {
      dus_lender_name: "string",
      third_party_report_request_name: "string",
    },

    reportInformation: {
      third_party_report_type: "string",
      third_part_report_company_name: "string",
      third_part_report_signatory_name: "string",
      third_part_report_assessor_name: "string",
      third_part_report_signature_date: "date",
      third_part_report_project_identifier: "string",
      third_part_report_scope_standard: "string",
      third_part_report_scope_standard_description: "string",
    },

    propertyLocation: {
      zip_code: "string",
      state: "string",
      //city:
      property_address: "string",
    },

    propertyDetails: {
      property_name: "string",
      jurisdiction: "string",
      property_land_area_in_acres: "string",
    },

    assessmentDetails: {
      zoning_designation: "string",
      existing_land_use_conformance: "select",
      existing_building_set_back_lines_conformance: "select",
      existing_building_size_conformance: "select",
      existing_building_density_converage_conformance: "select",
      number_of_required_parking_spaces: "string",
      number_of_existing_parking_spaces: "string",
      existing_parking_conformance: "select",
    },

    siteHistory: {
      any_special_zoning_permitting_or_conditions: "select",
      recorded_zoning_violation: "select",
      recorded_building_code_violation: "select",
      recorded_fire_code_violation: "select",
      available_certificates_of_occupancy: "select",
    },

    conclusions: {
      concluding_zoning_conformance_status: "select",
      zoning_nonconforming_characteristics: "string",
      zoning_recommended_action: "string",
      rebuildability_clause: "string",
      property_damage_threshold_percent: "string",
    },

    fannieMaeIdentifiers: {
      deal_identifier: "string",
      collateral_reference_number: "string",
    }

  },
  newProperty: {
    "name": "string",
    address: "address",
    zone: "select",
  },
  verificationForm: {
    task_description: "textArea"
  }
};
