import React from "react";
import * as PropTypes from "prop-types";
import { Card, CardContent } from "@material-ui/core";
import WorxFormContainer from "../../../../Container/Forms/Generic/WorxFormContainer";

const UseCellFormDisplay = (props) => {
  const { options, buttons, formik } = props;

  return (
    <Card raised={true}>
      <CardContent>
        <WorxFormContainer
          options={options}
          formik={formik}
          buttons={buttons}
        />
      </CardContent>
    </Card>
  );
};

UseCellFormDisplay.propTypes = {
  options: PropTypes.object,
  buttons: PropTypes.array,
  formik: PropTypes.object,
};

export default UseCellFormDisplay;
