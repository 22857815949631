import React from "react";
import * as PropTypes from "prop-types";
import { AutoCompleteFilter } from "../../../Constants/Jurisdiction";
import WorxAutocomplete from "./WorxAutocomplete";

const WorxAutocompleteFormik = (props) => {
  let {
    formik,
    fieldKey,
    nameKey = "name",
    label,
    disabled = false,
    options,
  } = props;

  let value = formik.values;

  for (let el of fieldKey.split(".")) {
    if (value && value?.[el]) {
      value = value?.[el];
    } else {
      value = null;
    }
  }

  let fixedVariables = {};
  if (options?.variables?.filters) {
    // eslint-disable-next-line no-unused-vars
    fixedVariables = options?.variables?.filters.get;
  }

  return (
    <div>
      <WorxAutocomplete
        fieldKey={fieldKey}
        nameKey={nameKey}
        label={label}
        disabled={disabled}
        options={options}
        getOptionSelected={(option, value) => option?.value === value?.value}
        value={value?.[0]}
        onInputChange={(e, value) => {
          let newFilter = { [nameKey]: value, ...fixedVariables };
          options.options.filter({
            variables: { ...newFilter, operator: "CONTAINS" },
          });
          if (options?.variables?.updateFixedVariables) {
            options?.variables?.filters.set(newFilter);
          }
        }}
        onChange={(event, value) => {
          if (value) {
            formik.setFieldValue(fieldKey, [
              { id: value.id, [nameKey]: value[nameKey] },
            ]);
          } else {
            formik.setFieldValue(fieldKey, "");
          }
        }}
      />
      {formik.touched[fieldKey] && formik.errors[fieldKey] ? (
        <div style={{ color: "red" }}>{"Required"}</div>
      ) : null}
    </div>
  );
};

WorxAutocompleteFormik.propTypes = {
  options: AutoCompleteFilter,
  label: PropTypes.string,
  fieldKey: PropTypes.string.isRequired,
  nameKey: PropTypes.string,
  disabled: PropTypes.bool,
  formik: PropTypes.object.isRequired,
};

export default WorxAutocompleteFormik;
