import React from "react";
import * as PropTypes from "prop-types";
import { makeStyles, Typography } from "@material-ui/core";
import WorxRadio from "../../Forms/Inputs/WorxRadio";
import { GetSet, AutoCompleteFilter } from "../../../Constants/Jurisdiction";
import WorxDatePicker from "../../Forms/Inputs/WorxDatePicker";
import WorxMediaUpload from "../../../Container/Forms/Inputs/WorxMediaUpload";
import WorxTextField from "../../Forms/Inputs/WorxTextField";
import WorxAddressField from "../../../Container/Forms/Inputs/WorxAddressField";
import Button from "@material-ui/core/Button";
import WorxAutocompleteFormik from "../../Forms/Inputs/WorxAutocompleteFormik";
import WorxAutocomplete from "../../Forms/Inputs/WorxAutocomplete";

const useStyles = makeStyles({
  buttonWrapper: {
    textAlign: "right",
  },
});

// eslint-disable-next-line no-unused-vars
const radioGroupStyles = makeStyles({
  root: {
    marginBottom: "1em",
  },
});

const buttonUseStyles = makeStyles({
  root: {
    marginLeft: "1em",
  },
});

const AddNewOrderDisplay = (props) => {
  /* eslint-disable no-unused-vars */
  let {
    jurisdictions,
    clients,
    contact,
    billing,
    prepForOpen,
    properties,
    siteInfoOptions,
    productSelections,
    billingSelections,
    preparedForSelections,
    initValues,
    formik,
  } = props;
  /* eslint-enable no-unused-vars */

  // eslint-disable-next-line no-unused-vars
  const classes = useStyles(),
    buttonClasses = buttonUseStyles()

  //site information addr, Property uses, Parcel Tax/ID, Construction date, Floor area, number of dwelling units, email, refered by.
  return (
    <React.Fragment>
      <WorxTextField fieldKey={"portfolio_name"} formik={formik} />
      <WorxTextField
        fieldKey={"client_reference_number"}
        label={"Client Reference Number"}
        formik={formik}
      />
      <WorxTextField
        fieldKey={"total_orders"}
        label={"Total Orders In Portfolio"}
        type={"number"}
        formik={formik}
      />

      <WorxTextField
        fieldKey={"report_order_items.0.name"}
        label={"Site Name"}
        formik={formik}
      />
      <WorxAutocompleteFormik
        fieldKey={"client"}
        options={clients}
        formik={formik}
      />
      <WorxAutocompleteFormik
        fieldKey={"report_order_items.0.jurisdiction"}
        label={"Jurisdiction"}
        options={jurisdictions}
        formik={formik}
      />
      <WorxAutocompleteFormik
        fieldKey={"report_order_items.0.property"}
        label={"Property"}
        options={properties}
        formik={formik}
      />

      <WorxDatePicker fieldKey={"report_due_date"} formik={formik} />

      <Typography variant={"h5"}>Contact Information</Typography>

      <WorxAutocomplete
        fieldKey={"contact_information"}
        options={{
          open: contact,
          options: {
            list:
              formik?.values?.client?.[0]?.id &&
              clients.options.list.findIndex((client) => {
                return client.id === formik?.values?.client?.[0]?.id;
              }) >= 0
                ? clients.options.list[
                    clients.options.list.findIndex((client) => {
                      return client.id === formik?.values?.client?.[0]?.id;
                    })
                  ]?.contact_information?.map((contact) => {
                    return {
                      id: contact.id,
                      name: contact?.address?.address_line1
                        ? contact?.address?.address_line1
                        : contact.phone,
                    };
                  })
                : [],
            filter: () => {},
          },
        }}
        onChange={(event, value) => {
          if (value) {
            let clientList = clients.options.list;
            let clientIndex = clientList.findIndex((client) => {
              return client.id === formik?.values?.client?.[0]?.id;
            });
            let profileList = clientList[clientIndex].contact_information;
            let profileIndex = profileList.findIndex((profile) => {
              return profile.id === value.id;
            });
            // eslint-disable-next-line no-unused-vars
            let { user, ...newValue } = profileList[profileIndex];
            formik.setFieldValue("contact_information", [newValue]);
          }
        }}
      />
      <WorxAddressField
        fieldKey={"contact_information.0.address"}
        initEdit={true}
        formik={formik}
      />

      <WorxTextField
        fieldKey={"contact_information.0.phone"}
        label={"Phone"}
        type={"phone"}
        formik={formik}
      />
      <WorxTextField
        fieldKey={"contact_information.0.fax"}
        label={"Fax"}
        type={"phone"}
        formik={formik}
      />

      <Typography variant={"h5"}>Billing Information</Typography>
      <WorxRadio
        fieldKey={"billing_selections"}
        options={billingSelections}
        formik={formik}
      />

      <WorxAutocomplete
        fieldKey={"billing_information"}
        options={{
          open: billing,
          options: {
            list:
              formik?.values?.client?.[0]?.id &&
              clients.options.list.findIndex((client) => {
                return client.id === formik?.values?.client?.[0]?.id;
              }) >= 0
                ? clients.options.list[
                    clients.options.list.findIndex((client) => {
                      return client.id === formik?.values?.client?.[0]?.id;
                    })
                  ].billing_information.map((contact) => {
                    return {
                      id: contact.id,
                      name: contact?.address?.address_line1
                        ? contact?.address?.address_line1
                        : contact.phone,
                    };
                  })
                : [],
            filter: () => {},
          },
        }}
        onChange={(event, value) => {
          if (value) {
            let clientList = clients.options.list;
            let clientIndex = clientList.findIndex((client) => {
              return client.id === formik?.values?.client?.[0]?.id;
            });
            let profileList = clientList[clientIndex].billing_information;
            let profileIndex = profileList.findIndex((profile) => {
              return profile.id === value.id;
            });
            formik.setFieldValue("billing_information", [
              profileList[profileIndex],
            ]);
          }
        }}
      />
      <WorxAddressField
        fieldKey={"billing_information.0.address"}
        initEdit={true}
        formik={formik}
      />

      <WorxTextField
        fieldKey={"billing_information.0.phone"}
        label={"Phone"}
        type={"phone"}
        formik={formik}
      />
      <WorxTextField
        fieldKey={"billing_information.0.fax"}
        label={"Fax"}
        type={"phone"}
        formik={formik}
      />

      <Typography variant={"h5"}>Prepared For</Typography>
      <WorxRadio
        fieldKey={"prepared_for_selections"}
        options={preparedForSelections}
        formik={formik}
      />

      <WorxAutocomplete
        fieldKey={"prepared_for"}
        options={{
          open: prepForOpen,
          options: {
            list:
              formik?.values?.client &&
              clients.options.list.findIndex((client) => {
                return client.id === formik?.values?.client?.[0]?.id;
              }) >= 0
                ? clients.options.list[
                    clients.options.list.findIndex((client) => {
                      return client.id === formik?.values?.client?.[0]?.id;
                    })
                  ].prepared_for.map((contact) => {
                    return {
                      id: contact.id,
                      name: contact?.address?.address_line1
                        ? contact?.address?.address_line1
                        : contact.phone,
                    };
                  })
                : [],
            filter: () => {},
          },
        }}
        onChange={(event, value) => {
          if (value) {
            let clientList = clients.options.list;
            let clientIndex = clientList.findIndex((client) => {
              return client.id === formik?.values?.client?.[0]?.id;
            });
            let profileList = clientList[clientIndex].prepared_for;
            let profileIndex = profileList.findIndex((profile) => {
              return profile.id === value.id;
            });
            formik.setFieldValue("prepared_for", [profileList[profileIndex]]);
          }
        }}
      />
      <WorxAddressField
        fieldKey={"prepared_for.0.address"}
        initEdit={true}
        formik={formik}
      />

      <WorxTextField
        fieldKey={"prepared_for.0.phone"}
        label={"Phone"}
        type={"phone"}
        formik={formik}
      />
      <WorxTextField
        fieldKey={"prepared_for.0.fax"}
        label={"Fax"}
        type={"phone"}
        formik={formik}
      />

      <Typography variant={"h5"}>Additional Files</Typography>
      <Typography variant={"h5"}>
        In Order to complete a report, you must supply Zoning Info, Inc. with a
        copy of an ALTA Survery and a Tenant List/Rent Roll or Unit Mix. When a
        Use requires seating, such as a Theater or Testurant, please provide a
        seat count for parking requirements. When a use is Warehouse or
        Industrial, please provide an employee count for parking requirements.
        Additional information may be required.
      </Typography>

      <WorxMediaUpload
        fieldKey={"report_order_items.0.client_documents"}
        label={"Upload Files"}
        type={"document"}
        entityType={"media"}
        jurisdiction={
          formik?.values?.report_order_items?.[0]?.jurisdiction?.[0]?.id
        }
        property={formik?.values?.report_order_items?.[0]?.property?.[0]?.id}
        formik={formik}
      />

      <Button
        variant={"contained"}
        color="primary"
        margin="normal"
        onClick={() => {
          formik.handleSubmit();
        }}
        classes={{ root: buttonClasses.root }}
      >
        Submit
      </Button>
    </React.Fragment>
  );
};

AddNewOrderDisplay.propTypes = {
  clients: AutoCompleteFilter,
  jurisdictions: AutoCompleteFilter,
  properties: AutoCompleteFilter,
  contact: GetSet,
  billing: GetSet,
  prepForOpen: GetSet,
  siteInfoOptions: PropTypes.array.isRequired,
  productSelections: PropTypes.array.isRequired,
  billingSelections: PropTypes.array.isRequired,
  preparedForSelections: PropTypes.array.isRequired,
  initValues: GetSet,
  formik: PropTypes.object.isRequired,
};

export default AddNewOrderDisplay;
