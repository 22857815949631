import React, { useContext, useEffect, useState } from "react";
// eslint-disable-next-line no-unused-vars
import * as PropTypes from "prop-types";
import { useLazyQuery, useMutation } from "@apollo/client";
import { getAnalystReport } from "../../Queries/Queries";
import { useFormik } from "formik";
import * as Yup from "yup";
import AnalystReconstructionDisplay from "../../Display/Analyst/AnalystReconstructionDisplay";
import { editAnalystReport } from "../../Queries/Mutations";
import MessageContext from "../../Context/MessageContext";

const AnalystReconstructionContainer = (props) => {
  const { id } = props;

  const [getReport, { data: report }] = useLazyQuery(getAnalystReport(id));

  // eslint-disable-next-line no-unused-vars
  const [initValues, setInitValues] = useState({});

  const [doEditReport, { data: response }] = useMutation(editAnalystReport());
  // eslint-disable-next-line no-unused-vars
  const [message, setMessage] = useContext(MessageContext);

  // setup
  useEffect(() => {
    getReport();
    setMessage(null);
  }, []);

  // report
  useEffect(() => {
    if (report) {
      setInitValues({
        ...report.analystReport,
        __typename: "AnalystReconstructionForm",
      });
    }
  }, [report]);

  // response
  useEffect(() => {
    if (response) {
      setInitValues({
        ...response?.editAnalystReport.analyst_report,
        __typename: "AnalystReconstructionForm",
      });
    }
  }, [response]);

  const defaultValues = {};

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...defaultValues, ...initValues },
    validationSchema: Yup.object({}),
    // eslint-disable-next-line no-unused-vars
    onSubmit: (values, formikTools) => {
      // eslint-disable-next-line no-unused-vars
      let { conformance_report_order_item, ...payload } = values;
      doEditReport({ variables: { data: payload } });
      setMessage("✓ Reconstruction Saved");
    },
  });

  return <AnalystReconstructionDisplay options={{}} id={id} formik={formik} />;
};

AnalystReconstructionContainer.propTypes = {
  id: PropTypes.number.isRequired,
  page: PropTypes.string,
};

export default AnalystReconstructionContainer;
