import React from "react";
import TextField from "@material-ui/core/TextField/TextField";
import * as PropTypes from "prop-types";
import { camelToTitle, underscoreToCamel } from "../../../tools/stringTools";
import { FormControl, FormLabel } from "@material-ui/core";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  textField: {
    marginLeft: "20px",
  },
  textFieldError: {
    marginLeft: "20px",
    color: "red",
  },
}));

const WorxLink = (props) => {
  let { formik, type, disabled = false, fieldKey } = props;
  const classes = useStyles();
  return (
    <React.Fragment>
      <div>
        <FormControl component="fieldset" margin="normal">
          <FormLabel>{camelToTitle(underscoreToCamel(fieldKey))}</FormLabel>
          <TextField
            className={classes.textField}
            disabled={disabled}
            margin="dense"
            label="Url"
            id={fieldKey + ".url"}
            value={formik.values[fieldKey].url}
            placeholder={camelToTitle(underscoreToCamel(fieldKey))}
            type={type ? type : "text"}
            onChange={formik.handleChange}
          />
          {formik.errors[fieldKey] ? (
            <div className={classes.textFieldError}>
              {formik.errors[fieldKey].url}
            </div>
          ) : null}
          <TextField
            className={classes.textField}
            disabled={disabled}
            margin="dense"
            label="Link Text"
            id={fieldKey + ".link_text"}
            value={formik.values[fieldKey].link_text}
            placeholder={camelToTitle(underscoreToCamel(fieldKey))}
            type={type ? type : "text"}
            onChange={formik.handleChange}
          />
          {formik.errors[fieldKey] ? (
            <div className={classes.textFieldError}>
              {formik.errors[fieldKey].link_text}
            </div>
          ) : null}
        </FormControl>
      </div>
    </React.Fragment>
  );
};

WorxLink.propTypes = {
  fieldKey: PropTypes.string.isRequired,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  formik: PropTypes.object.isRequired,
};

export default WorxLink;
