// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";
import * as PropTypes from "prop-types";
import { camelToTitle, underscoreToCamel } from "../../../tools/stringTools";
import { makeStyles, Typography } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";

const topCardUseStyles = makeStyles({
  root: {
    "& .MuiDataGrid-cell": {
      whiteSpace: "normal !important",
    },
    marginBottom: "2em",
  },
});

const WorxList = (props) => {
  /* eslint-disable no-unused-vars */
  let {
    fieldKey,
    columns,
    disabled = false,
    label = camelToTitle(underscoreToCamel(fieldKey)),
    formik,
  } = props;
  /* eslint-disable no-unused-vars */

  const topCardClasses = topCardUseStyles();

  let listValues = formik.values;

  for (let el of fieldKey.split(".")) {
    if (listValues && listValues?.[el]) {
      listValues = listValues?.[el];
    } else {
      listValues = [];
    }
  }

  return (
    <React.Fragment>
      <Typography variant={"body1"} gutterBottom>
        {label}
      </Typography>
      <DataGrid
        classes={{ root: topCardClasses.root }}
        autoHeight={true}
        getRowHeight={() => "auto"}
        columns={columns}
        rows={listValues}
      />
    </React.Fragment>
  );
};

WorxList.propTypes = {
  fieldKey: PropTypes.string.isRequired,
  columns: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.object,
  setInitValues: PropTypes.func,
  formik: PropTypes.object.isRequired,
};

export default WorxList;
