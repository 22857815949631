import React from "react";
import * as PropTypes from "prop-types";
import WorxFormContainer from "../../Container/Forms/Generic/WorxFormContainer";

const AnalystReconstructionDisplay = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { id, nameOverrides = {}, options, formik } = props;

  return (
    <React.Fragment>
      <WorxFormContainer
        formik={formik}
        nameOverride={nameOverrides}
        options={options}
      />
    </React.Fragment>
  );
};

AnalystReconstructionDisplay.propTypes = {
  id: PropTypes.number,
  options: PropTypes.object,
  nameOverrides: PropTypes.object,
  formik: PropTypes.object,
};

export default AnalystReconstructionDisplay;
