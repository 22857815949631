import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { useMutation, useQuery } from "@apollo/client";
import { getCodeSections } from "../../../../Queries/Queries";
import {
  createCodeSection,
  editCodeSection,
} from "../../../../Queries/Mutations";
import JurisdictionFormCodeDisplay from "../../../../Display/DataEntry/DataEntryOne_MainWorkflow/Forms/JurisdictionFormCodeDisplay";
import { GetSet } from "../../../../Constants/Jurisdiction";
import { useFormik } from "formik";
import * as Yup from "yup";

const JurisdictionFormCodeContainer = (props) => {
  let { data, codeType } = props;

  let [addCode, setAddCode] = useState(false);
  //let [newCode, setNewCode] = useState({});
  //let [isNew, setIsNew] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const { loading, data: codeQuery } = useQuery(
    getCodeSections(data.get.id, codeType.id)
  );
  const [initValues, setInitValues] = useState({ code_section_type: codeType });
  const [doEditCode] = useMutation(editCodeSection(), {
    refetchQueries: [{ query: getCodeSections(data.get.id, codeType.id) }],
  });
  const [doCreateCode] = useMutation(createCodeSection(), {
    refetchQueries: [{ query: getCodeSections(data.get.id, codeType.id) }],
  });

  useEffect(() => {
    if (codeQuery?.codeSections?.items?.[0]) {
      setInitValues(codeQuery.codeSections.items[0]);
    }
  }, [codeQuery]);

  let defaultCode = {
    name: "",
    code: "",
    code_date: null,
    code_file: [{ id: 0, filepath: "", filename: "" }],
    button: null,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...defaultCode, ...initValues, button: null },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      /*code: Yup.string().required('Required'),
      code_date: Yup.string().required('Required'),*/
    }),
    onSubmit: (values) => {
      // eslint-disable-next-line no-unused-vars
      let { button, code_section_type, ...code } = values;
      mutateCode(code);
    },
  });

  const mutateCode = (code) => {
    // Do this to get the payload in the right shape
    // eslint-disable-next-line no-unused-vars
    code.jurisdiction = [
      {
        id: data.get.id,
      },
    ];
    code.code_section_type = codeType.id;
    if (code.id) {
      doEditCode({ variables: { data: code } });
    } else {
      doCreateCode({ variables: { data: code } });
    }
  };

  return (
    <JurisdictionFormCodeDisplay
      loading={loading}
      codeType={codeType}
      isEdit={{ get: isEdit, set: setIsEdit }}
      addCode={{ get: addCode, set: setAddCode }}
      formik={formik}
    />
  );
};

JurisdictionFormCodeContainer.propTypes = {
  data: GetSet,
  codeType: PropTypes.object.isRequired,
  code: GetSet,
};

export default JurisdictionFormCodeContainer;
