import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
// eslint-disable-next-line no-unused-vars
import {worker} from "./mocks/browser";
import AppWithDevTools from "./AppWithDevTools";
import {BrowserRouter} from "react-router-dom";

//if (process.env.REACT_APP_USE_DEVTOOLS === 'Y') {
  //const { worker } = useWorker()

  /*require('./mocks/browser')
  worker.start();*/
//}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      {process.env.REACT_APP_USE_DEVTOOLS === 'Y' ? <AppWithDevTools /> : <App />}
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);




// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
