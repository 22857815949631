import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  createZoneRequirementLink,
  editZoneRequirementLink,
} from "../../../../Queries/Mutations";
import {
  getFootnotes,
  getUsesForJurisdiction,
} from "../../../../Queries/Queries";
import { GetSet } from "../../../../Constants/Jurisdiction";
import RequirementsCellFormDisplay from "../../../../Display/DataEntry/DataEntryThree_RequirmentsWorkflow/Forms/RequirementsCellFormDisplay";
import WorxDeleteButton from "../../../Forms/Inputs/WorxDeleteButton";

const RequirementsCellFormContainer = (props) => {
  const { initValues, jurisdictionID, filters, requery, grid, closeForm } =
    props;

  //formState
  const [useOptionsOpen, setUseOptionsOpen] = useState(false);
  const [footnoteOpen, setFootnoteOpen] = useState(false);

  //queries
  const [filterUses, { data: useQuery }] = useLazyQuery(
    getUsesForJurisdiction(jurisdictionID)
  );
  const [filterFootnotes, { data: footnotesQuery }] = useLazyQuery(
    getFootnotes(jurisdictionID, "requirement_footnote")
  );

  //mutations
  const [doCreateLink] = useMutation(createZoneRequirementLink(), requery);
  const [doEditLink] = useMutation(editZoneRequirementLink(), requery);

  let defaultLink = {
    name: "",
    code_location: "",
    footnote: [],
    jurisdiction: [{ id: jurisdictionID }],
    maximum: null,
    minimum: null,
    __typename: "requirementsMatrix",
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...defaultLink, ...initValues.get, button: null },
    validationSchema: Yup.object({}),
    onSubmit: (values, formikTools) => {
      // eslint-disable-next-line no-unused-vars
      let { button, ...link } = values;
      modifyCell(link);
      formikTools.resetForm();
    },
  });

  let modifyCell = (link) => {
    if (link.minimum === "") {
      link.minimum = null;
    }
    if (link.maximum === "") {
      link.maximum = null;
    }
    if (link.minimum) {
      link.minimum = +link.minimum;
    }
    if (link.maximum) {
      link.maximum = +link.maximum;
    }
    if (link.id) {
      doEditLink({ variables: { data: link } });
    } else {
      doCreateLink({ variables: { data: link } });
    }
    closeForm();
  };

  const buttons = [
    formik.values.id && (
      <WorxDeleteButton
        key={"delete"}
        callback={(response) => {
          closeForm();
          if (response?.deleteEntity?.errors?.length <= 0) {
            grid.filter({ variables: filters.get });
          }
        }}
        requery={requery}
        type={"zone_requirement_link"}
        id={formik.values.id}
      />
    ),
  ];

  useEffect(() => {
    filterUses();
    filterFootnotes();
  }, []);

  return (
    <RequirementsCellFormDisplay
      options={{
        use: {
          open: { get: useOptionsOpen, set: setUseOptionsOpen },
          options: {
            list: useQuery?.uses?.items ? useQuery.uses.items : [],
            filter: filterUses,
          },
        },
        footnote: {
          open: { get: footnoteOpen, set: setFootnoteOpen },
          options: {
            list: footnotesQuery?.footnotes?.items
              ? footnotesQuery.footnotes.items
              : [],
            filter: filterFootnotes,
          },
          variables: {
            nameKey: "name",
          },
        },
      }}
      buttons={buttons}
      formik={formik}
    />
  );
};

RequirementsCellFormContainer.propTypes = {
  initValues: PropTypes.object,
  jurisdictionID: PropTypes.number,
  filters: GetSet,
  requery: PropTypes.func,
  grid: PropTypes.object,
  closeForm: PropTypes.func,
};

export default RequirementsCellFormContainer;
