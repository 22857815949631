import React, { useContext } from "react";
import * as PropTypes from "prop-types";
import HeaderTitleContext from "../../Context/HeaderTitleContext";
import {
  getClaimedQueueItems,
  getQueueItemsShort,
} from "../../Queries/Queries";
import { claimQueueItem, releaseQueueItem } from "../../Queries/Mutations";
import QueueContainer from "../Queue/QueueContainer";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import ContentPreview from "../Tools/ContentPreview/ContentPreview";

const DataEntryContainer = (props) => {
  const { queue } = props;

  // eslint-disable-next-line no-unused-vars
  const [headerTitle, setHeaderTitle] = useContext(HeaderTitleContext);
  const history = useHistory();

  switch (queue) {
    case "main_workflow":
      setHeaderTitle("Data Entry 1");
      break;
    case "use_workflow":
      setHeaderTitle("Data Entry 2");
      break;
    case "requirements_workflow":
      setHeaderTitle("Data Entry 3");
      break;
  }

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 70,
      renderCell: Object.assign(
        (params) => {
          return (
            <Button
              onClick={() => {
                history.push({
                  pathname:
                    "/data_entry/" + queue + "/jurisdiction/" + params.row.id,
                  state: { data: params.row },
                });
              }}
            >
              {params.row.id}
            </Button>
          );
        },
        { displayName: "renderCell" }
      ),
    },
    { field: "name", headerName: "Name", width: 300 },
    {
      field: "task_description",
      headerName: "Task Description",
      width: 150,
      renderCell: Object.assign(
        (params) => {
          console.log(params)
          return (
            <ContentPreview availableSpace={18} content={params.value} />
          );
        },
        { displayName: "renderCell" }
      ),
    },
    { field: "type", headerName: "Type", width: 230 },
  ];

  const fixedFilters = { queue: queue };

  return (
    <QueueContainer
      key={queue}
      columns={columns}
      fixedFilters={fixedFilters}
      type={"Jurisdiction"}
      itemsQuery={() => getQueueItemsShort(queue)}
      queryKey={"queueItems"}
      claimedItemsQuery={() => getClaimedQueueItems(queue)}
      claimedItemsQueryKey={"claimedQueueItems"}
      claimQuery={() => claimQueueItem(queue)}
      claimKey={"claimQueueItem.claimed_item.jurisdiction.id"}
      claimRoute={`/data_entry/${queue}/jurisdiction`}
      release={releaseQueueItem}
      getReleaseKey={(params) => {
        return {
          variables: {
            data: { queue: queue, jurisdiction: [{ id: params?.row?.id }] },
          },
        };
      }}
      paginationMode={"server"}
    />
  );
};

DataEntryContainer.propTypes = {
  queue: PropTypes.string,
};

export default DataEntryContainer;
