import React from "react";
import * as PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { makeStyles, Typography } from "@material-ui/core";
import { AutoCompleteFilter } from "../../../Constants/Jurisdiction";
import { DataGrid } from "@mui/x-data-grid";
import TextField from "@material-ui/core/TextField/TextField";
import WorxFileUpload from "../../../Container/Forms/Inputs/WorxFileUpload";
import { camelToTitle, underscoreToCamel } from "../../../tools/stringTools";
import WorxAutocompleteFormik from "./WorxAutocompleteFormik";
import WorxDialogContainer from "../../../Container/WorxDialogContainer";

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles({
  fileWrapper: {
    marginBottom: "1em",
  },
});

const topCardUseStyles = makeStyles({
  root: {
    "& .MuiDataGrid-cell": {
      whiteSpace: "normal !important",
    },
    marginBottom: "2em",
  },
});

const WorxMediaUploadDisplay = (props) => {
  let {
    formik,
    type,
    hideLink = false,
    entityType,
    fieldKey,
    tail,
    value,
    disabled = false,
    buttonOnly = false,
    label = camelToTitle(underscoreToCamel(fieldKey)),
    media,
    mediaTypeOptions,
    createMedia,
  } = props;

  const topCardClasses = topCardUseStyles();

  let pageSize = 25;

  let mediaField = "field_media_" + type;

  let selected = [];
  if (value) {
    selected = value.map((val) => val.id);
  }

  return (
    <div data-testid={"mediaUpload"}>
      {!buttonOnly && (
        <React.Fragment>
          <div>
            <Typography className={"label"} variant={"h6"} gutterBottom>
              {label}
            </Typography>
          </div>
          {value &&
            !hideLink &&
            value.map((val) => {
              return (
                <div key={val?.id}>
                  <a
                    href={
                      val?.image
                        ? val?.image?.[0]?.filepath
                        : val?.document?.[0]?.filepath
                    }
                    target={"_blank"}
                    rel={"noreferrer"}
                  >
                    {val?.name}
                  </a>
                </div>
              );
            })}
        </React.Fragment>
      )}
      {!disabled && (
        <Button
          variant={"contained"}
          color="secondary"
          margin="none"
          id={"add-media"}
          onClick={() => {
            media.open.set(true);
          }}
        >
          Add Media
        </Button>
      )}

      <WorxDialogContainer
        id={"media-" + fieldKey}
        open={media.open.get}
        onClose={() => {
          media.open.set(false);
        }}
        onKeyDown={(e) => e.stopPropagation()}
        content={
          <React.Fragment>
            <WorxFileUpload
              mediaField={mediaField}
              fieldKey={"field_media_" + tail}
              entityType={entityType}
              formik={formik}
              type={type}
            />
            {type === "document" && (
              <WorxAutocompleteFormik
                fieldKey={"field_media_" + tail + "_type"}
                options={mediaTypeOptions}
                formik={formik}
              />
            )}
            {formik.values["field_media_" + tail] && (
              <Button
                variant={"contained"}
                color="secondary"
                margin="none"
                id={"create-media"}
                onClick={() => {
                  createMedia();
                }}
              >
                Create New Media
              </Button>
            )}
            <TextField
              margin="none"
              id={"name"}
              placeholder={"Name"}
              onChange={(event) => {
                let nextFilter = {
                  ...media.variables.filters.get,
                  name: event.target.value,
                  offset: 0,
                };
                media.options.filter({
                  variables: { name: event.target.value, offset: 0 },
                });
                media.variables.filters.set(nextFilter);
                media.variables.currentPage.set(0);
              }}
            />
            <DataGrid
              classes={{ root: topCardClasses.root }}
              key={media.variables.count.get}
              page={media.variables.currentPage.get}
              pagination
              paginationMode="server"
              rowCount={media.variables.count.get}
              pageSize={pageSize}
              onPageChange={(page) => {
                let nextFilter = {
                  ...media.variables.filters.get,
                  offset: pageSize * page,
                };
                media.options.filter({ variables: nextFilter });
                media.variables.filters.set(nextFilter);
                media.variables.currentPage.set(page);
              }}
              onSelectionModelChange={(newSelection) => {
                let nextSelection = newSelection.map((sel) => {
                  let index = media.options.list.findIndex(
                    (item) => item.id === sel
                  );
                  if (index >= 0) {
                    //if the item is not found then it is on a different page. we will add the latter. Set them to null for now.
                    if (media.options.list[index]) {
                      //return a deep copy
                      return JSON.parse(
                        JSON.stringify(media.options.list[index])
                      );
                    }
                  }
                  return null;
                });
                // get rid of null(items for other pages);
                nextSelection = nextSelection.filter((sel) => sel !== null);
                if (value) {
                  value.forEach((val) => {
                    let index = nextSelection.findIndex((sel) => {
                      if (sel) {
                        return sel.id === val.id;
                      }
                    });
                    // this should add all items from other pages.
                    if (index < 0) {
                      nextSelection.push(JSON.parse(JSON.stringify(val)));
                    }
                  });
                }

                //look for removals
                media.options.list.forEach((med) => {
                  let nsIndex = nextSelection.findIndex(
                    (ns) => ns.id === med.id
                  );
                  let smIndex = newSelection.findIndex((sm) => sm === med.id);
                  if (smIndex < 0 && nsIndex >= 0) {
                    nextSelection.splice(nsIndex, 1);
                  }
                });

                if (JSON.stringify(nextSelection) !== JSON.stringify(value)) {
                  formik.setFieldValue(fieldKey, nextSelection);
                }
              }}
              selectionModel={selected}
              loading={!media.options.list}
              columns={media.variables.columns}
              rows={media.options.list ? media.options.list : []}
              checkboxSelection={true}
              disableMultipleSelection={true}
              autoHeight
              getRowHeight={() => "auto"}
            />
            <Button
              variant={"contained"}
              color="secondary"
              margin="none"
              id={"add-media"}
              onClick={() => {
                media.open.set(false);
              }}
            >
              Done
            </Button>
          </React.Fragment>
        }
      />

      {/*<Dialog
      id={'media-' + fieldKey}
      open={media.open.get}
      onClose={() => {media.open.set(false)}}
      onKeyDown={(e) => e.stopPropagation()}
    >

    </Dialog>*/}
    </div>
  );
};

WorxMediaUploadDisplay.propTypes = {
  fieldKey: PropTypes.string.isRequired,
  buttonOnly: PropTypes.bool,
  tail: PropTypes.string.isRequired,
  value: PropTypes.array,
  type: PropTypes.string,
  label: PropTypes.string,
  entityType: PropTypes.string.isRequired,
  hideLink: PropTypes.bool,
  formik: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  media: AutoCompleteFilter,
  mediaTypeOptions: AutoCompleteFilter,
  createMedia: PropTypes.func,
};

export default WorxMediaUploadDisplay;
