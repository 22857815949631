import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import WorxDeleteButtonDisplay from "../../../Display/Forms/Inputs/WorxDeleteButtonDisplay";
import { useMutation } from "@apollo/client";
import { deleteEntity } from "../../../Queries/Mutations";

const WorxDeleteButton = (props) => {
  const { callback, requery = {}, type, id } = props;

  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [deleteData, setDeleteData] = useState(null);

  const [doDeleteCategory, { data: deleteResponse }] = useMutation(
    deleteEntity(),
    requery
  );

  useEffect(() => {
    if (deleteResponse && deleteResponse?.deleteEntity?.errors?.length <= 0) {
      callback(deleteResponse);
    }
  }, [deleteResponse]);

  return (
    <WorxDeleteButtonDisplay
      deleteConfirm={{
        get: deleteConfirm,
        set: setDeleteConfirm,
        return: (toDoOrNotToDo) => {
          if (toDoOrNotToDo) {
            setDeleteConfirm(false);
            doDeleteCategory({ variables: deleteData });
            setDeleteData(null);
          } else {
            setDeleteConfirm(false);
            setDeleteData(null);
          }
        },
      }}
      deleteData={{ get: deleteData, set: setDeleteData }}
      type={type}
      id={id}
    />
  );
};

WorxDeleteButton.propTypes = {
  callback: PropTypes.func,
  requery: PropTypes.object,
  type: PropTypes.string,
  id: PropTypes.number,
};

export default WorxDeleteButton;
