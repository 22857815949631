import React, {useEffect, useState} from "react";
import * as PropTypes from "prop-types";
import {useLazyQuery, useMutation} from "@apollo/client";
import {
  getCommercialClaimedQueueItems,
  getCommercialOrder,
} from "../../Queries/Queries";
import {Link} from "@material-ui/core";
import {Link as RouterLink} from "react-router-dom";
import {Button} from "@mui/material";
import {
  CommercialReleaseQueueItem, generateCommercialReport,
} from "../../Queries/Mutations";
import {testMode} from "../../Constants/Values";
import {useHistory} from "react-router";
import CommercialDisplay
  from "../../Display/CommercialReport/CommercialDisplay";

const CommercialContainer = (props) => {
  const {id} = props;

  const history = useHistory();
  const [items, setItems] = useState([]);
  const [lastDocument, setLastDocument] = useState(null);

  const [getReport, {loading, data: report}] = useLazyQuery(getCommercialOrder());

  const [release, {data: released}] = useMutation(CommercialReleaseQueueItem(), {
    refetchQueries: [
      { query: getCommercialClaimedQueueItems() },
    ],
  });
  const [doGenerateReport, {data: theDocument}] = useMutation(generateCommercialReport());

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 230,
      renderCell: Object.assign(
        (params) => {
          return (
            <Link aria-label={`row-id-${params.value}`} component={RouterLink} to={`/commercial/order/${id}/order-item/${params.value}`}>
              {params.value}
            </Link>
          );
        },
        { displayName: "renderCell" }
      ),
    },
    {
      field: "order",
      headerName: "Client",
      width: 230,
      valueGetter: () => {
        return report?.commercialOrder?.client?.[0]?.name;
      },
    },
    {
      field: "address",
      headerName: "Address",
      width: 230,
      valueGetter: (value) => {
        return value?.value?.address_line1;
      },
    },
    /*{
      field: "jurisdiction",
      headerName: "Jurisdiction",
      width: 230,
      valueGetter: (value) => {
        return value?.row?.property?.[0]?.jurisdiction?.[0]?.name;
      },
    },*/
    {
      field: "zone",
      headerName: "Zone",
      width: 230,
      valueGetter: (value) => {
        return value?.row?.property?.[0]?.zone?.[0]?.name;
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 230,
      valueGetter: (value) => {
        return value?.value;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 230,
      renderCell: Object.assign(
        (value) => {
          switch(value.row.status) {
            case 'pending':
              return <Button variant="contained" color="primary" onClick={() => {
                history.push(`/commercial/order/${id}/order-item/${value.row.id}`)
              }} >{"Needs Property"}</Button>;
            case 'working':
              return <>
                <Button variant="contained" color="primary" onClick={() => {
                  console.log(value);
                  let payload = {variables: {property: value.row.property[0].id, type: value.row.report_type}};
                  if (testMode) {
                    payload.variables.test = true;
                  }
                  doGenerateReport(payload)
                }} >{"Get Report"}</Button>
                <Button variant="contained" color="primary" onClick={() => {
                  history.push(`/commercial/order/${id}/order-item/${value.row.id}`)
                }} >{"Edit"}</Button>
              </>
          }
        },
        { displayName: "renderCell" }
      ),
    },
  ]

  useEffect(() => {
    getReport({variables: {id: id}});
  }, [])

  useEffect(() => {
    if(report) {
      setItems(report.commercialOrder.report_order_items)
    }
  }, [report])

  useEffect(() => {
    if(theDocument) {
      if(theDocument !== lastDocument) {
        setLastDocument(theDocument);
        window.open(theDocument.generateCommercialReport.report.document[0].filepath, "_blank");
      }
    }
  }, [theDocument])

  useEffect(() => {
    if(released) {
      history.push(`/commercial`);
    }
  }, [released])

  return <CommercialDisplay id={id} columns={columns} rows={{list: items}} loading={loading} release={release} />
}

CommercialContainer.propTypes = {
  id: PropTypes.number.isRequired,
}

export default CommercialContainer;
