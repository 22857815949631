import React from "react";
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import Card from "@material-ui/core/Card";
import { PropTypes } from "prop-types";

const MainContentWrapper = styled.div`
  align-items: center;
  background: ${(props) => props.theme.defaultBkgColor};
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`;

const CardBase = styled(Card)`
  padding: 0.5em 1.5em 1.5em 1.5em;
  width: 500px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1em;
`;

const LoginFormDisplay = (props) => {
  let { formik } = props;
  return (
    <MainContentWrapper>
      <CardBase>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            id="username"
            label="Username"
            defaultValue={formik.values.username}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            fullWidth={true}
            margin={"normal"}
          />
          <TextField
            id="password"
            label="Password"
            type="password"
            defaultValue={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            fullWidth={true}
            margin={"normal"}
          />
          <ButtonWrapper>
            <Button variant="contained" type="submit">
              Login
            </Button>
          </ButtonWrapper>
        </form>
      </CardBase>
    </MainContentWrapper>
  );
};

LoginFormDisplay.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default LoginFormDisplay;
