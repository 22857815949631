import React from "react";
import { PropTypes } from "prop-types";
import { DataGrid } from "@mui/x-data-grid";
import useTableFragment from "../Hooks/useTableFragment";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import Card from "@material-ui/core/Card";
import { CardContent } from "@material-ui/core";
import { makeStyles } from "@mui/styles";
import { Context } from "../Constants/Jurisdiction";

const cardUseStyles = makeStyles({
  worxCardStyleOverride: {
    width: (props) => (props.width > "80" ? "80%" : props.width + "%"),
  },
});

const buttonUseStyles = makeStyles({
  root: {
    marginBottom: "1em",
    marginRight: "1em",
  },
});

const topCardUseStyles = makeStyles({
  root: {
    "& .MuiDataGrid-cell": {
      whiteSpace: "normal !important",
    },
    marginBottom: "2em",
  },
});

const JurisdictionsDisplay = (props) => {
  const {
    jurisdictions,
    type,
    claimJurisdiction,
    claimUse,
    claimRequirements,
    userContext,
  } = props;
  const [locality] = useTableFragment("Locality");
  const [subdivision] = useTableFragment("Subdivision");
  const [administrativeArea] = useTableFragment("AdministrativeArea");
  const [country] = useTableFragment("Country");
  const history = useHistory();

  const topCardClasses = topCardUseStyles();

  const map = {
    locality: locality,
    subdivision: subdivision,
    administrative_area: administrativeArea,
    country: country,
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 70,
      renderCell: Object.assign(
        (params) => {
          return (
            <Button
              onClick={() => {
                history.push({
                  pathname: "/jurisdictions/create",
                  state: { data: params.row },
                });
              }}
            >
              {params.row.id}
            </Button>
          );
        },
        { displayName: "renderCell" }
      ),
    },
    { field: "name", headerName: "Name", width: 230 },
    { field: "type", headerName: "Type", width: 230 },
  ];

  if (type !== "All") {
    columns.push({
      field: "link",
      headerName: "Link",
      width: 230,
      renderCell: Object.assign(
        (params) => {
          return map[params.row.type].renderCell(params);
        },
        { displayName: "renderCell" }
      ),
    });
  }

  const cardClasses = cardUseStyles(props);
  const buttonClasses = buttonUseStyles();

  return (
    <Card raised={true} className={cardClasses.worxCardStyleOverride}>
      <CardContent>
        {userContext[0].roles && userContext[0].roles.includes("data_entry_1") && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => claimJurisdiction()}
            classes={{ root: buttonClasses.root }}
          >
            Claim Jurisdiction
          </Button>
        )}
        {userContext[0].roles && userContext[0].roles.includes("data_entry_2") && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => claimUse()}
            classes={{ root: buttonClasses.root }}
          >
            Claim Use
          </Button>
        )}
        {userContext[0].roles && userContext[0].roles.includes("data_entry_3") && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => claimRequirements()}
            classes={{ root: buttonClasses.root }}
          >
            Claim Requirement
          </Button>
        )}
        <DataGrid
          classes={{ root: topCardClasses.root }}
          autoHeight={true}
          getRowHeight={() => "auto"}
          columns={columns}
          rows={jurisdictions}
          pageSize={10}
          checkboxSelection={false}
        />
      </CardContent>
    </Card>
  );
};

JurisdictionsDisplay.propTypes = {
  jurisdictions: PropTypes.any.isRequired,
  type: PropTypes.string,
  claimJurisdiction: PropTypes.func.isRequired,
  claimUse: PropTypes.func.isRequired,
  claimRequirements: PropTypes.func.isRequired,
  userContext: Context,
};

export default JurisdictionsDisplay;
