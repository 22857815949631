import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  getJurisdictionOptions,
  getJurisdictions,
  getJurisdictionTypeOptions,
} from "../../../../Queries/Queries";
import JurisdictionFormOneDisplay from "../../../../Display/DataEntry/DataEntryOne_MainWorkflow/Forms/JurisdictionFormOneDisplay";
import { GetSet, subJurisdictionMap } from "../../../../Constants/Jurisdiction";
import { useFormik } from "formik";
import * as Yup from "yup";
import { editJurisdiction } from "../../../../Queries/Mutations";
//import {useHistory} from "react-router-dom";

const JurisdictionFormOneContainer = (props) => {
  let { data } = props;
  let type = data.get?.__typename;
  const [isEdit, setIsEdit] = useState(false);
  const [typeOpen, setTypeOpen] = useState(false);
  const [subOpen, setSubOpen] = useState(false);
  const [typeOptions, setTypeOptions] = useState([]);
  const [subOptions, setSubOptions] = useState([]);

  const [doEditJurisdiction] = useMutation(
    editJurisdiction(data.get.__typename),
    {
      refetchQueries: [{ query: getJurisdictions(type) }],
    }
  );
  const [getTypeOptions, { data: typeQuery }] = useLazyQuery(
    getJurisdictionTypeOptions(type, "", 50)
  );
  const [getSubOptions, { data: subQuery }] = useLazyQuery(
    getJurisdictionOptions(subJurisdictionMap[type], "", 50)
  );

  useEffect(() => {
    if (typeQuery) {
      setTypeOptions(
        typeQuery[`${data.get.__typename.toLowerCase()}TypeOptions`].items
      );
    }
  }, [typeQuery]);

  useEffect(() => {
    if (subQuery) {
      setSubOptions(subQuery.jurisdictions.items);
    }
  }, [subQuery]);

  let defaultJurisdiction = {
    id: "",
    [data.get.__typename.toLowerCase() + "_type"]: [
      { id: 0, name: "-Select-" },
    ],
    [subJurisdictionMap[type]]: [{ id: 0, name: "-Select-" }],
    code_definition: {
      url: "",
      link_text: "",
    },
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...defaultJurisdiction, ...data.get, button: null },
    validationSchema: Yup.object({
      [data.get.__typename.toLowerCase()]: Yup.array().of(
        Yup.object().shape({
          id: Yup.number().required("Required"),
          name: Yup.string().required("Required"),
        })
      ),
      [subJurisdictionMap[type]]: Yup.array().of(
        Yup.object().shape({
          id: Yup.number().required("Required"),
          name: Yup.string().required("Required"),
        })
      ),
      code_definition: Yup.object().shape({
        url: Yup.string().url("Enter a valid URL with http:// included."),
        link_text: Yup.string(),
      }),
    }),
    onSubmit: (values) => {
      // eslint-disable-next-line no-unused-vars
      let { button, ...jurisdiction } = values;
      doEditJurisdiction({ variables: { data: jurisdiction } });
    },
  });

  const updateData = (newData) => {
    data.set({
      ...data.get,
      ...newData,
    });
  };

  return (
    <JurisdictionFormOneDisplay
      data={{ get: data.get, set: updateData }}
      isEdit={{ get: isEdit, set: setIsEdit }}
      type={{
        open: { get: typeOpen, set: setTypeOpen },
        options: {
          list: typeOptions,
          filter: getTypeOptions,
        },
      }}
      sub={{
        open: { get: subOpen, set: setSubOpen },
        options: {
          list: subOptions,
          filter: getSubOptions,
        },
      }}
      formik={formik}
    />
  );
};

JurisdictionFormOneContainer.propTypes = {
  data: GetSet,
};

export default JurisdictionFormOneContainer;
