import React from "react";
import * as PropTypes from "prop-types";
import WorxFormContainer from "../../Container/Forms/Generic/WorxFormContainer";
import Button from "@material-ui/core/Button";

const AnalystParkingDisplay = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { id, options, formik } = props;

  return (
    <React.Fragment>
      <WorxFormContainer options={options} formik={formik} />
      <Button
        key={"submit"}
        variant={"contained"}
        color="primary"
        margin="normal"
        onClick={() => {
          let newList = [...formik.values.parking_answers.regular_spaces];
          newList.splice(newList.length - 1, 0, {
            id: newList.length - 1,
            new: true,
            required_spaces: 0,
          });
          formik.setFieldValue("parking_answers.regular_spaces", newList);
        }}
      >
        Add Parking
      </Button>
    </React.Fragment>
  );
};

AnalystParkingDisplay.propTypes = {
  id: PropTypes.number,
  options: PropTypes.object,
  formik: PropTypes.object,
};

export default AnalystParkingDisplay;
