import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { useLazyQuery, useMutation } from "@apollo/client";
import { getDocumentTypes, getMedia } from "../../../Queries/Queries";
import WorxMediaUploadDisplay from "../../../Display/Forms/Inputs/WorxMediaUploadDisplay";
import { camelToTitle } from "../../../tools/stringTools";
import { createMedia } from "../../../Queries/Mutations";
//import * as Yup from "yup";

// eslint-disable-next-line no-unused-vars
const WorxMediaUpload = (props) => {
  const {
    onComplete = () => {
      /*do nothing as default*/
    },
    fieldKey,
    property,
    disabled = false,
    entityType = "media",
    jurisdiction,
    type = "document",
    label,
    hideLink = false,
    typeLock,
    buttonOnly = false,
    formik,
  } = props;
  const [open, setOpen] = useState(false);
  const [oldOpen, setOldOpen] = useState(open);
  const [mediaTypeOpen, setMediaTypeOpen] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [filters, setFilters] = useState({
    limit: 25,
    offset: 0,
    operator: "CONTAINS",
  });

  const [filterMedia, { data: mediaQuery }] = useLazyQuery(getMedia(type), {
    fetchPolicy: "cache-and-network",
  });
  const [filterDocumentTypes, { data: documentTypesQuery }] = useLazyQuery(
    getDocumentTypes()
  );
  const [documentTypes, setDocumentTypes] = useState([]);

  let value = formik.values;

  for (let el of fieldKey.split(".")) {
    if (value && value?.[el]) {
      value = value?.[el];
    } else {
      value = null;
    }
  }

  let tail = fieldKey.split(".");
  tail = tail[tail.length - 1];
  tail = tail.split("/");
  tail = tail[tail.length - 1];

  // eslint-disable-next-line no-unused-vars
  const [doMediaCreate, { error: mediaCreateError, data: newMediaQuery }] =
    useMutation(createMedia(type), {
      refetchQueries: [{ query: getMedia(type), variables: filters }],
    });

  useEffect(() => {
    let newFilters = { ...filters };
    if (property) {
      newFilters.property = property;
    }
    if (jurisdiction) {
      newFilters.jurisdiction = jurisdiction;
    }
    setFilters(newFilters);
    filterDocumentTypes();
    filterMedia({ variables: newFilters });
  }, [property, jurisdiction]);

  useEffect(() => {
    if (documentTypesQuery) {
      let types = documentTypesQuery?.documentTypes;
      if (typeLock) {
        let newFilters = { ...filters, document_type: typeLock };
        setFilters(newFilters);
        filterMedia({ variables: newFilters });
        types = types.filter(
          (type) => typeLock.findIndex((lock) => lock == type.id) >= 0
        );
      }
      setDocumentTypes(types);
    }
  }, [documentTypesQuery]);

  useEffect(() => {
    if (open === false && open !== oldOpen) {
      onComplete(formik.values);
    }
    setOldOpen(open);
  }, [open]);

  useEffect(() => {
    if (mediaQuery?.mediaDocuments?.total) {
      setRowCount(mediaQuery.mediaDocuments.total);
    }
  }, [mediaQuery]);

  let createNewMedia = () => {
    if (formik?.values?.["field_media_" + tail]) {
      let payload = {
        ...(jurisdiction && { jurisdiction: [{ id: jurisdiction }] }),
        ...(property && { property: [{ id: property }] }),
        [type]: formik.values?.["field_media_" + tail],
        name: formik.values?.["field_media_" + tail][0].name,
        ...(type === "document" && {
          document_type: [formik.values?.["field_media_" + tail + "_type"][0]],
        }),
      };
      doMediaCreate({ variables: { data: payload } });
      formik.setFieldValue("field_media_" + tail);
      formik.setFieldValue("field_media_" + tail + "_type");
    }
  };

  return (
    <WorxMediaUploadDisplay
      fieldKey={fieldKey}
      buttonOnly={buttonOnly}
      tail={tail}
      value={value}
      disabled={disabled}
      label={label}
      entityType={entityType}
      type={type}
      hideLink={hideLink}
      formik={formik}
      media={{
        open: { get: open, set: setOpen },
        options: {
          list: mediaQuery?.["media" + camelToTitle(type) + "s"]?.items
            ? mediaQuery?.["media" + camelToTitle(type) + "s"]?.items
            : [],
          filter: filterMedia,
        },
        variables: {
          count: { get: rowCount, set: setRowCount },
          currentPage: { get: currentPage, set: setCurrentPage },
          filters: { get: filters, set: setFilters },
          columns: [
            { field: "name", headerName: "Name", width: 230 },
            {
              field: "document_type",
              headerName: "Type",
              width: 200,
              valueGetter: (value) => {
                return value?.row?.document_type?.[0]?.name;
              },
            },
            { field: "id", headerName: "ID", width: 230 },
          ],
        },
      }}
      mediaTypeOptions={{
        open: { get: mediaTypeOpen, set: setMediaTypeOpen },
        options: {
          list: documentTypes,
          filter: filterDocumentTypes,
        },
      }}
      createMedia={createNewMedia}
    />
  );
};

WorxMediaUpload.propTypes = {
  type: PropTypes.string.isRequired,
  fieldKey: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  property: PropTypes.number,
  entityType: PropTypes.string,
  jurisdiction: PropTypes.number,
  label: PropTypes.string,
  onComplete: PropTypes.func,
  hideLink: PropTypes.bool,
  typeLock: PropTypes.array,
  buttonOnly: PropTypes.bool,
  formik: PropTypes.object,
};

export default WorxMediaUpload;
