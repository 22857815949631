import React from "react";
import * as PropTypes from "prop-types";
import WorxFormContainer from "../../Container/Forms/Generic/WorxFormContainer";
import Button from "@material-ui/core/Button";

const AnalystCOfODisplay = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { id, options, formik } = props;

  return (
    <React.Fragment>
      <WorxFormContainer options={options} formik={formik} />
      <Button
        key={"submit"}
        variant={"contained"}
        color="primary"
        margin="normal"
        onClick={() => {
          formik.setFieldValue("reset", true);
          formik.handleSubmit();
        }}
      >
        Reset
      </Button>
    </React.Fragment>
  );
};

AnalystCOfODisplay.propTypes = {
  id: PropTypes.number,
  options: PropTypes.object,
  formik: PropTypes.object,
};

export default AnalystCOfODisplay;
