import React from "react";
import * as PropTypes from "prop-types";
import { DataGrid } from "@mui/x-data-grid";
import { GetSet } from "../../Constants/Jurisdiction";
import Button from "@material-ui/core/Button";

import { Card, CardContent, makeStyles } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import SurveyHeaderDisplay from "./SurveyHeaderDisplay";

const topCardUseStyles = makeStyles({
  root: {
    "& .MuiDataGrid-cell": {
      whiteSpace: "normal !important",
    },
    marginBottom: "2em",
  },
});

const SurveyApplicableRequirementsDisplay = (props) => {
  const {
    columns,
    rows,
    rowCount,
    /*currentPage,*/ filters,
    document,
    documentOpen,
    abuttingZoneOpen,
    jurisdiction,
    footnotes,
    formik,
  } = props;

  const topCardClasses = topCardUseStyles();

  return (
    <React.Fragment>
      <Card classes={{ root: topCardClasses.root }}>
        <CardContent>
          <SurveyHeaderDisplay
            documentFile={document?.mediaDocument?.document?.[0]}
            jurisdiction={jurisdiction}
            abuttingZoneOpen={abuttingZoneOpen}
            documentOpen={documentOpen}
            property={document?.mediaDocument?.property?.[0]}
          />
          <DataGrid
            classes={{ root: topCardClasses.root }}
            key={rows.length}
            pagination
            paginationMode="client"
            rowCount={rowCount}
            pageSize={filters.get.limit}
            loading={rows.length === 0}
            columns={columns}
            rows={rows ? rows : []}
            autoHeight
            checkboxSelection={false}
            getRowHeight={() => "auto"}
          />
          <div style={{ textAlign: "right", marginTop: "1em" }}>
            <Button
              variant={"contained"}
              color="primary"
              margin="normal"
              onClick={() => {
                //formik.setFieldValue('button', 'normal');
                formik.handleSubmit();
              }}
            >
              Submit
            </Button>
          </div>
          {footnotes &&
            Object.keys(footnotes).map((key, index) => {
              return (
                <Tooltip key={key} title={footnotes[key].description}>
                  <div id={"foot-" + index}>
                    <sup>{`[${index + 1}]`}</sup>
                    {`${footnotes[key].label}`}
                  </div>
                </Tooltip>
              );
            })}
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

SurveyApplicableRequirementsDisplay.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  rowCount: PropTypes.number.isRequired,
  currentPage: GetSet,
  filters: GetSet,
  document: PropTypes.object,
  documentOpen: GetSet,
  abuttingZoneOpen: GetSet,
  jurisdiction: PropTypes.number,
  footnotes: PropTypes.object,
  filterRequirements: PropTypes.func,
  formik: PropTypes.object,
};

export default SurveyApplicableRequirementsDisplay;
