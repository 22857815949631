import React from "react";
import * as PropTypes from "prop-types";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import AnalystStepperMenuDisplay from "../../Display/Analyst/AnalystStepperMenuDisplay";
import Card from "@material-ui/core/Card/Card";
import { CardContent } from "@material-ui/core";
import { makeStyles } from "@mui/styles";

const menuflex = makeStyles({
  wrapper: {
    display: "flex",
  },
  menu: {
    marginRight: "10px",
  },
  content: {
    flex: "1 0",
  },
});

const AnalystStepperDisplay = (props) => {
  const { menuItems } = props;

  let match = useRouteMatch();

  const menuCSS = menuflex();

  return (
    <div style={{ display: "flex" }}>
      <Card raised={true} classes={{ root: menuCSS.menu }}>
        <CardContent>
          <AnalystStepperMenuDisplay menuItems={menuItems} />
        </CardContent>
      </Card>

      <Card raised={true} classes={{ root: menuCSS.content }}>
        <CardContent>
          <Switch>
            {menuItems.map((item) => {
              return (
                <Route
                  key={item.path}
                  path={`${match.url}/${item.path}`}
                  render={() => {
                    return item.component;
                  }}
                />
              );
            })}
            <Route key={"/"} path={`${match.url}`}>
              <Redirect to={`${match.url}/${menuItems[0].path}`} />
            </Route>
          </Switch>
        </CardContent>
      </Card>
    </div>
  );
};

AnalystStepperDisplay.propTypes = {
  menuItems: PropTypes.array.isRequired,
};

export default AnalystStepperDisplay;
