import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  getBvViolations,
  getCnPlans,
  getFvViolations,
  getHistoricalLandmarkAnswers,
  getProperties,
  getResearchDocuments,
  getTenants,
  getUsesForJurisdiction,
  getZvViolations,
} from "../../Queries/Queries";
import ReceivedTabDisplay from "../../Display/Research/ReceivedTabDisplay";
import {
  /*camelToTitle,*/ titleToUnderscore /*underscoreToCamel*/,
} from "../../tools/stringTools";
import {
  editDocumentOrderItem,
  createReceivedAnswers,
  getReportItem,
} from "../../Queries/Mutations";
//import DocumentUploadSubContainer from "./DocumentUploadSubContainer";

const ReceivedTabContainer = (props) => {
  const { report_order_item, getReport } = props;

  const [workingOpen, setWorkingOpen] = useState(false);
  const [viewAnswersOpen, setViewAnswersOpen] = useState(false);
  const [answerID, setAnswerID] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const [rows, setRows] = useState(
    JSON.parse(JSON.stringify(report_order_item.document_order_items))
  );
  const [renderKey, setRenderKey] = useState(0);

  const [propertiesOpen, setPropertiesOpen] = useState(false);
  const [usesOpen, setUsesOpen] = useState(false);
  const [bvViolationsOpen, setBvViolationsOpen] = useState(false);
  const [fvViolationsOpen, setFvViolationsOpen] = useState(false);
  const [zvViolationsOpen, setZvViolationsOpen] = useState(false);
  const [historicalLandmarkAnswersOpen, setHistoricalLandmarkAnswersOpen] =
    useState(false);
  const [tenantsOpen, setTenantsOpen] = useState(false);
  const [cnPlansOpen, setCnPlansOpen] = useState(false);
  const [filterProperties, { data: propertyQuery }] = useLazyQuery(
    getProperties()
  );
  const [filterUses, { data: usesQuery }] = useLazyQuery(
    getUsesForJurisdiction(),
    {}
  );
  const [filterBvViolations, { data: bvViolationsQuery }] = useLazyQuery(
    getBvViolations(),
    {}
  );
  const [filterFvViolations, { data: fvViolationsQuery }] = useLazyQuery(
    getFvViolations(),
    {}
  );
  const [filterZvViolations, { data: zvViolationsQuery }] = useLazyQuery(
    getZvViolations(),
    {}
  );
  const [
    filterHistoricalLandmarkAnswers,
    { data: historicalLandmarkAnswersQuery },
  ] = useLazyQuery(getHistoricalLandmarkAnswers(), {});
  const [filterTenants, { data: tenantsQuery }] = useLazyQuery(
    getTenants(),
    {}
  );
  const [filterCnPlans, { data: cnPlansQuery }] = useLazyQuery(
    getCnPlans(),
    {}
  );
  const [filterAnswers, { data: answersQuery }] = useLazyQuery(
    getResearchDocuments(),
    {}
  );

  const [doEditDocumentOrderItem] = useMutation(editDocumentOrderItem(), {
    refetchQueries: getReportItem(report_order_item.id),
  });
  const [doAnswerBCV] = useMutation(
    createReceivedAnswers("building_code_violation"),
    { refetchQueries: getReportItem(report_order_item.id) }
  );
  const [doAnswerBL] = useMutation(createReceivedAnswers("business_license"), {
    refetchQueries: getReportItem(report_order_item.id),
  });
  const [doAnswerCOO] = useMutation(
    createReceivedAnswers("certificate_of_occupancy"),
    { refetchQueries: getReportItem(report_order_item.id) }
  );
  const [doAnswerC] = useMutation(createReceivedAnswers("condemnation"), {
    refetchQueries: getReportItem(report_order_item.id),
  });
  const [doAnswerCUP] = useMutation(
    createReceivedAnswers("conditional_use_permit"),
    { refetchQueries: getReportItem(report_order_item.id) }
  );
  const [doAnswerFCV] = useMutation(
    createReceivedAnswers("fire_code_violation"),
    { refetchQueries: getReportItem(report_order_item.id) }
  );
  const [doAnswerHL] = useMutation(
    createReceivedAnswers("historical_landmark"),
    { refetchQueries: getReportItem(report_order_item.id) }
  );
  const [doAnswerSUP] = useMutation(
    createReceivedAnswers("special_use_permit"),
    { refetchQueries: getReportItem(report_order_item.id) }
  );
  const [doAnswerS] = useMutation(createReceivedAnswers("survey"), {
    refetchQueries: getReportItem(report_order_item.id),
  });
  const [doAnswerTL] = useMutation(createReceivedAnswers("tenant_list"), {
    refetchQueries: getReportItem(report_order_item.id),
  });
  const [doAnswerUM] = useMutation(createReceivedAnswers("unit_mix"), {
    refetchQueries: getReportItem(report_order_item.id),
  });
  const [doAnswerV] = useMutation(createReceivedAnswers("variance"), {
    refetchQueries: getReportItem(report_order_item.id),
  });
  const [doAnswerZCV] = useMutation(
    createReceivedAnswers("zoning_code_violation"),
    { refetchQueries: getReportItem(report_order_item.id) }
  );
  const [doAnswerZL] = useMutation(createReceivedAnswers("zoning_letter"), {
    refetchQueries: getReportItem(report_order_item.id),
  });

  const [columns, setColumns] = useState([]);

  const defaultValues = {};

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...defaultValues, ...initialValues },
    validationSchema: Yup.object({}),
    onSubmit: (values, formikTools) => {
      /* eslint-disable no-unused-vars */
      let {
        property,
        document,
        name,
        type,
        __typename,
        __parent_id,
        ...payload
      } = values;
      /* eslint-disable no-unused-vars */
      payload.property = [{ id: property[0].id }];
      payload.document = [{ id: document[0].id }];
      switch (__typename) {
        case "building_code_violation":
          doAnswerBCV({ variables: { data: payload } });
          break;
        case "business_license":
          doAnswerBL({ variables: { data: payload } });
          break;
        case "certificate_of_occupancy":
          doAnswerCOO({ variables: { data: payload } });
          break;
        case "condemnation":
          doAnswerC({ variables: { data: payload } });
          break;
        case "conditional_use_permit":
          doAnswerCUP({ variables: { data: payload } });
          break;
        case "fire_code_violation":
          doAnswerFCV({ variables: { data: payload } });
          break;
        case "historical_/_landmark":
          doAnswerHL({ variables: { data: payload } });
          break;
        case "special_use_permit":
          doAnswerSUP({ variables: { data: payload } });
          break;
        case "survey":
          doAnswerS({ variables: { data: payload } });
          break;
        case "tenant_list":
          doAnswerTL({ variables: { data: payload } });
          break;
        case "unit_mix":
          doAnswerUM({ variables: { data: payload } });
          break;
        case "variance":
          doAnswerV({ variables: { data: payload } });
          break;
        case "zoning_code_violation":
          doAnswerZCV({ variables: { data: payload } });
          break;
        case "zoning_letter":
          doAnswerZL({ variables: { data: payload } });
          break;
        default:
          break;
      }
      setWorkingOpen(false);
      formikTools.resetForm();
      setRenderKey(renderKey + 1);
      let rowUpdate = [...rows];
      let index = rowUpdate.findIndex((row) => row.id === __parent_id);
      rowUpdate[index].document_entities[
        document?.[0]?.document_type?.[0]?.id
      ] = [true];
      setRows(rowUpdate);
      getReport();
    },
  });

  //const rows = report_order_item.document_order_items;

  useEffect(() => {
    filterBvViolations();
    filterProperties({
      variables: { jurisdiction: report_order_item.jurisdiction?.[0].id },
    });
    filterUses({
      variables: { jurisdiction: report_order_item.jurisdiction?.[0].id },
    });
    filterTenants({
      variables: { property: report_order_item.property?.[0].id },
    });
    filterAnswers({
      variables: { property: report_order_item.property?.[0].id },
    });
  }, []);

  useEffect(() => {
    setColumns([
      {
        field: "id",
        headerName: "ID",
        width: 100,
        renderCell: Object.assign(
          (params) => {
            return (
              <Button
                onClick={() => {
                  history.push({
                    pathname: "/data_entry/research/order/" + params.row.id,
                    state: { data: params.row },
                  });
                }}
              >
                {params.row.id}
              </Button>
            );
          },
          { displayName: "renderCell" }
        ),
      },
      { field: "name", headerName: "Name", width: 300 },
      {
        field: "document_type",
        headerName: "Type",
        width: 200,
        valueGetter: (value) => {
          return value.row.document_type[0].name;
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        width: 300,
        renderCell: Object.assign(
          (params) => {
            const id = rows.findIndex(
              (row) => row.id === params?.row?.__parent_id
            );
            return (
              <React.Fragment>
                {answersQuery?.researchDocuments?.items.findIndex((doc) => {
                  return doc.document[0].id === params.id;
                }) < 0 ? (
                  <Button
                    variant={"contained"}
                    color="primary"
                    margin="normal"
                    onClick={() => {
                      setWorkingOpen(true);
                      setInitialValues({
                        __typename: params?.row?.document_type
                          ? titleToUnderscore(
                              params?.row?.document_type[0].name
                            )
                          : "",
                        property: report_order_item.property,
                        document: [params?.row],
                        __parent_id: params?.row?.__parent_id,
                      });
                    }}
                  >
                    Questions
                  </Button>
                ) : (
                  <Button
                    variant={"contained"}
                    color="primary"
                    margin="normal"
                    onClick={() => {
                      let index =
                        answersQuery?.researchDocuments?.items.findIndex(
                          (doc) => {
                            return doc.document[0].id === params.id;
                          }
                        );
                      if (index >= 0) {
                        setAnswerID(index);
                        setViewAnswersOpen(true);
                      }
                    }}
                  >
                    View
                  </Button>
                )}
                {
                  <Button
                    variant={"contained"}
                    color="primary"
                    margin="normal"
                    onClick={() => {
                      let newDocs = [...rows[id].document];
                      const index = newDocs.findIndex(
                        (doc) => doc.id === params?.id
                      );
                      newDocs.splice(index, 1);
                      updateDocuments(params?.row?.__parent_id, newDocs);
                    }}
                  >
                    Remove
                  </Button>
                }
              </React.Fragment>
            );
          },
          { displayName: "renderCell" }
        ),
      },
    ]);
  }, [rows, answersQuery]);

  const updateDocuments = (id, documents) => {
    if (id && documents) {
      //visually update
      let localRows = Object.assign([], rows);
      let index = localRows.findIndex((row) => row.id === id);
      localRows[index].document = [];
      documents.forEach((doc) => {
        localRows[index].document.push(doc);
      });
      setRows(localRows);

      //server update
      let payload = {};
      payload.document = [];
      localRows[index].document.forEach((doc) => {
        payload.document.push({ id: doc.id });
      });
      payload.id = localRows[index].id;
      doEditDocumentOrderItem({ variables: { data: payload } });
      setRenderKey(renderKey + 1);
    }
  };

  return (
    rows && (
      <ReceivedTabDisplay
        key={renderKey}
        working={{ get: workingOpen, set: setWorkingOpen }}
        viewAnswersOpen={{ get: viewAnswersOpen, set: setViewAnswersOpen }}
        answerID={{ get: answerID, set: setAnswerID }}
        answers={answersQuery?.researchDocuments?.items ?? []}
        columns={columns}
        rowTotal={rows.length}
        pageSize={10}
        orderItems={rows ? rows : []}
        updateDocuments={updateDocuments}
        property={report_order_item.property}
        jurisdiction={report_order_item.jurisdiction}
        options={{
          property: {
            open: { get: propertiesOpen, set: setPropertiesOpen },
            options: {
              list: propertyQuery?.properties?.items
                ? propertyQuery?.properties?.items
                : [],
              filter: filterProperties,
            },
            variables: {
              filters: {
                get: { jurisdiction: report_order_item.jurisdiction?.[0].id },
                set: () => {
                  /*do nothing*/
                },
              },
            },
          },
          use: {
            open: { get: usesOpen, set: setUsesOpen },
            options: {
              list: usesQuery?.uses?.items ?? [],
              filter: filterUses,
            },
            variables: {
              filters: {
                get: { jurisdiction: report_order_item.jurisdiction?.[0].id },
                set: () => {
                  /*do nothing*/
                },
              },
            },
          },
          bv_violations: {
            open: { get: bvViolationsOpen, set: setBvViolationsOpen },
            options: {
              list: bvViolationsQuery?.bvViolations ?? [],
              filter: filterBvViolations,
            },
          },
          fv_violations: {
            open: { get: fvViolationsOpen, set: setFvViolationsOpen },
            options: {
              list: fvViolationsQuery?.fvViolations ?? [],
              filter: filterFvViolations,
            },
          },
          zv_violations: {
            open: { get: zvViolationsOpen, set: setZvViolationsOpen },
            options: {
              list: zvViolationsQuery?.zvViolations ?? [],
              filter: filterZvViolations,
            },
          },
          historical_landmark: {
            open: {
              get: historicalLandmarkAnswersOpen,
              set: setHistoricalLandmarkAnswersOpen,
            },
            options: {
              list:
                historicalLandmarkAnswersQuery?.historicalLandmarkAnswers ?? [],
              filter: filterHistoricalLandmarkAnswers,
            },
          },
          tenant: {
            open: { get: tenantsOpen, set: setTenantsOpen },
            options: {
              list: tenantsQuery?.tenants ?? [],
              filter: filterTenants,
            },
            variables: {
              filters: {
                get: { property: report_order_item.property?.[0].id },
                set: () => {
                  /*do nothing*/
                },
              },
            },
          },
          cn_plans: {
            open: { get: cnPlansOpen, set: setCnPlansOpen },
            options: {
              list: cnPlansQuery?.cnPlans ?? [],
              filter: filterCnPlans,
            },
          },
        }}
        formik={formik}
      />
    )
  );
};

ReceivedTabContainer.propTypes = {
  report_order_item: PropTypes.object,
  getReport: PropTypes.func,
};

export default ReceivedTabContainer;
