import React, { useContext, useEffect, useState } from "react";
// eslint-disable-next-line no-unused-vars
import * as PropTypes from "prop-types";
import { useLazyQuery, useMutation } from "@apollo/client";
import { getAnalystReport, getConformanceOptions } from "../../Queries/Queries";
import { useFormik } from "formik";
import * as Yup from "yup";
import AnalystConformanceDisplay from "../../Display/Analyst/AnalystConformanceDisplay";
import { editAnalystReport } from "../../Queries/Mutations";
import MessageContext from "../../Context/MessageContext";

const AnalystConformanceContainer = (props) => {
  const { id } = props;

  const [getReport, { data: report }] = useLazyQuery(getAnalystReport(id));
  const [filterConformanceOptions, { data: conformanceOptions }] = useLazyQuery(
    getConformanceOptions()
  );

  // eslint-disable-next-line no-unused-vars
  const [initValues, setInitValues] = useState({});

  const [useConformanceOpen, setUseConformanceOpen] = useState(false);
  const [lotSizeDensityConformanceOpen, setLotSizeDensityConformanceOpen] =
    useState(false);
  const [structureConformanceOpen, setStructureConformanceOpen] =
    useState(false);
  const [parkingConformanceOpen, setParkingConformanceOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [message, setMessage] = useContext(MessageContext);

  const [doEditReport, { data: response }] = useMutation(editAnalystReport());

  // setup
  useEffect(() => {
    getReport();
    filterConformanceOptions();
    setMessage(null);
  }, []);

  // report
  useEffect(() => {
    if (report) {
      let formData = {
        ...report.analystReport,
        ...(report.analystReport.use_conformance && {
          use_conformance: [
            {
              id: report.analystReport?.use_conformance,
              name: report.analystReport?.use_conformance,
            },
          ],
        }),
        ...(report.analystReport.lot_size_density_conformance && {
          lot_size_density_conformance: [
            {
              id: report.analystReport?.lot_size_density_conformance,
              name: report.analystReport?.lot_size_density_conformance,
            },
          ],
        }),
        ...(report.analystReport.structure_conformance && {
          structure_conformance: [
            {
              id: report.analystReport?.structure_conformance,
              name: report.analystReport?.structure_conformance,
            },
          ],
        }),
        ...(report.analystReport.parking_conformance && {
          parking_conformance: [
            {
              id: report.analystReport?.parking_conformance,
              name: report.analystReport?.parking_conformance,
            },
          ],
        }),
      };
      setInitValues({ ...formData, __typename: "AnalystConformanceForm" });
    }
  }, [report]);

  // response
  useEffect(() => {
    if (response) {
      let data = response?.editAnalystReport;
      let formData = {
        ...data.analyst_report,
        ...(data.analyst_report.use_conformance && {
          use_conformance: [
            {
              id: data.analyst_report?.use_conformance,
              name: data.analyst_report?.use_conformance,
            },
          ],
        }),
        ...(data.analyst_report.lot_size_density_conformance && {
          lot_size_density_conformance: [
            {
              id: data.analyst_report?.lot_size_density_conformance,
              name: data.analyst_report?.lot_size_density_conformance,
            },
          ],
        }),
        ...(data.analyst_report.structure_conformance && {
          structure_conformance: [
            {
              id: data.analyst_report?.structure_conformance,
              name: data.analyst_report?.structure_conformance,
            },
          ],
        }),
        ...(data.analyst_report.parking_conformance && {
          parking_conformance: [
            {
              id: data.analyst_report?.parking_conformance,
              name: data.analyst_report?.parking_conformance,
            },
          ],
        }),
      };
      setInitValues({ ...formData, __typename: "AnalystConformanceForm" });
    }
  }, [response]);

  const defaultValues = {};

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...defaultValues, ...initValues },
    validationSchema: Yup.object({}),
    // eslint-disable-next-line no-unused-vars
    onSubmit: (values, formikTools) => {
      // eslint-disable-next-line no-unused-vars
      let { conformance_report_order_item, reconstruction_clause, ...val } =
        values;
      let payload = {
        id: val.id,
        ...(values.use_conformance && {
          use_conformance: values?.use_conformance?.[0]?.id,
        }),
        ...(values.lot_size_density_conformance && {
          lot_size_density_conformance:
            values?.lot_size_density_conformance?.[0]?.id,
        }),
        ...(values.structure_conformance && {
          structure_conformance: values?.structure_conformance?.[0]?.id,
        }),
        ...(values.parking_conformance && {
          parking_conformance: values?.parking_conformance?.[0]?.id,
        }),
        ...(values.conformance_notes && {
          conformance_notes: values?.conformance_notes,
        }),
        ...(values.deficiencies && { deficiencies: values?.deficiencies }),
      };
      doEditReport({ variables: { data: payload } });
      setMessage("✓ Conformance Saved");
    },
  });

  return (
    <AnalystConformanceDisplay
      options={{
        use_conformance: {
          open: {
            get: useConformanceOpen,
            set: setUseConformanceOpen,
          },
          options: {
            list: conformanceOptions?.getConformanceOptions
              ? conformanceOptions?.getConformanceOptions.map((item) => {
                  return { id: item, name: item };
                })
              : [],
            filter: filterConformanceOptions,
          },
        },
        lot_size_density_conformance: {
          open: {
            get: lotSizeDensityConformanceOpen,
            set: setLotSizeDensityConformanceOpen,
          },
          options: {
            list: conformanceOptions?.getConformanceOptions
              ? conformanceOptions?.getConformanceOptions.map((item) => {
                  return { id: item, name: item };
                })
              : [],
            filter: filterConformanceOptions,
          },
        },
        structure_conformance: {
          open: {
            get: structureConformanceOpen,
            set: setStructureConformanceOpen,
          },
          options: {
            list: conformanceOptions?.getConformanceOptions
              ? conformanceOptions?.getConformanceOptions.map((item) => {
                  return { id: item, name: item };
                })
              : [],
            filter: filterConformanceOptions,
          },
        },
        parking_conformance: {
          open: {
            get: parkingConformanceOpen,
            set: setParkingConformanceOpen,
          },
          options: {
            list: conformanceOptions?.getConformanceOptions
              ? conformanceOptions?.getConformanceOptions.map((item) => {
                  return { id: item, name: item };
                })
              : [],
            filter: filterConformanceOptions,
          },
        },
      }}
      nameOverrides={{
        use_conformance: "Is the Use in Conformance?",
        lot_size_density_conformance:
          "Is the Lot Size and Density in Conformance?",
        structure_conformance: "Is the Structure in Conformance?",
        parking_conformance: "Is the Parking in Conformance?",
        conformance_notes: "Notes",
      }}
      id={id}
      formik={formik}
    />
  );
};

AnalystConformanceContainer.propTypes = {
  id: PropTypes.number.isRequired,
  page: PropTypes.string,
};

export default AnalystConformanceContainer;
