import React from "react";
import * as PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { Card, CardContent, makeStyles, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog/Dialog";
import { GetSet } from "../../../Constants/Jurisdiction";

const buttonUseStyles = makeStyles({
  root: {
    marginLeft: "1em",
  },
});

const WorxDeleteButtonDisplay = (props) => {
  const { deleteConfirm, deleteData, type, id } = props;

  const buttonClasses = buttonUseStyles();

  return (
    <React.Fragment>
      <Dialog
        id={"deleteConfirmation"}
        open={deleteConfirm.get}
        onClose={() => {
          deleteConfirm.set(false);
          deleteData.set(null);
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Card raised={true}>
          <CardContent>
            <Typography variant={"h5"}>Delete?</Typography>
            <form>
              <Button
                variant={"contained"}
                color="secondary"
                margin="normal"
                onClick={() => {
                  deleteConfirm.return(false);
                }}
                classes={{ root: buttonClasses.root }}
              >
                Cancel
              </Button>
              <Button
                variant={"contained"}
                color="primary"
                margin="normal"
                onClick={() => {
                  deleteConfirm.return(true);
                }}
                classes={{ root: buttonClasses.root }}
              >
                Confirm
              </Button>
            </form>
          </CardContent>
        </Card>
      </Dialog>

      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          deleteConfirm.set(true);
          deleteData.set({ data: { entity_type: type, id: id } });
        }}
      >
        delete
      </Button>
    </React.Fragment>
  );
};

WorxDeleteButtonDisplay.propTypes = {
  deleteConfirm: PropTypes.object,
  deleteData: GetSet,
  type: PropTypes.string,
  id: PropTypes.number,
};

export default WorxDeleteButtonDisplay;
