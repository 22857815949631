import React, { useContext, useEffect, useState } from "react";
// eslint-disable-next-line no-unused-vars
import * as PropTypes from "prop-types";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  getAnalystReport,
  getOrderItemStateTransitions,
} from "../../Queries/Queries";
import { useFormik } from "formik";
import * as Yup from "yup";
import AnalystReportStatusDisplay from "../../Display/Analyst/AnalystReportStatusDisplay";
//import {useHistory} from "react-router";
import { editConformanceReportOrderItem } from "../../Queries/Mutations";
import MessageContext from "../../Context/MessageContext";

const AnalystReportStatusContainer = (props) => {
  const { id } = props;

  const [getReport, { data: report }] = useLazyQuery(getAnalystReport(id));
  //const [init, setInit] = useState(false);
  const [getStatusOptions, { data: statusOptions }] = useLazyQuery(
    getOrderItemStateTransitions()
  );
  //const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const [message, setMessage] = useContext(MessageContext);

  // eslint-disable-next-line no-unused-vars
  const [initValues, setInitValues] = useState({});
  const [preparedForOpen, setPreparedForOpen] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);

  const [doEditReport /*, {error: error, data: response}*/] = useMutation(
    editConformanceReportOrderItem()
  );

  // setup
  useEffect(() => {
    getReport();
    setMessage(null);
  }, []);

  // report
  useEffect(() => {
    if (report) {
      let croi = { ...report.analystReport.conformance_report_order_item[0] };
      croi.status = [{ id: croi.status, name: croi.status }];
      setInitValues(croi);
      getStatusOptions({
        variables: {
          id: report.analystReport.conformance_report_order_item[0].id,
        },
      });
    }
  }, [report]);

  // response
  /*useEffect(() => {
    if(response) {
      if(!error) {
        let croi = {...report.analystReport.conformance_report_order_item[0]};
        croi.status = [{id: croi.status, name: croi.status}];
        setInitValues(croi);

        let array = history.location.pathname.split('/');
        array.splice(array.length - 1);
        history.push({pathname: `${array.join('/')}/property-status`});
      }
    }
  }, [response]);*/

  const defaultValues = { __typename: "analyst_report" };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...initValues, ...defaultValues },
    validationSchema: Yup.object({}),
    // eslint-disable-next-line no-unused-vars
    onSubmit: (values, formikTools) => {
      let newPayload = {
        id: values.id,
        ...(values.status && { status: values.status[0].id }),
        //...(values.client_docuemnts && {client_docuemnts: values.client_docuemnts.map((cd) => ({id: cd.id}))}),
        ...(values.name && { name: values.name }),
        ...(values.prepared_for && {
          prepared_for: values.prepared_for.map((pf) => ({ id: pf.id })),
        }),
        ...(values.billing_information && {
          billing_information: values.billing_information.map((bi) => ({
            id: bi.id,
          })),
        }),
        ...(values.client && {
          client: values.client.map((cli) => ({ id: cli.id })),
        }),
        ...(values.contact_information && {
          contact_information: values.contact_information.map((ci) => ({
            id: ci.id,
          })),
        }),
        ...(values.order_number && { order_number: values.order_number }),
        ...(values.portfolio_name && { portfolio_name: values.portfolio_name }),
        ...(values.report_due_date && {
          report_due_date: values.report_due_date,
        }),
        ...(values.report_order_items && {
          report_order_items: values.report_order_items.map((roi) => ({
            id: roi.id,
          })),
        }),
        ...(values.state_transition && {
          state_transition: values.state_transition,
        }),
        ...(values.total_orders && { total_orders: values.total_orders }),
        ///...(values.reliance_language && {reliance_language: values.reliance_language})
      };
      doEditReport({ variables: { data: newPayload } });
      setMessage("✓ Report Status Saved");
    },
  });

  return (
    <AnalystReportStatusDisplay
      options={{
        "order.0.prepared_for": {
          open: {
            get: preparedForOpen,
            set: setPreparedForOpen,
          },
          options: {
            list: report?.analystReport?.conformance_report_order_item?.[0]
              ?.order?.[0]?.client?.[0]?.prepared_for
              ? report?.analystReport?.conformance_report_order_item?.[0]?.order?.[0]?.client?.[0]?.prepared_for.map(
                  (item) => {
                    return { id: item.id, name: item.address.address_line1 };
                  }
                )
              : [],
            filter: () => {},
          },
        },
        status: {
          open: {
            get: statusOpen,
            set: setStatusOpen,
          },
          options: {
            list: statusOptions?.orderItemStateTransitions
              ? statusOptions?.orderItemStateTransitions.map((item) => {
                  return { id: item.id, name: item.label };
                })
              : [],
            filter: () => {},
          },
        },
      }}
      id={id}
      formik={formik}
    />
  );
};

AnalystReportStatusContainer.propTypes = {
  id: PropTypes.number.isRequired,
  page: PropTypes.string,
};

export default AnalystReportStatusContainer;
