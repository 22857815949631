import React from "react";
import * as PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import WorxTextField from "../Inputs/WorxTextField";
import WorxAutocompleteFormik from "../Inputs/WorxAutocompleteFormik";
import WorxCKEditorFormik from "../Inputs/WorxCKEditorFormik";
import Button from "@material-ui/core/Button";
import WorxMediaUpload from "../../../Container/Forms/Inputs/WorxMediaUpload";
import WorxDatePicker from "../Inputs/WorxDatePicker";
import WorxAutocompleteMultipleFormik from "../Inputs/WorxAutocompleteMultipleFormik";
import WorxCheckbox from "../Inputs/WorxCheckbox";
import WorxAddressField from "../../../Container/Forms/Inputs/WorxAddressField";
import WorxListCreateSwitchContainer from "../../../Container/Forms/Generic/WorxListCreateSwitchContainer";
import { Card, CardContent } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import WorxSelectString from "../Inputs/WorxSelectString";
import { Typography } from "@material-ui/core";
import {camelToTitle} from "../../../tools/stringTools";

const buttonUseStyles = makeStyles({
  alignHead: {
    display: "tableHeaderGroup",
  },
});

const topCardUseStyles = makeStyles({
  root: {
    "& .MuiDataGrid-cell": {
      whiteSpace: "normal !important",
    },
    marginBottom: "2em",
  },
});

const useStyles = makeStyles({
  worxRadioGroupStyleOverride: {
    marginBottom: "1em",
  },
  worxSpaceAround: {
    margin: "0 1em",
  },
  textfieldWrapper: {
    marginBottom: "1em",
  },
});

const WorxFormDisplay = (props) => {
  const {map, hideButtons = false, ...other} = props;
  const {
    disabledMap = {},
    hideMap,
    disabled = false,
    options = null,
    nameOverride = {},
    dependencyMap,
    buttons,
    formik,
  } = other;

  const classes = useStyles();
  const buttonClasses = buttonUseStyles();
  const topCardClasses = topCardUseStyles();

  if (!map) {
    return <div>Loading...</div>;
  }

  return (
    <React.Fragment>
      {Object.keys(map).map((key) => {
        let value = formik.values;
        let depValue = dependencyMap;

        for (let el of key.split(".")) {
          if (value && value?.[el]) {
            value = value?.[el];
          } else {
            value = null;
          }
        }

        for (let el of key.split(".")) {
          if (depValue && depValue?.[el]) {
            depValue = depValue?.[el];
          } else {
            depValue = null;
          }
        }

        let localDisabled = disabledMap[key] ? disabledMap[key] : disabled;

        let hide = false;
        if (depValue) {
          hide = true;
          let depTarget = formik.values;
          for (let el of depValue.target.split(".")) {
            if (depTarget && depTarget?.[el]) {
              depTarget = depTarget?.[el];
            } else {
              depTarget = null;
            }
          }

          if (depTarget) {
            //special case to always show
            if (depValue.value === "_any") {
              hide = false;
            } else if (
              typeof depValue.value === "string" ||
              depValue.value instanceof String
            ) {
              //taken from https://stackoverflow.com/questions/4059147/check-if-a-variable-is-a-string-in-javascript
              if (depValue.value === depTarget) {
                hide = false;
              }
            } else if (depValue?.value?.[0]?.id === depTarget?.[0]?.id) {
              hide = false;
            }
          }
        }

        if (!hide && !hideMap?.[key]) {
          if (typeof map[key] === 'object') {
            return <fieldset key={key}>
              <legend>{camelToTitle(key)}</legend>
              <WorxFormDisplay map={map[key]} hideButtons={true} {...other} />
            </fieldset>
          } else {
            switch (map[key]) {
              case "number":
                return (
                  <WorxTextField
                    key={key}
                    fieldKey={key}
                    label={nameOverride?.[key]}
                    disabled={localDisabled}
                    type={"number"}
                    formik={formik}
                  />
                );
              case "decimal":
                return (
                  <WorxTextField
                    key={key}
                    fieldKey={key}
                    label={nameOverride?.[key]}
                    disabled={localDisabled}
                    type={"number"}
                    trim={2}
                    formik={formik}
                  />
                );
              case "boolean":
                return (
                  <WorxCheckbox
                    key={key}
                    fieldKey={key}
                    label={nameOverride?.[key]}
                    formik={formik}
                  />
                );
              case "select":
                if (localDisabled) {
                  return (
                    <WorxTextField
                      key={key}
                      fieldKey={`${key}.0.name`}
                      label={nameOverride?.[key]}
                      disabled={localDisabled}
                      formik={formik}
                    />
                  );
                } else {
                  return (
                    <WorxAutocompleteFormik
                      key={key}
                      fieldKey={key}
                      nameKey={options?.[key]?.variables?.nameKey}
                      label={nameOverride?.[key]}
                      options={options?.[key]}
                      formik={formik}
                    />
                  );
                }
              case "stringSelect":
                if (localDisabled) {
                  return (
                    <WorxTextField
                      key={key}
                      fieldKey={`${key}.0.name`}
                      label={nameOverride?.[key]}
                      disabled={localDisabled}
                      formik={formik}
                    />
                  );
                } else {
                  return (
                    <WorxSelectString
                      key={key}
                      fieldKey={key}
                      label={nameOverride?.[key]}
                      options={options?.[key]}
                      formik={formik}
                    />
                  );
                }
              case "multi_select":
                if (localDisabled) {
                  return (
                    <WorxTextField
                      key={key}
                      fieldKey={`${key}.0.name`}
                      label={nameOverride[key]}
                      disabled={localDisabled}
                      formik={formik}
                    />
                  );
                } else {
                  return (
                    <WorxAutocompleteMultipleFormik
                      key={key}
                      fieldKey={key}
                      nameKey={options?.[key]?.variables?.nameKey}
                      label={nameOverride?.[key]}
                      options={options?.[key]}
                      formik={formik}
                    />
                  );
                }
              case "textArea":
                return (
                  <WorxCKEditorFormik
                    key={key}
                    fieldKey={key}
                    label={nameOverride?.[key]}
                    disabled={localDisabled}
                    formik={formik}
                  />
                );
              case "list":
                return (
                  <ul>
                    {value.map((item) => {
                      return <li key={item.id}>{item.name}</li>;
                    })}
                  </ul>
                );
              case "list_create":
                return (
                  <WorxListCreateSwitchContainer
                    key={key}
                    fieldKey={key}
                    label={nameOverride?.[key]}
                    options={options?.[key]}
                    setInitValues={options?.setInitValues}
                    formik={formik}
                  />
                );
              /*return <React.Fragment>
                <WorxListCreate fieldKey={key} options={options?.[key]} formik={formik} />
              </React.Fragment>;*/
              case "document":
                return (
                  <WorxMediaUpload
                    key={key}
                    type={"document"}
                    fieldKey={key}
                    label={nameOverride?.[key]}
                    disabled={localDisabled}
                    entityType={"media"}
                    formik={formik}
                  />
                );
              case "date":
                return (
                  <WorxDatePicker
                    key={key}
                    fieldKey={key}
                    label={nameOverride?.[key]}
                    formik={formik}
                  />
                );
              case "address":
                if (localDisabled) {
                  return (
                    <WorxAddressField
                      key={key}
                      fieldKey={key}
                      label={nameOverride?.[key]}
                      disabled={localDisabled}
                      formik={formik}
                    />
                  );
                } else {
                  return (
                    <Card classes={{root: topCardClasses.root}}>
                      <CardContent>
                        <WorxAddressField
                          key={key}
                          fieldKey={key}
                          label={nameOverride?.[key]}
                          disabled={localDisabled}
                          formik={formik}
                        />
                      </CardContent>
                    </Card>
                  );
                }
              case "grid":
                return (
                  !options?.[key]?.hide && (
                    <React.Fragment>
                      {options?.[key]?.label && (
                        <Typography
                          key={key}
                          className={"label"}
                          variant={"body1"}
                          gutterBottom
                        >
                          {options?.[key]?.label}
                        </Typography>
                      )}
                      <DataGrid
                        classes={{root: topCardClasses.root}}
                        key={key}
                        pagination
                        checkboxSelection={false}
                        rowCount={options?.[key]?.rowTotal}
                        autoHeight={true}
                        columns={options?.[key]?.columns}
                        rows={options?.[key]?.rows ?? value}
                        pageSize={options?.[key]?.pageSize}
                        paginationMode={
                          options?.[key]?.paginationMode
                            ? options?.[key]?.paginationMode
                            : "server"
                        }
                        onPageChange={options?.[key]?.onPageChange}
                        loading={!value}
                        className={classes.worxSpaceAround}
                        getRowHeight={() => "auto"}
                        rowHeight={50}
                      />
                    </React.Fragment>
                  )
                );
              case "label":
                return (
                  <Typography
                    key={key}
                    className={"label"}
                    variant={"body1"}
                    gutterBottom
                  >
                    {value}
                  </Typography>
                );
              default:
                return (
                  <WorxTextField
                    key={key}
                    fieldKey={key}
                    label={nameOverride?.[key]}
                    disabled={localDisabled}
                    formik={formik}
                  />
                );
            }
          }
        }
      })}

      {!hideButtons &&
        <>
          {buttons.map((button) => button)}
          <Button
            key={"submit"}
            variant={"contained"}
            color="primary"
            margin="normal"
            onClick={() => {
              formik.handleSubmit();
            }}
            classes={{ root: buttonClasses.root }}
          >
            Submit
          </Button>
        </>

      }
    </React.Fragment>
  );
};

WorxFormDisplay.propTypes = {
  map: PropTypes.object,
  disabled: PropTypes.bool,
  disabledMap: PropTypes.object,
  hideMap: PropTypes.object,
  options: PropTypes.object.isRequired,
  nameOverride: PropTypes.object,
  dependencyMap: PropTypes.object,
  buttons: PropTypes.array,
  hideButtons: PropTypes.bool,
  formik: PropTypes.object.isRequired,
};

export default WorxFormDisplay;


/*
case "sectionStart": {
                let startIndex = Object.keys(map).findIndex(startKey => {
                  return startKey === key;
                })
                console.log(startIndex);

                let endIndex = Object.keys(map).findIndex((endKey, i) => {
                  if (i > startIndex) {
                    return map[endKey] === "sectionEnd";
                  }
                })
                console.log(endIndex);

                //debugger;
                let subSet = Object.keys(map).filter((filterKey, i) => {
                  //console.log(i);
                  return i > startIndex && i < endIndex;
                }).reduce((inValue, newValue) => {
                  inValue[newValue] = map[newValue];
                  return inValue
                }, {});

                console.log(subSet);

                //<WorxFormContainer map={subSet} {...other} />
                return (
                  <fieldset>
                    <div>Testing3</div>
                  </fieldset>
                );
              }
              case "sectionEnd":
 */
