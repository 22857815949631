import React from "react";
// eslint-disable-next-line no-unused-vars
import * as PropTypes from "prop-types";
import Card from "@material-ui/core/Card/Card";
import { CardContent, Typography } from "@mui/material";

const AnswerContainer = (props) => {
  const { message } = props;

  return (
    message && (
      <Card raised={true}>
        <CardContent>
          <Typography variant={"body1"}>{message}</Typography>
        </CardContent>
      </Card>
    )
  );
};

AnswerContainer.propTypes = {
  message: PropTypes.string,
};

export default AnswerContainer;
