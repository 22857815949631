import React, { useContext, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { useHistory } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@material-ui/core";
import { getOrders } from "../../Queries/Queries";
import UserContext from "../../Context/UserContext";
import MainContentWidthContext from "../../Context/MainContentWidthContext";
import { GetSet } from "../../Constants/Jurisdiction";
import HeaderTitleContext from "../../Context/HeaderTitleContext";
import ClientPortalOrderListDisplay from "../../Display/ClientPortal/ClientPortalOrderListDisplay";

// eslint-disable-next-line no-unused-vars
const ClientPortalOrdersListContainer = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [headerTitle, setHeaderTitle] = useContext(HeaderTitleContext);
  setHeaderTitle("Client Portal");

  // eslint-disable-next-line no-unused-vars
  const [columns, setColumns] = useState([
    {
      field: "order_number",
      headerName: " ",
      width: 100,
      renderCell: Object.assign(
        (params) => {
          return (
            <Link component={RouterLink} to={`/portal/orders/${params.row.id}`}>
              {params.value}
            </Link>
          );
        },
        { displayName: "renderCell" }
      ),
    },
    {
      field: "client",
      headerName: "Client",
      width: 200,
      valueGetter: (value) => {
        return value?.row?.client?.[0]?.name;
      },
    },
    {
      field: "property",
      headerName: "Address",
      width: 230,
      valueGetter: (value) => {
        return value?.row?.report_order_items?.[0]?.property?.[0]?.name;
      },
    },
    {
      field: "created",
      headerName: "Order Date",
      width: 230,
      valueGetter: (value) => {
        return value?.row?.created;
      },
    },
    {
      field: "state",
      headerName: "State",
      width: 230,
      valueGetter: (value) => {
        return value?.row?.state;
      },
    },
  ]);

  const [filterStateOpen, setFilterStateOpen] = useState(false);
  const [filterState, setFilterState] = useState({
    id: "working",
    name: "Working",
  });
  const userContext = useContext(UserContext);
  const [rows, setRows] = useState([]);
  const [filters, setFilters] = useState({
    limit: 25,
    offset: 0,
    use: "",
    zone: "",
    operator: "CONTAINS",
    state: filterState.id,
  });
  const [rowTotal, setRowTotal] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [filterOrders, { loading: ordersLoading, data: ordersQuery }] =
    useLazyQuery(getOrders(), { variables: filters });

  const history = useHistory();
  const createNewOrder = () => {
    history.push({
      pathname: "/residential/create-order/",
    });
  };

  // eslint-disable-next-line no-unused-vars
  const [mainContentWidth, setMainContentWidth] = useContext(
    MainContentWidthContext
  );
  setMainContentWidth("80");

  useEffect(() => {
    if (userContext?.[0]?.id) {
      let newFilters = { ...filters };
      if (newFilters.client !== "" + userContext?.[0]?.id) {
        setFilters({ ...newFilters, client: "" + userContext[0].id });
      }
    }
  }, [userContext]);

  useEffect(() => {
    if (filters.client) {
      filterOrders({ variables: filters });
    }
  }, [filters]);

  useEffect(() => {
    if (ordersQuery?.orders?.total) {
      setRowTotal(ordersQuery?.orders?.total);
    }

    if (ordersQuery?.orders?.items) {
      setRows(ordersQuery?.orders?.items);
      //setRowTotal(ordersQuery?.residentialOrders?.total);
    }
  }, [ordersQuery]);

  return (
    <ClientPortalOrderListDisplay
      orders={{ list: rows, filter: filterOrders, loading: ordersLoading }}
      columns={columns}
      rows={rows}
      filters={{ get: filters, set: setFilters }}
      currentPage={{ get: currentPage, set: setCurrentPage }}
      rowTotal={rowTotal}
      createNewOrder={createNewOrder}
      filterStateOpen={{ get: filterStateOpen, set: setFilterStateOpen }}
      filterState={{ get: filterState, set: setFilterState }}
    />
  );
};

ClientPortalOrdersListContainer.propTypes = {
  data: GetSet,
};

export default ClientPortalOrdersListContainer;
