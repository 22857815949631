// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { useLazyQuery } from "@apollo/client";
import { getMediaDocument } from "../../Queries/Queries";
import SurveyApplicableRequirementsContainer from "./Forms/SurveyApplicableRequirementsContainer";
import JuniorAnalystUnitMixFormContainer from "./Forms/JuniorAnalystUnitMixFormContainer";
import JuniorAnalystTenantListFormContainer from "./Forms/JuniorAnalystTenantListFormContainer";

const JuniorAnalystSwitchContainer = (props) => {
  const { claimID } = props;
  // eslint-disable-next-line no-unused-vars
  const [fetchItem, { loading, error, data: itemQuery }] = useLazyQuery(
    getMediaDocument(claimID)
  );
  const [type, setType] = useState("none");

  useEffect(() => {
    fetchItem();
  }, []);

  useEffect(() => {
    if (itemQuery?.mediaDocument?.document_type?.[0]?.name) {
      setType(itemQuery?.mediaDocument?.document_type?.[0]?.name);
    }
  }, [itemQuery]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <React.Fragment>
      {type == "Survey" && (
        <SurveyApplicableRequirementsContainer
          documentID={claimID}
          propertyID={itemQuery?.mediaDocument?.property?.[0]?.id}
        />
      )}
      {type == "Unit Mix" && (
        <JuniorAnalystUnitMixFormContainer documentID={claimID} />
      )}
      {type == "Tenant List" && (
        <JuniorAnalystTenantListFormContainer documentID={claimID} />
      )}
    </React.Fragment>
  );
};

JuniorAnalystSwitchContainer.propTypes = {
  claimID: PropTypes.number,
};

export default JuniorAnalystSwitchContainer;
