import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import WorxAddressFieldDisplay from "../../../Display/Forms/Inputs/WorxAddressFieldDisplay";
import { USStates } from "../../../Constants/Values";

const WorxAddressField = (props) => {
  let { fieldKey, disabled = false, hideLabel, label, initEdit = false, formik } = props;

  const [edit, setEdit] = useState(initEdit);
  const [statesOpen, setStatesOpen] = useState(false);
  const [states, setStates] = useState(USStates);

  const filterStates = (value, setTarget) => {
    setTarget(
      USStates.filter(
        (state) => state.name.includes(value) || state.id.includes(value)
      )
    );
  };

  useEffect(() => {
    setEdit(!disabled);
  }, [disabled]);

  return (
    <WorxAddressFieldDisplay
      fieldKey={fieldKey}
      disabled={disabled}
      hideLabel={hideLabel}
      label={label}
      edit={{ get: edit, set: setEdit }}
      states={{
        open: { get: statesOpen, set: setStatesOpen },
        options: {
          list: states,
          filter: (filters) => {
            if (filters?.variables?.name) {
              filterStates(filters.variables.name, setStates);
            }
          },
        },
      }}
      formik={formik}
    />
  );
};

WorxAddressField.propTypes = {
  fieldKey: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  hideLabel: PropTypes.bool,
  label: PropTypes.string,
  initEdit: PropTypes.bool,
  formik: PropTypes.object.isRequired,
};

export default WorxAddressField;
