import React, { useContext, useEffect, useState } from "react";
// eslint-disable-next-line no-unused-vars
import * as PropTypes from "prop-types";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  getAnalystReport,
  getDocumentConformanceOptions,
} from "../../Queries/Queries";
import { useFormik } from "formik";
import * as Yup from "yup";
import { editAnalystReport } from "../../Queries/Mutations";
import AnalystMunicipalDocumentsDisplay from "../../Display/Analyst/AnalystMunicipalDocumentsDisplay";
import MessageContext from "../../Context/MessageContext";

const AnalystMunicipalDocumentsContainer = (props) => {
  const { id } = props;

  const [getReport, { data: report }] = useLazyQuery(getAnalystReport(id));
  const [
    filterDocumentConformanceOptions,
    { data: DocumentConformanceOptions },
  ] = useLazyQuery(getDocumentConformanceOptions());

  // eslint-disable-next-line no-unused-vars
  const [initValues, setInitValues] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [message, setMessage] = useContext(MessageContext);

  const [doEditReport, { data: response }] = useMutation(editAnalystReport());

  const [openStates, setOpenStates] = useState({});

  const setOpen = (id, value) => {
    setOpenStates({ ...openStates, [id]: value });
  };

  const getOpen = (id) => {
    return openStates[id] ?? false;
  };

  // setup
  useEffect(() => {
    getReport();
    filterDocumentConformanceOptions();
    setMessage(null);
  }, []);

  // report
  useEffect(() => {
    if (report) {
      setInitValues({
        ...report.analystReport,
        __typename: "AnalystMunicipalDocumentsForm",
      });
    }
  }, [report]);

  // response
  useEffect(() => {
    if (response) {
      setInitValues({
        ...response?.editAnalystReport.analyst_report,
        __typename: "AnalystMunicipalDocumentsForm",
      });
    }
  }, [response]);

  const defaultValues = {};

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...defaultValues, ...initValues },
    validationSchema: Yup.object({}),
    // eslint-disable-next-line no-unused-vars
    onSubmit: (values, formikTools) => {
      // eslint-disable-next-line no-unused-vars
      let { conformance_report_order_item, ...val } = values;
      let payload = {
        id: val.id,
        ...(values.municipal_documents && {
          municipal_documents: values?.municipal_documents,
        }),
      };
      doEditReport({ variables: { data: payload } });
      setMessage("✓ Saved");
    },
  });

  if (!DocumentConformanceOptions) {
    return <div>Loading...</div>;
  }

  return (
    DocumentConformanceOptions && (
      <AnalystMunicipalDocumentsDisplay
        id={id}
        options={{
          "municipal_documents.zoning_violations.conformance": {
            open: {
              get: getOpen("zoning_code"),
              set: (value) => {
                setOpen("zoning_code", value);
              },
            },
            options: {
              list: DocumentConformanceOptions?.getZoningCodeViolationsOptions.map(
                (violation) => {
                  return { id: violation, name: violation };
                }
              ),
              filter: () => {
                /*Do Nothing*/
              },
            },
          },
          "municipal_documents.building_violations.conformance": {
            open: {
              get: getOpen("building_code"),
              set: (value) => {
                setOpen("building_code", value);
              },
            },
            options: {
              list: DocumentConformanceOptions?.getBuildingCodeViolationsOptions.map(
                (violation) => {
                  return { id: violation, name: violation };
                }
              ),
              filter: () => {
                /*Do Nothing*/
              },
            },
          },
          "municipal_documents.fire_code_violations.conformance": {
            open: {
              get: getOpen("fire_code"),
              set: (value) => {
                setOpen("fire_code", value);
              },
            },
            options: {
              list: DocumentConformanceOptions?.getFireCodeViolationsOptions.map(
                (violation) => {
                  return { id: violation, name: violation };
                }
              ),
              filter: () => {
                /*Do Nothing*/
              },
            },
          },
          "municipal_documents.condemnation.conformance": {
            open: {
              get: getOpen("condemnation"),
              set: (value) => {
                setOpen("condemnation", value);
              },
            },
            options: {
              list: DocumentConformanceOptions?.getCondemnationOptions.map(
                (violation) => {
                  return { id: violation, name: violation };
                }
              ),
              filter: () => {
                /*Do Nothing*/
              },
            },
          },
          "municipal_documents.business_license.conformance": {
            open: {
              get: getOpen("business_license"),
              set: (value) => {
                setOpen("business_license", value);
              },
            },
            options: {
              list: DocumentConformanceOptions?.getBusinessLicenseOptions.map(
                (violation) => {
                  return { id: violation, name: violation };
                }
              ),
              filter: () => {
                /*Do Nothing*/
              },
            },
          },
          "municipal_documents.historical_landmark.conformance": {
            open: {
              get: getOpen("historical_landmark"),
              set: (value) => {
                setOpen("historical_landmark", value);
              },
            },
            options: {
              list: DocumentConformanceOptions?.getHistoricalLandmarkOptions.map(
                (violation) => {
                  return { id: violation, name: violation };
                }
              ),
              filter: () => {
                /*Do Nothing*/
              },
            },
          },
        }}
        formik={formik}
      />
    )
  );
};

AnalystMunicipalDocumentsContainer.propTypes = {
  id: PropTypes.number.isRequired,
  page: PropTypes.string,
};

export default AnalystMunicipalDocumentsContainer;
