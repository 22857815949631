// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import DisplayCellDisplay from "../../Display/JuniorAnalyst/DisplayCellDisplay";
//import useInterval from "../../Hooks/useInterval";
import { useFormik } from "formik";
import * as Yup from "yup";

const DisplayCellContainer = (props) => {
  const { index, params, getFormik } = props;
  const [openDisplay, setOpenDisplay] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...params.row },
    validationSchema: Yup.object({}),
    // eslint-disable-next-line no-unused-vars
    onSubmit: (values, formikTools) => {},
  });

  return (
    <DisplayCellDisplay
      index={index}
      params={params}
      openDisplay={{ get: openDisplay, set: setOpenDisplay }}
      formikRemote={getFormik()}
      formik={formik}
    />
  );
};

DisplayCellContainer.propTypes = {
  index: PropTypes.number.isRequired,
  params: PropTypes.object.isRequired,
  getFormik: PropTypes.func.isRequired,
  formik2: PropTypes.object,
};

export default DisplayCellContainer;
