import * as PropTypes from "prop-types";

export const subJurisdictionMap = {
  Locality: "subdivision",
  Subdivision: "administrative_area",
  AdministrativeArea: "country",
  Country: null,
};

export const GetSet = PropTypes.shape({
  get: PropTypes.any,
  set: PropTypes.func.isRequired,
});

export const ListFilter = PropTypes.shape({
  list: PropTypes.array,
  filter: PropTypes.func.isRequired,
  loading: PropTypes.bool,
});

export const AutoCompleteFilter = PropTypes.shape({
  open: GetSet,
  options: ListFilter,
});

export const Context = PropTypes.array.isRequired;
