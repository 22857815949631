import { createMuiTheme } from "@material-ui/core";

const Theme = createMuiTheme({
  palette: {
    primary: {
      light: "#5aacee",
      main: "#0b7dbb",
      dark: "#00518b",
    },
    secondary: {
      light: "#395374",
      main: "#072b48",
      dark: "#000021",
    },
    done: {
      light: "#33a532",
      main: "#2c602b",
      dark: "#061706",
    },
    background: {
      main: "#eeeeee",
    },
    text: {
      white: "#eeeeee",
    },
  },
});

export default Theme;
