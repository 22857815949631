import React from "react";
import * as PropTypes from "prop-types";
import WorxAutocompleteFormik from "../../Forms/Inputs/WorxAutocompleteFormik";
import WorxFormContainer from "../../../Container/Forms/Generic/WorxFormContainer";

const JuniorAnalystTenantFormDisplay = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { options, fields, formik } = props;

  return (
    <React.Fragment>
      <WorxAutocompleteFormik
        fieldKey={"use"}
        options={options?.use}
        formik={formik}
      />
      {fields && (
        <WorxFormContainer
          options={options}
          mapOverride={fields}
          hideMap={{ use: true, id: true }}
          formik={formik}
        />
      )}
    </React.Fragment>
  );
};

JuniorAnalystTenantFormDisplay.propTypes = {
  options: PropTypes.object.isRequired,
  fields: PropTypes.array,
  formik: PropTypes.object.isRequired,
};

export default JuniorAnalystTenantFormDisplay;
