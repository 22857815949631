import { gql } from "@apollo/client";
import { camelToTitle, camelToUnderscore } from "../tools/stringTools";
import {
  AdministrativeArea,
  BuildingCodeViolation,
  BusinessLicense,
  CertificateOfOccupancy,
  Condemnation,
  ConditionalUsePermit,
  ConformanceReportOrderItem,
  Country,
  FireCodeViolation,
  HistoricalLandmark,
  Locality,
  Order,
  Overlay,
  PD,
  SpecialUsePermit,
  Subdivision,
  Variance,
  worxAddress,
  worxFile,
  worxReference,
  Zone,
  ZoneUseLinkStatuses,
  ZoningCodeViolation,
  ZoningLetter,
} from "./Structures";

export const getJurisdictions = (type = "All") => {
  let map = {
    Locality: Locality,
    Subdivision: Subdivision,
    AdministrativeArea: AdministrativeArea,
    Country: Country,
  };

  let query = `query getJurisdictions {
    jurisdictions ${
      type !== "All" ? `(type: "${camelToUnderscore(type)}")` : ""
    } {
      items {
        id
        name
        type
        task_description
        ${type !== "All" ? map[type] : ""}
      }
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getJurisdiction = () => {
  let query = `query getJurisdiction($id: Int!) {
    jurisdiction(id: $id) {
      id
      name
      type
      task_description
      verification_state
      verified_date
      ${Locality}
      ${Subdivision}
      ${AdministrativeArea}
      ${Country}
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getQueueItemsShort = (queue) => {
  let query = `query getQueueItemsShort($queue: String${
    queue ? ` = "${queue}"` : ``
  }, $limit: Int, $offset: Int) {
    queueItems(queue: $queue, limit: $limit, offset: $offset) {
      items {
        id
        name
        type
        task_description
      }
      total
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getQueueItems = (queue) => {
  let query = `query getQueueItems($limit: Int, $offset: Int) {
    queueItems(queue: "${queue}", limit: $limit, offset: $offset) {
      items {
        id
        name
        type
        ${Locality}
        ${Subdivision}
        ${AdministrativeArea}
        ${Country}
      }
      total
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getClaimedQueueItems = () => {
  let query = `query getClaimedQueueItems($queue: String!) {
    claimedQueueItems(queue: $queue) {
      items {
        id
        name
        type
        task_description
      }
      total
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getClaimedReportItems = () => {
  let query = `query getClaimedReportItems($limit: Int = 10, $offset: Int = 0) {
    claimedReportItems(limit: $limit, offset: $offset) {
      items {
        ${ConformanceReportOrderItem}
      }
      total
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getJurisdictionLocality = (id) => {
  let query = `query getJurisdictionLocality {
    jurisdiction(id: ${id}) {
      id
      name
      type
      ... on Locality {
        id
        name
        code_definition {
            url
            link_text
        }
        subdivision {
          administrative_area {
            country {
              id
              name
              type
            }
            id
            name
            type
          }
          id
          name
          subdivision_type {
            description
            id
            name
            vid
          }
          type
        }
        type
      }
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getJurisdictionsLocality = () => {
  let query = `query getJurisdictionsLocality {
    jurisdictions(type: "Locality") {
      items {
        id
        name
        type
        ... on Locality {
          id
          name
          subdivision {
            administrative_area {
              country {
                id
                name
                type
              }
              id
              name
              type
            }
            id
            name
            subdivision_type {
              description
              id
              name
              vid
            }
            type
          }
          type
        }
      }
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getUser = () => {
  let query = `query UserQuery {
    currentUser {
      id
      mail
      name
      roles
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getUserById = (id) => {
  let query = `query getUserById($id: Int ${id ? ` = ${id}` : ``}) {
    user(id: $id) {
      ${worxReference}
      mail
      billing_information {
        address {${worxAddress}}
        id
        type
        user {${worxReference}}
      }
      contact_information {
        address {${worxAddress}}
        id
        type
        user {${worxReference}}
      }
      prepared_for {
        address {${worxAddress}}
        id
        type
        user {${worxReference}}
      }
      roles
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getUsers = (role) => {
  let query = `query getUsers($roles: [String] ${role ? ` = "${role}"` : ``}) {
    users(roles: $roles) {
      items {
        id
        name
        billing_information {
          address {
            address_line1
            address_line2
            administrative_area
            country_code
            locality
            postal_code
          }
          id
          type
          user {
            id
            name
          }
        }
        contact_information {
          address {
            address_line1
            address_line2
            administrative_area
            country_code
            locality
            postal_code
          }
          fax
          id
          phone
          type
          user {
            id
            name
          }
        }
        mail
        prepared_for {
          address {
            address_line1
            address_line2
            administrative_area
            country_code
            locality
            postal_code
          }
          fax
          id
          phone
          type
          user {
            id
            name
          }
        }
        roles
      }
      total
    }
  }`;
  return gql`
    ${query}
  `;
};

// page one
export const getJurisdictionTypeOptions = (
  type,
  defaultName = "",
  defaultLimit = 50
) => {
  let query = `query getJurisdictionTypeOptions($name: String = "${defaultName}", $limit: Int = ${defaultLimit}) {
      ${type.toLowerCase()}TypeOptions(name: $name, limit: $limit) {
        items {
          id
          name
        }
      }
    }
  `;
  return gql`
    ${query}
  `;
};

export const getJurisdictionOptions = (
  type,
  defaultName = "",
  defaultLimit = 50
) => {
  let query = `query getJurisdictionOptions($name: String = "${defaultName}", $limit: Int = ${defaultLimit}, $offset: Int = 0) {
      jurisdictions(${
        type ? `type: "${type}", ` : ``
      } limit: $limit, name: $name, offset: $offset) {
        items {
          id
          name
          type
          verified_date
          verification_state
          task_description
        }
      }
    }
  `;
  return gql`
    ${query}
  `;
};

// page two
export const getCategoriesForJurisdiction = (
  type = "zone_list",
  jurisdiction,
  defaultLimit = 25
) => {
  let query = `query getCategoryOptions($name: String = "", $limit: Int = ${defaultLimit}, $offset: Int = 0, $operator: String = "CONTAINS") {
      categories(type: "${type}", jurisdiction: ${jurisdiction}, name: $name, limit: $limit, offset: $offset, operator: $operator) {
        total
        items {
          id
          name
          ${type === "zoneuselink_statuses" ? `${ZoneUseLinkStatuses}` : ``}
        }
      }
    }
  `;
  return gql`
    ${query}
  `;
};

// page three
export const getZonesForJurisdiction = (
  type = "all",
  id = 0,
  defaultLimit = 100
) => {
  let query = `query getZonesForJurisdiction($designation: String = "", $limit: Int = ${defaultLimit}, $name: String = "", $offset: Int = 0, $jurisdiction: Int = ${id}, $operator: String = "CONTAINS") {
    zones(limit: $limit, jurisdiction: $jurisdiction ${
      type !== "all" ? `, type: "${type}"` : ``
    }, designation: $designation, name: $name, offset: $offset, operator: $operator) {
      count
      items {
        id
        name
        type
        ${type !== "all" ? window[type] : ``}
        ${
          type === "all"
            ? `${Zone}
          ${Overlay}
          ${PD}`
            : ``
        }
      }
    }
  }`;
  return gql`
    ${query}
  `;
};

// page five
export const getUsesForJurisdiction = (jurisdiction, defaultLimit = 15) => {
  let query = `query getUsesForJurisdiction($limit: Int = ${defaultLimit}, $offset: Int = 0, $name: String = "", $operator: String = "=", $jurisdiction: Int = ${
    jurisdiction ?? 0
  }) {
    uses(jurisdiction: $jurisdiction, limit: $limit, offset: $offset, name: $name, operator: $operator) {
      count
      items {
        name
        weight
        certificate_required
        code_location
        id
        jurisdiction {
          id
          name
        }
        category {
          id
          name
        }
        parking {
          id
          min_max
          name
          parking {
            id
            name
          }
          secondary_variable
          description
          spaces
          use {
            id
            name
          }
          zone {
            id
            name
            designation
          }
        }
      }
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getFootnotes = (jurisdiction, type) => {
  let query = `query getFootnotes($limit: Int = 25, $offset: Int = 0, $name: String = ""){
    footnotes(jurisdiction: ${jurisdiction}, name: $name, type: "${type}", limit: $limit, offset: $offset) {
      total
      items {
        id
        name
        type
        footnote
        jurisdiction {
          id
          name
        }
      }
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getCategoryOptions = (
  jurisdiction,
  type = "use_list",
  defaultName = "",
  defaultLimit = 50
) => {
  let query = `query getCategoryOptions($name: String = "${defaultName}", $limit: Int = ${defaultLimit}, $offset: Int = 0) {
    categories(jurisdiction: ${jurisdiction}, name: $name, limit: $limit, type: "${type}", offset: $offset) {
      items {
        id
        name
      }
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getParkingOptions = (defaultName = "", defaultLimit = 50) => {
  let query = `query getParkingOptions($name: String = "${defaultName}", $limit: Int = ${defaultLimit}) {
    parkings(name: $name, limit: $limit) {
      items {
        id
        name
      }
    }
  }`;
  return gql`
    ${query}
  `;
};

// page codes
export const getCodeSections = (id, type) => {
  let query = `query getCodeSections($limit: Int = 10, $offset: Int = 0, $name: String = "") {
    codeSections(limit: $limit, offset: $offset, name: $name, jurisdiction: ${id}${
    type ? ` , code_section_type: "${type}"` : ``
  }) {
      items {
        code
        code_section_type
        id
        jurisdiction {
          id
          name
        }
        code_date
        name
        code_file {
          id
          filename
          filepath
        }
      }
      total
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getCodeSectionTypes = () => {
  let query = `query getCodeSectionTypes {
    codeSectionTypeOptions {
      id
      name
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getMatrix = (jurisdictionId, defaultLimit = 25) => {
  let query = `query getMatrix($limit: Int = ${defaultLimit}, $offset: Int = 0, $use: String, $zone: String) {
    useMatrix(use: $use, zone: $zone, limit: $limit, offset: $offset,  jurisdiction: ${jurisdictionId}) 
  }`;
  return gql`
    ${query}
  `;
};

export const getZoneUseLinkStatusParents = () => {
  let query = `query ZoneUseLinkStatusParents {
    zoneUseLinkStatusParents {
      id
      name
    }
  }`;

  return gql`
    ${query}
  `;
};

export const getRequirementsMatrix = (jurisdictionId, defaultLimit = 25) => {
  let query = `query getRequirementsMatrix($limit: Int = ${defaultLimit}, $offset: Int = 0, $requirement: String, $zone: String) {
    requirementMatrix(requirement: $requirement, zone: $zone, limit: $limit, offset: $offset, jurisdiction: ${jurisdictionId})
  }`;
  return gql`
    ${query}
  `;
};

export const getRequirementsForJurisdiction = (
  jurisdictionId,
  defaultLimit = 100
) => {
  let query = `query getRequirementsForJurisdiction($limit: Int = ${defaultLimit}, $name: String = "", $offset: Int = 0) {
    requirements(jurisdiction: ${jurisdictionId}, limit: $limit, name: $name, offset: $offset) {
      total
      items {
        calculation {
          id
          name
        }
        code_location
        description
        id
        jurisdiction {
          id
          name
        }
        name
        weight
      }
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getCalculations = (defaultLimit = 100) => {
  let query = `query getCalculations($limit: Int = ${defaultLimit}, $name: String = "", $offset: Int = 0) {
    calculations(limit: $limit, name: $name, offset: $offset) {
      items {
        id
        name
      }
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getMediaImages = () => {
  let query = `query getMediaImages($jurisdiction: Int = 4, $limit: Int = 25, $name: String = "", $offset: Int = 0) {
    mediaImages(jurisdiction: $jurisdiction, limit: $limit, name: $name, offset: $offset) {
      items {
        image {
          filename
          filepath
          id
        }
        id
        jurisdiction {
          id
          name
        }
        name
        property {
          id
          name
        }
        type
      }
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getMedia = (type) => {
  let query = `query getMedia${camelToTitle(
    type
  )}s($jurisdiction: Int, $property: Int $limit: Int = 25, $name: String = "", $offset: Int = 0${
    type === "document" ? `, $document_type: JSON` : ``
  }) {
    media${camelToTitle(
      type
    )}s(jurisdiction: $jurisdiction, property: $property, limit: $limit, name: $name, offset: $offset${
    type === "document" ? `, document_type: $document_type` : ``
  }) {
      items {
        ${type} {
          filename
          filepath
          id
        }
        id
        jurisdiction {
          id
          name
        }
        name
        property {
          id
          name
        }
        ${
          type === "document"
            ? `document_type {
          id
          name
          vid
        }`
            : ``
        }
        type
      }
      total
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getProperty = (id) => {
  let query = `query getProperty($id: Int = ${id}) {
    property(id: $id) {
      abutting_zoning {
        id
        name
        zoning {
          id
          name
          designation
        }
      }
      address {
        additional_name
        address_line1
        address_line2
        administrative_area
        country_code
        dependent_locality
        family_name
        given_name
        langcode
        locality
        organization
        postal_code
        sorting_code
      }
      id
      name
      jurisdiction {
        id
        name
      }
      google_map {
        id
        name
        image {
          filename
          id
          filepath
        }
      }
      notes
      parcel_map {
        id
        name
        image {
          filename
          id
          filepath
        }
      }
      parcel_tax_id
      use {
        id
        name
      }
      zone {
        id
        name
        designation
      }
      zoning_map {
        id
        name
        image {
          filename
          id
          filepath
        }
      }
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getProperties = () => {
  let query = `query properties($jurisdiction: Int, $limit: Int = 25, $name: String = "", $offset: Int = 0, $operator: String = "=") {
    properties(jurisdiction: $jurisdiction, limit: $limit, name: $name, offset: $offset, operator: $operator) {
      count
      items {
        abutting_zoning {
        ${worxReference}
        zoning {
          ${worxReference}
        }
      }
      address {
        ${worxAddress}
      }
      ${worxReference}
      jurisdiction {
        ${worxReference}
        verified_date
        verification_state
        task_description
        type
      }
      google_map {
        ${worxReference}
        image {
          ${worxFile}
        }
      }
      notes
      parcel_map {
        ${worxReference}
        image {
          ${worxFile}
        }
      }
      parcel_tax_id
      use {
        ${worxReference}
      }
      zone {
        ${worxReference}
      }
      zoning_map {
        ${worxReference}
        image {
          ${worxFile}
        }
      }
    }
  }}`;
  return gql`
    ${query}
  `;
};

export const getOrdersShort = () => {
  let query = `query getOrdersShort($limit: Int = 25, $offset: Int = 0, $client: String, $id: Int, $state: String) {
    orders(limit: $limit, offset: $offset, client: $client, id: $id, state: $state) {
      items {
        id
        portfolio_name
        report_order_items {
          ... on ConformanceReportOrderItem {
            ${worxReference}
            property {
              ${worxReference}
              address {${worxAddress}}
            }
            jurisdiction {
              ${worxReference}
            }
          }
        }
        client {
          id
          mail
          name
          roles
        }
        state
      }
      total
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getOrders = () => {
  let query = `query getOrders($limit: Int = 25, $offset: Int = 0, $client: String, $id: Int, $state: String) {
    orders(limit: $limit, offset: $offset, client: $client, id: $id, state: $state) {
      items {
        adjustments {
          id
          amount
          label
          type
        }
        client_reference_number
        id
        order_number
        portfolio_name
        report_due_date
        report_order_items {
          ${ConformanceReportOrderItem}
        }
        total_orders
        created
        order_balance
        placed
        state
        total_paid
        total_price
        subtotal_price
        client {
          id
          mail
          name
          roles
        }
        billing_information {
          id
          address {
            address_line1
            address_line2
            administrative_area
            country_code
            locality
            postal_code
          }
          type
        }
        contact_information {
          id
          address {
            address_line1
            address_line2
            administrative_area
            country_code
            locality
            postal_code
          }
          fax
          phone
          type
        }
        prepared_for {
          id
          address {
            address_line1
            address_line2
            administrative_area
            country_code
            locality
            postal_code
          }
          fax
          phone
          type
        }
      }
      total
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getOrder = (id) => {
  let query = `query getOrder($id: Int${id ? ` = ${id}` : ``}) {
    order(id: $id) {
      adjustments {
        amount
        id
        label
        type
      }
      billing_information {
        address {${worxAddress}}
        id
        type
        user {
          id
          name
        }
      }
      client {
        id
        name
        roles
      }
      client_reference_number
      created
      id
      contact_information {
        address {${worxAddress}}
        fax
        id
        phone
        type
        user {
          id
          name
        }
      }
      order_balance
      order_number
      placed
      portfolio_name
      prepared_for {
        address {${worxAddress}}
        fax
        id
        phone
        type
        user {
          id
          name
        }
      }
      report_due_date
      report_order_items {
        ${ConformanceReportOrderItem}
      }
      state
      subtotal_price
      total_orders
      total_paid
      total_price
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getOrderStateOptions = (order) => {
  let query = `query getOrderStateOptions($order: Int ${
    order ? `= ${order}` : ``
  }) {
    stateTransitions(order: $order) {
      id
      label
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getDocumentOrderTemplates = (jurisdiction) => {
  let query = `query getDocumentOrderTemplates($jurisdiction: Int! ${
    jurisdiction ? ` = ${jurisdiction}` : ``
  }) {
    documentOrderTemplates(jurisdiction: $jurisdiction) {
      id
      name
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getAdjustmentTypes = () => {
  let query = `query getAdjustmentTypes {
    adjustmentTypes {
      id
      name
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getBvViolations = () => {
  let query = `query getBvViolations {
    bvViolations {
      id
      name
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getFvViolations = () => {
  let query = `query getFvViolations {
    fvViolations {
      id
      name
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getZvViolations = () => {
  let query = `query getZvViolations {
    zvViolations {
      id
      name
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getHistoricalLandmarkAnswers = () => {
  let query = `query getHistoricalLandmarkAnswers {
    historicalLandmarkAnswers {
      id
      name
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getTenants = () => {
  let query = `query getTenants($property: Int!) {
    tenants(property: $property) {
      id
      name
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getCnPlans = () => {
  let query = `query getCnPlans {
    cnPlans {
      id
      name
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getDocumentTypes = () => {
  let query = `query getDocumentTypes {
    documentTypes {
      id
      name
      vid
      description
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getRequirementCell = () => {
  let query = `query getRequirementCell($requirements: [Int], $zones: [Int]) {
    zoneRequirementCell(requirements: $requirements, zones: $zones) {
      id
      name
      maximum
      minimum
      requirement {
        id
        name
      }
      zone {
        id
        name
      }
      use {
        id
        name
      }
      code_location
      footnote {
        id
        name
      }
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getUseCell = () => {
  let query = `query getUseCell($uses: [Int], $zones: [Int]) {
    zoneUseCell(uses: $uses, zones: $zones) {
      allowed_use_type
      footnote {
        id
        name
      }
      id
      name
      status {
        id
        name
      }
      use {
        id
        name
        weight
      }
      zone {
        id
        name
      }
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getJurisdictionFiles = (jurisdiction, limit = 10) => {
  let query = `query getJurisdictionFiles($limit: Int = ${limit}, $offset: Int = 0) {
    jurisdictionFiles(jurisdiction: ${jurisdiction}, limit: $limit, offset: $offset) {
      items {
        filename
        filepath
        id
      }
      total
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getJuniorAnalystQueueItemsShort = (defaultLimit = 50) => {
  let query = `query getJuniorAnalystQueueItemsShort($limit: Int = ${defaultLimit}, $offset: Int = 0) {
    juniorAnalystQueueItems(limit: $limit, offset: $offset) {
      items {
        ${worxReference}
        property {
          ${worxReference}
          address {${worxAddress}}
        }
        jurisdiction {
          ${worxReference}
        }
        order_item_reference {
          order {
            id
            portfolio_name
            client {
              ${worxReference}
              mail
              roles
            }
            state
          }
        }
        document_type {
          id
          name
        }
      }
      total
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getJuniorAnalystQueueItems = (defaultLimit = 50) => {
  let query = `query getJuniorAnalystQueueItems($limit: Int = ${defaultLimit}, $offset: Int = 0) {
    juniorAnalystQueueItems(limit: $limit, offset: $offset) {
      items {
        document {
          id
          filepath
          filename
        }
        document_type {
          id
          name
        }
        id
        jurisdiction {
          id
          name
        }
        name
        property {
          id
          name
          address {${worxAddress}}
          google_map {
            image {${worxFile}}
          }
          parcel_map {
            image {${worxFile}}
          }
          zoning_map {
            image {${worxFile}}
          }
          abutting_zoning {
            id
            name
          }
        }
        order_item_reference {
          order {${Order}}
        }
        type
      }
      total
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getJuniorAnalystClaimedQueueItems = (limit = 50) => {
  let query = `query getJuniorAnalystClaimedQueueItems($limit: Int = ${limit}, $offset: Int = 0 ) {
    juniorAnalystClaimedQueueItems(limit: $limit, offset: $offset) {
      items {
        document {
          id
          filepath
          filename
        }
        document_type {
          id
          name
        }
        id
        jurisdiction {
          id
          name
        }
        name
        property {
          id
          name
        }
        type
      }
      total
    }
  }`;
  return gql`
    ${query}
  `;
};

//here
export const getAnalystQueueItems = (limit = 50) => {
  let query = `query getAnalystQueueItems($limit: Int = ${limit}, $offset: Int = 0) {
    analystQueueItems(limit: $limit, offset: $offset) {
      items {
        conformance_report_order_item {${ConformanceReportOrderItem}}
        id
      }
      total
    }
  }
  `;
  return gql`
    ${query}
  `;
};

export const getAnalystQueueItemsShort = (limit = 50) => {
  let query = `query getAnalystQueueItemsShort($limit: Int = ${limit}, $offset: Int = 0) {
    analystQueueItems(limit: $limit, offset: $offset) {
      items {
        id
        conformance_report_order_item {
          ... on ConformanceReportOrderItem {
            ${worxReference}
            order {
              id
              portfolio_name
              client {
                ${worxReference}
                mail
                roles
              }
              state
            }
            jurisdiction {
              ${worxReference}
            }
            property {
              ${worxReference}
              address {${worxAddress}}
            }
          }
        }
      }
      total
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getAnalystClaimedQueueItems = (limit = 50) => {
  let query = `query getAnalystClaimedQueueItems($limit: Int = ${limit}, $offset: Int = 0 ) {
    analystClaimedQueueItems(limit: $limit, offset: $offset) {
      items {
        conformance_report_order_item {${ConformanceReportOrderItem}}
        id
      }
      total
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getResearchQueueItemsShort = (limit = 50) => {
  let query = `query getResearchQueueItemsShort($limit: Int = ${limit}, $offset: Int = 0) {
    researchQueueItems(limit: $limit, offset: $offset) {
      items {
        ... on ConformanceReportOrderItem {
          ${worxReference}
          order {
            id
            portfolio_name
            client {
              ${worxReference}
              mail
              roles
            }
            state
          }
          jurisdiction {
            ${worxReference}
          }
          property {
            ${worxReference}
            address {${worxAddress}}
          }
        }        
      }
      total
    }
  }
  `;
  return gql`
    ${query}
  `;
};

/*export const getResearchQueueItems = (limit = 50) => {
  let query = `query getAnalystQueueItems($limit: Int = ${limit}, $offset: Int = 0) {
    researchQueueItems(limit: $limit, offset: $offset) {
      items {${ConformanceReportOrderItem}}
      total
    }
  }
  `;
  return gql`${query}`
};*/

export const getResearchClaimedQueueItems = (limit = 50) => {
  let query = `query getResearchClaimedQueueItems($limit: Int = ${limit}, $offset: Int = 0 ) {
    researchClaimedQueueItems(limit: $limit, offset: $offset) {
      items {
        ... on ConformanceReportOrderItem {
            ${worxReference}
            order {
              id
              portfolio_name
              client {
                ${worxReference}
                mail
                roles
              }
              state
            }
            jurisdiction {
              ${worxReference}
            }
            property {
              ${worxReference}
              address {${worxAddress}}
            }
          }
        }
      total
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getAnalystReport = (id) => {
  let query = `query getAnalystReport($id: Int!${id ? ` = ${id}` : ``}) {
    analystReport(id: $id) {
      id
      conformance_report_order_item {${ConformanceReportOrderItem}}
      use_conformance
      structure_conformance
      reconstruction_notes
      reconstruction_clause
      parking_conformance
      lot_size_density_conformance
      deficiencies
      conformance_notes
      
      cofo_answers
      cofo_notes
      
      requirements_answers
      requirements_notes
      
      parking_answers
      parking_conformance
      parking_notes
      
      municipal_documents
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getApplicableRequirementsTable = (property = 50) => {
  let query = `query getApplicableRequirementsTable($property: Int = ${property}) {
    getApplicableRequirementsTable(property: $property) {
      id
      table
      footnotes
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getMediaDocument = (id = 0) => {
  let query = `query getMediaDocument($id: Int = ${id}) {
    mediaDocument(id: $id) {
      ${worxReference}
      jurisdiction {${worxReference}}
      property {
        id
        name
        address {${worxAddress}}
        google_map {
          image {${worxFile}}
        }
        parcel_map {
          image {${worxFile}}
        }
        zoning_map {
          image {${worxFile}}
        }
        abutting_zoning {
          id
          name
          zoning {
            id
            name
          }
        }
      }
      type
      document {${worxFile}}
      document_type {${worxReference}}
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getTenantFieldsToDisplay = (use) => {
  let query = `query getTenantFieldsToDisplay($use: Int!${
    use ? ` = ${use}` : ``
  }) {
    getTenantFieldsToDisplay(use: $use)
  }`;
  return gql`
    ${query}
  `;
};

export const getSurveyFieldsToDisplay = (id) => {
  let query = `query getSurveyFieldsToDisplay($property: Int!${
    id ? ` = ${id}` : ``
  }) {
    getSurveyFieldsToDisplay(property: $property)
  }`;
  return gql`
    ${query}
  `;
};

export const getResidentialOrders = () => {
  let query = `query residentialOrders($client: String, $id: Int, $limit: Int, $offset: Int, $state: String) {
    residentialOrders(
      client: $client
      id: $id
      limit: $limit
      offset: $offset
      state: $state
    ) {
      items {
        id
        adjustments {
          amount
          id
          label
          type
        }
        billing_information {
          address {
            ${worxAddress}
          }
          id
          type
          user {${worxReference}}
        }
        client {${worxReference}}
        contact_information {
          id
          address {
            ${worxAddress}
          }
        }
        report_order_items {
          ... on ResidentialReportOrderItem {
            ${worxReference}
            property {${worxReference}}
            jurisdiction {${worxReference}}
          }
        }
        created
        order_number
      }
      total
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getPropertyTypeValues = () => {
  let query = `query getPropertyTypeValues {
    getPropertyTypeValues
  }`;
  return gql`
    ${query}
  `;
};

export const getOrderItemStateTransitions = () => {
  let query = `query getOrderItemStateTransitions ($id: Int) {
    orderItemStateTransitions(order_item: $id) {
      id
      label
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getCOfOUses = () => {
  let query = `query getCOfOUses ($id: Int, $limit: Int, $offset: Int) {
    getCOfOUses(id: $id, limit: $limit, offset: $offset) {
      rows {
        columns {
          use {
            id
            name
          }
        }
      }
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getConformanceOptions = () => {
  let query = `query getConformanceOptions {
      getConformanceOptions
    }
  `;
  return gql`
    ${query}
  `;
};

export const getCOfOAttachedOptions = () => {
  let query = `query getCOfOAttachedOptions {
    getCofOAttachedOptions
  }`;
  return gql`
    ${query}
  `;
};

export const getCofOConformanceOptions = () => {
  let query = `query getCofOConformanceOptions {
    getCofOConformanceOptions
  }`;
  return gql`
    ${query}
  `;
};

export const getDocumentConformanceOptions = () => {
  let query = `query getDocumentConformanceOptions {
    getZoningCodeViolationsOptions
    getBuildingCodeViolationsOptions
    getFireCodeViolationsOptions
    getCondemnationOptions
    getBusinessLicenseOptions
    getHistoricalLandmarkOptions
  }`;
  return gql`
    ${query}
  `;
};

export const getParkingConformanceOptions = () => {
  let query = `query getParkingConformanceOptions {
    getParkingConformanceOptions
  }`;
  return gql`
    ${query}
  `;
};

export const getResearchDocuments = () => {
  let query = `query getResearchDocuments($limit: Int, $name: String, $offset: Int, $property: Int, $type: String) {
    researchDocuments(limit: $limit, name: $name, offset: $offset, property: $property, type: $type) {
      items {
        id
        ${ZoningLetter}
        ${ZoningCodeViolation}
        ${BuildingCodeViolation}
        ${BusinessLicense}
        ${CertificateOfOccupancy}
        ${Condemnation}
        ${ConditionalUsePermit}
        ${FireCodeViolation}
        ${HistoricalLandmark}
        ${SpecialUsePermit}
        ${Variance}
      }
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getFannieForm = (id) => {
  let query = `query getFannieForm($id: Int${id ? ` = ${id}`: ``}) {
    fannieForm(id: $id) {
      any_special_zoning_permitting_or_conditions
      available_certificates_of_occupancy
      collateral_reference_number
      concluding_zoning_conformance_status
      deal_identifier
      dus_lender_name
      existing_parking_conformance
      existing_land_use_conformance
      existing_building_size_conformance
      existing_building_set_back_lines_conformance
      existing_building_density_converage_conformance
      id
      zoning_recommended_action
      zoning_nonconforming_characteristics
      zoning_designation
      zip_code
      third_party_report_request_name
      third_party_report_type
      third_part_report_signature_date
      third_part_report_signatory_name
      third_part_report_scope_standard_description
      third_part_report_scope_standard
      third_part_report_company_name
      third_part_report_project_identifier
      third_part_report_assessor_name
      state
      recorded_zoning_violation
      recorded_fire_code_violation
      recorded_building_code_violation
      rebuildability_clause
      property_name
      property_land_area_in_acres
      property_damage_threshold_percent
      property_address
      number_of_required_parking_spaces
      number_of_existing_parking_spaces
      jurisdiction
    }
  }`;
  return gql`
    ${query}
  `;
};

export const getFannieOptions = () => {
  let query = `query getFannieOptions {
    getFannieConformanceOptions
    getFannieOptions
  }`;
  return gql`
      ${query}
  `;
}

export const getCommercialQueueItems = () => {
  let query = `query getCommercialQueueItems($limit: Int, $offset: Int) {
    commercialQueueItems(limit: $limit, offset: $offset) {
      items {
        id
        state
        total_price
        total_paid
        subtotal_price
        placed
        order_number
        order_balance
        billing_information {
          address {${worxAddress}}
        }
        client {
          id
          name
        }
        contact_information {
          address {${worxAddress}}
        }
        report_order_items {
          ... on CommercialReportOrderItem {
            id
            name
            property {
              address {${worxAddress}}
            }
          }
        }
      }
    }
  }`;
  return gql`
      ${query}
  `;
}

export const getCommercialClaimedQueueItems = () => {
  let query = `query getCommercialClaimedQueueItems($limit: Int, $offset: Int) {
    commercialClaimedQueueItems(limit: $limit, offset: $offset) {
      items {
        id
        state
        total_price
        total_paid
        subtotal_price
        placed
        order_number
        order_balance
        billing_information {
          address {${worxAddress}}
        }
        client {
          id
          name
        }
        contact_information {
          address {${worxAddress}}
        }
        report_order_items {
          ... on CommercialReportOrderItem {
            id
            name
            property {
              address {${worxAddress}}
            }
          }
        }
      }
      total
    }
  }`;
  return gql`
      ${query}
  `;
}

export const getCommercialOrder = () => {
  let query = `query getCommercialOrder($id: Int!) {
    commercialOrder(id: $id) {
      id
        state
        total_price
        total_paid
        subtotal_price
        placed
        order_number
        order_balance
        billing_information {
          address {${worxAddress}}
        }
        client {
          id
          name
        }
        contact_information {
          address {${worxAddress}}
        }
        report_order_items {
          ... on CommercialReportOrderItem {
            id
            name
            address {${worxAddress}}
            property {
              id
              jurisdiction {${worxReference}}
              zone {${worxReference}}
              address {${worxAddress}}
            }
            property {
              id
              jurisdiction {
                id
                name
              }
              zone {
                id
                name
              }
            }
            report_type
            status
          }
        }
    }
  }`;
  return gql`
      ${query}
  `;
}

export const getCommercialReportItem = () => {
  let query = `query getCommercialReportItem($id: Int!) {
    commercialReportItem(id: $id) {
      id
      name
      property {
        ${worxReference}
        address {${worxAddress}}
        zone {${worxReference}}
        jurisdiction {${worxReference}}
      }
      address {${worxAddress}}
      quantity
      total_price
      unit_price
    }
  }`;
  return gql`
      ${query}
  `;
}


