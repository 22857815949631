import React from "react";
import * as PropTypes from "prop-types";
import WorxTextField from "../../Forms/Inputs/WorxTextField";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@mui/styles";
import WorxAutocompleteFormik from "../../Forms/Inputs/WorxAutocompleteFormik";
import { AutoCompleteFilter } from "../../../Constants/Jurisdiction";

const buttonUseStyles = makeStyles({
  root: {
    marginLeft: "1em",
  },
});

const useStyles = makeStyles({
  worxSpaceAround: {
    margin: "1em",
  },
});

const DocumentOrderItemFormDisplay = (props) => {
  const { templateOptions, availableReports, formik } = props;

  const buttonClasses = buttonUseStyles();
  const classes = useStyles();

  return (
    <div className={classes.worxSpaceAround}>
      <WorxTextField fieldKey={"name"} formik={formik} />
      <WorxAutocompleteFormik
        fieldKey={"report_parent"}
        options={availableReports}
        formik={formik}
      />
      <WorxAutocompleteFormik
        fieldKey={"document_order_template"}
        options={templateOptions}
        formik={formik}
      />
      <WorxTextField
        fieldKey={"unit_price"}
        type={"number"}
        trim={2}
        formik={formik}
      />
      <WorxTextField fieldKey={"quantity"} type={"number"} formik={formik} />
      <Button
        variant={"contained"}
        color="primary"
        margin="normal"
        onClick={() => {
          formik.handleSubmit();
        }}
        classes={{ root: buttonClasses.root }}
      >
        Submit
      </Button>
    </div>
  );
};

DocumentOrderItemFormDisplay.propTypes = {
  availableReports: AutoCompleteFilter,
  templateOptions: AutoCompleteFilter,
  formik: PropTypes.object.isRequired,
};

export default DocumentOrderItemFormDisplay;
