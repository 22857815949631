import React from "react";
import App from "./App";
import DevTools, { roles } from "./DevTools/DevTools";
import {useWorker} from "./Hooks/useWorker";
import useLocalStorage from "use-local-storage";
import {useLocation} from "react-router-dom";


export default function AppWithDevTools() {
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location.search));

  const storageKey = "activeRoles";
  let initialRoles = roles;

  if(params.roles) {
    initialRoles = params.roles.split(",");
    localStorage.removeItem(storageKey);
  }

  const [activeRoles, setActiveRoles] = useLocalStorage(storageKey, initialRoles);
  const isReady = useWorker({activeRoles});

  if(!isReady) {
    return null;
  }

  return <React.Fragment>
    <App mocked={true} />
    <DevTools activeRoles={activeRoles} setActiveRoles={setActiveRoles} />
  </React.Fragment>
}
