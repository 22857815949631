import React from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import Button from "@material-ui/core/Button";
import { PropTypes } from "prop-types";
import { Card, CardContent, StepButton } from "@material-ui/core";
import { makeStyles } from "@mui/styles";
import { GetSet } from "../../../Constants/Jurisdiction";
import RequirementsFormContainer from "../../../Container/DataEntry/DataEntryThree_RequirmentsWorkflow/Forms/RequirementsFormContainer";
import RequirementsFootnoteFormContainer from "../../../Container/DataEntry/DataEntryThree_RequirmentsWorkflow/Forms/RequirementsFootnoteFormContainer";
import DocumentDrawerContainer from "../../../Container/DataEntry/DocumentDrawerContainer";
import RequirementsGridMatrixContainer from "../../../Container/DataEntry/DataEntryThree_RequirmentsWorkflow/Forms/RequirementsGridMatrixContainer";

const useStyles = makeStyles({
  worxButtonStyleOverride: {
    marginLeft: "1em",
  },
  right: {
    float: "right",
  },
});

// eslint-disable-next-line no-unused-vars
const appBarUseStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    textAlign: "right",
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const topCardUseStyles = makeStyles({
  root: {
    marginBottom: "2em",
  },
});

const RequirementsWorkflowDisplay = (props) => {
  let {
    steps,
    step,
    lastStep,
    data,
    releaseJurisdiction,
    removeJurisdiction,
    setHeaderTitle,
    documentsOpen,
  } = props;
  const classes = useStyles();
  const topCardClasses = topCardUseStyles();

  setHeaderTitle(data.get.name);

  let offset = 0;
  if (step.get !== 0) {
    offset = step.get - 1;
  }

  return (
    <React.Fragment>
      {data.get && (
        <React.Fragment>
          <Card classes={{ root: topCardClasses.root }}>
            <CardContent>
              <Button
                className={classes.right}
                onClick={() => documentsOpen.set(!documentsOpen.get)}
                variant="contained"
              >
                Documents
              </Button>
              <DocumentDrawerContainer
                jurisdiction={data.get.id}
                open={documentsOpen}
              />
              {step.get === 0 && (
                <RequirementsFootnoteFormContainer
                  step={step.get}
                  data={data}
                />
              )}
              {step.get === 1 && (
                <RequirementsFormContainer step={step.get} data={data} />
              )}
              {step.get === 2 && (
                <RequirementsGridMatrixContainer step={step.get} data={data} />
              )}
              {/*step.get === 2 && <DataEntryGridWrapper step={step.get} jurisdiction={data.get.id} />*/}
              {step.get === 3 && (
                <RequirementsFormContainer step={step.get} data={data} />
              )}
              {step.get === 4 && (
                <RequirementsGridMatrixContainer step={step.get} data={data} />
              )}
              {/*step.get === 4 && <DataEntryGridWrapper step={step.get} jurisdiction={data.get.id} />*/}
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              <Stepper nonLinear activeStep={step.get}>
                {steps.slice(offset, offset + 3).map((label, index) => {
                  return (
                    <Step key={label} active={index === step.get - offset}>
                      <StepButton completed={index < 1 && step.get !== 0}>
                        {label}
                      </StepButton>
                    </Step>
                  );
                })}
              </Stepper>
              <Button
                onClick={() => step.set(step.get - 1)}
                disabled={step.get === 0}
                variant="contained"
              >
                Back
              </Button>
              {step.get < lastStep && (
                <Button
                  onClick={() => step.set(step.get + 1)}
                  variant="contained"
                  color="primary"
                  className={classes.worxButtonStyleOverride}
                >
                  Next
                </Button>
              )}
              <Button
                onClick={() => releaseJurisdiction()}
                variant="contained"
                color="Secondary"
                className={classes.worxButtonStyleOverride}
              >
                Release Jurisdiction
              </Button>
              {step.get === lastStep && (
                <Button
                  onClick={() => {
                    step.set(step.get + 1);
                    removeJurisdiction();
                  }}
                  variant="contained"
                  color="primary"
                >
                  Finish
                </Button>
              )}
            </CardContent>
          </Card>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

RequirementsWorkflowDisplay.propTypes = {
  steps: PropTypes.array.isRequired,
  step: GetSet,
  data: GetSet,
  releaseJurisdiction: PropTypes.func.isRequired,
  removeJurisdiction: PropTypes.func.isRequired,
  renderKey: PropTypes.number.isRequired,
  lastStep: PropTypes.number.isRequired,
  setHeaderTitle: PropTypes.func,
  documentsOpen: GetSet,
};

export default RequirementsWorkflowDisplay;
