import React, {useContext, useEffect} from "react";
import * as PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
//import {useRouteMatch} from "react-router-dom";
import HeaderTitleContext from "../../Context/HeaderTitleContext";
import AnalystReportStatusContainer from "./AnalystReportStatusContainer";
import AnalystStepperDisplay from "../../Display/Analyst/AnalystStepperDisplay";
import AnalystPropertyStatusContainer from "./AnalystPropertyStatusContainer";
import AnalystConformanceContainer from "./AnalystConformanceContainer";
import AnalystReconstructionContainer from "./AnalystReconstructionContainer";
import AnalystRequirementsContainer from "./AnalystRequirementsContainer";
import AnalystCOfOContainer from "./AnalystCOfOContainer";
import AnalystMunicipalDocumentsContainer from "./AnalystMunicipalDocumentsContainer";
import AnalystParkingContainer from "./AnalystParkingContainer";
import AnalystConformanceReportContainer from "./AnalystConformanceReportContainer";
import AnalystFannieContainer from "./AnalystFannieContainer";
import {useLazyQuery} from "@apollo/client";
import {getAnalystReport} from "../../Queries/Queries";

// eslint-disable-next-line no-unused-vars
const buttonUseStyles = makeStyles({
  root: {
    marginLeft: "1em",
  },
  blue: {
    background: "#5aacee",
    color: "white",
  },
  green: {
    background: "#33a532",
    color: "white",
  },
});

const AnalystStepperContainer = (props) => {
  const { id } = props;
  // eslint-disable-next-line no-unused-vars
  const [header, setHeader] = useContext(HeaderTitleContext);
  const [getReport, { data: report }] = useLazyQuery(getAnalystReport(id));

  //let match = useRouteMatch();

  let menuItems = [
    {
      display: `Report Status`,
      path: `report-status`,
      component: (
        <AnalystReportStatusContainer key={id + "-report-status"} id={id} />
      ),
    },
    {
      display: `Property Status`,
      path: `property-status`,
      component: (
        <AnalystPropertyStatusContainer key={id + "-property-status"} id={id} />
      ),
    },
    {
      display: `Municipal Documents`,
      path: `municipal-documents`,
      component: (
        <AnalystMunicipalDocumentsContainer
          key={id + "-municipal-documents"}
          id={id}
        />
      ),
    },
    {
      display: `C of O`,
      path: `cofo`,
      component: <AnalystCOfOContainer key={id + "-cofo"} id={id} />,
    },
    {
      display: `Requirements`,
      path: `requirements`,
      component: (
        <AnalystRequirementsContainer key={id + "-requirements"} id={id} />
      ),
    },
    {
      display: `Parking`,
      path: `parking`,
      component: <AnalystParkingContainer key={id + "-parking"} id={id} />,
    },
    {
      display: `Conformance`,
      path: `conformance`,
      component: (
        <AnalystConformanceContainer key={id + "-conformance"} id={id} />
      ),
    },
    {
      display: `Reconstruction`,
      path: `reconstruction`,
      component: (
        <AnalystReconstructionContainer key={id + "-reconstruction"} id={id} />
      ),
    },
    {
      display: `Fannie`,
      path: `fannie`,
      component: (
        <AnalystFannieContainer key={id + "-fannie"} id={id} />
      ),
    },
    {
      display: `Generate Report`,
      path: `generate-report`,
      component: (
        <AnalystConformanceReportContainer
          key={id + "-generate-report"}
          id={id}
        />
      ),
    },
  ];

  useEffect(() => {
    if(report) {
      if(report?.analystReport?.conformance_report_order_item?.[0]?.property?.[0]?.name) {
        setHeader(`Analyst: ${report?.analystReport?.conformance_report_order_item?.[0]?.property?.[0]?.name}`);
      }
    } else {
      setHeader("Analyst:");
      getReport();
    }
  }, [report])

  return (
    <React.Fragment>
      <AnalystStepperDisplay menuItems={menuItems} />
    </React.Fragment>
  );
};

AnalystStepperContainer.propTypes = {
  id: PropTypes.number,
};

export default AnalystStepperContainer;
