import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import * as PropTypes from "prop-types";
import ClaimedItemsDisplay from "../../Display/Queue/ClaimedItemsDisplay";
import { useHistory } from "react-router";
import Button from "@material-ui/core/Button";
//import {getJuniorAnalystClaimedQueueItems, getOrder, juniorAnalystReleaseQueueItem} from "../../Queries/Queries";

const ClaimedItemsContainer = (props) => {
  const {
    columns,
    query,
    queryKey,
    claimRoute,
    release,
    releaseArgs,
    queueQuery,
    queueQueryArgs,
    getReleaseKey = (params) => {
      return {
        variables: { data: { media_document: [{ id: params.row.id }] } },
      };
    },
    fixedFilters,
  } = props;
  const [rows, setRows] = useState([]);
  const [getQueue, { loading, data: queue }] = useLazyQuery(query());
  // eslint-disable-next-line no-unused-vars
  const [actionColumns, setActionColumns] = useState([
    ...columns,
    {
      field: "action",
      headerName: "Action",
      width: 230,
      renderCell: Object.assign(
        (params) => {
          delete params.row.issues;
          return renderButtons(params);
        },
        { displayName: "renderCell" }
      ),
    },
  ]);
  // eslint-disable-next-line no-unused-vars
  const [doRelease, { data: releaseResponse }] = useMutation(release(), {
    refetchQueries: [
      { query: query(releaseArgs) },
      { query: queueQuery(queueQueryArgs) },
    ],
  });

  useEffect(() => {
    getQueue({variables: {...fixedFilters}});
  }, []);

  const renderButtons = (params) => {
    const history = useHistory();
    return (
      <React.Fragment>
        <Button
          onClick={() => {
            history.push({
              pathname: `${claimRoute}/${params.row.id}`,
              state: { data: params.row },
            });
          }}
          color="primary"
        >
          Edit
        </Button>
        <Button
          onClick={() => {
            doRelease(getReleaseKey(params));
          }}
          color="primary"
        >
          Release
        </Button>
      </React.Fragment>
    );
  };

  useEffect(() => {
    if (queue) {
      if (queryKey) {
        setRows(queue?.[queryKey].items);
      } else {
        setRows(queue);
      }
    }
  }, [queue]);

  return <ClaimedItemsDisplay rows={rows} columns={actionColumns} loading={loading} />;
};

ClaimedItemsContainer.propTypes = {
  columns: PropTypes.array,
  query: PropTypes.func,
  queryKey: PropTypes.string,
  release: PropTypes.func,
  releaseArgs: PropTypes.string,
  queueQuery: PropTypes.func,
  queueQueryArgs: PropTypes.string,
  claimRoute: PropTypes.string,
  getReleaseKey: PropTypes.func,
  fixedFilters: PropTypes.object,
};

export default ClaimedItemsContainer;
