import { gql } from "@apollo/client";
import {
  camelToTitle,
  camelToUnderscore,
  underscoreToCamel,
} from "../tools/stringTools";
import { subJurisdictionMap } from "../Constants/Jurisdiction";
import {
  AdministrativeArea,
  ConformanceReportOrderItem,
  Country,
  Locality,
  Subdivision,
  worxFile,
  worxReference,
} from "./Structures";

export const deleteEntity = () => {
  let mutation = `mutation MyMutation($data: DeleteEntityInput) {
    deleteEntity(data: $data) {
      errors
      message
    }
  }`;
  return gql`
    ${mutation}
  `;
};

export const editAnalystReport = () => {
  let mutation = `mutation editAnalystReport($data: AnalystReportInput) {
    editAnalystReport(data: $data) {
      analyst_report {
        id
        conformance_report_order_item {${ConformanceReportOrderItem}}
        use_conformance
        structure_conformance
        
        cofo_answers
        cofo_notes
    
        requirements_answers
        requirements_notes
        
        reconstruction_notes
        reconstruction_clause
        
        parking_answers
        parking_conformance
        parking_notes
    
        municipal_documents
        
        parking_conformance
        lot_size_density_conformance
        deficiencies
        conformance_notes
      }
    }
  }`;
  return gql`
    ${mutation}
  `;
};

export const claimResearchQueueItem = () => {
  let mutation = `mutation claimResearchQueueItem($data: ResearchClaimQueueItemInput = {}) {
    researchClaimQueueItem(data: $data) {
      claimed_item {
        order_item {${ConformanceReportOrderItem}}
      }
      errors
    }
  }`;
  return gql`
    ${mutation}
  `;
};

export const researchReleaseQueueItem = () => {
  let mutation = `mutation researchReleaseQueueItem($data: ResearchReleaseQueueItemInput) {
    researchReleaseQueueItem(data: $data) {
      errors
    }
  }`;
  return gql`
    ${mutation}
  `;
};

export const editOrder = () => {
  let mutation = `mutation editOrder($data: OrderEditInput) {
    editOrder(data: $data) {
      errors
      order {
        id
        client_reference_number
        order_number
        portfolio_name
        report_due_date
        report_order_items {
          id
        }
        total_orders
      }
    }
  }`;
  return gql`
    ${mutation}
  `;
};

export const createOrderItem = (type) => {
  let query = `mutation createOrderItem($data: ${underscoreToCamel(
    type
  )}Input) {
    create${underscoreToCamel(type)}(data: $data) {
      errors
      report_item {
       id
       name
      }
    }
  }`;
  return gql`
    ${query}
  `;
};

export const editOrderItem = (type) => {
  let query = `mutation editOrderItem($data: ${underscoreToCamel(type)}Input) {
    edit${underscoreToCamel(type)}(data: $data) {
      errors
      report_item {
       id
       name
      }
    }
  }`;
  return gql`
    ${query}
  `;
};

export const editJurisdiction = (type) => {
  let query = `mutation editJurisdiction($data: ${type}EditInput) {
    edit${underscoreToCamel(type)}(data: $data) {
      errors
      ${type.toLowerCase()} {
        id
        name
        ${subJurisdictionMap[underscoreToCamel(type)]} {
          id
          name
        }
        ${camelToUnderscore(type)}_type {
          id
          name
        }
      }
    }
  }`;
  return gql`
    ${query}
  `;
};

export const claimQueueItem = (type) => {
  let query = `mutation claimQueueItem {
    claimQueueItem(data: {queue: "${type}"}) {
      claimed_item {
        jurisdiction {
          id
          name
          ${Locality}
          ${Subdivision}
          ${AdministrativeArea}
          ${Country}
        }
        step
      }
    }
  }`;
  return gql`
    ${query}
  `;
};

/*export const releaseQueueItem = (type, id) => {
  let query = `mutation releaseQueueItem {
    releaseQueueItem(data: {queue: "${type}", jurisdiction: {id: ${id}}}) {
      errors
    }
  }`;
  return gql`${query}`;
};*/

export const releaseQueueItem = (type, id) => {
  let query = `mutation releaseQueueItem($data: ReleaseQueueItemInput${
    type && id ? `= {queue: "${type}", jurisdiction: [{id: ${id}}]}` : ``
  }) {
    releaseQueueItem(data: $data) {
      errors
    }
  }`;
  return gql`
    ${query}
  `;
};

export const deleteQueueItem = (type, id) => {
  let mutation = `mutation deleteQueueItem {
    removeQueueItem(data: {queue: "${type}", jurisdiction: {id: ${id}}}) {
      errors
    }
  }`;
  return gql`
    ${mutation}
  `;
};

//rework to use conformance order report reusable item.
export const claimReportItem = (type = "research_claim") => {
  let mutation = `mutation claimReportItem($data: ReportItemClaimInput = {claim_type: "${type}"}) {
    claimReportItem(data: $data) {
      claimed_item {
        claim_type
        report_item {
          id
          ${ConformanceReportOrderItem}
        }
      }
    }
  }`;
  return gql`
    ${mutation}
  `;
};

export const claimAnalystQueueItem = (type = "analyst_queue") => {
  let mutation = `mutation claimAnalystQueueItem($data: AnalystClaimQueueItemInput = {queue: "${type}"}) {
    analystClaimQueueItem(data: $data) {
      claimed_item {
        analyst_report {
          conformance_report_order_item {
            id
          }
          id
        }
      }
      errors
    }
  }`;
  return gql`
    ${mutation}
  `;
};

export const releaseAnalystQueueItem = () => {
  let mutation = `mutation releaseAnalystQueueItem($data: AnalystReleaseQueueItemInput) {
    analystReleaseQueueItem(data: $data) {
      errors
    }
  }`;
  return gql`
    ${mutation}
  `;
};

export const removeAnalystQueueItem = () => {
  let mutation = `mutation removeAnalystQueueItem($data: AnalystRemoveQueueItemInput) {
    analystRemoveQueueItem(data: $data) {
      errors
    }
  }`;
  return gql`
    ${mutation}
  `;
};

export const getReportItem = (id) => {
  let query = `query getReportItem($id: Int = ${id}) {
    reportItem(id: $id) {
      ${ConformanceReportOrderItem}
    }
  }`;
  return gql`
    ${query}
  `;
};

export const addCategory = (type = "Zone") => {
  let query = `mutation addCategory($data: ${type}ListCreateInput) {
    create${type}List(data: $data) {
      category {
        id
        name
      }
      errors
    }
  }`;
  return gql`
    ${query}
  `;
};

export const editCategory = (type = "Zone") => {
  let query = `mutation editCategory($data: ${type}ListEditInput) {
    edit${type}List(data: $data) {
      category {
        id
        name
        jurisdiction {
          id
          name
        }
      }
      errors
    }
  }`;
  return gql`
    ${query}
  `;
};

export const addUseCategory = () => {
  let query = `mutation addUseCategory($data: UseListCreateInput) {
    createUseList(data: $data) {
      category {
        id
        name
      }
      errors
    }
  }`;
  return gql`
    ${query}
  `;
};

export const editUseCategory = () => {
  let query = `mutation editUseCategory($data: UseListEditInput) {
    editUseList(data: $data) {
      category {
        id
        name
        jurisdiction {
          id
          name
        }
      }
      errors
    }
  }`;
  return gql`
    ${query}
  `;
};

export const addZone = (type) => {
  let query = `mutation addZone($data: ${type}CreateInput) {
    create${type}(data: $data) {
      zone {
        id
        name
      }
      errors
    }
  }`;
  return gql`
    ${query}
  `;
};

export const editZone = (type) => {
  let query = `mutation editZone($data: ${type}EditInput) {
    edit${type}(data: $data) {
      zone {
        id
        name
      }
      errors
    }
  }`;
  return gql`
    ${query}
  `;
};

export const createCodeSection = () => {
  let query = `mutation createCodeSection($data: CodeSectionCreateInput) {
    createCodeSection(data: $data) {
      code_section {
        code
        id
        name
        jurisdiction {
          id
        }
        code_section_type
        code_date
        code_file {
          id
          filename
          filepath
        }
      }
      errors
    }
  }`;
  return gql`
    ${query}
  `;
};

export const editCodeSection = () => {
  let query = `mutation editCodeSection($data: CodeSectionEditInput)  {
    editCodeSection(data: $data) {
      code_section {
        code
        id
        name
        jurisdiction {
          id
        }
        code_section_type
        code_date
        code_file {
          id
          filename
          filepath
        }
        
      }
      errors
    }
  }`;
  return gql`
    ${query}
  `;
};

// page 5
export const editUse = () => {
  let query = `mutation editUse($data: UseEditInput) {
    editUse(data: $data) {
      errors
      use {
        id
        name
        jurisdiction {
          id
          name
        }
        code_location
        description
        certificate_required
        category {
          id
          name
        }
        weight
      }
    }
  }
  `;
  return gql`
    ${query}
  `;
};

export const createUse = () => {
  let query = `mutation createUse($data: UseCreateInput) {
    createUse(data: $data) {
      errors
      use {
        id
        name
        jurisdiction {
          id
          name
        }
        code_location
        description
        certificate_required
        category {
          id
          name
        }
        weight
      }
    }
  }
  `;
  return gql`
    ${query}
  `;
};

export const createFootnote = () => {
  let query = `mutation createFootnote($data: FootnoteCreateInput) {
    createFootnote(data: $data) {
      errors
      footnote {
        id
        type
        name
        footnote
        jurisdiction {
          id
          name
        }
      }
    }
  }
  `;
  return gql`
    ${query}
  `;
};

export const editFootnote = () => {
  let query = `mutation editFootnote($data: FootnoteEditInput) {
    editFootnote(data: $data) {
      errors
      footnote {
        id
        type
        name
        footnote
        jurisdiction {
          id
          name
        }
      }
    }
  }
  `;
  return gql`
    ${query}
  `;
};

export const uploadFile = () => {
  let query = `mutation uploadFile($entity: String = "", $field: String = "", $file: Upload = "") {
    uploadFile(entity: $entity, field: $field, file: $file) {
      errors
      file {
        filename
        id
        filepath
      }
    }
  }`;
  return gql`
    ${query}
  `;
};

export const editLink = () => {
  let mutation = `mutation editLink($data: ZoneUseLinkEditInput) {
    editZoneUseLink(data: $data) {
      errors
      zone_use_link {
        id
        zone {
          id
          name
        }
        link {
          id
          name
          use {
            id
            name
          }
          zone {
            id
            name
          }
          status {
            id
            name
          }
          footnote {
            id
            name
          }
          __typename
        }
        __typename
      }
    }
  }`;
  return gql`
    ${mutation}
  `;
};

export const createLink = () => {
  let mutation = `mutation createLink($data: ZoneUseLinkCreateInput) {
    createZoneUseLink(data: $data) {
      errors
      zone_use_link {
        id
        zone {
          id
          name
        }
        link {
          id
          name
          use {
            id
            name
          }
          zone {
            id
            name
          }
          status {
            id
            name
          }
          footnote {
            id
            name
          }
          __typename
        }
        __typename
      }
    }
  }`;
  return gql`
    ${mutation}
  `;
};

export const createLegend = () => {
  let mutation = `mutation createLegend($data: ZoneUseLinkStatusesCreateInput) {
    createZoneUseLinkStatus(data: $data) {
      category {
        id
        description
        jurisdiction {
          id
          name
        }
        name
        type
        parent {
          id
          name
        }
      }
      errors
    }
  }`;
  return gql`
    ${mutation}
  `;
};

export const editLegend = () => {
  let mutation = `mutation editLegend($data: ZoneUseLinkStatusesEditInput) {
    editZoneUseLinkStatus(data: $data) {
      category {
        description
        id
        jurisdiction {
          id
          name
        }
        name
        type
        parent {
          id
          name
        }
      }
      errors
    }
  }`;
  return gql`
    ${mutation}
  `;
};

export const createParkingLink = () => {
  let mutation = `mutation createParkingLink($data: ParkingLinkCreateInput) {
    createParkingLink(data: $data) {
      errors
      parking_link {
        description
        id
        min_max
        name
        parking {
          id
          name
        }
        secondary_variable
        spaces
        use {
          id
          name
        }
        zone {
          id
          name
        }
      }
    }
  }`;
  return gql`
    ${mutation}
  `;
};

export const editParkingLink = () => {
  let mutation = `mutation editParkingLink($data: ParkingLinkEditInput) {
    editParkingLink(data: $data) {
      errors
      parking_link {
        description
        id
        min_max
        name
        parking {
          id
          name
        }
        secondary_variable
        spaces
        use {
          id
          name
        }
        zone {
          id
          name
        }
      }
    }
  }`;
  return gql`
    ${mutation}
  `;
};

export const createZoneRequirementLink = () => {
  let mutation = `mutation createZoneRequirementLink($data: ZoneRequirementLinkCreateInput){
    createZoneRequirementLink(data: $data) {
      errors
      zone_requirement_link {
        id
        link {
          id
          name
          minimum
          maximum
          jurisdiction {
            id
            name
          }
          footnote {
            id
            name
          }
          code_location
          requirement {
            id
            name
          }
          use {
            id
            name
          }
          weight
          zone {
            id
            name
          }
        }
      }
    }
  }`;
  return gql`
    ${mutation}
  `;
};

export const editZoneRequirementLink = () => {
  let mutation = `mutation editZoneRequirementLink($data: ZoneRequirementLinkEditInput){
    editZoneRequirementLink(data: $data) {
      errors
      zone_requirement_link {
        id
        link {
          id
          name
          minimum
          maximum
          jurisdiction {
            id
            name
          }
          footnote {
            id
            name
          }
          code_location
          requirement {
            id
            name
          }
          use {
            id
            name
          }
          weight
          zone {
            id
            name
          }
        }
      }
    }
  }`;
  return gql`
    ${mutation}
  `;
};

export const editRequirement = () => {
  let mutation = `mutation editRequirement($data: RequirementEditInput = {id: 10, name: "", jurisdiction: {id: 10}}) {
    editRequirement(data: $data) {
      errors
      requirement {
        calculation {
          id
          name
        }
        code_location
        description
        id
        jurisdiction {
          id
          name
        }
        name
        weight
      }
    }
  }`;
  return gql`
    ${mutation}
  `;
};

export const createRequirement = () => {
  let mutation = `mutation createRequirement($data: RequirementCreateInput = {name: "", jurisdiction: {id: 10}}) {
    createRequirement(data: $data) {
      errors
      requirement {
        calculation {
          id
          name
        }
        code_location
        description
        id
        jurisdiction {
          id
          name
        }
        name
        weight
      }
    }
  }`;
  return gql`
    ${mutation}
  `;
};

export const editDocumentOrderItem = () => {
  let mutation = `mutation editDocumentOrderItem($data: DocumentOrderItemInput) {
    editDocumentOrderItem(data: $data) {
      report_item {
        id
        name
      }
    }
  }`;
  return gql`
    ${mutation}
  `;
};

export const createMediaDocument = () => {
  let mutation = `mutation createMediaDocument($data: MediaDocumentInput) {
    createMediaDocument(data: $data) {
      errors
      media_document {
        id
        name
        document_type {
          id
          name
          vid
        }
      }
    }
  }`;
  return gql`
    ${mutation}
  `;
};

export const createMediaImage = () => {
  let mutation = `mutation createMediaImage($data: MediaImageInput) {
    createMediaImage(data: $data) {
      errors
      media_image {
        id
        name
      }
    }
  }`;
  return gql`
    ${mutation}
  `;
};

export const createMedia = (type) => {
  let mutation = `mutation createMedia${camelToTitle(
    type
  )}($data: Media${camelToTitle(type)}Input) {
    createMedia${camelToTitle(type)}(data: $data) {
      errors
      media_${type} {
        id
        name
        ${
          type === "document"
            ? `document_type {
          id
          name
          vid
        }`
            : ``
        }
      }
    }
  }`;
  return gql`
    ${mutation}
  `;
};

export const editProperty = () => {
  let mutation = `mutation editProperty($data: PropertyEditInput) {
    editProperty(data: $data) {
      errors
      property {
        id
        name
      }
    }
  }`;
  return gql`
    ${mutation}
  `;
};

export const createOrder = () => {
  let mutation = `mutation createOrder($data: OrderCreateInput) {
    createOrder(data: $data) {
      errors
      order {
        id
        client_reference_number
        order_number
        portfolio_name
        report_due_date
        report_order_items {
          id
        }
        total_orders
      }
    }
  }`;
  return gql`
    ${mutation}
  `;
};

export const researchOrderDocument = () => {
  let mutation = `mutation researchOrderDocument($data: ResearchOrderDocumentInput) {
    researchOrderDocument(data: $data) {
      errors
      report_item {
        id
        name
        document_order_template {
          id
          name
        }
        order_date
      }
    }
  }`;
  return gql`
    ${mutation}
  `;
};

export const editConformanceReportOrderItem = () => {
  let mutation = `mutation editConformanceReportOrderItem($data: ConformanceReportOrderItemInput) {
    editConformanceReportOrderItem(data: $data) {
      errors
      report_item {
        ... on ConformanceReportOrderItem {
          id
          name
          status
        }
      }
    }
  }`;
  return gql`
    ${mutation}
  `;
};

export const createReceivedAnswers = (type) => {
  let key =
    underscoreToCamel(type).charAt(0).toUpperCase() +
    underscoreToCamel(type).slice(1);
  let mutation = `mutation createReceivedAnswers($data: ${key}CreateInput) {
    create${key}(data: $data) {
      ${type} {
        id
        name
      }
      errors
    }
  }`;
  return gql`
    ${mutation}
  `;
};

export const checkCategoryDuplicate = () => {
  let mutation = `mutation checkCategoryDuplicate($data: CategoryDuplicateCheckInput) {
    checkCategoryDuplicate(data: $data) {
      errors
      exists
    }
  }`;
  return gql`
    ${mutation}
  `;
};

export const checkUseDuplicate = () => {
  let mutation = `mutation checkUseDuplicate($data: UseDuplicateCheckInput) {
    checkUseDuplicate(data: $data) {
      errors
      exists
    }
  }`;
  return gql`
    ${mutation}
  `;
};

export const checkZoneDuplicate = () => {
  let mutation = `mutation checkZoneDuplicate($data: ZoneDuplicateCheckInput) {
    checkZoneDuplicate(data: $data) {
      errors
      exists
    }
  }`;
  return gql`
    ${mutation}
  `;
};

export const claimJuniorAnalystQueueItem = () => {
  let mutation = `mutation claimJuniorAnalystQueueItem {
    juniorAnalystClaimQueueItem(data: {queue: "jr_analyst_queue"}) {
      claimed_item {
        media_document {
          ${worxReference}
          document {${worxFile}}
          document_type {
            ${worxReference}
            description
            vid
          }
          jurisdiction {${worxReference}}
          property {${worxReference}}
          type
        }
        property {${worxReference}}
      }
      errors
    }
  }`;
  return gql`
    ${mutation}
  `;
};

export const setApplicableRequirementsTable = () => {
  let mutation = `mutation setApplicableRequirementsTable($data: ApplicableRequirementsTableInput) {
    setApplicableRequirementsTable(data: $data) {
      id
      table
    }
  }`;
  return gql`
    ${mutation}
  `;
};

export const createUnitMix = () => {
  let mutation = `mutation createUnitMix($data: UnitMixCreateInput) {
    createUnitMix(data: $data) {
      errors
      unit_mix {
        id
        name
      }
    }
  }`;
  return gql`
    ${mutation}
  `;
};

export const createTenant = () => {
  let mutation = `mutation createTenant($data: TenantCreateInput) {
    createTenant(data: $data) {
      errors
      tenant {
        id
        name
      }
    }
  }`;
  return gql`
    ${mutation}
  `;
};

export const createTenantList = () => {
  let mutation = `mutation createTenantList($data: TenantListCreateInput) {
    createTenantList(data: $data) {
      errors
      tenant_list {
        id
        name
      }
    }
  }`;
  return gql`
    ${mutation}
  `;
};

export const createSurveyField = () => {
  let mutation = `mutation createSurveyField($data: SurveyFieldCreateInput = {}) {
    createSurveyField(data: $data) {
      errors
      survey_field {
        id
        label
        value
      }
    }
  }`;
  return gql`
    ${mutation}
  `;
};

export const createSurvey = () => {
  let mutation = `mutation createSurvey($data: SurveyCreateInput) {
    createSurvey(data: $data) {
      errors
      survey {
        id
        name
      }
    }
  }`;
  return gql`
    ${mutation}
  `;
};

export const createResidentialOrder = () => {
  let mutation = `mutation createResidentialOrder($data: ResidentialOrderCreateInput) {
    createResidentialOrder(data: $data) {
      errors
      order {
        id
      }
    }
  }`;
  return gql`
    ${mutation}
  `;
};

export const authorizeSubmit = () => {
  let mutation = `mutation authorizeSubmit($data: AuthorizeSubmitInput) {
    authorizeSubmit(data: $data) {
      errors
      response
    }
  }`;
  return gql`
    ${mutation}
  `;
};

export const juniorAnalystReleaseQueueItem = () => {
  let mutation = `mutation juniorAnalystReleaseQueueItem($data: JuniorAnalystReleaseQueueItemInput) {
    juniorAnalystReleaseQueueItem(data: $data) {
      errors
    }
  }`;
  return gql`
    ${mutation}
  `;
};

export const analystReleaseQueueItem = () => {
  let mutation = `mutation analystReleaseQueueItem($data: AnalystReleaseQueueItemInput) {
    analystReleaseQueueItem(data: $data) {
      errors
    }
  }`;
  return gql`
    ${mutation}
  `;
};

export const editSurveyField = () => {
  let mutation = `mutation editSurveyField($data: SurveyFieldEditInput) {
    editSurveyField(data: $data) {
      errors
      survey_field {
        id
        label
        value
      }
    }
  }`;
  return gql`
    ${mutation}
  `;
};

export const editTenant = () => {
  let mutation = `mutation editTenant($data: TenantEditInput) {
    editTenant(data: $data) {
      errors
      tenant {
        id
        name
      }
    }
  }`;
  return gql`
    ${mutation}
  `;
};

export const generateConformanceReport = () => {
  let mutation = `mutation generateConformanceReport($analyst_report: Int, $test: Boolean) {
    generateConformanceReport(data: {analyst_report: $analyst_report, test: $test}) {
      errors
      report {
        id
        name
        document_type {
          id
          name
        }
        document {
          filename
          filepath
          id
        }
      }
    }
  }`;
  return gql`
    ${mutation}
  `;
};

export const generateCommercialReport = () => {
  let mutation = `mutation generateCommercialReport($property: Int, $test: Boolean, $type: String) {
    generateCommercialReport(data: {property: $property, test: $test, type: $type}) {
      errors
      report {
        id
        name
        document_type {
          id
          name
        }
        document {
          filename
          filepath
          id
        }
      }
    }
  }`;
  return gql`
      ${mutation}
  `;
};

export const editFannieForm = () => {
  let mutation = `mutation editFannieForm($data: FannieFormInput) {
    editFannieForm(data: $data) {
      errors
    }
  }`;
  return gql`
    ${mutation}
  `;
};


export const CommercialClaimQueueItem = () => {
  let query = `mutation CommercialClaimQueueItem($data: CommercialClaimQueueItemInput = {queue: "commercial_queue"}) {
    commercialClaimQueueItem(data: $data) {
      claimed_item {
        order {
          id
        }
      }
      errors
    }
  }`;
  return gql`
      ${query}
  `;
}

export const CommercialReleaseQueueItem = () => {
  let query = `mutation CommercialReleaseQueueItem($data: CommercialReleaseQueueItemInput) {
    commercialReleaseQueueItem(data: $data) {
      errors
    }
  }`;
  return gql`
      ${query}
  `;
}

export const CommercialRemoveQueueItem = () => {
  let query = `mutation CommercialRemoveQueueItem($data: CommercialRemoveQueueItemInput) {
    commercialRemoveQueueItem(data: $data) {
      errors
    }
  }`;
  return gql`
      ${query}
  `;
}

export const createProperty = () => {
  let query = `mutation createProperty($data: PropertyCreateInput) {
    createProperty(data: $data) {
      errors
      property {
        id
        name
        jurisdiction {
          id
          name
          verified_date
          verification_state
          task_description
          type
        }
      }
    }
  }`;
  return gql`
      ${query}
  `;
}
