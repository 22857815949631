import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { FormLabel, makeStyles, Typography } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import { GetSet, ListFilter } from "../../../../Constants/Jurisdiction";
import * as PropTypes from "prop-types";
import WorxCKEditorFormik from "../../../Forms/Inputs/WorxCKEditorFormik";
//import Truncate from 'react-truncate-html';

const useStyles = makeStyles({
  worxRadioGroupStyleOverride: {
    marginBottom: "1em",
  },
  buttonWrapper: {
    marginTop: "1em",
    textAlign: "right",
  },
});

const buttonUseStyle = makeStyles({
  root: {
    marginLeft: "1em",
  },
});

const topCardUseStyles = makeStyles({
  root: {
    "& .MuiDataGrid-cell": {
      whiteSpace: "normal !important",
    },
    marginBottom: "2em",
  },
});

const FootnoteFormDisplay = (props) => {
  let {
    addFootnotes,
    columns,
    footnotes,
    initValues,
    pageSize,
    rowCount,
    currentPage,
    filters,
    formik,
  } = props;

  const classes = useStyles();
  const buttonClasses = buttonUseStyle();
  const topCardClasses = topCardUseStyles();

  return (
    <React.Fragment>
      <Typography variant={"h5"} gutterBottom>
        Footnotes
      </Typography>
      <FormLabel>Create Footnotes</FormLabel>
      {!addFootnotes.get && (
        <RadioGroup
          aria-label="Create Footnotes"
          name="create-uses"
          value={addFootnotes.get}
          onChange={(event) => {
            addFootnotes.set(event.target.value);
            initValues.set({});
          }}
          className={classes.worxRadioGroupStyleOverride}
        >
          <FormControlLabel value={true} control={<Radio />} label="Yes" />
          <FormControlLabel value={false} control={<Radio />} label="No" />
        </RadioGroup>
      )}
      {addFootnotes.get && (
        <form>
          <div>
            <TextField
              margin="normal"
              id={"name"}
              value={formik.values.name}
              label={"Name"}
              onChange={formik.handleChange}
            />
          </div>
          <WorxCKEditorFormik fieldKey={"footnote"} formik={formik} />
          <div className={classes.buttonWrapper}>
            <Button
              variant={"contained"}
              color="secondary"
              margin="normal"
              onClick={() => {
                addFootnotes.set(false);
                formik.resetForm();
              }}
              classes={{ root: buttonClasses.root }}
            >
              Cancel
            </Button>
            <Button
              variant={"contained"}
              color="primary"
              margin="normal"
              onClick={() => {
                formik.setFieldValue("button", "normal");
                formik.handleSubmit();
              }}
              classes={{ root: buttonClasses.root }}
            >
              Submit
            </Button>
            <Button
              variant={"contained"}
              color="primary"
              margin="normal"
              onClick={() => {
                formik.setFieldValue("button", "repeat");
                formik.handleSubmit();
              }}
              classes={{ root: buttonClasses.root }}
            >
              Submit and Add Another
            </Button>
          </div>
        </form>
      )}

      {!addFootnotes.get && (
        <div className={classes.worxDataGridWrapperStyleOverride}>
          <div>
            <TextField
              margin="normal"
              id={"name"}
              placeholder={"Name"}
              onChange={(event) => {
                let nextFilter = {
                  ...filters.get,
                  name: event.target.value,
                  offset: 0,
                  operator: "CONTAINS",
                };
                footnotes.filter({ variables: nextFilter });
                filters.set(nextFilter);
              }}
            />
          </div>
          <DataGrid
            classes={{ root: topCardClasses.root }}
            key={rowCount}
            page={currentPage.get}
            pagination
            paginationMode="server"
            rowCount={rowCount}
            pageSize={pageSize}
            onPageChange={(page) => {
              let nextFilter = { ...filters.get, offset: pageSize * page };
              footnotes.filter({ variables: nextFilter });
              currentPage.set(page);
              filters.set(nextFilter);
            }}
            loading={!footnotes.list}
            columns={columns}
            rows={footnotes.list ? footnotes.list : []}
            autoHeight
            getRowHeight={() => "auto"}
            checkboxSelection={false}
          />
        </div>
      )}
    </React.Fragment>
  );
};

FootnoteFormDisplay.propTypes = {
  addFootnotes: GetSet,
  footnotes: ListFilter,
  columns: PropTypes.array,
  initValues: GetSet,
  pageSize: PropTypes.number.isRequired,
  rowCount: PropTypes.number.isRequired,
  currentPage: GetSet,
  filters: GetSet,
  formik: PropTypes.object.isRequired,
};

export default FootnoteFormDisplay;
