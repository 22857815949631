import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField/TextField";
import * as PropTypes from "prop-types";
import { AutoCompleteFilter } from "../../../Constants/Jurisdiction";
import { camelToTitle, underscoreToCamel } from "../../../tools/stringTools";

const WorxAutocomplete = (props) => {
  let {onChange, disabled = false, fieldKey, nameKey = 'name',
    onInputChange = (e, value) => {
      let newFilter = { [nameKey]: value };
      options.options.filter({
        variables: { ...newFilter, operator: "CONTAINS" },
      });
      if (options?.variables?.updateFixedVariables) {
        options?.variables?.filters.set(newFilter);
      }
    },
    label = camelToTitle(underscoreToCamel(fieldKey)), options, value, error, width
  } = props;

  return (
    <div>
      <Autocomplete
        autoComplete
        disabled={disabled}
        id={fieldKey}
        style={{ width: width ? width : 300 }}
        open={options.open.get}
        onOpen={() => {
          //options.options.filter({variables: fixedVariables});
          options.open.set(true);
        }}
        onClose={() => {
          options.open.set(false);
        }}
        getOptionSelected={(option, value) =>
          option?.[nameKey] === value?.[nameKey]
        }
        getOptionLabel={(option) => {
          return option[nameKey];
        }}
        options={options.options.list}
        onInputChange={onInputChange}
        onChange={onChange}
        loading={
          options?.options?.list ? options?.options?.list.length > 0 : false
        }
        value={value ? value : null}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label={label}
              variant="outlined"
              margin="normal"
            />
          );
        }}
      />
      {error ? <div style={{ color: "red" }}>{error}</div> : null}
    </div>
  );
};

WorxAutocomplete.propTypes = {
  options: AutoCompleteFilter,
  label: PropTypes.string,
  fieldKey: PropTypes.string.isRequired,
  nameKey: PropTypes.string,
  onInputChange: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.object,
  error: PropTypes.string,
  width: PropTypes.number,
};

export default WorxAutocomplete;
