import React from "react";
import Button from "@material-ui/core/Button";
import { FormLabel, makeStyles, Typography } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import {
  AutoCompleteFilter,
  GetSet,
  ListFilter,
} from "../../../../Constants/Jurisdiction";
import * as PropTypes from "prop-types";
import WorxTextField from "../../../Forms/Inputs/WorxTextField";
import WorxAutocompleteFormik from "../../../Forms/Inputs/WorxAutocompleteFormik";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField/TextField";

const useStyles = makeStyles({
  worxRadioGroupStyleOverride: {
    marginBottom: "1em",
  },
  buttonWrapper: {
    textAlign: "right",
  },
});

const buttonUseStyle = makeStyles({
  root: {
    marginLeft: "1em",
  },
});

const topCardUseStyles = makeStyles({
  root: {
    "& .MuiDataGrid-cell": {
      whiteSpace: "normal !important",
    },
    marginBottom: "2em",
  },
});

const RequirementsFormDisplay = (props) => {
  const {
    addRequirement,
    requirements,
    columns,
    rowTotal,
    calculationOptions,
    initValues,
    pageSize,
    filters,
    currentPage,
    step,
    formik,
  } = props;

  const classes = useStyles();
  const buttonClasses = buttonUseStyle();
  const topCardClasses = topCardUseStyles();

  return (
    <React.Fragment>
      <Typography variant={"h5"} gutterBottom>
        Requirements {`(Step ${step + 1})`}
      </Typography>
      <FormLabel>Create Requirement</FormLabel>
      {!addRequirement.get && (
        <RadioGroup
          aria-label="Create Footnotes"
          name="create-uses"
          value={addRequirement.get === "true"}
          onChange={(event) => {
            addRequirement.set(event.target.value);
            initValues.set({});
          }}
          className={classes.worxRadioGroupStyleOverride}
        >
          <FormControlLabel value={true} control={<Radio />} label="Yes" />
          <FormControlLabel value={false} control={<Radio />} label="No" />
        </RadioGroup>
      )}
      {addRequirement.get && (
        <form>
          <WorxTextField fieldKey={"name"} formik={formik} />
          <WorxAutocompleteFormik
            fieldKey={"calculation"}
            options={calculationOptions}
            formik={formik}
          />
          {/*<WorxTextField fieldKey={'code_location'} formik={formik}/>
        <WorxCKEditorFormik fieldKey={'description'} formik={formik}/>*/}
          <WorxTextField fieldKey={"weight"} type={"number"} formik={formik} />
          <div className={classes.buttonWrapper}>
            <Button
              variant={"contained"}
              color="secondary"
              margin="normal"
              onClick={() => {
                addRequirement.set(false);
                formik.resetForm();
              }}
            >
              Cancel
            </Button>
            <Button
              variant={"contained"}
              color="primary"
              margin="normal"
              onClick={() => {
                formik.setFieldValue("button", "normal");
                formik.handleSubmit();
                addRequirement.set(false);
              }}
              classes={{ root: buttonClasses.root }}
            >
              Submit
            </Button>
            <Button
              variant={"contained"}
              color="primary"
              margin="normal"
              onClick={() => {
                formik.setFieldValue("button", "repeat");
                formik.handleSubmit();
              }}
              classes={{ root: buttonClasses.root }}
            >
              Submit and Add Another
            </Button>
          </div>
        </form>
      )}
      {!addRequirement.get && rowTotal && (
        <div className={classes.worxDataGridWrapperStyleOverride}>
          <div>
            <TextField
              margin="normal"
              id={"requirement"}
              placeholder={"Requirement"}
              onChange={(event) => {
                let nextFilter = {
                  ...filters.get,
                  requirement: event.target.value,
                  offset: 0,
                  name: event.target.value,
                };
                requirements.filter({ variables: nextFilter });
                filters.set(nextFilter);
                currentPage.set(0);
              }}
            />
          </div>
          <DataGrid
            classes={{ root: topCardClasses.root }}
            autoHeight={true}
            getRowHeight={() => "auto"}
            page={currentPage.get}
            key={rowTotal}
            pagination
            paginationMode="server"
            rowCount={rowTotal}
            pageSize={pageSize}
            onPageChange={(page) => {
              let nextFilter = { ...filters.get, offset: pageSize * page };
              requirements.filter({ variables: nextFilter });
              filters.set(nextFilter);
              currentPage.set(page);
            }}
            loading={!requirements.list}
            columns={columns}
            rows={requirements.list ? requirements.list : []}
          />
        </div>
      )}
    </React.Fragment>
  );
};

RequirementsFormDisplay.propTypes = {
  addRequirement: GetSet,
  requirements: ListFilter,
  columns: PropTypes.array,
  rowTotal: PropTypes.number.isRequired,
  calculationOptions: AutoCompleteFilter,
  initValues: GetSet,
  step: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  filters: GetSet,
  currentPage: GetSet,
  formik: PropTypes.object.isRequired,
};

export default RequirementsFormDisplay;
