import React from "react";
import { makeStyles } from "@mui/styles";
import logo from "../../Images/zoning_logo.png";

const useStyles = makeStyles({
  worxLogoWrapper: {
    margin: "1em",
  },
});

const LogoDisplay = () => {
  const classes = useStyles();
  return (
    <div className={classes.worxLogoWrapper}>
      <img src={logo} alt="Zoning Logo" />
    </div>
  );
};

export default LogoDisplay;
