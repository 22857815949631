import React from "react";
import * as PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@mui/styles";

const buttonUseStyles = makeStyles({
  root: {
    marginLeft: "1em",
  },
  blue: {
    background: "#5aacee",
    color: "white",
  },
  green: {
    background: "#33a532",
    color: "white",
  },
});

const RequirementsCellDisplay = (props) => {
  const { params, showForm } = props;
  const buttonClasses = buttonUseStyles();

  return (
    <React.Fragment>
      {params.link?.[0]?.minimum >= 0 || params.link?.[0]?.maximum >= 0 ? (
        <Button
          variant="contained"
          className={
            params?.link?.[0]?.footnote.length > 0
              ? buttonClasses.green
              : buttonClasses.blue
          }
          onClick={() => showForm(params)}
        >
          {params.link?.[0]?.minimum !== null && (
            <div>Min: {params.link[0].minimum}</div>
          )}
          {params.link?.[0]?.maximum !== null && (
            <div>Max: {params.link[0].maximum}</div>
          )}
        </Button>
      ) : (
        <Button
          onClick={() => {
            showForm(params);
          }}
        >
          +
        </Button>
      )}
    </React.Fragment>
  );
};

RequirementsCellDisplay.propTypes = {
  params: PropTypes.object.isRequired,
  showForm: PropTypes.func.isRequired,
};

export default RequirementsCellDisplay;
