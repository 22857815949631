import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  getMediaDocument,
  getSurveyFieldsToDisplay,
} from "../../../Queries/Queries";
import { createSurvey } from "../../../Queries/Mutations";
import { useHistory } from "react-router";
import JuniorAnalystSurveyFormDisplay from "../../../Display/JuniorAnalyst/Forms/JuniorAnalystSurveyFormDisplay";
import {string} from "yup";

const JuniorAnalystSurveyFormContainer = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { documentID } = props;

  // eslint-disable-next-line no-unused-vars
  const history = useHistory();

  const [fetchItem, { data: itemQuery }] = useLazyQuery(
    getMediaDocument(documentID)
  );

  // eslint-disable-next-line no-unused-vars
  const [initValues, setInitValues] = useState({});
  const [fetchFields, { data: fieldsQuery }] = useLazyQuery(
    getSurveyFieldsToDisplay()
  );

  const [doCreateSurvey, { data: SurveyResponse }] = useMutation(
    createSurvey()
  );

  useEffect(() => {
    fetchItem();
  }, []);

  useEffect(() => {
    if (SurveyResponse) {
      history.push({
        pathname: "/junior_analyst",
      });
    }
  }, [SurveyResponse]);

  useEffect(() => {
    if (itemQuery?.mediaDocument?.document_type?.[0]?.name) {
      //setType(itemQuery?.mediaDocument?.document_type?.[0]?.name);
      fetchFields({
        variables: { property: itemQuery?.mediaDocument?.property?.[0]?.id },
      });
      setInitValues({
        name: '',
        property: [{ id: itemQuery?.mediaDocument?.property?.[0]?.id }],
        survey_document: [
          {
            id: itemQuery?.mediaDocument.id,
            name: itemQuery?.mediaDocument.name,
            document: itemQuery?.mediaDocument.document,
          },
        ],
        __typename: "survey",
      });
      formik.resetForm();
    }
  }, [itemQuery]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...initValues },
    validationSchema: Yup.object({name: string().required()}),
    // eslint-disable-next-line no-unused-vars
    onSubmit: (values, formikTools) => {
      delete values.survey_document[0].document;
      doCreateSurvey({ variables: { data: values } });
    },
  });

  return (
    <JuniorAnalystSurveyFormDisplay
      title={
        itemQuery?.mediaDocument?.property?.[0]?.name +
        " :: " +
        itemQuery?.mediaDocument?.property?.[0]?.jurisdiction?.[0]?.name
      }
      mapOverride={
        fieldsQuery?.getSurveyFieldsToDisplay
          ? fieldsQuery?.getSurveyFieldsToDisplay
          : []
      }
      disabledMap={{ survey_document: true }}
      hideMap={{ property: true, id: true }}
      formik={formik}
    />
  );
};

JuniorAnalystSurveyFormContainer.propTypes = {
  documentID: PropTypes.number.isRequired,
};

export default JuniorAnalystSurveyFormContainer;
