import React, { useContext, useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  getApplicableRequirementsTable,
  getMediaDocument,
} from "../../../Queries/Queries";
import SurveyApplicableRequirementsDisplay from "../../../Display/JuniorAnalyst/SurveyApplicableRequirementsDisplay";
//import Button from "@material-ui/core/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import DisplayCellContainer from "../DisplayCellContainer";
import { useHistory } from "react-router";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import HeaderTitleContext from "../../../Context/HeaderTitleContext";
import { addressToDisplay } from "../../../tools/addressTools";
import { setApplicableRequirementsTable } from "../../../Queries/Mutations";
//import Button from '@material-ui/core/Button';


const SurveyApplicableRequirementsContainer = (props) => {
  const { documentID, propertyID } = props;

  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const [filters, setFilters] = useState({ limit: 5, offset: 0 });
  // eslint-disable-next-line no-unused-vars
  let [header, setHeader] = useContext(HeaderTitleContext);
  const [columns, setColumns] = useState();
  const [rows, setRows] = useState([]);
  const [footnotes, setFootnotes] = useState({});
  const [currentPage, setCurrentPage] = useState([]);
  const [initValues, setInitValues] = useState({});
  const [filterRequirements, { data: requirementList }] = useLazyQuery(
    getApplicableRequirementsTable(propertyID)
  );
  // eslint-disable-next-line no-unused-vars
  const [fetchDocument, { loading, error, data: documentQuery }] = useLazyQuery(
    getMediaDocument(documentID)
  );
  const [jurisdiction, setJurisdiction] = useState(null);

  const [documentOpen, setDocumentOpen] = useState(false);
  const [abuttingZoneOpen, setAbuttingZoneOpen] = useState(false);
  const [oldForm, setOldForm] = useState("");

  const [getForm, { data: formResponse }] = useMutation(
    setApplicableRequirementsTable()
  );

  const keyMap = {
    "Requirement ID": "requirement_id",
    Zone: "zone",
    Name: "requirement",
    Label: "label",
    "": "min_max",
    Requirement: "required",
    Existing: "existing",
    Difference: "difference",
    Display: "display",
  };

  setHeader(
    `${addressToDisplay(
      documentQuery?.mediaDocument?.property?.[0]?.address
    )} (${"orderId"})`
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...initValues },
    validationSchema: Yup.object({}),
    // eslint-disable-next-line no-unused-vars
    onSubmit: (values, formikTools) => {
      getForm({
        variables: {
          data: {
            id: propertyID,
            table: {
              header:
                requirementList?.getApplicableRequirementsTable?.table.header,
              rows: values,
            },
          },
        },
      });
    },
  });

  //[]
  useEffect(() => {
    filterRequirements({ variables: filters });
    fetchDocument();
  }, []);

  //formResponse
  useEffect(() => {
    if (formResponse) {
      if (formResponse) {
        history.push({
          pathname: `/junior_analyst/junior_analyst_workflow/survey_temp_form/${documentID}`,
          state: { data: formResponse },
        });
      } else {
        alert("This queue is empty.");
      }
    }
  }, [formResponse]);

  //RequirementList
  useEffect(() => {
    if (!columns || columns.length === 0) {
      if (
        requirementList?.getApplicableRequirementsTable?.table?.header &&
        footnotes
      ) {
        let newColumns =
          requirementList?.getApplicableRequirementsTable?.table.header.map(
            (header) => {
              if (header === "") {
                return {
                  field: keyMap[header],
                  headerName: "",
                  width: 50,
                  renderCell: Object.assign(
                    (params) => {
                      return (
                        <div
                          dangerouslySetInnerHTML={{ __html: params.value }}
                        />
                      );
                    },
                    { displayName: "render" + keyMap[header] }
                  ),
                };
              } else if (header === "Name") {
                return {
                  field: keyMap[header],
                  headerName: header,
                  width: 400,
                  renderCell: Object.assign(
                    (params) => {
                      let index = null;
                      if (footnotes) {
                        index = Object.keys(footnotes).findIndex(
                          (noteID) => noteID === params?.row?.footnote?.[0]
                        );
                      }
                      index;
                      return (
                        <Tooltip title={params.value}>
                          <div>
                            {index >= 0 && (
                              <sup>
                                [<a href={"#foot-" + index}>{index + 1}</a>]
                              </sup>
                            )}
                            {`${params.value}`}
                          </div>
                        </Tooltip>
                      );
                    },
                    { displayName: "render" + keyMap[header] }
                  ),
                };
              } else if (header === "Display") {
                return {
                  field: "display",
                  headerName: "Display",
                  width: 140,
                  height: 100,
                  // eslint-disable-next-line no-unused-vars
                  renderCell: Object.assign(
                    (params) => {
                      let index =
                        requirementList?.getApplicableRequirementsTable?.table.rows.findIndex(
                          (row) => row.id === params.row.id
                        );
                      return (
                        <DisplayCellContainer
                          index={index}
                          params={params}
                          getFormik={getFormik}
                        />
                      );
                    },
                    { displayName: "renderDisplay" }
                  ),
                };
              } else {
                return {
                  field: keyMap[header],
                  headerName: header,
                  width: 180,
                  renderCell: Object.assign(
                    (params) => {
                      return (
                        <div
                          dangerouslySetInnerHTML={{ __html: params.value }}
                        />
                      );
                    },
                    { displayName: "render" + keyMap[header] }
                  ),
                };
              }
            }
          );
        setColumns(newColumns);
      }
    }

    setRows(requirementList?.getApplicableRequirementsTable?.table.rows);
    setFootnotes(requirementList?.getApplicableRequirementsTable?.footnotes);

    setInitValues(requirementList?.getApplicableRequirementsTable?.table.rows);
  }, [requirementList, footnotes]);

  /*<Tooltip title={params.value}>
                  <div>
                    {index >= 0 && <sup>[
                      <a href={'#foot-' + index}>{index + 1}</a>
                      ]</sup>}
                    {`${params.value}`}
                  </div>
                </Tooltip>;*/

  useEffect(() => {
    if (JSON.stringify(formik.values) !== oldForm) {
      setOldForm(JSON.stringify(formik.values));
      setRows(Object.keys(formik.values).map((id) => formik.values[id]));
    }
  }, [formik]);

  const getFormik = () => {
    return formik;
  };

  useEffect(() => {
    if (documentQuery) {
      setJurisdiction(documentQuery?.mediaDocument?.jurisdiction?.[0]?.id);
    }
  }, [documentQuery]);

  return (
    <SurveyApplicableRequirementsDisplay
      columns={columns ?? []}
      rows={rows ?? []}
      filters={{ get: filters, set: setFilters }}
      currentPage={{ get: currentPage, set: setCurrentPage }}
      rowCount={rows?.length ?? 0}
      document={documentQuery}
      documentOpen={{ get: documentOpen, set: setDocumentOpen }}
      abuttingZoneOpen={{ get: abuttingZoneOpen, set: setAbuttingZoneOpen }}
      jurisdiction={jurisdiction}
      footnotes={footnotes}
      filterRequirements={filterRequirements}
      formik={formik}
    />
  );
};

SurveyApplicableRequirementsContainer.propTypes = {
  documentID: PropTypes.number,
  propertyID: PropTypes.number.isRequired,
};

export default SurveyApplicableRequirementsContainer;
