import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import React from "react";
import AddNewResidentialOrderContainer from "../Container/ResidentialOrders/Forms/AddNewResidentialOrderContainer";
import ClientPortalOrdersListContainer from "../Container/ClientPortal/ClientPortalOrdersListContainer";
import ClientPortalOrderContainer from "../Container/ClientPortal/Forms/ClientPortalOrderContainer";

const PortalRouter = () => {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route
        path={`${match.url}/orders/:id`}
        render={(props) => {
          // eslint-disable-next-line react/prop-types
          return <ClientPortalOrderContainer id={props.match.params.id} />;
        }}
      />
      <Route path={`${match.url}/orders`}>
        <ClientPortalOrdersListContainer />
      </Route>
      <Route path={`${match.url}/create-order`}>
        <AddNewResidentialOrderContainer />
      </Route>
      <Route path={`${match.url}/`}>
        <Redirect to={`${match.url}/orders`} />
      </Route>
    </Switch>
  );
};

export default PortalRouter;
