import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { Link, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core"; //import {makeStyles} from "@mui/styles";

const listItemUseStyles = makeStyles({
  root: {
    padding: "0",
  },
});

const linkUseStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.white,
    minWidth: "200px",
    padding: "1em",
    "&:hover": {
      background: theme.palette.secondary.light,
      color: theme.palette.text.white,
      textDecoration: "none",
    },
  },
}));

const SidebarMenuItemDisplay = ({ path, value }) => {
  const listItemClasses = listItemUseStyles();
  const linkClasses = linkUseStyles();

  return (
    <React.Fragment>
      <ListItem classes={{ root: listItemClasses.root }}>
        <Link
          classes={{ root: linkClasses.root }}
          component={RouterLink}
          to={path}
        >
          {value}
        </Link>
      </ListItem>
    </React.Fragment>
  );
};

SidebarMenuItemDisplay.propTypes = {
  path: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default SidebarMenuItemDisplay;
