import { Route, Switch, useRouteMatch } from "react-router-dom";
import React from "react";
import ResearchQueueContainer from "../Container/Reaserch/ResearchQueueContainer";
import ResearchTabbedContainer from "../Container/Reaserch/ResearchTabbedContainer";

const ResearchRouter = () => {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route
        path={`${match.url}/research_workflow/:id`}
        render={(props) => {
          // eslint-disable-next-line react/prop-types
          return <ResearchTabbedContainer id={props.match.params.id} />;
        }}
      />
      <Route path={`${match.url}`}>
        <ResearchQueueContainer />
      </Route>
    </Switch>
  );
};

export default ResearchRouter;
