import { Route, Switch, useRouteMatch } from "react-router-dom";
import React from "react";
import OrdersContainer from "../Container/Orders/OrdersContainer";
import AddNewOrderContainer from "../Container/Orders/Forms/AddNewOrderContainer";
import OrderContainer from "../Container/Orders/Forms/OrderContainer";

const OrdersRouter = () => {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.url}/add-new-order`}>
        <AddNewOrderContainer />
      </Route>
      <Route
        path={`${match.url}/:id`}
        render={(props) => {
          // eslint-disable-next-line react/prop-types
          return <OrderContainer id={props.match.params.id} />;
        }}
      />
      <Route path={`${match.url}`}>
        <OrdersContainer />
      </Route>
    </Switch>
  );
};

export default OrdersRouter;
