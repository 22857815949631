import {useEffect, useState} from "react";
import {setupWorker} from "msw";
import {
  all,
  /*MockGetClaimedQueueItems,
  MockGetQueueItemsShort,
  MockLogin,
  MockUserQuery*/
} from "../Queries/Mocks";

export function useWorker(devToolSettings) {
  const {activeRoles} = devToolSettings;
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const worker = setupWorker(...all(activeRoles));

    /*const worker = setupWorker(
      MockUserQuery(activeRoles),
      MockLogin(),
      MockGetQueueItemsShort(),
      MockGetClaimedQueueItems(),
    )*/

    async function startWorker() {
      await worker.start();
      setIsReady(true);
    }

    startWorker(worker);

    return () => {
      worker.stop();
    }
  }, [])

  return isReady
}
