import React, { useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AddressDetailsContainer from "../../Container/Reaserch/Forms/AddressDetailsContainer";
import { Card } from "@material-ui/core";
//import {makeStyles} from "@mui/styles";
import OrderDocumentsTabContainer from "../../Container/Reaserch/OrderDocumentsTabContainer";
import ReceivedTabContainer from "../../Container/Reaserch/ReceivedTabContainer";
import { CardContent } from "@material-ui/core";
import {
  camelToTitle,
  titleToUnderscore,
  underscoreToCamel,
} from "../../tools/stringTools";

const ResearchTabedDisplay = (props) => {
  const { id, report_order_item, getReport, tabs, history, tail } = props;

  //const cardStyles = cardUseStyles();

  // eslint-disable-next-line no-unused-vars
  const [value, setValue] = useState(0);

  return (
    <React.Fragment>
      {report_order_item && (
        <React.Fragment>
          <AppBar position="static">
            <Tabs
              value={tabs.findIndex(
                (label) => label === camelToTitle(underscoreToCamel(tail))
              )}
              onChange={(e, value) => {
                getReport();
                //setValue(value)
                history.push({
                  pathname: `/research/research_workflow/${id}/${titleToUnderscore(
                    tabs[value]
                  )}`,
                  state: { report_order_item: report_order_item },
                });
              }}
              aria-label="simple tabs example"
            >
              {tabs.map((tab) => (
                <Tab key={`tab-${tab}`} label={tab} />
              ))}
            </Tabs>
          </AppBar>
          <Card>
            <CardContent>
              {tail === titleToUnderscore(tabs[0]) && (
                <div>
                  <AddressDetailsContainer
                    id={report_order_item?.property?.[0]?.id}
                  />
                </div>
              )}
              {tail === titleToUnderscore(tabs[1]) && (
                <div>
                  <OrderDocumentsTabContainer
                    report_order_item={report_order_item}
                    getReport={getReport}
                  />
                </div>
              )}
              {tail === titleToUnderscore(tabs[2]) && <div>Item Three</div>}
              {tail === titleToUnderscore(tabs[3]) && (
                <div>
                  <ReceivedTabContainer
                    report_order_item={report_order_item}
                    getReport={getReport}
                  />
                </div>
              )}
            </CardContent>
          </Card>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

ResearchTabedDisplay.propTypes = {
  id: PropTypes.string,
  report_order_item: PropTypes.object,
  tabs: PropTypes.array,
  getReport: PropTypes.func,
  history: PropTypes.object,
  tail: PropTypes.string,
};

export default ResearchTabedDisplay;
