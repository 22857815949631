import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import { FormLabel, makeStyles, Typography } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import {
  AutoCompleteFilter,
  GetSet,
  ListFilter,
} from "../../../../Constants/Jurisdiction";
import * as PropTypes from "prop-types";
import WorxCKEditorFormik from "../../../Forms/Inputs/WorxCKEditorFormik";
import WorxTextField from "../../../Forms/Inputs/WorxTextField";
import WorxAutocompleteFormik from "../../../Forms/Inputs/WorxAutocompleteFormik";

const useStyles = makeStyles({
  buttonWrapper: {
    textAlign: "right",
  },
});

const buttonUseStyles = makeStyles({
  root: {
    marginLeft: "1em",
  },
});

const radioUseStyles = makeStyles({
  worxRadioGroupStyleOverride: {
    marginBottom: "1em",
  },
});

const topCardUseStyles = makeStyles({
  root: {
    "& .MuiDataGrid-cell": {
      whiteSpace: "normal !important",
    },
    marginBottom: "2em",
  },
});

const UseLegendFormDisplay = (props) => {
  let {
    addLegendStatuses,
    legends,
    columns,
    parents,
    initValues,
    rowCount,
    currentPage,
    pageSize,
    filters,
    formik,
  } = props;

  const classes = useStyles();
  const radioClasses = radioUseStyles();
  const buttonClasses = buttonUseStyles();
  const topCardClasses = topCardUseStyles();

  return (
    <React.Fragment>
      <Typography variant={"h5"} gutterBottom>
        Legends
      </Typography>
      <FormLabel>Create Legend</FormLabel>
      {!addLegendStatuses.get && (
        <RadioGroup
          aria-label="Create Footnotes"
          name="create-uses"
          value={addLegendStatuses.get}
          onChange={(event) => {
            addLegendStatuses.set(event.target.value);
            initValues.set({});
          }}
          className={radioClasses.worxRadioGroupStyleOverride}
        >
          <FormControlLabel value={true} control={<Radio />} label="Yes" />
          <FormControlLabel value={false} control={<Radio />} label="No" />
        </RadioGroup>
      )}
      {addLegendStatuses.get && (
        <form>
          <WorxTextField fieldKey={"name"} formik={formik} />
          <WorxCKEditorFormik fieldKey={"description"} formik={formik} />
          <WorxAutocompleteFormik
            fieldKey={"parent"}
            options={parents}
            formik={formik}
          />
          <div className={classes.buttonWrapper}>
            <Button
              variant={"contained"}
              color="secondary"
              margin="normal"
              onClick={() => {
                addLegendStatuses.set(false);
                formik.resetForm();
              }}
            >
              Cancel
            </Button>
            <Button
              variant={"contained"}
              color="primary"
              margin="normal"
              onClick={() => {
                formik.setFieldValue("button", "normal");
                formik.handleSubmit();
              }}
              classes={{ root: buttonClasses.root }}
            >
              Submit
            </Button>
            <Button
              variant={"contained"}
              color="primary"
              margin="normal"
              onClick={() => {
                formik.setFieldValue("button", "repeat");
                formik.handleSubmit();
              }}
              classes={{ root: buttonClasses.root }}
            >
              Submit and Add Another
            </Button>
          </div>
        </form>
      )}
      {!addLegendStatuses.get && (
        <DataGrid
          classes={{ root: topCardClasses.root }}
          key={rowCount}
          pagination
          paginationMode="server"
          rowCount={rowCount}
          pageSize={pageSize}
          onPageChange={(page) => {
            let nextFilter = { ...filters.get, offset: pageSize * page };
            legends.filter({ variables: nextFilter });
            filters.set(nextFilter);
            currentPage.set(page);
          }}
          loading={!legends.list}
          columns={columns}
          rows={legends.list ? legends.list : []}
          autoHeight
          getRowHeight={() => "auto"}
          checkboxSelection={false}
        />
      )}
    </React.Fragment>
  );
};

UseLegendFormDisplay.propTypes = {
  addLegendStatuses: GetSet,
  legends: ListFilter,
  columns: PropTypes.array,
  parents: AutoCompleteFilter,
  initValues: GetSet,
  rowCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  filters: GetSet,
  currentPage: GetSet,
  formik: PropTypes.object.isRequired,
};

export default UseLegendFormDisplay;
