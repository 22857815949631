import React from "react";
// eslint-disable-next-line no-unused-vars
import * as PropTypes from "prop-types";
import WorxFormContainer from "../../Container/Forms/Generic/WorxFormContainer";

const AnalystPropertyStatusDisplay = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { id, options, formik } = props;

  return <WorxFormContainer options={options} formik={formik} />;
};

AnalystPropertyStatusDisplay.propTypes = {
  id: PropTypes.number,
  options: PropTypes.object,
  formik: PropTypes.object,
};

export default AnalystPropertyStatusDisplay;
