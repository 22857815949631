import React, { useContext, useEffect, useState } from "react";
import ResearchTabedDisplay from "../../Display/Research/ResearchTabedDisplay";
import PropTypes from "prop-types";
import { useLazyQuery } from "@apollo/client";
import { getReportItem } from "../../Queries/Mutations";
import MainContentWidthContext from "../../Context/MainContentWidthContext";
import HeaderTitleContext from "../../Context/HeaderTitleContext";
import { useHistory } from "react-router";
import {
  camelToTitle,
  titleToUnderscore,
  underscoreToCamel,
} from "../../tools/stringTools";

const ResearchTabbedContainer = (props) => {
  const { id } = props;
  // eslint-disable-next-line no-unused-vars
  const [headerTitle, setHeaderTitle] = useContext(HeaderTitleContext);

  const tabs = ["Address Details", "Order Documents", "Follow Up", "Receive"];
  const history = useHistory();
  let pathArray = history.location.pathname.split("/");
  let tail = pathArray[pathArray.length - 1];

  //set the default tab
  if (
    tabs.findIndex((label) => label === camelToTitle(underscoreToCamel(tail))) <
    0
  ) {
    history.push({
      pathname: `/research/research_workflow/${id}/${titleToUnderscore(
        tabs[0]
      )}`,
      state: { report_order_item: null },
    });
  }

  // eslint-disable-next-line no-unused-vars
  const [mainContentWidth, setMainContentWidth] = useContext(
    MainContentWidthContext
  );
  setMainContentWidth("80");

  const [getReport, { data: queryReportOrderItem }] = useLazyQuery(
    getReportItem(id),
    { fetchPolicy: "no-cache" }
  );
  const [debouceReport, setDebounceReport] = useState(null);

  useEffect(() => {
    getReport();
  }, []);

  useEffect(() => {
    if (queryReportOrderItem) {
      setHeaderTitle(
        `${queryReportOrderItem.reportItem.name} (${queryReportOrderItem.reportItem.id})`
      );
      setDebounceReport(queryReportOrderItem.reportItem);
    }
  }, [queryReportOrderItem]);

  return debouceReport ? (
    <ResearchTabedDisplay
      id={id}
      report_order_item={debouceReport}
      tabs={tabs}
      getReport={getReport}
      history={history}
      tail={tail}
    />
  ) : (
    <div>Loading...</div>
  );
};

ResearchTabbedContainer.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ResearchTabbedContainer;
