import React from "react";
// eslint-disable-next-line no-unused-vars
import * as PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import { GetSet } from "../../../Constants/Jurisdiction";
import AdjustmentFormDisplay from "../../../Display/Orders/Forms/AdjustmentFormDisplay";

const AdjustmentFormContainer = (props) => {
  // eslint-disable-next-line no-unused-vars
  let { referenceData, initValues, edit } = props;
  //let type = initValues.get.__typename;

  let defaultAdjustmentItemValues = {};

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...defaultAdjustmentItemValues, ...initValues.get },
    validationSchema: Yup.object({}),
    // eslint-disable-next-line no-unused-vars
    onSubmit: (values, formikTools) => {
      edit.set(false);
      let { update, ...payload } = values;
      update(payload);
    },
  });

  return <AdjustmentFormDisplay formik={formik} />;
};

AdjustmentFormContainer.propTypes = {
  initValues: GetSet,
  referenceData: PropTypes.object,
  edit: GetSet,
};

export default AdjustmentFormContainer;
