import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import QueueDisplay from "../../Display/Queue/QueueDisplay";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router";
import Button from "@material-ui/core/Button";

const QueueContainer = (props) => {

  const {
    type,
    columns,
    fixedFilters = {},
    pageSize = 25,
    itemsQuery,
    queryKey,
    customSetRows = (queryValues, setQueryRows, setQueryTotal) => {
      if (queryValues?.[queryKey]) {
        setQueryRows(queryValues?.[queryKey]?.items);
        setQueryTotal(queryValues?.[queryKey]?.total);
      }
    },
    claimKey,
    claimRoute,
    claimQuery,
    //claimQueue,
    claimedItemsQuery,
    claimedItemsQueryKey,
    release,
    getReleaseKey,
    paginationMode = "server",
  } = props;

  const history = useHistory();

  const [rows, setRows] = useState([]);
  const [rowTotal, setRowTotal] = useState(pageSize);
  const [currentPage, setCurrentPage] = useState(0);
  const [filters, setFilters] = useState({
    limit: pageSize,
    offset: 0,
    ...fixedFilters,
  });
  const [filterItems, { loading, data: query }] = useLazyQuery(itemsQuery());
  const [claimItem, { data: claimedItem }] = useMutation(
    claimQuery(),
    {
      refetchQueries: [{ query: claimedItemsQuery(), variables: {...filters} }, { query: itemsQuery(), variables: {...filters}}],
    }
  );

  useEffect(() => {
    filterItems({ variables: {...filters} });
  }, []);

  useEffect(() => {
    customSetRows(query, setRows, setRowTotal);
  }, [query]);

  useEffect(() => {
    if (claimedItem) {
      let value = claimedItem;
      for (let el of claimKey.split(".")) {
        if (value && value?.[el]) {
          value = value?.[el];
        } else {
          value = null;
        }
      }

      if (value) {
        history.push({
          pathname: `${claimRoute}/${value}`,
          state: {
            data: claimedItem?.claimQueueItem?.claimed_item?.jurisdiction,
          },
        });
      } else {
        alert("This queue is empty.");
      }
    }
  }, [claimedItem]);

  // eslint-disable-next-line no-unused-vars
  const renderButtons = (params) => {
    return (
      <React.Fragment>
        <Button
          onClick={() => {console.log('clicked')}}
          color="primary"
        >
          Make Priority
        </Button>
      </React.Fragment>
    );
  };

  const [actionColumns] = useState([
    ...columns,
    {
      field: "action",
      headerName: "Action",
      width: 230,
      renderCell: Object.assign(
        (params) => {
          delete params.row.issues;
          return renderButtons(params);
        },
        { displayName: "renderCell" }
      ),
    },
  ]);

  return (
    <QueueDisplay
      type={type}
      rows={{ list: rows, filter: filterItems }}
      currentPage={{ get: currentPage, set: setCurrentPage }}
      filters={{ get: filters, set: setFilters }}
      rowTotal={rowTotal}
      claimItem={claimItem}
      claimRoute={claimRoute}
      itemsQuery={itemsQuery}
      columns={columns}
      columnsWithAction={actionColumns}
      claimedItemsQuery={claimedItemsQuery}
      claimedItemsQueryKey={claimedItemsQueryKey}
      release={release}
      getReleaseKey={getReleaseKey}
      pageSize={pageSize}
      paginationMode={paginationMode}
      fixedFilters={fixedFilters}
      loading={loading}
    />
  );
};

QueueContainer.propTypes = {
  columns: PropTypes.array,
  fixedFilters: PropTypes.object,
  pageSize: PropTypes.number,

  type: PropTypes.string,
  itemsQuery: PropTypes.func,
  queryKey: PropTypes.string,
  customSetRows: PropTypes.func,

  claimKey: PropTypes.string,
  claimRoute: PropTypes.string,
  claimQuery: PropTypes.func,
  claimQueue: PropTypes.string,

  claimedItemsQuery: PropTypes.func,
  claimedItemsQueryKey: PropTypes.string,

  release: PropTypes.func,
  getReleaseKey: PropTypes.func,

  paginationMode: PropTypes.string,
};

export default QueueContainer;
