import React, { useContext, useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  getAnalystReport,
  getDocumentTypes,
  getOrders,
  getResearchDocuments,
} from "../../Queries/Queries";
import { GetSet } from "../../Constants/Jurisdiction";
import { useHistory } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@material-ui/core";
import MainContentWidthContext from "../../Context/MainContentWidthContext";
import HeaderTitleContext from "../../Context/HeaderTitleContext";
import ClientRelationsDisplay from "../../Display/ClientRelations/ClientRelationsDisplay";
import { useFormik } from "formik";
import * as Yup from "yup";
import { editConformanceReportOrderItem } from "../../Queries/Mutations";

const ClientRelationsContainer = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { data } = props;
  // eslint-disable-next-line no-unused-vars
  let [header, setHeader] = useContext(HeaderTitleContext);
  setHeader("Orders");

  const [rows, setRows] = useState([]);
  const [filters, setFilters] = useState({
    order: { limit: 25, offset: 0, use: "", zone: "", operator: "CONTAINS" },
    report: { limit: 25, offset: 0, use: "", zone: "", operator: "CONTAINS" },
    document: { limit: 25, offset: 0, use: "", zone: "", operator: "CONTAINS" },
  });
  const [rowTotal, setRowTotal] = useState({
    order: 0,
    report: 0,
    document: 0,
  });
  const [currentPage, setCurrentPage] = useState({
    order: 0,
    report: 0,
    document: 0,
  });
  const [filterOrders, { loading: ordersLoading, data: ordersQuery }] =
    useLazyQuery(getOrders(), { variables: filters });
  const [filterAnalystReport, { data: analystReportQuery }] = useLazyQuery(
    getAnalystReport(),
    { variables: filters }
  );
  const [
    filterResearchDocumentsAnswers,
    { data: researchDocumentsAnswersQuery },
  ] = useLazyQuery(getResearchDocuments(), { variables: filters });
  const [filterDocumentTypes, { data: documentTypes }] = useLazyQuery(
    getDocumentTypes(),
    { variables: filters }
  );

  const [order, setOrder] = useState(null);
  const [report, setReport] = useState(null);
  const [document, setDocument] = useState(null);
  const [researchDocument, setResearchDocument] = useState(null);
  const [initValues, setInitValues] = useState({});

  const [answersOpen, setAnswersOpen] = useState(false);

  const history = useHistory();
  const createNewOrder = () => {
    history.push({
      pathname: "/orders/add-new-order/",
    });
  };

  const defaultValues = { document: null };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...defaultValues, ...initValues },
    validationSchema: Yup.object({}),
    // eslint-disable-next-line no-unused-vars
    onSubmit: (values, formikTools) => {
      /*This form exist to enable the media upload component to be used. This submit is never called*/
    },
  });

  // eslint-disable-next-line no-unused-vars
  const [mainContentWidth, setMainContentWidth] = useContext(
    MainContentWidthContext
  );
  setMainContentWidth("80");

  const [doEditCR] = useMutation(editConformanceReportOrderItem());

  useEffect(() => {
    filterOrders();
    filterDocumentTypes();
    filterResearchDocumentsAnswers();
  }, [header]);

  useEffect(() => {
    if (ordersQuery?.orders?.total) {
      let totals = rowTotal;
      totals.order = ordersQuery?.orders?.total;
      setRowTotal(totals);
    }

    if (ordersQuery?.orders?.items) {
      setRows(ordersQuery?.orders?.items);
      //setRowTotal(ordersQuery?.orders?.total);
    }
  }, [ordersQuery]);

  useEffect(() => {
    if (analystReportQuery?.orders?.total) {
      let totals = rowTotal;
      totals.report = ordersQuery?.orders?.total;
      setRowTotal(totals);
    }
  }, [analystReportQuery]);

  useEffect(() => {
    if (report) {
      setInitValues({ document: report.client_documents });
      filterAnalystReport({ variables: { id: report.id } });
    }
  }, [report]);

  let columns = [
    {
      field: "id",
      headerName: "ID",
      width: 100,
      renderCell: Object.assign(
        (params) => {
          return (
            <Link component={RouterLink} to={`/orders/${params.row.id}`}>
              {params.value}
            </Link>
          );
        },
        { displayName: "renderCell" }
      ),
    },
    {
      field: "order",
      headerName: "Client",
      width: 150,
      valueGetter: (value) => {
        return value.row.client[0].name;
      },
    },
    {
      field: "portfolio_name",
      headerName: "Portfolio",
      width: 230,
      valueGetter: (value) => {
        return value.value;
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 230,
      valueGetter: (value) => {
        return value?.row?.report_order_items?.[0]?.name;
      },
    },
    {
      field: "Address",
      headerName: "Address",
      width: 230,
      valueGetter: (value) => {
        return value?.row?.report_order_items?.[0]?.property?.[0]?.address
          .address_line1;
      },
    },
    {
      field: "City",
      headerName: "City",
      width: 230,
      valueGetter: (value) => {
        return value?.row?.report_order_items?.[0]?.property?.[0]?.address
          .locality;
      },
    },
    {
      field: "State",
      headerName: "State",
      width: 230,
      valueGetter: (value) => {
        return value?.row?.report_order_items?.[0]?.property?.[0]?.address
          .administrative_area;
      },
    },
    {
      field: "report_order_items",
      headerName: "Jurisdiction",
      width: 230,
      valueGetter: (value) => {
        return value?.value?.[0]?.jurisdiction?.[0]?.name;
      },
    },
    { field: "state", headerName: "Status", width: 230 },
    /*{ field: 'client', headerName: 'Client', width: 230,
      renderCell: Object.assign(params => {
        delete params.row.issues;
        return renderButton(params);
      }, { displayName: 'renderCell' })
    },*/
  ];

  const getCurrentPage = (key) => currentPage[key];

  const getCurrentPageSetter = (key) => (value) => {
    let cp = currentPage;
    cp[key] = value;
    setCurrentPage(cp);
  };

  const getRowTotal = (key) => rowTotal[key];

  const getRowTotalSetter = (key) => (value) => {
    let rt = rowTotal;
    rt[key] = value;
    setRowTotal(rt);
  };

  const getFilters = (key) => filters[key];

  const getFiltersSetter = (key) => (value) => {
    let f = filters;
    f[key] = value;
    setFilters(f);
  };

  return (
    <React.Fragment>
      <ClientRelationsDisplay
        orders={{ list: rows, filter: filterOrders, loading: ordersLoading }}
        columns={columns}
        rows={rows}
        filters={{
          order: { get: getFilters("order"), set: getFiltersSetter("order") },
          report: {
            get: getFilters("report"),
            set: getFiltersSetter("report"),
          },
          document: {
            get: getFilters("document"),
            set: getFiltersSetter("documents"),
          },
        }}
        currentPage={{
          order: {
            get: getCurrentPage("order"),
            set: getCurrentPageSetter("order"),
          },
          report: {
            get: getCurrentPage("report"),
            set: getCurrentPageSetter("report"),
          },
          document: {
            get: getCurrentPage("document"),
            set: getCurrentPageSetter("documents"),
          },
        }}
        rowTotal={{
          order: { get: getRowTotal("order"), set: getRowTotalSetter("order") },
          report: {
            get: getRowTotal("report"),
            set: getRowTotalSetter("report"),
          },
          document: {
            get: getRowTotal("document"),
            set: getRowTotalSetter("documents"),
          },
        }}
        createNewOrder={createNewOrder}
        order={{ get: order, set: setOrder }}
        report={{ get: report, set: setReport }}
        document={{ get: document, set: setDocument }}
        researchDocument={{ get: researchDocument, set: setResearchDocument }}
        documentTypes={documentTypes?.documentTypes}
        editCR={doEditCR}
        answers={researchDocumentsAnswersQuery?.researchDocuments?.items ?? []}
        answersOpen={{ get: answersOpen, set: setAnswersOpen }}
        analystReportQuery={analystReportQuery?.analystReport ?? {}}
        formik={formik}
      />
    </React.Fragment>
  );
};

ClientRelationsContainer.propTypes = {
  data: GetSet,
};

export default ClientRelationsContainer;
