import React, { useContext, useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  getFannieForm, getFannieOptions,
} from "../../Queries/Queries";
import { useFormik } from "formik";
import * as Yup from "yup";
import {editFannieForm} from "../../Queries/Mutations";
import MessageContext from "../../Context/MessageContext";
import AnalystCOfODisplay from "../../Display/Analyst/AnalystFannieFormDisplay";

const AnalystFannieContainer = (props) => {
  const { id } = props;

  const [getForm, { data: form }] = useLazyQuery(getFannieForm(id));
  //const [init, setInit] = useState(false);
  const [fetchOptions, { data: options }] = useLazyQuery(
    getFannieOptions()
  );

  // eslint-disable-next-line no-unused-vars
  const [message, setMessage] = useContext(MessageContext);

  // eslint-disable-next-line no-unused-vars
  const [initValues, setInitValues] = useState({});
  const [opens, setOpens] = useState({});

  const [doEditForm] = useMutation(
    editFannieForm()
  );

  // setup
  useEffect(() => {
    getForm();
    fetchOptions();
    setMessage(null);
  }, []);

  // report
  useEffect(() => {
    if (form) {
      let newForm = JSON.parse(JSON.stringify(form.fannieForm));

      //newForm.third_party_report_type = [{id: newForm.third_party_report_type, name: newForm.third_party_report_type }];
      //newForm.third_part_report_scope_standard = {id: newForm.third_part_report_scope_standard, name: newForm.third_part_report_scope_standard };
      newForm.existing_land_use_conformance = [{id: newForm.existing_land_use_conformance, name: newForm.existing_land_use_conformance }];
      newForm.existing_building_set_back_lines_conformance = [{id: newForm.existing_building_set_back_lines_conformance, name: newForm.existing_building_set_back_lines_conformance }];
      newForm.existing_building_size_conformance = [{id: newForm.existing_building_size_conformance, name: newForm.existing_building_size_conformance }];
      newForm.existing_building_density_converage_conformance = [{id: newForm.existing_building_density_converage_conformance, name: newForm.existing_building_density_converage_conformance }];
      newForm.existing_parking_conformance = [{id: newForm.existing_parking_conformance, name: newForm.existing_parking_conformance }];
      newForm.any_special_zoning_permitting_or_conditions = [{id: newForm.any_special_zoning_permitting_or_conditions, name: newForm.any_special_zoning_permitting_or_conditions }];
      newForm.recorded_zoning_violation = [{id: newForm.recorded_zoning_violation, name: newForm.recorded_zoning_violation }];
      newForm.recorded_building_code_violation = [{id: newForm.recorded_building_code_violation, name: newForm.recorded_building_code_violation }];
      newForm.recorded_fire_code_violation = [{id: newForm.recorded_fire_code_violation, name: newForm.recorded_fire_code_violation }];
      newForm.available_certificates_of_occupancy = [{id: newForm.available_certificates_of_occupancy, name: newForm.available_certificates_of_occupancy }];
      newForm.concluding_zoning_conformance_status = [{id: newForm.concluding_zoning_conformance_status, name: newForm.concluding_zoning_conformance_status }];

      setInitValues(newForm);
    }
  }, [form]);

  const defaultValues = { __typename: "fannie" };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...initValues, ...defaultValues },
    validationSchema: Yup.object({}),
    // eslint-disable-next-line no-unused-vars
    onSubmit: (values, formikTools) => {

      let newPayload = {
        ...values,
        //...(values.third_party_report_type && { third_party_report_type: values.third_party_report_type[0].id }),
        //...(values.third_part_report_scope_standard && { third_part_report_scope_standard: values.third_part_report_scope_standard[0].id }),
        ...(values.existing_land_use_conformance && { existing_land_use_conformance: values.existing_land_use_conformance[0].id }),
        ...(values.existing_building_set_back_lines_conformance && { existing_building_set_back_lines_conformance: values.existing_building_set_back_lines_conformance[0].id }),
        ...(values.existing_building_size_conformance && { existing_building_size_conformance: values.existing_building_size_conformance[0].id }),
        ...(values.existing_building_density_converage_conformance && { existing_building_density_converage_conformance: values.existing_building_density_converage_conformance[0].id }),
        ...(values.existing_parking_conformance && { existing_parking_conformance: values.existing_parking_conformance[0].id }),
        ...(values.any_special_zoning_permitting_or_conditions && { any_special_zoning_permitting_or_conditions: values.any_special_zoning_permitting_or_conditions[0].id }),
        ...(values.recorded_zoning_violation && { recorded_zoning_violation: values.recorded_zoning_violation[0].id }),
        ...(values.recorded_building_code_violation && { recorded_building_code_violation: values.recorded_building_code_violation[0].id }),
        ...(values.recorded_fire_code_violation && { recorded_fire_code_violation: values.recorded_fire_code_violation[0].id }),
        ...(values.available_certificates_of_occupancy && { available_certificates_of_occupancy: values.available_certificates_of_occupancy[0].id }),
        ...(values.concluding_zoning_conformance_status && { concluding_zoning_conformance_status: values.concluding_zoning_conformance_status[0].id }),
      };

      doEditForm({ variables: { data: newPayload } });
      setMessage("✓ Fannie Form Saved");
    },
  });

  let setOpen = (key) => {
    return (value) => {
      let newOpens = opens;
      newOpens[key] = value;
      setOpens(newOpens);
    }
  }

  let dropDownState = (key) => {
    return {
      get: opens[key],
      set: setOpen(key)
    }
  }

  return options ? <AnalystCOfODisplay
      options={{
        third_party_report_type: {
          open: dropDownState("third_party_report_type"),
          options: {
            list: options?.getFannieOptions.map(
              (option) => {
                return {id: option, name: option};
              }
            ),
            filter: () => {
              /*Do Nothing*/
            },
          },
        },
        third_part_report_scope_standard: {
          open: dropDownState("third_part_report_scope_standard"),
          options: {
            list: options?.getFannieOptions.map(
              (option) => {
                return {id: option, name: option};
              }
            ),
            filter: () => {
              /*Do Nothing*/
            },
          }
        },
        existing_land_use_conformance: {
          open: dropDownState("existing_land_use_conformance"),
          options: {
            list: options?.getFannieOptions.map(
              (option) => {
                return {id: option, name: option};
              }
            ),
            filter: () => {
              /*Do Nothing*/
            },
          }
        },
        existing_building_set_back_lines_conformance: {
          open: dropDownState("existing_building_set_back_lines_conformance"),
          options: {
            list: options?.getFannieOptions.map(
              (option) => {
                return {id: option, name: option};
              }
            ),
            filter: () => {
              /*Do Nothing*/
            },
          }
        },
        existing_building_size_conformance:  {
          open: dropDownState("existing_building_size_conformance"),
          options: {
            list: options?.getFannieOptions.map(
              (option) => {
                return {id: option, name: option};
              }
            ),
            filter: () => {
              /*Do Nothing*/
            },
          }
        },
        existing_building_density_converage_conformance: {
          open: dropDownState("existing_building_density_converage_conformance"),
          options: {
            list: options?.getFannieOptions.map(
              (option) => {
                return {id: option, name: option};
              }
            ),
            filter: () => {
              /*Do Nothing*/
            },
          }
        },
        existing_parking_conformance: {
          open: dropDownState("existing_parking_conformance"),
          options: {
            list: options?.getFannieOptions.map(
              (option) => {
                return {id: option, name: option};
              }
            ),
            filter: () => {
              /*Do Nothing*/
            },
          }
        },
        any_special_zoning_permitting_or_conditions: {
          open: dropDownState("any_special_zoning_permitting_or_conditions"),
          options: {
            list: options?.getFannieOptions.map(
              (option) => {
                return {id: option, name: option};
              }
            ),
            filter: () => {
              /*Do Nothing*/
            },
          }
        },
        recorded_zoning_violation: {
          open: dropDownState("recorded_zoning_violation"),
          options: {
            list: options?.getFannieOptions.map(
              (option) => {
                return {id: option, name: option};
              }
            ),
            filter: () => {
              /*Do Nothing*/
            },
          }
        },
        recorded_building_code_violation: {
          open: dropDownState("recorded_building_code_violation"),
          options: {
            list: options?.getFannieOptions.map(
              (option) => {
                return {id: option, name: option};
              }
            ),
            filter: () => {
              /*Do Nothing*/
            },
          }
        },
        recorded_fire_code_violation: {
          open: dropDownState("recorded_fire_code_violation"),
          options: {
            list: options?.getFannieOptions.map(
              (option) => {
                return {id: option, name: option};
              }
            ),
            filter: () => {
              /*Do Nothing*/
            },
          }
        },
        available_certificates_of_occupancy: {
          open: dropDownState("available_certificates_of_occupancy"),
          options: {
            list: options?.getFannieOptions.map(
              (option) => {
                return {id: option, name: option};
              }
            ),
            filter: () => {
              /*Do Nothing*/
            },
          }
        },
        concluding_zoning_conformance_status: {
          open: dropDownState("concluding_zoning_conformance_status"),
          options: {
            list: options?.getFannieConformanceOptions.map(
              (option) => {
                return {id: option, name: option};
              }
            ),
            filter: () => {
              /*Do Nothing*/
            },
          }
        },
      }}
      formik={formik}
    /> : <div>Loading...</div>
};

AnalystFannieContainer.propTypes = {
  id: PropTypes.number.isRequired,
  page: PropTypes.string,
};

export default AnalystFannieContainer;
