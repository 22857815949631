let date = new Date();
let oldDate = new Date(date.getTime() - 15 * 24 * 60 * 60 * 1000);

export const propertyList = [{
  "abutting_zoning": [],
  "address": {
    "additional_name": "",
    "address_line1": "3773 Timberglen Road",
    "address_line2": "",
    "administrative_area": "SC",
    "country_code": "US",
    "dependent_locality": "",
    "family_name": "",
    "given_name": "",
    "langcode": "",
    "locality": "Rockhill",
    "organization": "",
    "postal_code": "12345",
    "sorting_code": ""
  },
  "id": 1,
  "name": "Cool Property",
  "jurisdiction": [
    {
      "id": 1,
      "name": "United States",
      "type": "country",
      "verified_date": Math.floor(date.getTime() / 1000),
      "verification_state": null,
      "task_description": null,
    }
  ],
  "google_map": [],
  "notes": null,
  "parcel_map": [],
  "parcel_tax_id": null,
  "use": [],
  "zone": [
    {
      "id": 1988,
      "name": "Multifamily District"
    }
  ],
  "zoning_map": []
},
  {
    "abutting_zoning": [
      {
        "id": 1,
        "name": null,
        "zoning": []
      }
    ],
    "address": {
      "additional_name": "",
      "address_line1": "6069 Beltline Road",
      "address_line2": "",
      "administrative_area": "SC",
      "country_code": "US",
      "dependent_locality": "",
      "family_name": "",
      "given_name": "",
      "langcode": "",
      "locality": "Rockhill",
      "organization": "",
      "postal_code": "12345",
      "sorting_code": ""
    },
    "id": 2,
    "name": "Grand Seasons",
    "jurisdiction": [
      {
        "id": 2,
        "name": "Oklahoma",
        "type": "administrative_area",
        "verified_date": Math.floor(oldDate.getTime() / 1000),
        "verification_state": null,
        "task_description": null
      }
    ],
    "google_map": [],
    "notes": null,
    "parcel_map": [],
    "parcel_tax_id": null,
    "use": [],
    "zone": [
      {
        "id": 11297,
        "name": "Multifamily District 2"
      }
    ],
    "zoning_map": []
  },
  {
    "abutting_zoning": [
      {
        "id": 2,
        "name": null,
        "zoning": []
      }
    ],
    "address": {
      "additional_name": "",
      "address_line1": "2305 Ashcroft Lane",
      "address_line2": "",
      "administrative_area": "SC",
      "country_code": "US",
      "dependent_locality": "",
      "family_name": "",
      "given_name": "",
      "langcode": "",
      "locality": "Rockhill",
      "organization": "",
      "postal_code": "12345",
      "sorting_code": ""
    },
    "id": 3,
    "name": "Aspen Court",
    "jurisdiction": [
      {
        "id": 9635,
        "name": "Rockhill - South Carolina"
      }
    ],
    "google_map": [],
    "notes": null,
    "parcel_map": [],
    "parcel_tax_id": null,
    "use": [],
    "zone": [
      {
        "id": 5298,
        "name": "Residential Multi-Family 22"
      }
    ],
    "zoning_map": []
  },
  {
    "abutting_zoning": [
      {
        "id": 3,
        "name": null,
        "zoning": []
      }
    ],
    "address": {
      "additional_name": "",
      "address_line1": "2225 Randol Mill Road",
      "address_line2": "",
      "administrative_area": "SC",
      "country_code": "US",
      "dependent_locality": "",
      "family_name": "",
      "given_name": "",
      "langcode": "",
      "locality": "Rockhill",
      "organization": "",
      "postal_code": "12345",
      "sorting_code": ""
    },
    "id": 4,
    "name": "Arlington Downs Tower",
    "jurisdiction": [
      {
        "id": 9635,
        "name": "Rockhill - South Carolina"
      }
    ],
    "google_map": [],
    "notes": null,
    "parcel_map": [],
    "parcel_tax_id": null,
    "use": [
      {
        "id": 5731,
        "name": "Office, Business or Professional "
      }
    ],
    "zone": [
      {
        "id": 5308,
        "name": "Industrial Manufacturing"
      }
    ],
    "zoning_map": []
  },
  {
    "abutting_zoning": [
      {
        "id": 4,
        "name": null,
        "zoning": []
      }
    ],
    "address": {
      "additional_name": "",
      "address_line1": "1111 South Oak Street",
      "address_line2": "",
      "administrative_area": "SC",
      "country_code": "US",
      "dependent_locality": "",
      "family_name": "",
      "given_name": "",
      "langcode": "",
      "locality": "Rockhill",
      "organization": "",
      "postal_code": "12345",
      "sorting_code": ""
    },
    "id": 5,
    "name": "Across the Street",
    "jurisdiction": [
      {
        "id": 9635,
        "name": "Rockhill - South Carolina"
      }
    ],
    "google_map": [],
    "notes": null,
    "parcel_map": [],
    "parcel_tax_id": null,
    "use": [],
    "zone": [
      {
        "id": 5298,
        "name": "Residential Multi-Family 22"
      }
    ],
    "zoning_map": []
  }]
