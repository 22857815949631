import React from "react";
import { useHistory } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import SidebarContainer from "../Container/Menu/SidebarContainer";
import { AppBar, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import MainRouter from "../Routing/MainRouter";
import { PropTypes } from "prop-types";
import MessageContainer from "../Container/MassageContainer";

const useStyles = makeStyles({
  mainContent: {
    width: (props) =>
      props.mainContentWidth > "80"
        ? "80%"
        : props.mainContentWidth < "50"
        ? "50%"
        : props.mainContentWidth === "0"
        ? "50%"
        : props.mainContentWidth + "%",
  },
  mainRouterDisplayWrapper: {
    display: "flex",
    minHeight: "100vh",
  },
  mainContentWrapper: {
    display: "flex",
    flexDirection: "column",
    padding: "2em",
    width: "100%",
  },
});

const appBarUseStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    textAlign: "right",
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const buttonUseStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.text.white,
  },
}));

const MainContentDisplay = (props) => {
  const { headerTitle } = props;
  const classes = useStyles(props);

  //const classes = useStyles();
  const appBarClasses = appBarUseStyles();
  const history = useHistory();
  const client = useApolloClient();
  const buttonClasses = buttonUseStyles();

  return (
    <React.Fragment>
      <AppBar classes={{ root: appBarClasses.root }}>
        <Button
          className={classes.root}
          classes={{ label: buttonClasses.label }}
          variant="text"
          onClick={() => {
            localStorage.removeItem("access_token");
            localStorage.removeItem("refresh_token");
            client.clearStore().then(() => {
              history.push("/");
            });
          }}
        >
          Logout
        </Button>
      </AppBar>
      <div className={classes.mainRouterDisplayWrapper}>
        <SidebarContainer />
        <div className={classes.mainContentWrapper}>
          <Typography variant="h1" gutterBottom={true}>
            {headerTitle}
          </Typography>
          <MessageContainer />
          <MainRouter {...props} />
        </div>
      </div>
    </React.Fragment>
  );
  /*return (
    <div className={classes.mainContentWrapper}>
      <div className={classes.mainContent}>

      </div>
    </div>
  );*/
};

MainContentDisplay.propTypes = {
  headerTitle: PropTypes.string,
};

export default MainContentDisplay;
