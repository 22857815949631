import React from "react";
import * as PropTypes from "prop-types";
import { AutoCompleteFilter } from "../../../Constants/Jurisdiction";
import WorxAutocomplete from "./WorxAutocomplete";

const WorxSelectString = (props) => {
  let {
    formik,
    fieldKey,
    nameKey = "name",
    label,
    disabled = false,
    options,
  } = props;

  let value = formik.values;

  for (let el of fieldKey.split(".")) {
    if (value && value?.[el]) {
      value = value?.[el];
    } else {
      value = null;
    }
  }

  let item = { id: value, name: value };

  return (
    <div>
      <WorxAutocomplete
        fieldKey={fieldKey}
        nameKey={nameKey}
        label={label}
        disabled={disabled}
        options={options}
        getOptionSelected={(option, value) => option?.value === value?.value}
        value={item}
        onChange={(event, value) => {
          if (value) {
            formik.setFieldValue(fieldKey, value.name);
          } else {
            formik.setFieldValue(fieldKey, "");
          }
        }}
      />
      {formik.touched[fieldKey] && formik.errors[fieldKey] ? (
        <div style={{ color: "red" }}>{"Required"}</div>
      ) : null}
    </div>
  );
};

WorxSelectString.propTypes = {
  options: AutoCompleteFilter,
  label: PropTypes.string,
  fieldKey: PropTypes.string.isRequired,
  nameKey: PropTypes.string,
  disabled: PropTypes.bool,
  formik: PropTypes.object.isRequired,
};

export default WorxSelectString;
