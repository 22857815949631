import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { FormMaps } from "../../../Constants/GenericFormMaps";
import WorxFormDisplay from "../../../Display/Forms/Generic/WorxFormDisplay";

const WorxFormContainer = (props) => {
  const {
    disabled = false,
    disabledMap = {},
    hideMap,
    options = {},
    dependencyMap = {},
    mapOverride,
    nameOverride = {},
    buttons = [],
    formik,
  } = props;
  const map = FormMaps;
  const [oldState, setOldState] = useState(formik.values);

  useEffect(() => {
    if (formik.values !== oldState) {
      setOldState(formik.values);
    }
  }, [formik.values.document]);

  return (
    <WorxFormDisplay
      disabled={disabled}
      disabledMap={disabledMap}
      hideMap={hideMap}
      options={options}
      dependencyMap={dependencyMap}
      nameOverride={nameOverride}
      map={mapOverride ?? map[formik?.values?.__typename]}
      buttons={buttons}
      formik={formik}
    />
  );
};

WorxFormContainer.propTypes = {
  disabled: PropTypes.bool,
  disabledMap: PropTypes.object,
  hideMap: PropTypes.object,
  options: PropTypes.object,
  dependencyMap: PropTypes.object,
  mapOverride: PropTypes.object,
  nameOverride: PropTypes.object,
  buttons: PropTypes.array,
  formik: PropTypes.object.isRequired,
};

export default WorxFormContainer;
