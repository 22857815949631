import React, { useContext } from "react";
import HeaderTitleContext from "../Context/HeaderTitleContext";
import ErrorDisplay from "../Display/ErrorDisplay";
import * as PropTypes from "prop-types";
//import {useHistory} from "react-router";

const ErrorContainer = (props) => {
  //const {errors, path} = props;
  //const [mainContentWidth] = useContext(MainContentWidthContext);

  // eslint-disable-next-line no-unused-vars
  const [headerTitle, setHeaderTitle] = useContext(HeaderTitleContext);
  //const history = useHistory();

  setHeaderTitle("Error");

  if (!props.errors) {
    //history.goBack();
  }

  return <ErrorDisplay {...props} />;
};

ErrorContainer.propTypes = {
  errors: PropTypes.object,
};

export default ErrorContainer;
