import React from "react";
import * as PropTypes from "prop-types";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import { GetSet } from "../../Constants/Jurisdiction";

const dataGridStyles = makeStyles({
  root: {
    "& .MuiDataGrid-cell": {
      whiteSpace: "normal !important",
    },
    '& [data-id^="p"]': {
      height: "0px",
    },
    '& [data-id^="p"] [data-field="id"]': {
      display: "none !important",
    },
    marginBottom: "2em",
  },
});

const WorxDataGridDisplay = (props) => {
  const {
    columns,
    rows,
    rowTotal,
    currentPage,
    filters,
    paginationMode,
    pageSize = 25,
    loading,
  } = props;

  const dataGridClasses = dataGridStyles();

  let localRows = JSON.parse(JSON.stringify(rows.list));

  let rowCount = rowTotal;
  if (!rowCount) {
    rowCount = rows.list.length;
  }

  return paginationMode === 'server' ?
    <div>
    <DataGrid key={rowTotal}
              classes={{root: dataGridClasses.root}}
              columns={columns}
              rows={localRows ?? []}
              page={currentPage.get}
              pagination
              paginationMode={paginationMode}
              rowCount={rowCount}
              pageSize={filters.get.limit}
              onPageChange={(page) => {
                let nextFilter = {...filters.get, offset: filters.get.limit * (page)};
                filters.set(nextFilter);
                currentPage.set(page);
                rows.filter({variables: nextFilter});
              }}
              loading={loading}
              autoHeight
              //getEstimatedRowHeight={() => 100}
              getRowHeight={() => 'auto'}
              checkboxSelection={false} />
    </div>
    :
    <div>
    <DataGrid key={rowTotal}
              classes={{root: dataGridClasses.root}}
              columns={columns}
              rows={localRows ?? []}
              pagination
              paginationMode={paginationMode}
              pageSize={pageSize}
              loading={localRows.length <= 0}
              autoHeight
              getRowHeight={() => 'auto'}
              checkboxSelection={false} />
    </div>
}

WorxDataGridDisplay.propTypes = {
  rowTotal: PropTypes.number,
  currentPage: GetSet,
  filters: GetSet,
  pageSize: PropTypes.number,
  rows: PropTypes.object,
  columns: PropTypes.array,
  paginationMode: PropTypes.string,
  loading: PropTypes.bool,
};

export default WorxDataGridDisplay;
