import React, { useContext, useEffect, useState } from "react";
import OrderDisplay from "../../../Display/Orders/Forms/OrderDisplay";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as PropTypes from "prop-types";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  getAdjustmentTypes,
  getOrder,
  getOrderStateOptions,
  getUserById,
} from "../../../Queries/Queries";
import Button from "@material-ui/core/Button";
import MainContentWidthContext from "../../../Context/MainContentWidthContext";
import HeaderTitleContext from "../../../Context/HeaderTitleContext";
import { editOrder } from "../../../Queries/Mutations";

const OrderContainer = (props) => {
  const { id } = props;
  const [edit, setEdit] = useState(false);
  const [billingOpen, setBillingOpen] = useState(false);
  const [contactOpen, setContactOpen] = useState(false);
  const [preparedForOpen, setPreparedForOpen] = useState(false);
  const [editOrderItem, setEditOrderItem] = useState(false);
  const [createOrderItemOpen, setCreateOrderItemOpen] = useState(false);
  const [adjustmentsOpen, setAdjustmentsOpen] = useState(false);
  const [transitionOpen, setTransitionOpen] = useState(false);
  const [test, setTest] = useState({});
  const [billingProfiles, setBillingProfiles] = useState([]);
  const [contactProfiles, setContactProfiles] = useState([]);
  const [preparedForProfiles, setPreparedForProfiles] = useState([]);

  // eslint-disable-next-line no-unused-vars
  let [header, setHeader] = useContext(HeaderTitleContext);

  // eslint-disable-next-line no-unused-vars
  const [mainContentWidth, setMainContentWidth] = useContext(
    MainContentWidthContext
  );

  // eslint-disable-next-line no-unused-vars
  const [createOrderItemOptions, setCreateOrderItemOptions] = useState([
    {
      id: "ConformanceReportOrderItem",
      name: "Conformance Report Order",
    },
    {
      id: "DocumentOrderItem",
      name: "Document Order",
    },
  ]);

  const [initValues, setInitValues] = useState({});
  const [initOrderItemValues, setInitOrderItemValues] = useState({});

  const [getTheOrder, { data: orderQuery }] = useLazyQuery(getOrder(id));
  const [filterAdjustmentTypes, { data: adjustmentQuery }] = useLazyQuery(
    getAdjustmentTypes()
  );
  const [getStateTransitions, { data: stateTransitionsQuery }] = useLazyQuery(
    getOrderStateOptions(id),
    {
      fetchPolicy: "network-only",
    }
  );
  const [filterUsers, { data: usersQuery }] = useLazyQuery(getUserById());

  // eslint-disable-next-line no-unused-vars
  const [doEditOrder] = useMutation(editOrder(), {
    refetchQueries: [{ query: getOrder(id) }],
  });

  const [columns, setColumns] = useState([
    { field: "name", headerName: "Name", width: 230 },
    {
      field: "unit_price",
      headerName: "Unit Price",
      valueGetter: (value) => {
        return (+value.value).toFixed(2);
      },
      width: 230,
    },
    { field: "quantity", headerName: "Quantity", width: 230 },
    {
      field: "total_price",
      headerName: "Total Price",
      valueGetter: (value) => (+value.value).toFixed(2),
      width: 230,
    },
  ]);
  // eslint-disable-next-line no-unused-vars
  const [adjustmentColumns, setAdjustmentColumns] = useState([
    { field: "type", headerName: "Type", width: 230 },
    { field: "label", headerName: "Label", width: 230 },
    { field: "amount", headerName: "Amount", width: 230 },
    {
      field: "actions",
      headerName: "Actions",
      width: 230,
      // eslint-disable-next-line no-unused-vars
      renderCell: Object.assign(
        (params) => {
          return (
            <div>
              <Button
                variant={"contained"}
                color="primary"
                margin="normal"
                onClick={() => {
                  setEditOrderItem(true);
                  setInitOrderItemValues(params.row); //needs to be the selected values
                }}
              >
                Edit
              </Button>
              <Button
                variant={"contained"}
                color="primary"
                margin="normal"
                onClick={() => {
                  setTest(params);
                }}
              >
                Delete
              </Button>
            </div>
          );
        },
        { displayName: "renderCell" }
      ),
    },
  ]);
  const [rows, setRows] = useState([]);
  const [pageFilters, setPageFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [rowTotal, setRowTotal] = useState(0);

  const [availableReports, setAvailableReports] = useState([]);

  let defaultValues = {};

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...defaultValues, ...initValues },
    validationSchema: Yup.object({}),
    onSubmit: (values, formikTools) => {
      setEdit(false);
      /* eslint-disable no-unused-vars */
      const {
        client,
        adjustments,
        report_order_items,
        created,
        order_balance,
        placed,
        state,
        total_paid,
        total_price,
        create_order_item,
        create_adjustment_item,
        subtotal_price,
        ...payload
      } = values;
      /* eslint-enable no-unused-vars */
      // eslint-disable-next-line no-unused-vars
      const { order, jurisdiction, property, report_order_items_payload } =
        report_order_items;
      // eslint-disable-next-line no-unused-vars
      const { roles, newClient } = client;
      let newAdjustments = adjustments.map((adj) => {
        // eslint-disable-next-line no-unused-vars
        const { id, ...newAdj } = adj;
        return newAdj;
      });

      payload.report_order_items = [report_order_items_payload];
      payload.adjustments = newAdjustments;
      payload.client = newClient;

      let newPayload = {
        id: values.id,
        ...(values.adjustments && { adjustments: values.adjustments }),
        ...(values.billing_information && {
          billing_information: values.billing_information.map((bi) => ({
            id: bi.id,
          })),
        }),
        ...(values.client && {
          client: values.client.map((cli) => ({ id: cli.id })),
        }),
        ...(values.contact_information && {
          contact_information: values.contact_information.map((ci) => ({
            id: ci.id,
          })),
        }),
        ...(values.order_number && { order_number: values.order_number }),
        ...(values.portfolio_name && { portfolio_name: values.portfolio_name }),
        ...(values.prepared_for && {
          prepared_for: values.prepared_for.map((pf) => ({ id: pf.id })),
        }),
        ...(values.report_due_date && {
          report_due_date: values.report_due_date,
        }),
        ...(values.report_order_items && {
          report_order_items: values.report_order_items.map((roi) => ({
            id: roi.id,
          })),
        }),
        ...(values.state_transition && {
          state_transition: values.state_transition,
        }),
        ...(values.total_orders && { total_orders: values.total_orders }),
      };
      doEditOrder({ variables: { data: newPayload } });
      formikTools.resetForm();
    },
  });

  useEffect(() => {
    setHeader(`Order: ${id}`);
    setMainContentWidth("80");
    getTheOrder();
    filterAdjustmentTypes();
  }, []);

  useEffect(() => {
    if (orderQuery?.order) {
      setHeader(`Order: ${orderQuery?.order.portfolio_name} (${id})`);
      setInitValues(orderQuery?.order);
      filterUsers({ variables: { id: orderQuery?.order.client[0].id } });
    }
    let newRows = [];
    let newAvailableReports = [];
    orderQuery?.order?.report_order_items.map((roi) => {
      newRows.push(roi);
      //Add logic here when other types of reports exist to only add the ones that can have document order children.
      newAvailableReports.push({ id: roi.id, name: roi.name });
      if (roi?.document_order_items) {
        roi?.document_order_items.forEach((doi) => {
          newRows.push({
            ...doi,
            report_parent: [{ id: roi.id, name: roi.name }],
          });
        });
      }
    });
    getStateTransitions();
    setRows(newRows);
    setRowTotal(newRows.length);
    setAvailableReports(newAvailableReports);
  }, [orderQuery]);

  useEffect(() => {
    if (usersQuery?.user) {
      setBillingProfiles(usersQuery.user.billing_information);
      setContactProfiles(usersQuery.user.billing_information);
      setPreparedForProfiles(usersQuery.user.billing_information);
    }
  }, [usersQuery]);

  useEffect(() => {
    if (formik.values.adjustments) {
      let list = [...formik.values.adjustments];
      let index = list.findIndex((item) => {
        if (item && test?.row) {
          return item.id === test.row.id;
        } else {
          return -1;
        }
      });
      if (index >= 0) {
        list.splice(index);
        formik.setFieldValue("adjustments", list);
      }
    }
  }, [test]);

  useEffect(() => {
    if (!edit) {
      let newColumns = [...columns];
      let index = newColumns.findIndex((col) => col.field === "actions");
      if (index >= 0) {
        newColumns.splice(index);
      }
      setColumns(newColumns);
    } else {
      let newColumns = [...columns];
      newColumns.push({
        field: "actions",
        headerName: "Actions",
        width: 230,
        // eslint-disable-next-line no-unused-vars
        renderCell: Object.assign(
          (params) => {
            return (
              <div>
                <Button
                  variant={"contained"}
                  color="primary"
                  margin="normal"
                  onClick={() => {
                    setEditOrderItem(true);
                    setInitOrderItemValues(params.row); //needs to be the selected values
                  }}
                >
                  Edit
                </Button>
              </div>
            );
          },
          { displayName: "renderCell" }
        ),
      });
      setColumns(newColumns);
    }
  }, [edit]);

  return (
    <OrderDisplay
      columns={columns}
      rows={rows}
      adjustmentColumns={adjustmentColumns}
      pageFilters={{ get: pageFilters, set: setPageFilters }}
      currentPage={{ get: currentPage, set: setCurrentPage }}
      rowTotal={rowTotal}
      edit={{ get: edit, set: setEdit }}
      editOrderItem={{ get: editOrderItem, set: setEditOrderItem }}
      initOrderItemValues={{
        get: initOrderItemValues,
        set: setInitOrderItemValues,
      }}
      availableReports={{ get: availableReports, set: availableReports }}
      billingProfiles={{
        open: { get: billingOpen, set: setBillingOpen },
        options: {
          list: billingProfiles.map((contact) => {
            return {
              id: contact.id,
              name: contact?.address?.address_line1
                ? contact?.address?.address_line1
                : contact.phone,
            };
          }),
          filter: () => {},
        },
        variables: {
          profiles: billingProfiles,
        },
      }}
      contactProfiles={{
        open: { get: contactOpen, set: setContactOpen },
        options: {
          list: contactProfiles.map((contact) => {
            return {
              id: contact.id,
              name: contact?.address?.address_line1
                ? contact?.address?.address_line1
                : contact.phone,
            };
          }),
          filter: () => {},
        },
        variables: {
          profiles: contactProfiles,
        },
      }}
      preparedForProfiles={{
        open: { get: preparedForOpen, set: setPreparedForOpen },
        options: {
          list: preparedForProfiles.map((contact) => {
            return {
              id: contact.id,
              name: contact?.address?.address_line1
                ? contact?.address?.address_line1
                : contact.phone,
            };
          }),
          filter: () => {},
        },
        variables: {
          profiles: preparedForProfiles,
        },
      }}
      createOrderOptions={{
        open: { get: createOrderItemOpen, set: setCreateOrderItemOpen },
        options: { list: createOrderItemOptions, filter: () => {} },
      }}
      adjustmentTypeOptions={{
        open: { get: adjustmentsOpen, set: setAdjustmentsOpen },
        options: {
          list: adjustmentQuery?.adjustmentTypes
            ? adjustmentQuery.adjustmentTypes
            : [],
          filter: filterAdjustmentTypes,
        },
      }}
      stateTransitionOptions={{
        open: { get: transitionOpen, set: setTransitionOpen },
        options: {
          list: stateTransitionsQuery?.stateTransitions
            ? stateTransitionsQuery?.stateTransitions
            : [],
          filter: getStateTransitions,
        },
      }}
      formik={formik}
    />
  );
};

OrderContainer.propTypes = {
  id: PropTypes.string.isRequired,
};

export default OrderContainer;
