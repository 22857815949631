import React from "react";
import * as PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

const WorxDialogDisplay = (props) => {
  let { title, content, actions } = props;

  return (
    <Dialog {...props}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>{content}</DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
};

WorxDialogDisplay.propTypes = {
  title: PropTypes.string,
  content: PropTypes.any.isRequired,
  actions: PropTypes.any,
};

export default WorxDialogDisplay;
