import React from "react";
import Button from "@material-ui/core/Button";
import { FormLabel, makeStyles, Typography } from "@material-ui/core";
import {
  AutoCompleteFilter,
  GetSet,
  ListFilter,
} from "../../../Constants/Jurisdiction";
import * as PropTypes from "prop-types";
import WorxTextField from "../../Forms/Inputs/WorxTextField";
import WorxAutocompleteFormik from "../../Forms/Inputs/WorxAutocompleteFormik";
import WorxCKEditorFormik from "../../Forms/Inputs/WorxCKEditorFormik";
import WorxAddressFieldDisplay from "../../Forms/Inputs/WorxAddressFieldDisplay";
import WorxMediaUpload from "../../../Container/Forms/Inputs/WorxMediaUpload";
import WorxAutocompleteMultipleFormik from "../../Forms/Inputs/WorxAutocompleteMultipleFormik";

const useStyles = makeStyles({
  buttonWrapper: {
    textAlign: "right",
  },
});

const buttonUseStyles = makeStyles({
  root: {
    marginLeft: "1em",
  },
});

// eslint-disable-next-line no-unused-vars
const radioGroupUseStyles = makeStyles({
  root: {
    marginBottom: "1em",
  },
});

const AddressDetailsDisplay = (props) => {
  // eslint-disable-next-line no-unused-vars,no-unreachable
  /* eslint-disable no-unused-vars */
  let {
    id,
    editAddressDetails,
    zoningOptions,
    useOptions,
    abuttingZone0,
    abuttingZone1,
    abuttingZone2,
    abuttingZone3,
    jurisdiction,
    formik,
  } = props;
  /* eslint-enable no-unused-vars */
  const classes = useStyles();
  const buttonClasses = buttonUseStyles();

  return (
    <React.Fragment>
      {editAddressDetails.get && (
        <form>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "70%",
            }}
          >
            <WorxAddressFieldDisplay
              fieldKey={"address"}
              edit={{
                get: false,
                set: () => {
                  /*Do nothing*/
                },
              }}
              formik={formik}
            />
            <div>|</div>
            <Typography variant={"body2"} gutterBottom>
              {formik.values.jurisdiction[0].name}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "50%",
            }}
          >
            <div>
              <span>Jurisdiction GIS: </span>
              <a
                href={jurisdiction?.gis_map?.url}
                target={"_blank"}
                rel="noreferrer"
              >
                {jurisdiction?.gis_map?.link_text !== ""
                  ? jurisdiction?.gis_map?.link_text
                  : "File"}
              </a>
            </div>
            <div>
              <span>Jurisdiction Parcel Map: </span>
              <a
                href={jurisdiction?.parcel_map?.url}
                target={"_blank"}
                rel="noreferrer"
              >
                {jurisdiction?.parcel_map?.link_text !== ""
                  ? jurisdiction?.parcel_map?.link_text
                  : "File"}
              </a>
            </div>
          </div>
          {/*<WorxAutocompleteFormik fieldKey={'jurisdiction'} options={jurisdictionOptions} formik={formik} />*/}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "80%",
            }}
          >
            <WorxMediaUpload
              fieldKey={"google_map"}
              entityType={"media"}
              property={id}
              type={"image"}
              formik={formik}
            />
            <WorxMediaUpload
              fieldKey={"zoning_map"}
              label={"Zoning Map"}
              entityType={"media"}
              property={id}
              type={"image"}
              formik={formik}
            />
            <WorxMediaUpload
              fieldKey={"parcel_map"}
              label={"Parcel Map"}
              entityType={"media"}
              property={id}
              type={"image"}
              formik={formik}
            />
            <WorxTextField
              fieldKey={"parcel_tax_id"}
              label={"Parcel/Tax ID"}
              formik={formik}
            />
          </div>

          <WorxAutocompleteMultipleFormik
            fieldKey={"zone"}
            nameKey={"designation"}
            options={zoningOptions}
            formik={formik}
          />
          <WorxAutocompleteMultipleFormik
            fieldKey={"use"}
            options={useOptions}
            formik={formik}
          />
          <FormLabel>Abutting Zoning</FormLabel>
          <div style={{ display: "flex" }}>
            <div>
              <WorxTextField
                fieldKey={"abutting_zoning.0.name"}
                label={"Name"}
                formik={formik}
              />
              <WorxAutocompleteFormik
                fieldKey={"abutting_zoning.0.zoning"}
                nameKey={"designation"}
                label={"Search Zones"}
                options={{
                  open: abuttingZone0,
                  options: { ...zoningOptions.options },
                  variables: { ...zoningOptions.variables },
                }}
                formik={formik}
              />
            </div>
            <div>
              <WorxTextField
                fieldKey={"abutting_zoning.1.name"}
                label={"Name"}
                formik={formik}
              />
              <WorxAutocompleteFormik
                fieldKey={"abutting_zoning.1.zoning"}
                nameKey={"designation"}
                label={"Search Zones"}
                options={{
                  open: abuttingZone1,
                  options: { ...zoningOptions.options },
                  variables: { ...zoningOptions.variables },
                }}
                formik={formik}
              />
            </div>
            <div>
              <WorxTextField
                fieldKey={"abutting_zoning.2.name"}
                label={"Name"}
                formik={formik}
              />
              <WorxAutocompleteFormik
                fieldKey={"abutting_zoning.2.zoning"}
                nameKey={"designation"}
                label={"Search Zones"}
                options={{
                  open: abuttingZone2,
                  options: { ...zoningOptions.options },
                  variables: { ...zoningOptions.variables },
                }}
                formik={formik}
              />
            </div>
            <div>
              <WorxTextField
                fieldKey={"abutting_zoning.3.name"}
                label={"Name"}
                formik={formik}
              />
              <WorxAutocompleteFormik
                fieldKey={"abutting_zoning.3.zoning"}
                nameKey={"designation"}
                label={"Search Zones"}
                options={{
                  open: abuttingZone3,
                  options: { ...zoningOptions.options },
                  variables: { ...zoningOptions.variables },
                }}
                formik={formik}
              />
            </div>
          </div>
          <WorxCKEditorFormik fieldKey={"notes"} formik={formik} />
          <div className={classes.buttonWrapper}>
            <Button
              variant={"contained"}
              color="secondary"
              margin="normal"
              onClick={() => {
                editAddressDetails.set(false);
                formik.resetForm();
              }}
            >
              Cancel
            </Button>
            <Button
              variant={"contained"}
              color="primary"
              margin="normal"
              onClick={() => {
                formik.setFieldValue("button", "normal");
                formik.handleSubmit();
              }}
              classes={{ root: buttonClasses.root }}
            >
              Submit
            </Button>
          </div>
        </form>
      )}
      {!editAddressDetails.get && <React.Fragment></React.Fragment>}
    </React.Fragment>
  );
};

AddressDetailsDisplay.propTypes = {
  id: PropTypes.number,
  editAddressDetails: GetSet,
  categories: ListFilter,
  zoningOptions: AutoCompleteFilter,
  useOptions: AutoCompleteFilter,
  abuttingZone0: GetSet,
  abuttingZone1: GetSet,
  abuttingZone2: GetSet,
  abuttingZone3: GetSet,
  jurisdiction: PropTypes.object,
  formik: PropTypes.object.isRequired,
};

export default AddressDetailsDisplay;
