import React from "react";
// eslint-disable-next-line no-unused-vars
import * as PropTypes from "prop-types";
import AnswerDisplay from "../../Display/Research/AnswerDisplay";

const AnswerContainer = (props) => {
  const { answer } = props;

  let map = {
    zoning_code_violation: "zv_violations",
    building_code_violation: "bv_violations",
    fire_code_violation: "fv_violations",
    certificate_of_occupancy: "not_needed",
    condemnation: "cn_plans",
  };

  return <AnswerDisplay map={map} answer={answer} />;
};

AnswerContainer.propTypes = {
  answer: PropTypes.object,
};

export default AnswerContainer;
