import React, { useContext } from "react";
//import {makeStyles, Typography} from "@material-ui/core";
import * as PropTypes from "prop-types";
import UserContext from "../../../Context/UserContext";
import Card from "@material-ui/core/Card/Card";
import { CardContent } from "@material-ui/core";
import { addressToDisplay } from "../../../tools/addressTools";

const ClientPortalOrderDisplay = (props) => {
  const { order } = props;
  const userContext = useContext(UserContext);

  return (
    <Card raised={true}>
      <CardContent>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Order ID: {order?.id}</span>
          <span>Client: {userContext?.[0]?.name}</span>
        </div>
        <div>
          Address: {addressToDisplay(order?.contact_information?.[0]?.address)}
        </div>
        <div>Placed: {order?.placed}</div>
      </CardContent>
    </Card>
  );
};

ClientPortalOrderDisplay.propTypes = {
  order: PropTypes.object,
};

export default ClientPortalOrderDisplay;
