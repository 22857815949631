import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  getJurisdiction,
  getProperty,
  getUsesForJurisdiction,
  getZonesForJurisdiction,
} from "../../../Queries/Queries";
import AddressDetailsDisplay from "../../../Display/Research/Forms/AddressDetailsDisplay";
import { editProperty } from "../../../Queries/Mutations";

/*const useStyles = makeStyles({
  worxButtonStyleOverride: {
    marginLeft: '1em',
  }
});*/

//{id: 10, abutting_zoning: {id: 10, name: "", zoning: {id: 10, name: ""}}, google_map: {id: 10, image: {id: 10}}, jurisdiction: {id: 10}, notes: "", parcel_map: {id: 10}, zoning_map: {id: 10}, zone: {id: 10}}

const AddressDetailsContainer = (props) => {
  const { id } = props;
  const [zoningOpen, setZoningOpen] = useState(false);
  const [useOpen, setUseOpen] = useState(false);
  const [abuttingZone0, setAbuttingZone0] = useState(false);
  const [abuttingZone1, setAbuttingZone1] = useState(false);
  const [abuttingZone2, setAbuttingZone2] = useState(false);
  const [abuttingZone3, setAbuttingZone3] = useState(false);
  const [editAddressDetails, setEditAddressDetails] = useState(true);
  const [filterProperty, { data: propertyQuery }] = useLazyQuery(
    getProperty(id)
  );
  const [initialValues, setInitialValues] = useState({});
  const [filterUses, { data: useQuery }] = useLazyQuery(
    getUsesForJurisdiction()
  );
  const [filterZoning, { data: zoningQuery }] = useLazyQuery(
    getZonesForJurisdiction()
  );
  const [filterJurisdiction, { data: jurisdictionQuery }] = useLazyQuery(
    getJurisdiction()
  );

  const [doEditProperty] = useMutation(editProperty(), {
    refetchQueries: [{ query: getProperty(id) }],
  });

  useEffect(() => {
    filterProperty();
  }, []);

  useEffect(() => {
    if (propertyQuery?.property) {
      setInitialValues(propertyQuery.property);
      formik.resetForm();
      filterJurisdiction({
        variables: { id: propertyQuery.property.jurisdiction[0].id },
      });
    }
    //if(propertyQuery?.property?.jurisdiction[0]) {}
  }, [propertyQuery]);

  let defaultProperty = {
    address: {
      additional_name: "",
      address_line1: "",
      address_line2: "",
      administrative_area: "",
      country_code: "",
      dependent_locality: "",
      family_name: "",
      given_name: "",
      langcode: "",
      locality: "",
      organization: "",
      postal_code: "",
      sorting_code: "",
    },
    jurisdiction: [
      {
        id: 0,
        name: "unknown",
      },
    ],
    zone: [
      {
        id: 0,
        name: "unknown",
      },
    ],
    abutting_zoning: [
      {
        id: 0,
        name: "unknown",
      },
    ],
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...defaultProperty, ...initialValues },
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      /* eslint-disable no-unused-vars */
      let {
        button,
        field_media_zoning_map,
        field_media_google_map,
        field_media_parcel_map,
        ...payload
      } = values;
      /* eslint-disable no-unused-vars */
      doEditProperty({ variables: { data: payload } });
    },
  });

  if (Object.keys(initialValues).length === 0) {
    return <div>Not Ready</div>;
  }

  return (
    <AddressDetailsDisplay
      id={id}
      editAddressDetails={{
        get: editAddressDetails,
        set: setEditAddressDetails,
      }}
      zoningOptions={{
        open: { get: zoningOpen, set: setZoningOpen },
        options: {
          list: zoningQuery?.zones?.items ? zoningQuery?.zones?.items : [],
          filter: filterZoning,
        },
        variables: {
          filters: {
            get: {
              jurisdiction: propertyQuery?.property?.jurisdiction?.[0]?.id ?? 1,
            },
            set: () => {
              /*do nothing*/
            },
          },
        },
      }}
      useOptions={{
        open: { get: useOpen, set: setUseOpen },
        options: {
          list: useQuery?.uses?.items ? useQuery?.uses?.items : [],
          filter: filterUses,
        },
        variables: {
          filters: {
            get: {
              jurisdiction: propertyQuery?.property?.jurisdiction?.[0]?.id ?? 1,
            },
            set: () => {
              /*do nothing*/
            },
          },
        },
      }}
      abuttingZone0={{ get: abuttingZone0, set: setAbuttingZone0 }}
      abuttingZone1={{ get: abuttingZone1, set: setAbuttingZone1 }}
      abuttingZone2={{ get: abuttingZone2, set: setAbuttingZone2 }}
      abuttingZone3={{ get: abuttingZone3, set: setAbuttingZone3 }}
      property={propertyQuery?.property}
      jurisdiction={jurisdictionQuery?.jurisdiction ?? {}}
      formik={formik}
    />
  );
};

AddressDetailsContainer.propTypes = {
  id: PropTypes.number.isRequired,
};

export default AddressDetailsContainer;
