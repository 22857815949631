import React from "react";
import * as PropTypes from "prop-types";
import {
  AutoCompleteFilter,
  GetSet,
  ListFilter
} from "../../Constants/Jurisdiction";
import { Typography } from "@material-ui/core";
import WorxDataGridDisplay from "../WorxDataGrid/WorxDataGridDisplay";
import WorxAutocompleteFormik from "../Forms/Inputs/WorxAutocompleteFormik";
import TextField from "@material-ui/core/TextField";
import WorxAddressField from "../../Container/Forms/Inputs/WorxAddressField";
import {Button} from "@mui/material";
import WorxDialogContainer from "../../Container/WorxDialogContainer";
import WorxFormContainer from "../../Container/Forms/Generic/WorxFormContainer";
import Card from "@material-ui/core/Card";
import {makeStyles} from "@mui/styles";
import { CardContent } from "@material-ui/core";

const topCardUseStyles = makeStyles({
  root: {
    "& .MuiDataGrid-cell": {
      whiteSpace: "normal !important",
    },
    marginBottom: "2em",
  },
});

const CommercialOrderItemDisplay = (props) => {
  const {orderItem, jurisdictions, columns, properties, currentPage, propertyFilters, rowTotal, showNewPropertyForm, propertyFormOptions, showVerificationForm, verificationFormOptions, jurisdictionFormik, propertyFormik, verificationFormik, propertyLoading} = props;

  const topCardClasses = topCardUseStyles();

  return <div style={{display: "flex", justifyContent: "space-between"}}>
    <div style={{flex: "1 10px", marginRight: "10px"}}>
      <Card classes={{root: topCardClasses.root}} raised={true}>
        <CardContent>
          <section >
            <Typography variant={"h5"}>Order Confirmation</Typography>

            <Typography variant={"h6"}>Order #{orderItem.id} details:</Typography>

            <div style={{display: 'flex', justifyContent: "space-between"}}>
              <Typography variant={'body1'}>{orderItem.quantity}x</Typography>
              <WorxAddressField fieldKey={'address'} hideLabel={true} label={'Address Report'} formik={jurisdictionFormik} disabled={true} />
              <Typography variant={'body1'}>${(+orderItem.unit_price).toFixed(2)}</Typography>
            </div>

            <Typography variant={"h6"}>Order Total: ${(+orderItem.total_price).toFixed(2)}</Typography>
          </section>
        </CardContent>
      </Card>
    </div>

    <div style={{flex: 2}}>
      <Card classes={{root: topCardClasses.root}} raised={true}>
        <CardContent>
          <section>
            <WorxAutocompleteFormik fieldKey={'jurisdiction'} options={jurisdictions} formik={jurisdictionFormik} />
            {jurisdictionFormik?.values?.jurisdiction?.[0]?.id && (!orderItem.property || orderItem?.property.length <= 0) && <TextField
              margin="normal"
              id={"name"}
              label={'Name'}
              placeholder={"Name"}
              onChange={(event) => {
                let nextFilter = {
                  ...propertyFilters.get,
                  name: event.target.value,
                  offset: 0,
                  operator: "CONTAINS"
                };
                properties.filter({ variables: nextFilter });
                propertyFilters.set(nextFilter);
                currentPage.set(0);
              }}
            />}
            {(!orderItem.property || orderItem.property.length <= 0) && <WorxDataGridDisplay
              columns={columns}
              rows={properties}
              paginationMode={'server'}
              currentPage={currentPage}
              filters={propertyFilters}
              pageSize={25}
              rowTotal={rowTotal}
              loading={propertyLoading}
            />}
            <div style={{display: 'flex'}}>
              {jurisdictionFormik?.values?.jurisdiction?.[0]?.id &&
                <Button variant="contained" color="primary" onClick={() => {
                  showNewPropertyForm.set(true);
                }}>{`${orderItem.property && orderItem?.property.length > 0 ? `Replace With` : `Create`} New Property`}</Button>
              }
              {orderItem.property && orderItem?.property.length > 0 && <div>
                <Button variant="contained" color="primary" onClick={() => {
                  Object.keys(orderItem.property[0]).forEach(key => {
                    propertyFormik.setFieldValue(key, orderItem.property[0][key])
                  });
                  propertyFormik.setFieldValue('edit', true);
                  showNewPropertyForm.set(true);
                }}>{"Edit Property"}</Button>
              </div>}
            </div>
            {jurisdictionFormik?.values?.jurisdiction?.[0]?.id && <WorxDialogContainer
              title={'Create Property'}
              id={'test'}
              open={showNewPropertyForm.get}
              onClose={() => showNewPropertyForm.set(false)}
              content={<WorxFormContainer formik={propertyFormik} options={propertyFormOptions} />}
            />}
            <WorxDialogContainer
              title={'Request Verification'}
              id={'test'}
              open={showVerificationForm.get}
              onClose={() => showVerificationForm.set(false)}
              content={<WorxFormContainer formik={verificationFormik} options={verificationFormOptions} />}
            />
          </section>
        </CardContent>
      </Card>
    </div>
  </div>
}

CommercialOrderItemDisplay.propTypes = {
  orderItem: PropTypes.object.isRequired,
  columns: PropTypes.array,

  currentPage: GetSet,
  filters: GetSet,
  rowTotal: PropTypes.number,

  loading: PropTypes.bool,

  jurisdictions: AutoCompleteFilter,

  propertyFilters: GetSet,
  properties: ListFilter,

  showNewPropertyForm: GetSet,
  propertyFormOptions: PropTypes.object,

  showVerificationForm: GetSet,
  verificationFormOptions: PropTypes.object,

  jurisdictionFormik: PropTypes.object,
  propertyFormik: PropTypes.object,
  verificationFormik: PropTypes.object,

  propertyLoading: PropTypes.bool,
}

export default CommercialOrderItemDisplay;
