import React from "react";
import * as PropTypes from "prop-types";
//import WorxListCreate from "../../../Display/Forms/Inputs/WorxListCreate";
import SurveyFieldFormContainer from "./SurveyFieldFormContainer";
import DefaultListCreateContainer from "./DefaultListCreateContainer";
//import TenantFormContainer from "./TenantFormContainer";

const WorxListCreateSwitchContainer = (props) => {
  let { fieldKey, formik } = props;

  switch (formik.values["__typename"]) {
    case "survey":
      return <SurveyFieldFormContainer fieldKey={fieldKey} formik={formik} />;
    /*case 'tenant_list':
      return <TenantFormContainer fieldKey={fieldKey} formik={formik} />*/
    default:
      return <DefaultListCreateContainer fieldKey={fieldKey} formik={formik} />;
  }
};

WorxListCreateSwitchContainer.propTypes = {
  fieldKey: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  options: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  setInitValues: PropTypes.func,
  formik: PropTypes.object.isRequired,
};

export default WorxListCreateSwitchContainer;
