import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField/TextField";
import * as PropTypes from "prop-types";
import { camelToTitle, underscoreToCamel } from "../../../tools/stringTools";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  textfieldWrapper: {
    marginBottom: ".5em",
  },
});

const WorxTextField = (props) => {
  let {
    formik,
    disabled = false,
    type,
    trim,
    fieldKey,
    label = camelToTitle(underscoreToCamel(fieldKey)),
  } = props;

  let value = formik.values;
  let error = formik.errors;
  let touched = formik.touched;

  for (let el of fieldKey.split(".")) {
    if (value && value?.[el]) {
      value = value?.[el];
    } else {
      value = null;
    }

    if (error && error?.[el]) {
      error = error?.[el];
    } else {
      error = null;
    }

    if (touched && touched?.[el]) {
      touched = touched?.[el];
    } else {
      touched = null;
    }
  }

  let defaultValue = "";
  if (type === "number") {
    defaultValue = null;
    /*if(trim) {
      value = (+value).toFixed(trim).toString()//trim dec places down to a max digits.
    }*/
  }

  useEffect(() => {
    if (type === "number" && trim && value) {
      formik.setFieldValue(fieldKey, +(+value).toFixed(trim));
    }
  }, []);

  const classes = useStyles();
  let labelWidth = 0;
  if (label.length < 15) {
    labelWidth = 15;
  } else {
    labelWidth = label.length;
  }

  return (
    <React.Fragment>
      <section
        data-testid={"text-field"}
        className={classes.textfieldWrapper}
        style={{ width: `${labelWidth}em` }}
        aria-label={label}
      >
        <TextField
          fullWidth
          margin="normal"
          label={label}
          id={fieldKey}
          disabled={disabled}
          value={value ? value : defaultValue}
          type={type ? type : "text"}
          onChange={formik.handleChange}
          onBlur={(event) => {
            if (trim && type === "number") {
              formik.setFieldValue(
                fieldKey,
                +(+event.target.value).toFixed(trim)
              );
            }
            //avoid empty strings in a number values
            if (type === "number" && event.target.value === "") {
              formik.setFieldValue(fieldKey, null);
            }
          }}
        />
        {touched && error ? <div style={{ color: "red" }}>{error}</div> : null}
      </section>
    </React.Fragment>
  );
};

WorxTextField.propTypes = {
  fieldKey: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  trim: PropTypes.number,
  label: PropTypes.string,
  formik: PropTypes.object.isRequired,
};

export default WorxTextField;
