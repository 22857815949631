import { Route, Switch, useRouteMatch } from "react-router-dom";
import React from "react";
import CommercialOrderItemContainer
  from "../Container/CommercialReport/CommercialOrderItemContainer";
import CommercialContainer
  from "../Container/CommercialReport/CommercialContainer";
import CommercialQueueContainer
  from "../Container/CommercialReport/CommercialQueueContainer";

const CommercialRouter = () => {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route
        path={`${match.url}/order/:id/order-item/:orderItem`}
        render={(props) => {
          // eslint-disable-next-line react/prop-types
          return <CommercialOrderItemContainer id={+props.match.params.orderItem} orderId={+props.match.params.id} />;
        }}
      />
      <Route
        path={`${match.url}/order/:id`}
        render={(props) => {
          // eslint-disable-next-line react/prop-types
          return <CommercialContainer id={+props.match.params.id} />;
        }}
      />
      <Route
        path={`${match.url}`}
        render={() => {
          // eslint-disable-next-line react/prop-types
          return <CommercialQueueContainer />;
        }}
      />
    </Switch>
  );
};

export default CommercialRouter;
