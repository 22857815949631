import React from "react";
import * as PropTypes from "prop-types";
import WorxFormContainer from "../../Container/Forms/Generic/WorxFormContainer";

const AnalystCOfODisplay = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { options, formik } = props;

  return (
    <React.Fragment>
      <WorxFormContainer options={options} formik={formik} />
    </React.Fragment>
  );
};

AnalystCOfODisplay.propTypes = {
  options: PropTypes.object,
  formik: PropTypes.object,
};

export default AnalystCOfODisplay;
