let date = new Date();
let oldDate = new Date(date.getTime() - 15 * 24 * 60 * 60 * 1000);

export const jurisdictionList = [
  {
    "id": 1,
    "name": "United States",
    "type": "country",
    "verified_date": Math.floor(date.getTime() / 1000),
    "verification_state": null,
    "task_description": null,
  },
  {
    "id": 2,
    "name": "Oklahoma",
    "type": "administrative_area",
    "verified_date": Math.floor(oldDate.getTime() / 1000),
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 3,
    "name": "Oklahoma - Oklahoma",
    "type": "subdivision",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 4,
    "name": "Oklahoma City - Oklahoma - Oklahoma",
    "type": "locality",
    "verified_date": null,
    "verification_state": "verifying",
    "task_description": null
  },
  {
    "id": 5,
    "name": "Texas",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 6,
    "name": "Denton - Texas",
    "type": "subdivision",
    "verified_date": null,
    "verification_state": null,
    "task_description": 'This is a task description'
  },
  {
    "id": 7,
    "name": "Flower Mound - Denton - Texas",
    "type": "locality",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 8,
    "name": "Denton - Denton - Texas",
    "type": "locality",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 10,
    "name": "Alabama",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 11,
    "name": "Alaska",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 12,
    "name": "Arizona",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 13,
    "name": "Arkansas",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 14,
    "name": "California",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 15,
    "name": "Colorado",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 16,
    "name": "Connecticut",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 17,
    "name": "Delaware",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 18,
    "name": "Florida",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 19,
    "name": "Georgia",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 20,
    "name": "Hawaii",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 21,
    "name": "Idaho",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 22,
    "name": "Illinois",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 23,
    "name": "Indiana",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 24,
    "name": "Iowa",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 25,
    "name": "Kansas",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 26,
    "name": "Kentucky",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 27,
    "name": "Louisiana",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 28,
    "name": "Maine",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 29,
    "name": "Maryland",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 30,
    "name": "Massachusetts",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 31,
    "name": "Michigan",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 32,
    "name": "Minnesota",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 33,
    "name": "Mississippi",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 34,
    "name": "Missouri",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 35,
    "name": "Montana",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 36,
    "name": "Nebraska",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 37,
    "name": "Nevada",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 38,
    "name": "New Hampshire",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 39,
    "name": "New Jersey",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 40,
    "name": "New Mexico",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 41,
    "name": "New York",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 42,
    "name": "North Carolina",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 43,
    "name": "North Dakota",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 44,
    "name": "Ohio",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 45,
    "name": "Oregon",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 46,
    "name": "Pennsylvania",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 47,
    "name": "Rhode Island",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 48,
    "name": "South Carolina",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 49,
    "name": "South Dakota",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 50,
    "name": "Tennessee",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 52,
    "name": "Utah",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 53,
    "name": "Vermont",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 54,
    "name": "Virginia",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 55,
    "name": "Washington",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 56,
    "name": "West Virginia",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 57,
    "name": "Wisconsin",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 58,
    "name": "Wyoming",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 59,
    "name": "District of Columbia",
    "type": "administrative_area",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 60,
    "name": "Autauga - Alabama",
    "type": "subdivision",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 61,
    "name": "Weston - Wyoming",
    "type": "subdivision",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 62,
    "name": "Washakie - Wyoming",
    "type": "subdivision",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 63,
    "name": "Unita - Wyoming",
    "type": "subdivision",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 64,
    "name": "Teton - Wyoming",
    "type": "subdivision",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 65,
    "name": "Sweetwater - Wyoming",
    "type": "subdivision",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 66,
    "name": "Sublette - Wyoming",
    "type": "subdivision",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 67,
    "name": "Sheridan - Wyoming",
    "type": "subdivision",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 68,
    "name": "Platte - Wyoming",
    "type": "subdivision",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 69,
    "name": "Park - Wyoming",
    "type": "subdivision",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 70,
    "name": "Niobrara - Wyoming",
    "type": "subdivision",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 71,
    "name": "Natrona - Wyoming",
    "type": "subdivision",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 72,
    "name": "Lincoln - Wyoming",
    "type": "subdivision",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 73,
    "name": "Laramie - Wyoming",
    "type": "subdivision",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 74,
    "name": "Johnson - Wyoming",
    "type": "subdivision",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 75,
    "name": "Hot Springs - Wyoming",
    "type": "subdivision",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 76,
    "name": "Goshen - Wyoming",
    "type": "subdivision",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 77,
    "name": "Fremont - Wyoming",
    "type": "subdivision",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 78,
    "name": "Crook - Wyoming",
    "type": "subdivision",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 79,
    "name": "Converse - Wyoming",
    "type": "subdivision",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 80,
    "name": "Carbon - Wyoming",
    "type": "subdivision",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 81,
    "name": "Campbell - Wyoming",
    "type": "subdivision",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 82,
    "name": "Big Horn - Wyoming",
    "type": "subdivision",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 83,
    "name": "Albany - Wyoming",
    "type": "subdivision",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 84,
    "name": "Wood - Wisconsin",
    "type": "subdivision",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 85,
    "name": "Winnebago - Wisconsin",
    "type": "subdivision",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 86,
    "name": "Waushara - Wisconsin",
    "type": "subdivision",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 87,
    "name": "Waupaca - Wisconsin",
    "type": "subdivision",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 88,
    "name": "Waukesha - Wisconsin",
    "type": "subdivision",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 89,
    "name": "Washington - Wisconsin",
    "type": "subdivision",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 90,
    "name": "Washburn - Wisconsin",
    "type": "subdivision",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 91,
    "name": "Walworth - Wisconsin",
    "type": "subdivision",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 92,
    "name": "Vilas - Wisconsin",
    "type": "subdivision",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 93,
    "name": "Vernon - Wisconsin",
    "type": "subdivision",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 94,
    "name": "Trempealeau - Wisconsin",
    "type": "subdivision",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 95,
    "name": "Taylor - Wisconsin",
    "type": "subdivision",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 96,
    "name": "Sheboygan - Wisconsin",
    "type": "subdivision",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 97,
    "name": "Shawano - Wisconsin",
    "type": "subdivision",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 98,
    "name": "Sawyer - Wisconsin",
    "type": "subdivision",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 99,
    "name": "Sauk - Wisconsin",
    "type": "subdivision",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 100,
    "name": "St. Croix - Wisconsin",
    "type": "subdivision",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 101,
    "name": "Rusk - Wisconsin",
    "type": "subdivision",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  },
  {
    "id": 102,
    "name": "Rock - Wisconsin",
    "type": "subdivision",
    "verified_date": null,
    "verification_state": null,
    "task_description": null
  }
]
