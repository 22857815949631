import React, { useEffect, useState } from "react";
// eslint-disable-next-line no-unused-vars
import * as PropTypes from "prop-types";
import OrderItemFormDisplay from "../../../Display/Orders/Forms/OrderItemFormDisplay";
import { useFormik } from "formik";
import * as Yup from "yup";
import { GetSet } from "../../../Constants/Jurisdiction";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  getJurisdictionOptions,
  getOrder,
  getProperties,
} from "../../../Queries/Queries";
import { createOrderItem, editOrderItem } from "../../../Queries/Mutations";

const OrderItemFormContainer = (props) => {
  let { referenceData, initValues, edit } = props;
  let type = initValues.get.__typename;

  // eslint-disable-next-line no-unused-vars
  const [doEditOrderItem] = useMutation(editOrderItem(type), {
    refetchQueries: [{ query: getOrder(referenceData.id) }],
  });
  const [doCreateOrderItem] = useMutation(createOrderItem(type), {
    refetchQueries: [{ query: getOrder(referenceData.id) }],
  });

  const [jurisdictionOpen, setJurisdictionOpen] = useState(false);
  const [filterJurisdictions, { data: jurisdictionsQuery }] = useLazyQuery(
    getJurisdictionOptions("locality", "", 50)
  );
  const [jurisdictions, setJurisdictions] = useState([]);

  const [propertyOpen, setPropertyOpen] = useState(false);
  const [filterProperties, { data: propertiesQuery }] = useLazyQuery(
    getProperties()
  );
  const [properties, setProperties] = useState([]);

  const [fixedPropertyFilters, setFixedPropertyFilters] = useState({});

  let defaultOrderItemValues = {};

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...defaultOrderItemValues, ...initValues.get },
    validationSchema: Yup.object({}),
    // eslint-disable-next-line no-unused-vars
    onSubmit: (values, formikTools) => {
      edit.set(false);

      if (values.id) {
        /* eslint-disable no-unused-vars */
        const {
          property,
          document_order_items,
          total_price,
          order,
          ...payload
        } = values;
        /* eslint-enable no-unused-vars */
        payload.order = { id: order.id, name: order.name };
        doEditOrderItem({ variables: { data: payload } });
      } else {
        // eslint-disable-next-line no-unused-vars
        const { total_price, ...payload } = values;
        doCreateOrderItem({ variables: { data: payload } });
      }
      formikTools.resetForm();
    },
  });

  useEffect(() => {
    filterJurisdictions();
  }, []);

  useEffect(() => {
    if (jurisdictionsQuery?.jurisdictions?.items) {
      setJurisdictions(jurisdictionsQuery?.jurisdictions?.items);
    }
  }, [jurisdictionsQuery]);

  useEffect(() => {
    if (formik?.values?.jurisdiction) {
      setFixedPropertyFilters({
        jurisdiction: formik?.values?.jurisdiction?.[0]?.id,
      });
      filterProperties({
        variables: { offset: 0, limit: 50, ...fixedPropertyFilters },
      });
    }
  }, [formik?.values?.jurisdiction]);

  useEffect(() => {
    if (propertiesQuery?.properties?.items) {
      setProperties(propertiesQuery?.properties?.items);
    }
  }, [propertiesQuery]);

  return (
    <OrderItemFormDisplay
      jurisdictions={{
        open: { get: jurisdictionOpen, set: setJurisdictionOpen },
        options: {
          list: jurisdictions,
          filter: filterJurisdictions,
        },
      }}
      properties={{
        open: { get: propertyOpen, set: setPropertyOpen },
        options: {
          list: properties,
          filter: filterProperties,
        },
        variables: {
          filters: { get: fixedPropertyFilters, set: setFixedPropertyFilters },
        },
      }}
      formik={formik}
    />
  );
};

OrderItemFormContainer.propTypes = {
  initValues: GetSet,
  referenceData: PropTypes.object,
  edit: GetSet,
};

export default OrderItemFormContainer;
