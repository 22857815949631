import React, { useEffect, useState } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import { GetSet } from "../../../../Constants/Jurisdiction";
import {
  getCategoriesForJurisdiction,
  getZoneUseLinkStatusParents,
} from "../../../../Queries/Queries";
import { useFormik } from "formik";
import * as Yup from "yup";
import UseLegendFormDisplay from "../../../../Display/DataEntry/DataEntryTwo_UseWorkflow/Forms/UseLegendFormDisplay";
import Button from "@material-ui/core/Button";
import WorxDeleteButton from "../../../Forms/Inputs/WorxDeleteButton";
import { createLegend, editLegend } from "../../../../Queries/Mutations";

const UseLegendFormContainer = (props) => {
  let { data } = props;
  let [addLegendStatuses, setAddLegendStatuses] = useState(false);
  let [initValues, setInitValues] = useState({});
  let id = data.get.id;
  const pageSize = 25;
  const [currentPage, setCurrentPage] = useState(0);
  const [filters, setFilters] = useState({ limit: pageSize, offset: 0 });
  const [rowCount, setRowCount] = useState(0);
  const [filterCategories, { data: categoriesQuery }] = useLazyQuery(
    getCategoriesForJurisdiction("zoneuselink_statuses", id),
    {
      fetchPolicy: "cache-and-network",
    }
  );
  const [parentOpen, setParentOpen] = useState(false);
  const [filterParents, { data: parentsQuery }] = useLazyQuery(
    getZoneUseLinkStatusParents()
  );
  const [doCreateLegend] = useMutation(createLegend(), {
    update(
      cache,
      {
        data: {
          createZoneUseLinkStatus: { category },
        },
      }
    ) {
      const { categories } = cache.readQuery({
        query: getCategoriesForJurisdiction("zoneuselink_statuses", id),
      });
      cache.writeQuery({
        query: getCategoriesForJurisdiction("zoneuselink_statuses", id),
        data: {
          categories: {
            CategoryConnection: {
              items: categories.items.concat(category),
            },
          },
        },
      });
    },
  });
  const [doEditLegend] = useMutation(editLegend(), null);

  // Pull first set of uses only on first pass.
  useEffect(() => {
    //first fetch needed in lazy case
    filterCategories();
  }, []);

  useEffect(() => {
    if (categoriesQuery) {
      setRowCount(categoriesQuery.categories.total);
    }
  }, [categoriesQuery]);

  let defaultLegend = {
    name: "",
    description: "",
    parent: [{ id: 0, name: "" }],
    button: null,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...defaultLegend, ...initValues, button: null },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      description: Yup.string().required("Required"),
    }),
    onSubmit: (values, formikTools) => {
      // eslint-disable-next-line no-unused-vars
      let { button, ...legend } = values;
      switch (button) {
        case "normal":
          mutateLegend(legend);
          setAddLegendStatuses(false);
          break;
        case "repeat":
          mutateLegend(legend);
          break;
        case "cancel":
          setAddLegendStatuses(false);
          break;
      }
      setInitValues({});
      formikTools.resetForm();
    },
  });

  const mutateLegend = async (footnote) => {
    // Do this to get the payload in the right shape
    // eslint-disable-next-line no-unused-vars
    let { new: isNew, edit, type, rowIndex, ...payload } = footnote;
    payload.jurisdiction = [
      {
        id: data.get.id,
      },
    ];
    if (payload.id) {
      await doEditLegend({ variables: { data: payload } }).then();
    } else {
      // take the temp id out of the payload
      // eslint-disable-next-line no-unused-vars
      let { id, ...createPayload } = payload;
      await doCreateLegend({ variables: { data: createPayload } });
      setRowCount(rowCount + 1);
    }
  };

  const columns = [
    { field: "name", headerName: "Name", width: 230 },
    {
      field: "description",
      headerName: "Description",
      width: 230,
      renderCell: Object.assign(
        (params) => {
          const strippedString = params.value.replace(/(<([^>]+)>)/gi, "");
          return <div>{strippedString}</div>;
        },
        { displayName: "renderCell" }
      ),
    },
    {
      field: "Operation",
      headerName: "ID",
      width: 200,
      renderCell: Object.assign(
        (params) => {
          return (
            <React.Fragment>
              <WorxDeleteButton
                callback={(response) => {
                  if (response?.deleteEntity?.errors?.length <= 0) {
                    filterCategories({ variables: filters });
                  }
                }}
                type={"category"}
                id={params.row.id}
              />
              <Button
                onClick={() => {
                  formik.resetForm();
                  setInitValues(params.row);
                  setAddLegendStatuses(true);
                }}
              >
                edit
              </Button>
            </React.Fragment>
          );
        },
        { displayName: "renderCell" }
      ),
    },
  ];

  return (
    <UseLegendFormDisplay
      addLegendStatuses={{ get: addLegendStatuses, set: setAddLegendStatuses }}
      legends={{
        list: categoriesQuery?.categories?.items,
        filter: filterCategories,
      }}
      columns={columns}
      parents={{
        open: { get: parentOpen, set: setParentOpen },
        options: {
          list: parentsQuery?.zoneUseLinkStatusParents
            ? parentsQuery.zoneUseLinkStatusParents
            : [],
          filter: filterParents,
        },
      }}
      initValues={{ get: initValues, set: setInitValues }}
      currentPage={{ get: currentPage, set: setCurrentPage }}
      filters={{ get: filters, set: setFilters }}
      pageSize={pageSize}
      rowCount={rowCount}
      formik={formik}
    />
  );
};

UseLegendFormContainer.propTypes = {
  data: GetSet,
};

export default UseLegendFormContainer;
