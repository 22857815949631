//Taken from renevanderark https://stackoverflow.com/questions/7225407/convert-camelcasetext-to-sentence-case-text/7225450
export const camelToTitle = (camelCase) =>
  camelCase
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase());

export const camelToUnderscore = (camelCase) =>
  camelCase
    .replace(/([A-Z])/g, (match) => `_${match}`)
    .slice(1)
    .toLowerCase();

export const underscoreToCamel = (underscore) =>
  underscore
    .replace(/_./g, (match) => match.toUpperCase())
    .replace(/\.\d\../g, (match) => match.toUpperCase())
    .replace(/_/g, () => "")
    .replace(/\.\d\./g, () => "");

export const titleToUnderscore = (title) => {
  let parts = title.split(" ");
  let lowerparts = parts.map((part) => part.toLowerCase());
  let under = lowerparts.join("_");
  return under;
};
