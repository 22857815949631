import React from "react";
import LogoContainer from "../../Container/Menu/LogoContainer";
import { Drawer, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core"; //needs to be replaced
import MainMenuContainer from "../../Container/Menu/MainMenuContainer";

const drawerMinWidth = "200px";

const drawerUseStyles = makeStyles((theme) => ({
  root: {
    minWidth: drawerMinWidth,
  },
  paper: {
    background: theme.palette.secondary.main,
    marginTop: "2.2em",
    minWidth: drawerMinWidth,
  },
}));

const dividerUseStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.secondary.light,
  },
}));

const SidebarDisplay = () => {
  const drawerClasses = drawerUseStyles();
  const dividerClasses = dividerUseStyles();

  return (
    <Drawer
      classes={{ root: drawerClasses.root, paper: drawerClasses.paper }}
      variant="permanent"
      anchor="left"
    >
      <LogoContainer />
      <Divider classes={{ root: dividerClasses.root }} />
      <MainMenuContainer />
    </Drawer>
  );
};

export default SidebarDisplay;
