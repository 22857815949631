import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLazyQuery, useMutation } from "@apollo/client";
import { createLink, editLink } from "../../../../Queries/Mutations";
import { getCategoryOptions, getFootnotes } from "../../../../Queries/Queries";
import { GetSet } from "../../../../Constants/Jurisdiction";
import WorxDeleteButton from "../../../Forms/Inputs/WorxDeleteButton";
import UseCellFormDisplay from "../../../../Display/DataEntry/DataEntryTwo_UseWorkflow/Forms/UseCellFormDisplay";

const UseCellFormContainer = (props) => {
  const { initValues, jurisdictionID, filters, requery, grid, closeForm } =
    props;

  //formState
  const [statusOpen, setStatusOpen] = useState(false);
  const [footnoteOpen, setFootnoteOpen] = useState(false);

  //queries
  const [filterStatus, { data: statusQuery }] = useLazyQuery(
    getCategoryOptions(jurisdictionID, "zoneuselink_statuses")
  );
  const [filterFootnotes, { data: footnotesQuery }] = useLazyQuery(
    getFootnotes(jurisdictionID, "use_footnote")
  );

  //mutations
  const [doCreateLink] = useMutation(createLink(), requery);
  const [doEditLink] = useMutation(editLink(), requery);

  let defaultLink = {
    name: "",
    zone: [],
    use: [],
    status: [],
    footnote: [],
    __typename: "useMatrix",
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...defaultLink, ...initValues.get, button: null },
    validationSchema: Yup.object({}),
    onSubmit: (values, formikTools) => {
      // eslint-disable-next-line no-unused-vars
      let { button, ...link } = values;
      modifyCell(link);
      formikTools.resetForm();
    },
  });

  let modifyCell = (link) => {
    link.jurisdiction = [{ id: jurisdictionID }];
    if (link.id) {
      doEditLink({ variables: { data: link } });
    } else {
      doCreateLink({ variables: { data: link } });
    }
    closeForm();
  };

  const buttons = [
    formik.values.id && (
      <WorxDeleteButton
        key={"delete"}
        callback={(response) => {
          closeForm();
          if (response?.deleteEntity?.errors?.length <= 0) {
            grid.filter({ variables: filters.get });
          }
        }}
        requery={requery}
        type={"zone_use_link"}
        id={formik.values.id}
      />
    ),
  ];

  useEffect(() => {
    filterStatus();
    filterFootnotes();
  }, []);

  return (
    <UseCellFormDisplay
      options={{
        status: {
          open: { get: statusOpen, set: setStatusOpen },
          options: {
            list: statusQuery?.categories?.items
              ? statusQuery.categories.items
              : [],
            filter: filterStatus,
          },
        },
        footnote: {
          open: { get: footnoteOpen, set: setFootnoteOpen },
          options: {
            list: footnotesQuery?.footnotes?.items
              ? footnotesQuery.footnotes.items
              : [],
            filter: filterFootnotes,
          },
        },
      }}
      buttons={buttons}
      formik={formik}
    />
  );
};

UseCellFormContainer.propTypes = {
  initValues: PropTypes.object,
  jurisdictionID: PropTypes.number,
  filters: GetSet,
  requery: PropTypes.func,
  grid: PropTypes.object,
  closeForm: PropTypes.func,
};

export default UseCellFormContainer;
