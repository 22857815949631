//import logo from './logo.svg';
import "./App.css";
import { FormComponent, FormContainer } from "react-authorize-net";
import React, { useEffect } from "react";
import { apiLoginId, clientKey, environment } from "../../../Constants/Auth";
import * as PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { authorizeSubmit } from "../../../Queries/Mutations";
import { useHistory } from "react-router";

const AuthorizeNetFormContainer = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { orderID } = props;

  // eslint-disable-next-line no-unused-vars
  const [doCreateOrder, { data: authResponse }] = useMutation(
    authorizeSubmit()
  );

  const history = useHistory();
  useEffect(() => {
    if (authResponse) {
      history.push({
        pathname: "/residential/my-order",
      });
    }
  }, [authResponse]);

  return (
    <div id="worx-authorize-net-wrapper">
      <FormContainer
        apiLoginId={apiLoginId}
        environment={environment}
        clientKey={clientKey}
        amount={10}
        component={FormComponent}
        onSuccess={(response) => {
          doCreateOrder({
            variables: { authorize_nonce: response, order_id: orderID },
          });
        }}
        onError={(errors) => {
          console.log(errors);
        }}
      />
    </div>
  );
};

AuthorizeNetFormContainer.propTypes = {
  orderID: PropTypes.number.isRequired,
};

export default AuthorizeNetFormContainer;
