import React from "react";
import * as PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { ListItem, Link } from "@mui/material";
import { makeStyles } from "@material-ui/core";

const listItemUseStyles = makeStyles({
  root: {
    padding: "0",
  },
});

const linkUseStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.white,
    minWidth: "200px",
    padding: "1em",
    "&:hover": {
      background: theme.palette.secondary.light,
      color: theme.palette.text.white,
      textDecoration: "none",
    },
  },
}));

const AnalystStepperMenuDisplay = (props) => {
  const { menuItems } = props;

  const listItemClasses = listItemUseStyles();
  const linkClasses = linkUseStyles();

  return (
    <div>
      {menuItems.map((item) => {
        return (
          <ListItem key={item.path} classes={{ root: listItemClasses.root }}>
            <Link
              classes={{ root: linkClasses.root }}
              component={RouterLink}
              to={item.path}
            >
              {item.display}
            </Link>
          </ListItem>
        );
      })}
    </div>
  );
};

AnalystStepperMenuDisplay.propTypes = {
  menuItems: PropTypes.array.isRequired,
};

export default AnalystStepperMenuDisplay;
