import React from "react";
import * as PropTypes from "prop-types";
import WorxDialogDisplay from "../Display/WorxDialogDisplay";

const WorxDialogContainer = (props) => {
  let { title, content, actions } = props;

  return (
    <WorxDialogDisplay
      title={title}
      content={content}
      actions={actions}
      {...props}
    />
  );
};

WorxDialogContainer.propTypes = {
  title: PropTypes.string,
  content: PropTypes.any.isRequired,
  actions: PropTypes.any,
};

export default WorxDialogContainer;
