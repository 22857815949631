import React from 'react';
import {Tooltip} from "@mui/material";

// eslint-disable-next-line react/prop-types
const ContentPreview = ({availableSpace = 1, content = 'this a long test'}: {availableSpace: number, content: string}) => {

  let localContent: string = content ?? '';

  return (<Tooltip title={localContent.length > availableSpace ? <div dangerouslySetInnerHTML={{__html: localContent}} />  : ''}>
    <div dangerouslySetInnerHTML={{__html: `${localContent.slice(0, availableSpace)}${localContent.length > availableSpace ? '...' : ''}`}} />
  </Tooltip>)
}

export default ContentPreview;
