import React, { useContext } from "react";
import {
  getAnalystClaimedQueueItems, getAnalystQueueItemsShort,
} from "../../Queries/Queries";
import {
  analystReleaseQueueItem,
  claimAnalystQueueItem,
} from "../../Queries/Mutations";
import { useHistory } from "react-router";
import HeaderTitleContext from "../../Context/HeaderTitleContext";
import Button from "@material-ui/core/Button";
import QueueContainer from "../Queue/QueueContainer";

// eslint-disable-next-line no-unused-vars
const AnalystQueueContainer = (props) => {
  const history = useHistory();

  // eslint-disable-next-line no-unused-vars
  const [headerTitle, setHeaderTitle] = useContext(HeaderTitleContext);
  setHeaderTitle("Analyst");

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 100,
      renderCell: Object.assign(
        (params) => {
          return (
            <Button aria-label={`row-id-${params.value}`}
              onClick={() => {
                history.push({
                  pathname: "/analyst/analyst-workflow/" + params.row.id,
                  state: { data: params.row },
                });
              }}
            >
              {params.row.id}
            </Button>
          );
        },
        { displayName: "renderCell" }
      ),
    },
    /*{ field: 'id', headerName: 'ID', width: 230,
      renderCell: Object.assign(params => {
        return <Link component={RouterLink} to={`/orders/${params.row.id}`}>
          {params.value}
        </Link>;
      }, { displayName: 'renderCell' })
    },*/
    {
      field: "order",
      headerName: "Client",
      width: 230,
      valueGetter: (value) => {
        return value.row.conformance_report_order_item?.[0]?.order?.[0]
          ?.client[0].name;
      },
    },
    {
      field: "portfolio_name",
      headerName: "Portfolio",
      width: 230,
      valueGetter: (value) => {
        return value.row.conformance_report_order_item?.[0]?.order?.[0]
          ?.portfolio_name;
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 230,
      valueGetter: (value) => {
        return value?.row?.conformance_report_order_item?.[0]?.name;
      },
    },
    {
      field: "Address",
      headerName: "Address",
      width: 230,
      valueGetter: (value) => {
        return value?.row?.conformance_report_order_item?.[0]?.property?.[0]
          ?.address.address_line1;
      },
    },
    {
      field: "City",
      headerName: "City",
      width: 230,
      valueGetter: (value) => {
        return value?.row?.conformance_report_order_item?.[0]?.property?.[0]
          ?.address.locality;
      },
    },
    {
      field: "State",
      headerName: "State",
      width: 230,
      valueGetter: (value) => {
        return value?.row?.conformance_report_order_item?.[0]?.property?.[0]
          ?.address.administrative_area;
      },
    },
    {
      field: "report_order_items",
      headerName: "Jurisdiction",
      width: 230,
      valueGetter: (value) => {
        return value?.row?.conformance_report_order_item?.[0]?.jurisdiction?.[0]
          ?.name;
      },
    },
    {
      field: "state",
      headerName: "Status",
      width: 230,
      valueGetter: (value) => {
        return value.row.conformance_report_order_item?.[0]?.order?.[0]?.state;
      },
    },
  ];

  return (
    <QueueContainer
      columns={columns}
      type={"Item"}
      itemsQuery={getAnalystQueueItemsShort}
      queryKey={"analystQueueItems"}
      claimedItemsQuery={getAnalystClaimedQueueItems}
      claimedItemsQueryKey={"analystClaimedQueueItems"}
      claimQuery={claimAnalystQueueItem}
      claimKey={"analystClaimQueueItem.claimed_item.analyst_report.id"}
      claimRoute={"/analyst/analyst-workflow"}
      release={analystReleaseQueueItem}
      getReleaseKey={(params) => {
        return {
          variables: { data: { analyst_report: [{ id: params.row.id }] } },
        };
      }}
    />
  );
};

AnalystQueueContainer.propTypes = {};

export default AnalystQueueContainer;
