import React, { useState } from "react";
// eslint-disable-next-line no-unused-vars
import * as PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import WorxMediaUpload from "../Forms/Inputs/WorxMediaUpload";

const DocumentUploadSubContainer = (props) => {
  const {
    hideOnDone = true,
    initValues,
    callback = () => {},
    jurisdiction,
    property,
  } = props;
  const defaultValues = {};
  const [done, setDone] = useState(initValues?.document ? true : false);

  const uploadForm = useFormik({
    enableReinitialize: true,
    initialValues: { ...defaultValues, ...initValues },
    validationSchema: Yup.object({}),
    // eslint-disable-next-line no-unused-vars
    onSubmit: (values) => {},
  });

  return (
    <React.Fragment>
      {(!done || !hideOnDone) && (
        <WorxMediaUpload
          hideLink={true}
          type={"document"}
          label={""}
          onComplete={() => {
            uploadForm.handleSubmit();
            if (uploadForm.values.document) {
              callback(uploadForm.values.id, uploadForm.values.document);
              uploadForm.setFieldValue("document", null);
              setDone(true);
            }
          }}
          fieldKey={"document"}
          entityType={"media"}
          jurisdiction={jurisdiction?.[0]?.id}
          property={property?.[0]?.id}
          typeLock={initValues.document_order_template[0].research_documents
            .map((doc) => doc.id)
            .filter((docID) => {
              const index = initValues.document.findIndex((uploadedDoc) => {
                return uploadedDoc.document_type[0].id == docID;
              });
              if (index < 0) {
                return true;
              }
              return false;
            })}
          formik={uploadForm}
        />
      )}
    </React.Fragment>
  );
};

DocumentUploadSubContainer.propTypes = {
  jurisdiction: PropTypes.object,
  property: PropTypes.object.isRequired,
  hideOnDone: PropTypes.bool,
  initValues: PropTypes.object.isRequired,
  callback: PropTypes.func,
};

export default DocumentUploadSubContainer;
