import React from "react";
import * as PropTypes from "prop-types";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { DataGrid } from "@mui/x-data-grid";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import {
  GetSet,
  AutoCompleteFilter,
  ListFilter,
} from "../../../../Constants/Jurisdiction";
import { FormLabel, makeStyles, Typography } from "@material-ui/core";
import WorxFileUpload from "../../../../Container/Forms/Inputs/WorxFileUpload";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { format, parseISO } from "date-fns";

const useStyles = makeStyles({
  buttonWrapper: {
    textAlign: "right",
  },
});

const radioGroupStyles = makeStyles({
  root: {
    marginBottom: "1em",
  },
});

const buttonUseStyles = makeStyles({
  root: {
    marginLeft: "1em",
  },
});

const topCardUseStyles = makeStyles({
  root: {
    "& .MuiDataGrid-cell": {
      whiteSpace: "normal !important",
    },
    marginBottom: "2em",
  },
});

const JurisdictionFormThreeDisplay = (props) => {
  let {
    addZonesDistricts,
    columns,
    zones,
    initValues,
    categoryOptions,
    pageSize,
    filters,
    rowCount,
    currentPage,
    formik,
  } = props;

  const classes = useStyles();
  const buttonClasses = buttonUseStyles();
  const radioGroupClasses = radioGroupStyles();
  const topCardClasses = topCardUseStyles();

  return (
    <React.Fragment>
      <Typography variant={"h5"} gutterBottom>
        Zoning Districts / Overlays / PUD’s
      </Typography>
      <FormLabel>Zone District Type</FormLabel>
      {!addZonesDistricts.get && (
        <RadioGroup
          aria-label="Zoning Districts / Overlays / PUD’s"
          name="zone-districts"
          value={addZonesDistricts.get}
          onChange={(event) => {
            addZonesDistricts.set(event.target.value);
            initValues.set({});
          }}
          classes={{ root: radioGroupClasses.root }}
        >
          <FormControlLabel value={"zone"} control={<Radio />} label="Zone" />
          <FormControlLabel
            value={"overlay"}
            control={<Radio />}
            label="Overlay"
          />
          <FormControlLabel value={"pd"} control={<Radio />} label="PD / PUD" />
        </RadioGroup>
      )}
      {addZonesDistricts.get && (
        <form onSubmit={formik.handleSubmit}>
          <div>
            <TextField
              margin="normal"
              id={"designation"}
              value={formik.values.designation}
              label={"Designation"}
              onChange={formik.handleChange}
            />
            {formik.touched.designation && formik.errors.designation ? (
              <div style={{ color: "red" }}>{formik.errors.designation}</div>
            ) : null}
          </div>
          <div>
            <TextField
              margin="normal"
              id={"name"}
              value={formik.values.name}
              label={"Name"}
              onChange={formik.handleChange}
            />
            {formik.touched.name ? (
              <div style={{ color: "red" }}>{formik.errors.name}</div>
            ) : null}
          </div>
          <div>
            <TextField
              margin="normal"
              id={"description"}
              value={formik.values.description}
              label={"Description"}
              onChange={formik.handleChange}
            />
            {formik.touched.description && formik.errors.description ? (
              <div style={{ color: "red" }}>{formik.errors.description}</div>
            ) : null}
          </div>
          <div>
            <Autocomplete
              autoComplete
              id={"category"}
              style={{ width: 300 }}
              open={categoryOptions.open.get}
              onOpen={() => {
                categoryOptions.options.filter();
                categoryOptions.open.set(true);
              }}
              onClose={() => {
                categoryOptions.open.set(false);
              }}
              getOptionSelected={(option, value) =>
                option?.name === value?.name
              }
              getOptionLabel={(option) => option.name}
              options={categoryOptions.options.list}
              loading={false}
              onInputChange={(e, value) => {
                categoryOptions.options.filter({ variables: { name: value } });
              }}
              onChange={(event, value) => {
                if (value) {
                  formik.setFieldValue("category", [
                    { id: value.id, name: value.name },
                  ]);
                } else {
                  formik.setFieldValue("category", []);
                }
              }}
              value={
                formik.values.category !== null ? formik.values.category[0] : 0
              }
              renderInput={(params) => {
                return (
                  <TextField {...params} label={"Category"} margin="normal" />
                );
              }}
            />
            {formik.touched.category && formik.errors.category ? (
              <div style={{ color: "red" }}>{"Required"}</div>
            ) : null}
          </div>
          <div>
            <TextField
              margin="normal"
              id={"weight"}
              type="number"
              value={formik.values.weight}
              label={"Weight"}
              onChange={formik.handleChange}
            />
            {formik.touched.weight && formik.errors.weight ? (
              <div style={{ color: "red" }}>{formik.errors.weight}</div>
            ) : null}
          </div>
          <WorxFileUpload
            fieldKey={"code_file"}
            entityType={"zone"}
            formik={formik}
          />
          <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id={"code_date"}
                format="yyyy-MM-dd"
                value={parseISO(formik.values.code_date)}
                label={"Code Date"}
                onChange={(value) => {
                  formik.setFieldValue(
                    "code_date",
                    format(value, "yyyy-MM-dd")
                  );
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
          {formik.touched.weight && formik.errors.weight ? (
            <div style={{ color: "red" }}>{formik.errors.weight}</div>
          ) : null}
          <div className={classes.buttonWrapper}>
            <Button
              variant={"contained"}
              color="secondary"
              margin="normal"
              onClick={() => {
                addZonesDistricts.set(null);
                formik.resetForm();
              }}
            >
              Cancel
            </Button>
            <Button
              variant={"contained"}
              color="primary"
              margin="normal"
              onClick={() => {
                formik.setFieldValue("button", "normal");
                formik.handleSubmit();
              }}
              classes={{ root: buttonClasses.root }}
            >
              Submit
            </Button>
            <Button
              variant={"contained"}
              color="primary"
              margin="normal"
              onClick={() => {
                formik.setFieldValue("button", "repeat");
                formik.handleSubmit();
              }}
              classes={{ root: buttonClasses.root }}
            >
              Submit and Add Another
            </Button>
          </div>
        </form>
      )}

      {!addZonesDistricts.get && (
        <React.Fragment>
          <div>
            <TextField
              margin="normal"
              id={"designation"}
              placeholder={"Designation"}
              onChange={(event) => {
                let nextFilter = {
                  ...filters.get,
                  designation: event.target.value,
                  offset: 0,
                };
                zones.filter({ variables: nextFilter });
                filters.set(nextFilter);
                currentPage.set(0);
              }}
            />
          </div>
          <DataGrid
            classes={{ root: topCardClasses.root }}
            key={rowCount}
            page={currentPage.get}
            pagination
            paginationMode="server"
            rowCount={rowCount}
            pageSize={pageSize}
            onPageChange={(page) => {
              let nextFilter = { ...filters.get, offset: pageSize * page };
              zones.filter({ variables: nextFilter });
              filters.set(nextFilter);
              currentPage.set(page);
            }}
            loading={!zones.list}
            columns={columns}
            rows={zones.list ? zones.list : []}
            autoHeight
            getRowHeight={() => "auto"}
            checkboxSelection={false}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

JurisdictionFormThreeDisplay.propTypes = {
  addZonesDistricts: GetSet,
  columns: PropTypes.array,
  zones: ListFilter,
  initValues: GetSet,
  categoryOptions: AutoCompleteFilter,
  pageSize: PropTypes.number.isRequired,
  rowCount: PropTypes.number,
  filters: GetSet,
  currentPage: GetSet,
  formik: PropTypes.object.isRequired,
};

export default JurisdictionFormThreeDisplay;
