import React from "react";
import * as PropTypes from "prop-types";
import AbuttingZonesDisplay from "../Display/AbuttingZonesDisplay";
import { GetSet } from "../Constants/Jurisdiction";

const AbuttingZonesContainer = (props) => {
  //const type = 'document';
  const { open, abuttingZones } = props;
  //const [pageSize, setPageSize] = useState(15);
  //const [open, setOpen] = useState(false);
  //const [rowCount, setRowCount] = useState(0);
  //const [currentPage, setCurrentPage] = useState(0);
  //const [filters, setFilters] = useState({limit: pageSize, offset: 0, operator: 'CONTAINS'});

  //const [filterJurisdictionFiles, {data: jurisdictionFilesQuery}] = useLazyQuery(getJurisdictionFiles(jurisdiction, pageSize), {variables: filters, fetchPolicy: 'cache-and-network'});

  /*useEffect(() => {
    let newFilters = {...filters};
    if(property) {
      newFilters.property = property;
    }
    if(jurisdiction) {
      newFilters.jurisdiction = jurisdiction;
    }
    setFilters(newFilters);
    filterJurisdictionFiles({variables: newFilters});
  }, []);

  useEffect(() => {
    if(jurisdictionFilesQuery?.jurisdictionFiles?.total) {
      setRowCount(jurisdictionFilesQuery?.jurisdictionFiles?.total);
    }
  }, [jurisdictionFilesQuery]);*/

  return <AbuttingZonesDisplay open={open} abuttingZones={abuttingZones} />;
};

AbuttingZonesContainer.propTypes = {
  abuttingZones: PropTypes.array.isRequired,
  open: GetSet,
};

export default AbuttingZonesContainer;
