import React, { useContext, useEffect } from "react";
import JurisdictionsDisplay from "../Display/JurisdictionsDisplay";
import { getJurisdictions } from "../Queries/Queries";
import { claimQueueItem } from "../Queries/Mutations";
import { useMutation, useQuery } from "@apollo/client";
import * as PropTypes from "prop-types";
import { useHistory } from "react-router";
import UserContext from "../Context/UserContext";

const JurisdictionsContainer = (props) => {
  const { loading, error, data } = useQuery(getJurisdictions(props.type));
  const [claimJurisdiction, { data: claimedJurisdiction }] = useMutation(
    claimQueueItem("main_workflow")
  );
  const [claimUse, { data: claimedUse }] = useMutation(
    claimQueueItem("use_workflow")
  );
  const [claimRequirements, { data: claimedRequirments }] = useMutation(
    claimQueueItem("requirements_workflow")
  );
  const history = useHistory();
  const userContext = useContext(UserContext);

  useEffect(() => {
    if (claimedJurisdiction) {
      history.push({
        pathname:
          "/jurisdictions/workflow/jurisdiction/" +
          claimedJurisdiction.claimQueueItem.claimed_item.jurisdiction.id,
        state: {
          data: claimedJurisdiction.claimQueueItem.claimed_item.jurisdiction,
        },
      });
    }
  }, [claimedJurisdiction]);

  useEffect(() => {
    if (claimedUse) {
      history.push({
        pathname:
          "/jurisdictions/workflow/use/" +
          claimedUse.claimQueueItem.claimed_item.jurisdiction.id,
        state: { data: claimedUse.claimQueueItem.claimed_item.jurisdiction },
      });
    }
  }, [claimedUse]);

  useEffect(() => {
    if (claimedRequirments) {
      history.push({
        pathname:
          "/jurisdictions/workflow/requirements/" +
          claimedRequirments.claimQueueItem.claimed_item.jurisdiction.id,
        state: {
          data: claimedRequirments.claimQueueItem.claimed_item.jurisdiction,
        },
      });
    }
  }, [claimedRequirments]);

  if (loading) return <p>loading...</p>;
  if (error) return <p>There was an error loading the Jurisdiction</p>;

  let width = "";

  switch (props.type) {
    case "All":
      width = "34";
      break;
    case "Locality":
      width = "46.7";
      break;
    case "AdministrativeArea":
      width = "46.7";
      break;
    case "Subdivision":
      width = "46.7";
      break;
    case "Country":
      width = "46.7";
      break;
  }

  return (
    data.jurisdictions && (
      <JurisdictionsDisplay
        userContext={userContext}
        jurisdictions={data.jurisdictions.items}
        type={props.type}
        claimJurisdiction={claimJurisdiction}
        claimUse={claimUse}
        claimRequirements={claimRequirements}
        width={width}
      />
    )
  );
};

JurisdictionsContainer.propTypes = {
  type: PropTypes.string,
};

export default JurisdictionsContainer;
