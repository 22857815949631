import React, { useContext, useState } from "react";
import {
  getResearchClaimedQueueItems,
  getResearchQueueItemsShort,
} from "../../Queries/Queries";
import { useHistory } from "react-router";
import HeaderTitleContext from "../../Context/HeaderTitleContext";
import Button from "@material-ui/core/Button";
import QueueContainer from "../Queue/QueueContainer";
import {
  claimResearchQueueItem,
  researchReleaseQueueItem,
} from "../../Queries/Mutations";

// eslint-disable-next-line no-unused-vars
const ResearchQueueContainer = (props) => {
  const history = useHistory();

  // eslint-disable-next-line no-unused-vars
  const [headerTitle, setHeaderTitle] = useContext(HeaderTitleContext);
  setHeaderTitle("Research");

  // eslint-disable-next-line no-unused-vars
  const [columns, setColumns] = useState([
    {
      field: "id",
      headerName: "ID",
      width: 100,
      renderCell: Object.assign(
        (params) => {
          return (
            <Button aria-label={`row-id-${params.value}`}
              onClick={() => {
                history.push({
                  pathname: "/research/research_workflow/" + params.row.id,
                  state: { data: params.row },
                });
              }}
            >
              {params.row.id}
            </Button>
          );
        },
        { displayName: "renderCell" }
      ),
    },
    {
      field: "name",
      headerName: "Name",
      width: 150,
      renderCell: Object.assign(
        (params) => {
          return <div>{params.row.name}</div>;
        },
        { displayName: "renderCell" }
      ),
    },
    {
      field: "order",
      headerName: "Client",
      width: 230,
      valueGetter: (value) => {
        return value.row?.order?.[0]?.client[0].name;
      },
    },
    {
      field: "portfolio_name",
      headerName: "Portfolio",
      width: 230,
      valueGetter: (value) => {
        return value.row?.order?.[0]?.portfolio_name;
      },
    },
    {
      field: "Address",
      headerName: "Address",
      width: 230,
      valueGetter: (value) => {
        return value?.row?.property?.[0]?.address.address_line1;
      },
    },
    {
      field: "City",
      headerName: "City",
      width: 230,
      valueGetter: (value) => {
        return value?.row?.property?.[0]?.address.locality;
      },
    },
    {
      field: "State",
      headerName: "State",
      width: 230,
      valueGetter: (value) => {
        return value?.row?.property?.[0]?.address.administrative_area;
      },
    },
    {
      field: "report_order_items",
      headerName: "Jurisdiction",
      width: 230,
      valueGetter: (value) => {
        return value?.row?.jurisdiction?.[0]?.name;
      },
    },
    {
      field: "state",
      headerName: "Status",
      width: 230,
      valueGetter: (value) => {
        return value.row?.order?.[0]?.state;
      },
    },
  ]);

  return (
    <QueueContainer
      columns={columns}
      type={"Item"}
      itemsQuery={getResearchQueueItemsShort}
      queryKey={"researchQueueItems"}
      claimedItemsQuery={getResearchClaimedQueueItems}
      claimedItemsQueryKey={"researchClaimedQueueItems"}
      claimQuery={claimResearchQueueItem}
      claimKey={"researchClaimQueueItem.claimed_item.order_item.id"}
      claimRoute={"/research/research_workflow"}
      release={researchReleaseQueueItem}
      getReleaseKey={(params) => {
        return { variables: { data: { order_item: [{ id: params.row.id }] } } };
      }}
    />
  );
};

ResearchQueueContainer.propTypes = {};

export default ResearchQueueContainer;
