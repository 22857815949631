import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import * as PropTypes from "prop-types";
import { camelToTitle, underscoreToCamel } from "../../../tools/stringTools";

const WorxRadio = (props) => {
  let {
    formik,
    fieldKey,
    disabled = false,
    listIDKey = "id",
    listNameKey = "name",
    listInfoKey = "info",
    options,
  } = props;

  return (
    <div data-testid={"radio"}>
      <div>
        <RadioGroup
          aria-label={camelToTitle(underscoreToCamel(fieldKey))}
          name={fieldKey}
          value={formik.values[fieldKey] ? formik.values[fieldKey] : ""}
          onChange={(event) => {
            formik.setFieldValue(fieldKey, event.target.value);
          }}
          disabled={disabled}
          onClick={(event) => {
            if (!disabled && event.target.value === formik.values[fieldKey]) {
              formik.setFieldValue(fieldKey, "");
            }
          }}
        >
          {options.map((option) => {
            return (
              <React.Fragment key={option[listIDKey]}>
                <FormControlLabel
                  value={option[listIDKey]}
                  control={<Radio />}
                  label={option[listNameKey]}
                />
                {option[listInfoKey] && <div>{option[listInfoKey]}</div>}
              </React.Fragment>
            );
          })}
        </RadioGroup>
      </div>
      {formik.touched[fieldKey] && formik.errors[fieldKey] ? (
        <div style={{ color: "red" }}>{formik.errors[fieldKey]}</div>
      ) : null}
    </div>
  );
};

WorxRadio.propTypes = {
  fieldKey: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  listIDKey: PropTypes.string,
  listNameKey: PropTypes.string,
  listInfoKey: PropTypes.string,
  options: PropTypes.array.isRequired,
  formik: PropTypes.object.isRequired,
};

export default WorxRadio;
