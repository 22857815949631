import React from "react";
import * as propTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DocumentDrawerContainer from "../../Container/DataEntry/DocumentDrawerContainer";
import AbuttingZonesContainer from "../../Container/AbuttingZonesContainer";
import { GetSet } from "../../Constants/Jurisdiction";

const SurveyHeaderDisplay = (props) => {
  const {
    documentFile,
    property,
    jurisdiction,
    abuttingZoneOpen,
    documentOpen,
  } = props;

  const googleMapFile = property?.google_map?.[0]?.image?.[0];
  const parcelMapFile = property?.parcel_map?.[0]?.image?.[0];
  const zoningMapFile = property?.zoning_map?.[0]?.image?.[0];

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "1.5em",
        padding: "0 1em",
        alignItems: "center",
      }}
    >
      {documentFile && (
        <div style={{ margin: "0 0.5em" }}>
          This Document:{" "}
          <a href={documentFile && documentFile?.filepath}>
            {documentFile?.filename}
          </a>
        </div>
      )}
      {googleMapFile && (
        <div style={{ margin: "0 0.5em" }}>
          Google Map:{" "}
          <a href={googleMapFile?.filepath}>{googleMapFile?.filename}</a>
        </div>
      )}
      {parcelMapFile && (
        <div style={{ margin: "0 0.5em" }}>
          Parcel Map:{" "}
          <a href={parcelMapFile?.filepath}>{parcelMapFile?.filename}</a>
        </div>
      )}
      {zoningMapFile && (
        <div style={{ margin: "0 0.5em" }}>
          Zoning Map:{" "}
          <a href={zoningMapFile?.filepath}>{zoningMapFile?.filename}</a>
        </div>
      )}
      <div style={{ margin: "0 0.5em" }}>
        <Button onClick={() => abuttingZoneOpen.set(!abuttingZoneOpen.get)}>
          Abutting Zone
        </Button>
      </div>
      <div style={{ margin: "0 0.5em" }}>
        <Button
          onClick={() => documentOpen.set(!documentOpen.get)}
          variant="contained"
        >
          Documents
        </Button>
      </div>
      {jurisdiction && (
        <DocumentDrawerContainer
          jurisdiction={jurisdiction}
          open={documentOpen}
        />
      )}
      {property?.abutting_zoning && (
        <AbuttingZonesContainer
          abuttingZones={property?.abutting_zoning}
          open={abuttingZoneOpen}
        />
      )}
    </div>
  );
};

SurveyHeaderDisplay.propTypes = {
  documentFile: propTypes.object,
  property: propTypes.object,
  jurisdiction: propTypes.object,
  abuttingZoneOpen: GetSet,
  documentOpen: GetSet,
};

export default SurveyHeaderDisplay;
