import React, { useEffect } from "react";
import * as PropTypes from "prop-types";
import { camelToTitle, underscoreToCamel } from "../../../tools/stringTools";
import { makeStyles } from "@mui/styles";
import { AutoCompleteFilter, GetSet } from "../../../Constants/Jurisdiction";
import WorxAutocomplete from "./WorxAutocomplete";
import { USStates } from "../../../Constants/Values";
import WorxTextField from "./WorxTextField";

const useStyles = makeStyles({
  textfieldWrapper: {
    marginBottom: ".5em",
  },
});

const WorxAddressFieldDisplay = (props) => {
  /* eslint-disable no-unused-vars */
  let {
    fieldKey,
    disabled = false,
    hideLabel = false,
    label = camelToTitle(underscoreToCamel(fieldKey)),
    edit,
    states,
    countryCode = "US",
    formik,
  } = props;
  /* eslint-disable no-unused-vars */
  let addressField = formik.values;
  let error = formik.errors;
  let touched = formik.touched;

  for (let el of fieldKey.split(".")) {
    if (addressField && addressField?.[el]) {
      addressField = addressField?.[el];
    } else {
      addressField = null;
    }

    if (error && error?.[el]) {
      error = error?.[el];
    } else {
      error = null;
    }

    if (touched && touched?.[el]) {
      touched = touched?.[el];
    } else {
      touched = null;
    }
  }

  const classes = useStyles();

  const addr = `${
    addressField?.address_line1 ? addressField?.address_line1 : ""
  }, ${addressField?.address_line2 ? addressField.address_line2 + "," : ""} ${
    addressField?.locality ? addressField?.locality : ""
  }, ${
    addressField?.administrative_area ? addressField?.administrative_area : ""
  }, ${addressField?.postal_code ? addressField?.postal_code : ""}`;

  useEffect(() => {
    formik.setFieldValue(fieldKey + ".country_code", countryCode);
  }, []);

  return (
    <React.Fragment>
      {!hideLabel && <div>{label}</div>}
      {edit.get === false && (
        <React.Fragment>
          <div
            onClick={() => {
              if (!disabled) {
                edit.set(true);
              }
            }}
          >
            {addr ? addr : ""}
          </div>
        </React.Fragment>
      )}
      {edit.get === true && (
        <div className={classes.textfieldWrapper}>
          <WorxTextField
            disabled={disabled}
            fieldKey={fieldKey + ".address_line1"}
            label={"Line 1"}
            formik={formik}
          />
          <WorxTextField
            disabled={disabled}
            fieldKey={fieldKey + ".address_line2"}
            label={"Line 2"}
            formik={formik}
          />
          <WorxTextField
            disabled={disabled}
            fieldKey={fieldKey + ".locality"}
            label={"City"}
            formik={formik}
          />
          <WorxAutocomplete
            disabled={disabled}
            fieldKey={fieldKey}
            label={"State"}
            options={states}
            value={(() => {
              let index = USStates.findIndex(
                (state) => state.id === addressField?.administrative_area
              );
              return USStates[index] ? USStates[index] : "";
            })()}
            onChange={(event, value) => {
              if (value) {
                formik.setFieldValue(
                  fieldKey + ".administrative_area",
                  value.id
                );
              } else {
                formik.setFieldValue(fieldKey + ".administrative_area", "");
              }
            }}
            error={
              touched?.["administrative_area"] && error?.["administrative_area"]
            }
          />
          <WorxTextField
            disabled={disabled}
            fieldKey={fieldKey + ".postal_code"}
            label={"Postal Code"}
            formik={formik}
          />
          {touched && error ? (
            <div style={{ color: "red" }}>{"Error in address."}</div>
          ) : null}
        </div>
      )}
    </React.Fragment>
  );
};

WorxAddressFieldDisplay.propTypes = {
  fieldKey: PropTypes.string.isRequired,
  hideLabel: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.string,
  edit: GetSet,
  countryCode: PropTypes.string,
  states: AutoCompleteFilter,
  disabled: PropTypes.bool,
  formik: PropTypes.object.isRequired,
};

export default WorxAddressFieldDisplay;
