import React from "react";
import * as PropTypes from "prop-types";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { GetSet } from "../../../../Constants/Jurisdiction";
import DateFnsUtils from "@date-io/date-fns";
import { format, parseISO } from "date-fns";
import {
  Link,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import WorxCKEditorFormik from "../../../Forms/Inputs/WorxCKEditorFormik";
import WorxFileUpload from "../../../../Container/Forms/Inputs/WorxFileUpload";

const useStyles = makeStyles({
  buttonWrapper: {
    textAlign: "right",
  },
});

const buttonUseStyles = makeStyles({
  root: {
    marginLeft: "1em",
  },
});

const JurisdictionFormCodeDisplay = (props) => {
  // eslint-disable-next-line no-unused-vars
  let { loading, codeType, addCode, setInitValues, formik, isEdit } = props;

  const classes = useStyles();
  const buttonClasses = buttonUseStyles();

  return (
    !loading && (
      <React.Fragment>
        <Typography variant={"h5"} gutterBottom>
          Add code for {codeType.name}
        </Typography>
        {!(addCode.get || formik.values.name !== "") && (
          <RadioGroup
            aria-label="Add code"
            name="zone-districts"
            value={addCode.get || formik.values.name !== ""}
            onChange={(event) => {
              addCode.set(event.target.value);
            }}
          >
            <FormControlLabel value={false} control={<Radio />} label="No" />
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
          </RadioGroup>
        )}
        {(addCode.get || (formik.values.name !== "" && isEdit.get)) && (
          <form>
            <div>
              <TextField
                margin="normal"
                id={"name"}
                value={formik.values.name}
                label={"Name"}
                onChange={formik.handleChange}
              />
            </div>
            {formik.touched.name && formik.errors.name ? (
              <div style={{ color: "red" }}>{formik.errors.name}</div>
            ) : null}
            <WorxCKEditorFormik fieldKey="code" formik={formik} />
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id={"code_date"}
                  format="yyyy-MM-dd"
                  value={parseISO(formik.values.code_date)}
                  label={"Code Date"}
                  onChange={(value) => {
                    formik.setFieldValue(
                      "code_date",
                      format(value, "yyyy-MM-dd")
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            {formik.touched.weight && formik.errors.weight ? (
              <div style={{ color: "red" }}>{formik.errors.weight}</div>
            ) : null}
            <div>
              <WorxFileUpload
                fieldKey={"code_file"}
                entityType={"code_section"}
                formik={formik}
              />
            </div>
            <div className={classes.buttonWrapper}>
              <Button
                variant={"contained"}
                color="secondary"
                margin="normal"
                onClick={() => {
                  formik.resetForm();
                  addCode.set(false);
                  isEdit.set(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant={"contained"}
                color="primary"
                margin="normal"
                onClick={() => {
                  formik.handleSubmit();
                  addCode.set(false);
                  if (Object.keys(formik.errors).length === 0) {
                    isEdit.set(false);
                  }
                }}
                classes={{ root: buttonClasses.root }}
              >
                Submit
              </Button>
            </div>
            {formik.touched.weight && formik.errors.weight ? (
              <div style={{ color: "red" }}>{formik.errors.weight}</div>
            ) : null}
          </form>
        )}
        {!addCode.get && formik.values.name !== "" && !isEdit.get && (
          <div>
            <Button
              color="primary"
              margin="normal"
              onClick={() => {
                isEdit.set(true);
              }}
            >
              Edit
            </Button>
            <List>
              <ListItem>
                <ListItemText primary="Name" secondary={formik.values.name} />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Code"
                  secondary={
                    <div
                      dangerouslySetInnerHTML={{ __html: formik.values.code }}
                    />
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Code Date"
                  secondary={formik.values.code_date}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Code File"
                  secondary={
                    <Link
                      href={
                        formik?.values?.code_file?.[0]?.filepath
                          ? formik.values.code_file[0].filepath
                          : ""
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      {formik?.values?.code_file?.[0]?.filename
                        ? formik.values.code_file[0].filename
                        : ""}
                    </Link>
                  }
                />
              </ListItem>
            </List>
          </div>
        )}
      </React.Fragment>
    )
  );
};

JurisdictionFormCodeDisplay.propTypes = {
  loading: PropTypes.bool.isRequired,
  codeType: PropTypes.object.isRequired,
  isEdit: GetSet,
  addCode: GetSet,
  setInitValues: PropTypes.any,
  formik: PropTypes.object.isRequired,
};

export default JurisdictionFormCodeDisplay;
