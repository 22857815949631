import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GetSet } from "../../../../Constants/Jurisdiction";
import {
  getParkingOptions,
  getUsesForJurisdiction,
  getZonesForJurisdiction,
} from "../../../../Queries/Queries";
import { useFormik } from "formik";
import * as Yup from "yup";
import ParkingFormDisplay from "../../../../Display/DataEntry/DataEntryTwo_UseWorkflow/Forms/ParkingFormDisplay";

import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import Button from "@material-ui/core/Button";
import {
  createParkingLink,
  deleteEntity,
  editParkingLink,
} from "../../../../Queries/Mutations";

const ParkingFormContainer = (props) => {
  const { data } = props;
  const [initValues, setInitValues] = useState({});
  const [rowCount, setRowCount] = useState(0);
  const id = data.get.id;
  const pageSize = 25;
  const [currentPage, setCurrentPage] = useState(0);
  const [filters, setFilters] = useState({ limit: pageSize, offset: 0 });
  const [filterUses, { data: usesQuery }] = useLazyQuery(
    getUsesForJurisdiction(data.get.id, 50),
    {
      fetchPolicy: "cache-and-network",
    }
  );
  // popover
  const [anchorEl, setAnchorEl] = useState(false);

  // options
  const [parkingOpen, setParkingOpen] = useState(false);
  const [zonesOpen, setZonesOpen] = useState(false);
  const [filterParkingOptions, { data: parkingOptionsQuery }] = useLazyQuery(
    getParkingOptions()
  );
  const [filterZoneOptions, { data: zoneOptionsQuery }] = useLazyQuery(
    getZonesForJurisdiction(undefined, data.get.id)
  );

  // mutations
  const [doCreateParkingLink] = useMutation(createParkingLink(), {
    refetchQueries: [
      { query: getUsesForJurisdiction(id, 50), variables: filters },
    ],
  });
  const [doEditParkingLink] = useMutation(editParkingLink(), {
    refetchQueries: [
      { query: getUsesForJurisdiction(id, 50), variables: filters },
    ],
  });

  const [doDeleteEntity, { data: deleteResponse }] = useMutation(
    deleteEntity()
  );

  // Pull first set of uses only on first pass.
  useEffect(() => {
    filterUses({ variables: filters });
  }, []);

  useEffect(() => {
    if (usesQuery?.uses?.count) {
      if (usesQuery?.uses?.count != rowCount) {
        setRowCount(usesQuery?.uses?.count);
      }
    }
  }, [usesQuery]);

  useEffect(() => {
    if (deleteResponse?.deleteEntity?.errors?.length <= 0) {
      filterUses({ variables: filters });
    }
  }, [deleteResponse]);

  const deleteParking = (values) => {
    doDeleteEntity({
      variables: { data: { entity_type: "jur_parking_link", id: values.id } },
    });
  };

  // formic
  let defaultLegend = {
    name: "",
    parking: [{ id: 0, name: null }],
    use: [{ id: 0, name: null }],
    spaces: "",
    min_max: "",
    description: "",
    secondary_variable: "",
    zone: [{ id: 0, name: null }],
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...defaultLegend, ...initValues, button: null },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      parking: Yup.array().of(
        Yup.object().shape({
          id: Yup.number().required("Required"),
          name: Yup.string().required("Required"),
        })
      ),
      use: Yup.array().of(
        Yup.object().shape({
          id: Yup.number().required("Required"),
        })
      ),
      min_max: Yup.string().required("Required"),
      spaces: Yup.string().required("Required"),
    }),
    onSubmit: (values, formikTools) => {
      //eslint-disable-next-line no-unused-vars
      let { button, ...link } = values;
      switch (button) {
        case "normal":
          //doUploadFile({variables: { file: code_file }});
          mutateParkingLink(link);
          setAnchorEl(null);
          break;
        case "repeat":
          mutateParkingLink(link);
          break;
        case "cancel":
          setAnchorEl(null);
          break;
      }
      setInitValues({});
      formikTools.resetForm();
    },
  });

  // eslint-disable-next-line no-unused-vars
  const mutateParkingLink = async (parking) => {
    // Do this to get the payload in the right shape
    // eslint-disable-next-line no-unused-vars
    let { new: isNew, edit, type, rowIndex, ...payload } = parking;
    if (payload.id) {
      await doEditParkingLink({ variables: { data: payload } }).then();
    } else {
      // take the temp id out of the payload
      // eslint-disable-next-line no-unused-vars
      let { id, ...createPayload } = payload;
      await doCreateParkingLink({ variables: { data: createPayload } });
    }
  };

  const columns = [
    {
      field: "name",
      headerName: "Use",
      width: 230,
      renderCell: Object.assign(
        (params) => {
          return (
            <Tooltip title={params.value}>
              <div>{params.value}</div>
            </Tooltip>
          );
        },
        { displayName: "renderCell" }
      ),
    },
    {
      field: "parking",
      headerName: "Parking",
      width: 230,
      renderCell: Object.assign(
        (params) => {
          return (
            <div>
              {params.row.parking.map((parking, index) => {
                return (
                  <Button
                    onClick={(event) => {
                      setAnchorEl(event.currentTarget);
                      setInitValues(parking);
                    }}
                    key={parking.id}
                  >
                    {parking.name}
                    {index < params.row.parking.length - 1 && ", "}
                  </Button>
                );
              })}
            </div>
          );
        },
        { displayName: "renderCell" }
      ),
      flex: 1,
    },
    {
      field: "operations",
      headerName: "Add Parking",
      width: 150,
      renderCell: Object.assign(
        (params) => {
          return (
            <Button
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
                setInitValues({ use: [{ id: params.row.id }] });
              }}
              variant="contained"
              color="primary"
            >
              Add
            </Button>
          );
        },
        { displayName: "renderCell" }
      ),
    },
  ];

  return (
    <ParkingFormDisplay
      anchorEl={{ get: anchorEl, set: setAnchorEl }}
      uses={{ list: usesQuery?.uses?.items, filter: filterUses }}
      columns={columns}
      rowCount={rowCount}
      parkingOptions={{
        open: { get: parkingOpen, set: setParkingOpen },
        options: {
          list: parkingOptionsQuery?.parkings?.items
            ? parkingOptionsQuery?.parkings.items
            : [],
          filter: filterParkingOptions,
        },
      }}
      zoneOptions={{
        open: { get: zonesOpen, set: setZonesOpen },
        options: {
          list: zoneOptionsQuery?.zones?.items
            ? zoneOptionsQuery?.zones.items
            : [],
          filter: filterZoneOptions,
        },
      }}
      initValues={{ get: initValues, set: setInitValues }}
      deleteParking={deleteParking}
      currentPage={{ get: currentPage, set: setCurrentPage }}
      filters={{ get: filters, set: setFilters }}
      pageSize={pageSize}
      formik={formik}
    />
  );
};

ParkingFormContainer.propTypes = {
  data: GetSet,
};

export default ParkingFormContainer;
