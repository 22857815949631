import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { AutoCompleteFilter, GetSet } from "../../Constants/Jurisdiction";
import Drawer from "@material-ui/core/Drawer";
import { Card, CardContent, makeStyles } from "@material-ui/core";

const topCardUseStyles = makeStyles({
  root: {
    "& .MuiDataGrid-cell": {
      whiteSpace: "normal !important",
    },
    marginBottom: "2em",
  },
});

const drawerStyles = makeStyles({
  paper: {
    width: "500px !important",
  },
});

const DocumentDrawerDisplay = (props) => {
  const { open, media } = props;
  const topCardClasses = topCardUseStyles();
  const drawerClasses = drawerStyles();

  return (
    <React.Fragment>
      {" "}
      {media && (
        <Drawer
          id={"drawer-test"}
          anchor={"right"}
          open={open.get}
          onClose={() => {
            open.set(false);
          }}
          onKeyDown={(e) => e.stopPropagation()}
          classes={{ paper: drawerClasses.paper }}
        >
          {media && (
            <div data-testid={"docDrawer"}>
              <Card classes={{ root: topCardClasses.root }}>
                <CardContent>
                  <DataGrid
                    classes={{ root: topCardClasses.root }}
                    page={media.variables.currentPage.get}
                    pagination
                    paginationMode="server"
                    rowCount={media.variables.count.get}
                    pageSize={media.variables.pageSize.get}
                    onPageChange={(page) => {
                      let nextFilter = {
                        ...media.variables.filters.get,
                        offset: media.variables.pageSize.get * page,
                      };
                      media.options.filter({ variables: nextFilter });
                      media.variables.filters.set(nextFilter);
                      media.variables.currentPage.set(page);
                    }}
                    loading={!media.options.list}
                    columns={media.variables.columns}
                    rows={media.options.list ? media.options.list : []}
                    checkboxSelection={false}
                    disableMultipleSelection={true}
                    autoHeight
                    getRowHeight={() => "auto"}
                  />
                </CardContent>
              </Card>
            </div>
          )}
        </Drawer>
      )}
    </React.Fragment>
  );
};

DocumentDrawerDisplay.propTypes = {
  open: GetSet,
  media: AutoCompleteFilter,
};

export default DocumentDrawerDisplay;
