import React from "react";
import * as PropTypes from "prop-types";
import WorxTextField from "../../Forms/Inputs/WorxTextField";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@mui/styles";

const buttonUseStyles = makeStyles({
  root: {
    marginLeft: "1em",
  },
});

const useStyles = makeStyles({
  worxSpaceAround: {
    margin: "1em",
  },
});

const AdjustmentFormDisplay = (props) => {
  const { formik } = props;

  const buttonClasses = buttonUseStyles();
  const classes = useStyles();

  return (
    <div className={classes.worxSpaceAround}>
      <WorxTextField fieldKey={"amount"} formik={formik} />
      <WorxTextField fieldKey={"label"} formik={formik} />
      <Button
        variant={"contained"}
        color="primary"
        margin="normal"
        onClick={() => {
          formik.handleSubmit();
        }}
        classes={{ root: buttonClasses.root }}
      >
        Submit
      </Button>
    </div>
  );
};

AdjustmentFormDisplay.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default AdjustmentFormDisplay;
