import React from "react";
import * as PropTypes from "prop-types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {
  camelToUnderscore,
  underscoreToCamel,
} from "../../../../tools/stringTools";
import {
  GetSet,
  AutoCompleteFilter,
  subJurisdictionMap,
} from "../../../../Constants/Jurisdiction";
import Button from "@material-ui/core/Button";
import {
  Link,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import WorxLink from "../../../Forms/Inputs/WorxLink";
// eslint-disable-next-line no-unused-vars
import WorxMediaUpload from "../../../../Container/Forms/Inputs/WorxMediaUpload";

const useStyles = makeStyles({
  buttonWrapper: {
    textAlign: "right",
  },
});

const JurisdictionFormOneDisplay = (props) => {
  // eslint-disable-next-line no-unused-vars
  let { data, type, sub, formik, isEdit } = props;

  let typeKey = `${camelToUnderscore(
    data.get?.__typename ? data.get.__typename : ""
  )}_type`;
  let locality_type = data?.get?.[typeKey]?.[0]?.name
    ? data.get[typeKey][0].name
    : "";

  const classes = useStyles();

  return (
    <React.Fragment>
      {isEdit.get && (
        <form>
          <Autocomplete
            autoComplete
            id={data.get.__typename.toLowerCase() + "_type"}
            style={{ width: 300 }}
            open={type.open.get}
            onOpen={() => {
              type.options.filter();
              type.open.set(true);
            }}
            onClose={() => {
              type.open.set(false);
            }}
            getOptionSelected={(option, value) => option?.name === value?.name}
            getOptionLabel={(option) => option.name}
            options={type.options.list}
            loading={false}
            onInputChange={(e, value) => {
              type.options.filter({ variables: { name: value } });
            }}
            onChange={(e, value) => {
              formik.setFieldValue(
                data.get.__typename.toLowerCase() + "_type",
                [{ id: value.id, name: value.name }]
              );
            }}
            defaultValue={data.get[typeKey] ? data.get[typeKey][0] : ""}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label={data.get.__typename + " Type"}
                  margin="normal"
                />
              );
            }}
          />
          {formik.touched[data.get.__typename.toLowerCase() + "_type"] &&
          formik.errors[data.get.__typename.toLowerCase() + "_type"] ? (
            <div style={{ color: "red" }}>
              {formik.errors[data.get.__typename.toLowerCase() + "_type"]}
            </div>
          ) : null}
          <Autocomplete
            autoComplete
            id={subJurisdictionMap[data.get.__typename]}
            style={{ width: 300 }}
            open={sub.open.get}
            onOpen={() => {
              sub.options.filter();
              sub.open.set(true);
            }}
            onClose={() => {
              sub.open.set(false);
            }}
            getOptionSelected={(option, value) => option?.name === value?.name}
            getOptionLabel={(option) => option.name}
            options={sub.options.list}
            loading={false}
            onInputChange={(e, value) => {
              sub.options.filter({ variables: { name: value } });
            }}
            onChange={(e, value) => {
              formik.setFieldValue(subJurisdictionMap[data.get.__typename], [
                { id: value.id, name: value.name },
              ]);
            }}
            defaultValue={data.get[subJurisdictionMap[data.get.__typename]][0]}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label={underscoreToCamel(
                    subJurisdictionMap[data.get.__typename]
                  )}
                  margin="normal"
                />
              );
            }}
          />
          {formik.touched[subJurisdictionMap[type]] &&
          formik.errors[subJurisdictionMap[type]] ? (
            <div style={{ color: "red" }}>
              {formik.errors[subJurisdictionMap[type]]}
            </div>
          ) : null}
          <WorxLink fieldKey="code_definition" formik={formik} />
          <WorxLink fieldKey="gis_map" formik={formik} />
          <WorxLink fieldKey="parcel_map" formik={formik} />
          <div className={classes.buttonWrapper}>
            <Button
              variant={"contained"}
              color="primary"
              margin="normal"
              onClick={() => {
                formik.setFieldValue("button", "normal");
                formik.handleSubmit();
                if (Object.keys(formik.errors).length === 0) {
                  isEdit.set(false);
                }
              }}
            >
              Submit
            </Button>
          </div>
        </form>
      )}
      {!isEdit.get && (
        <div>
          <Button
            variant={"contained"}
            color="primary"
            margin="normal"
            onClick={() => {
              isEdit.set(true);
            }}
          >
            Edit
          </Button>
          <List>
            <ListItem>
              <ListItemText
                primary={data.get.__typename + " Type"}
                secondary={locality_type}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={underscoreToCamel(
                  subJurisdictionMap[data.get.__typename]
                )}
                secondary={
                  data.get[subJurisdictionMap[data.get.__typename]][0].name
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Code Definition"
                secondary={
                  <Link
                    href={data.get.code_definition.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {data.get.code_definition.link_text
                      ? data.get.code_definition.link_text
                      : data.get.code_definition.url}
                  </Link>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="GIS Map"
                secondary={
                  <Link
                    href={data.get.gis_map.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {data.get.gis_map.link_text
                      ? data.get.gis_map.link_text
                      : data.get.gis_map.url}
                  </Link>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Parcel Map"
                secondary={
                  <Link
                    href={data.get.parcel_map.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {data.get.parcel_map.link_text
                      ? data.get.parcel_map.link_text
                      : data.get.parcel_map.url}
                  </Link>
                }
              />
            </ListItem>
          </List>
        </div>
      )}
    </React.Fragment>
  );
};

JurisdictionFormOneDisplay.propTypes = {
  data: GetSet,
  isEdit: GetSet,
  type: AutoCompleteFilter,
  sub: AutoCompleteFilter,
  formik: PropTypes.object.isRequired,
};

export default JurisdictionFormOneDisplay;
