import React, { useContext, useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  getJurisdictionOptions,
  getProperties,
  getUsers,
} from "../../../Queries/Queries";
import { createResidentialOrder } from "../../../Queries/Mutations";
import AddNewResidentialOrderDisplay from "../../../Display/ResidentialOrders/Forms/AddNewResidentialOrderDisplay";
import UserContext from "../../../Context/UserContext";
import { useHistory } from "react-router";

const AddNewResidentialOrderContainer = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { data } = props;
  const [initValues, setInitValues] = useState({});

  const userContext = useContext(UserContext);
  const [jurisdictionOpen, setJurisdictionOpen] = useState(false);
  const [filterJurisdictions, { data: jurisdictionsQuery }] = useLazyQuery(
    getJurisdictionOptions(undefined, "", 50)
  );
  const [jurisdictions, setJurisdictions] = useState([]);

  const [propertyOpen, setPropertyOpen] = useState(false);
  const [filterProperties, { data: propertiesQuery }] = useLazyQuery(
    getProperties()
  );
  const [properties, setProperties] = useState([]);

  const [usersOpen, setUsersOpen] = useState(false);
  const [filterUsers, { data: usersQuery }] = useLazyQuery(
    getUsers("customer")
  );
  const [users, setUsers] = useState([]);

  const [contactOpen, setContactOpen] = useState(false);
  const [billingOpen, setBillingOpen] = useState(false);

  const [fixedPropertyFilters, setFixedPropertyFilters] = useState({});

  const siteInfoOptions = [
    { id: "order", name: "Order" },
    { id: "proposal", name: "Proposal" },
  ];

  const billingSelections = [
    {
      id: "contact",
      name: "Billing Information is Same as Contact Information",
    },
  ];

  //mutations
  // eslint-disable-next-line no-unused-vars
  const [doCreateOrder, { data: createResponse }] = useMutation(
    createResidentialOrder()
  );

  let defaultValues = {
    client: [{ id: userContext[0].id, name: userContext[0].name }],
    jurisdiction: [{ id: null }],
    property: [{ id: null }],
    contact_information: [
      {
        address: {
          address_line1: "",
          administrative_area: "",
          locality: "",
          postal_code: "",
          country_code: "US",
        },
      },
    ],
    billing_information: [
      {
        address: {
          address_line1: "",
          administrative_area: "",
          locality: "",
          postal_code: "",
          country_code: "US",
        },
      },
    ],
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...defaultValues, ...initValues },
    validationSchema: Yup.object({
      jurisdiction: Yup.array().of(
        Yup.object().shape({
          id: Yup.string().required("A jurisdiction must be selected."),
        })
      ),
      property: Yup.array().of(
        Yup.object().shape({
          id: Yup.string().required("A property must be selected."),
        })
      ),
      contact_information: Yup.array().of(
        Yup.object().shape({
          address: Yup.object().shape({
            address_line1: Yup.string().required(
              "Address Line One must be filled."
            ),
            administrative_area: Yup.string().required(
              "Administrative Area must be filled."
            ),
            locality: Yup.string().required("Locality must be filled."),
            postal_code: Yup.string().required("Postal Code must be filled."),
          }),
        })
      ),
      billing_information: Yup.array().of(
        Yup.object().shape({
          address: Yup.object().shape({
            address_line1: Yup.string().required(
              "Address Line One must be filled."
            ),
            administrative_area: Yup.string().required(
              "Administrative Area must be filled."
            ),
            locality: Yup.string().required("Locality must be filled."),
            postal_code: Yup.string().required("Postal Code must be filled."),
          }),
        })
      ),
    }),
    // eslint-disable-next-line no-unused-vars
    onSubmit: (values, formikTools) => {
      /* eslint-disable no-unused-vars */
      const {
        billing_selections,
        prepared_for_selections,
        product_selections,
        site_information,
        site_information_options,
        ...payload
      } = values;
      /* eslint-enable no-unused-vars */
      doCreateOrder({ variables: { data: payload } });
      setInitValues({});
    },
  });

  const history = useHistory();
  useEffect(() => {
    if (createResponse) {
      history.push({
        pathname:
          "/residential/checkout/" +
          createResponse.createResidentialOrder.order.id,
      });
    }
  }, [createResponse]);

  useEffect(() => {
    filterJurisdictions();
    filterUsers();
  }, []);

  useEffect(() => {
    if (jurisdictionsQuery?.jurisdictions?.items) {
      setJurisdictions(jurisdictionsQuery?.jurisdictions?.items);
    }
  }, [jurisdictionsQuery]);

  useEffect(() => {
    if (usersQuery?.users?.items) {
      setUsers(usersQuery?.users?.items);
    }
  }, [usersQuery]);

  useEffect(() => {
    if (formik?.values?.report_order_items?.[0]?.jurisdiction) {
      setFixedPropertyFilters({
        jurisdiction:
          formik?.values?.report_order_items?.[0]?.jurisdiction?.[0]?.id,
      });
      filterProperties({
        variables: { offset: 0, limit: 50, ...fixedPropertyFilters },
      });
    }
  }, [formik?.values?.report_order_items?.[0]?.jurisdiction]);

  useEffect(() => {
    if (propertiesQuery?.properties?.items) {
      let newProperties = propertiesQuery?.properties?.items.map((item) => {
        return { ...item, displayName: item.address?.address_line1 };
      });
      setProperties(newProperties);
    }
  }, [propertiesQuery]);

  useEffect(() => {
    if (
      formik.values.billing_selections === "contact" &&
      formik.values.contact_information
    ) {
      // eslint-disable-next-line no-unused-vars
      let { id, ...address } = formik.values.contact_information[0];
      formik.setFieldValue("billing_information", [address]);
    }
  }, [formik.values.billing_selections]);

  return (
    <AddNewResidentialOrderDisplay
      clients={{
        open: { get: usersOpen, set: setUsersOpen },
        options: {
          list: users,
          filter: filterUsers,
        },
      }}
      client={userContext[0]}
      jurisdictions={{
        open: { get: jurisdictionOpen, set: setJurisdictionOpen },
        options: {
          list: jurisdictions,
          filter: filterJurisdictions,
        },
      }}
      properties={{
        open: { get: propertyOpen, set: setPropertyOpen },
        options: {
          list: properties,
          filter: filterProperties,
        },
        variables: {
          filters: { get: fixedPropertyFilters, set: setFixedPropertyFilters },
        },
      }}
      contact={{ get: contactOpen, set: setContactOpen }}
      billing={{ get: billingOpen, set: setBillingOpen }}
      siteInfoOptions={siteInfoOptions}
      billingSelections={billingSelections}
      initValues={{ get: initValues, set: setInitValues }}
      formik={formik}
    />
  );
};

AddNewResidentialOrderContainer.propTypes = {
  data: PropTypes.any.isRequired,
};

export default AddNewResidentialOrderContainer;
