import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import * as PropTypes from "prop-types";
import { getRequirementsMatrix } from "../../../../Queries/Queries";
import { GetSet } from "../../../../Constants/Jurisdiction";
import RequirementsGridMatrixDisplay from "../../../../Display/DataEntry/DataEntryThree_RequirmentsWorkflow/Forms/RequirementsGridMatrixDisplay";
import RequirementsCellDisplay from "../../../../Display/DataEntry/DataEntryThree_RequirmentsWorkflow/Forms/RequirementsCellDisplay";
import Tooltip from "@material-ui/core/Tooltip";

const RequirementsGridMatrixContainer = (props) => {
  const { data, step } = props;

  const linkTypes = ["primary", "secondary", "temporary"];
  const [initValues, setInitValues] = useState({});
  const [addLink, setAddLink] = useState(false);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [linkTypesOpen, setLinkTypesOpen] = useState(false);
  const [filters, setFilters] = useState({
    operator: "CONTAINS",
    limit: 25,
    offset: 0,
    requirement: "",
    zone: "",
  });

  const [rowTotal, setRowTotal] = useState(25);
  const [fetchMatrix, { loading: gridLoading, data: grid }] = useLazyQuery(
    getRequirementsMatrix(data.get.id, 25),
    { variables: filters }
  );

  const requery = {
    refetchQueries: [
      { query: getRequirementsMatrix(data.get.id, 25), variables: filters },
    ],
  };

  useEffect(() => {
    fetchMatrix();
  }, []);

  useEffect(() => {
    if (grid?.requirementMatrix?.count) {
      setRowTotal(grid.requirementMatrix.count);
    }

    if (
      grid?.requirementMatrix?.header &&
      columns.length !== grid?.requirementMatrix?.header.length
    ) {
      setColumns(
        grid.requirementMatrix.header.map((headerValue) => {
          if (headerValue === "Use") {
            return {
              field: headerValue,
              renderCell: Object.assign(
                (params) => {
                  return (
                    <Tooltip title={params.row["requirement"][0].name}>
                      <div>{params.row["requirement"][0].name}</div>
                    </Tooltip>
                  );
                },
                { displayName: "renderCell" }
              ),
              //valueGetter: (value) => value.row['requirement'][0].name,
              headerName: headerValue,
              width: 330,
            };
          } else {
            return {
              field: headerValue,
              renderCell: Object.assign(
                (params) => {
                  return params.value;
                },
                { displayName: "renderCell" }
              ),
              headerName: headerValue,
              //width: 230
            };
          }
        })
      );
    }

    if (grid?.requirementMatrix?.rows) {
      setRows(
        grid.requirementMatrix.rows.map((row) => {
          let newRow = row.cells.reduce((outValue, newValue) => {
            //outValue[newValue.zone[0].designation] = newValue;
            const params = { ...newValue, requirement: row.requirement };
            outValue[newValue.zone[0].designation] = (
              <RequirementsCellDisplay params={params} showForm={showForm} />
            );
            return outValue;
          }, {});
          newRow.id = row.requirement[0].id;
          return { ...newRow, requirement: row.requirement };
        })
      );
    }
  }, [grid]);

  const showForm = (params) => {
    setAddLink(true);
    if (params.link[0]) {
      setInitValues(params.link[0]);
    } else {
      let link = {
        requirement: [{ id: params.requirement[0].id }],
        zone: [{ id: params.zone[0].id }],
      };
      setInitValues(link);
    }
  };

  const closeForm = () => {
    setAddLink(false);
  };

  return (
    <React.Fragment>
      <RequirementsGridMatrixDisplay
        grid={{ list: grid, filter: fetchMatrix, loading: gridLoading }}
        initValues={{ get: initValues, set: setInitValues }}
        linkTypeOptions={{
          open: { get: linkTypesOpen, set: setLinkTypesOpen },
          options: {
            list: [
              { id: "primary", name: "Primary" },
              { id: "secondary", name: "Secondary" },
              { id: "temporary", name: "Temporary" },
            ],
            filter: () => {
              /*dont do anything*/
            },
          },
        }}
        addLink={addLink}
        showForm={showForm}
        closeForm={closeForm}
        columns={columns}
        rows={rows}
        filters={{ get: filters, set: setFilters }}
        linkTypes={linkTypes}
        rowTotal={rowTotal}
        step={step}
        requery={requery}
        jurisdictionID={data.get.id}
      />
    </React.Fragment>
  );
};

RequirementsGridMatrixContainer.propTypes = {
  data: GetSet,
  step: PropTypes.number.isRequired,
};

export default RequirementsGridMatrixContainer;
