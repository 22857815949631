import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import FootnoteFormDisplay from "../../../../Display/DataEntry/DataEntryTwo_UseWorkflow/Forms/FootnoteFormDisplay";
import { GetSet } from "../../../../Constants/Jurisdiction";
import { getFootnotes } from "../../../../Queries/Queries";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "@material-ui/core/Button";
import WorxDeleteButton from "../../../Forms/Inputs/WorxDeleteButton";
import { createFootnote, editFootnote } from "../../../../Queries/Mutations";

const FootnoteFormContainer = (props) => {
  const { data } = props;
  const [addFootnotes, setAddFootnotes] = useState(false);
  const [initValues, setInitValues] = useState({});
  const [rowCount, setRowCount] = useState(0);
  const [filterFootnotes, { data: footnotesQuery }] = useLazyQuery(
    getFootnotes(data.get.id, "use_footnote"),
    { fetchPolicy: "cache-and-network" }
  );
  const [currentPage, setCurrentPage] = useState(0);
  const jurisdiction_id = data.get.id;
  const pageSize = 25;
  const [filters, setFilters] = useState({ limit: pageSize, offset: 0 });
  const [doCreateFootnote] = useMutation(createFootnote(), {
    update(
      cache,
      {
        data: {
          createFootnote: { footnote },
        },
      }
    ) {
      const { footnotes } = cache.readQuery({
        query: getFootnotes(jurisdiction_id, "use_footnote"),
      });
      cache.writeQuery({
        query: getFootnotes(jurisdiction_id),
        data: {
          footnotes: {
            FootnoteConnection: {
              items: footnotes.items.concat(footnote),
            },
          },
        },
      });
    },
  });
  const [doEditFootnote] = useMutation(editFootnote());

  useEffect(() => {
    filterFootnotes();
  }, []);

  useEffect(() => {
    if (footnotesQuery) {
      setRowCount(footnotesQuery.footnotes.total);
    }
  }, [footnotesQuery]);

  let defaultFootnote = {
    name: "",
    type: "use_footnote",
    footnote: "",
    button: null,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...defaultFootnote, ...initValues, button: null },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      footnote: Yup.string().required("Required"),
    }),
    onSubmit: (values, formikTools) => {
      // eslint-disable-next-line no-unused-vars
      let { button, ...footnote } = values;
      switch (button) {
        case "normal":
          mutateFootnote(footnote);
          setAddFootnotes(null);
          break;
        case "repeat":
          mutateFootnote(footnote);
          break;
        case "cancel":
          setAddFootnotes(null);
          break;
      }
      setInitValues({});
      formikTools.resetForm();
    },
  });

  const mutateFootnote = async (footnote) => {
    // Do this to get the payload in the right shape
    // eslint-disable-next-line no-unused-vars
    let { new: isNew, edit, type, rowIndex, ...payload } = footnote;
    payload.jurisdiction = [
      {
        id: data.get.id,
      },
    ];
    payload.type = "use_footnote";
    if (payload.id) {
      await doEditFootnote({ variables: { data: payload } }).then();
    } else {
      // take the temp id out of the payload
      // eslint-disable-next-line no-unused-vars
      let { id, ...createPayload } = payload;
      await doCreateFootnote({ variables: { data: createPayload } });
      setRowCount(rowCount + 1);
    }
  };

  const columns = [
    { field: "name", headerName: "Name", width: 230 },
    {
      field: "footnote",
      headerName: "Footnote",
      width: 230,
      renderCell: Object.assign(
        (params) => {
          const strippedString = params.value.replace(/(<([^>]+)>)/gi, "");
          return <div>{strippedString}</div>;
        },
        { displayName: "renderCell" }
      ),
    },
    {
      field: "Operation",
      headerName: "ID",
      width: 200,
      renderCell: Object.assign(
        (params) => {
          return (
            <React.Fragment>
              <WorxDeleteButton
                callback={(response) => {
                  if (response?.deleteEntity?.errors?.length <= 0) {
                    filterFootnotes({ variables: filters });
                  }
                }}
                type={"footnote"}
                id={params.row.id}
              />
              <Button
                onClick={() => {
                  setAddFootnotes(true);
                  setInitValues(params.row);
                }}
              >
                edit
              </Button>
            </React.Fragment>
          );
        },
        { displayName: "renderCell" }
      ),
    },
  ];

  return (
    <FootnoteFormDisplay
      addFootnotes={{ get: addFootnotes, set: setAddFootnotes }}
      footnotes={{
        list: footnotesQuery?.footnotes?.items,
        filter: filterFootnotes,
      }}
      columns={columns}
      initValues={{ get: initValues, set: setInitValues }}
      pageSize={pageSize}
      rowCount={rowCount}
      filters={{ get: filters, set: setFilters }}
      currentPage={{ get: currentPage, set: setCurrentPage }}
      formik={formik}
    />
  );
};

FootnoteFormContainer.propTypes = {
  data: GetSet,
};

export default FootnoteFormContainer;
