import axios from "axios";
import { LoginURI, ClientID, ClientSecret } from "../Constants/Auth";

export async function getAccessToken(username, password) {
  let bodyFormData = new FormData();
  bodyFormData.set("grant_type", "password");
  bodyFormData.set("client_id", ClientID);
  bodyFormData.set("client_secret", ClientSecret);
  bodyFormData.set("username", username);
  bodyFormData.set("password", password);

  try {
    return await axios({
      method: "post",
      url: LoginURI,
      data: bodyFormData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    });
  } catch (e) {
    return e.response;
  }
}

export async function getRefreshToken(refreshToken) {
  let bodyFormData = new FormData();
  bodyFormData.set("grant_type", "refresh_token");
  bodyFormData.set("refresh_token", refreshToken);
  bodyFormData.set("client_id", ClientID);
  bodyFormData.set("client_secret", ClientSecret);

  try {
    return await axios({
      method: "post",
      url: LoginURI,
      data: bodyFormData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    });
  } catch (e) {
    return e.response;
  }
}
