import React from "react";
import * as PropTypes from "prop-types";
import WorxFormContainer from "../../Container/Forms/Generic/WorxFormContainer";

const AnalystMunicipalDocumentsDisplay = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { id, options, formik } = props;

  return (
    <React.Fragment>
      <WorxFormContainer
        options={options}
        nameOverride={{
          "municipal_documents.zoning_violations.notes": "Notes",
          "municipal_documents.building_violations.notes": "Notes",
          "municipal_documents.fire_code_violations.notes": "Notes",
          "municipal_documents.condemnation.notes": "Notes",
          "municipal_documents.business_license.notes": "Notes",
          "municipal_documents.historical_landmark.notes": "Notes",

          "municipal_documents.zoning_violations.conformance": "",
          "municipal_documents.building_violations.conformance": "",
          "municipal_documents.fire_code_violations.conformance": "",
          "municipal_documents.condemnation.conformance": "",
          "municipal_documents.business_license.conformance": "",
          "municipal_documents.historical_landmark.conformance": "",

          "municipal_documents.zoning_violations.document_link": "",
          "municipal_documents.building_violations.document_link": "",
          "municipal_documents.fire_code_violations.document_link": "",
          "municipal_documents.condemnation.document_link": "",
          "municipal_documents.business_license.document_link": "",
          "municipal_documents.historical_landmark.document_link": "",

          "municipal_documents.zoning_violations.other": "Other?",
          "municipal_documents.building_violations.other": "Other?",
          "municipal_documents.fire_code_violations.other": "Other?",
          "municipal_documents.condemnation.other": "Other?",
          "municipal_documents.business_license.other": "Other?",
          "municipal_documents.historical_landmark.other": "Other?",
        }}
        disabledMap={{
          "municipal_documents.zoning_violations.document_link": true,
          "municipal_documents.building_violations.document_link": true,
          "municipal_documents.fire_code_violations.document_link": true,
          "municipal_documents.condemnation.document_link": true,
          "municipal_documents.business_license.document_link": true,
          "municipal_documents.historical_landmark.document_link": true,
        }}
        dependencyMap={{
          municipal_documents: {
            zoning_violations: {
              other: {
                target: "municipal_documents.zoning_violations.conformance",
                value: "Other",
              },
            },
            building_violations: {
              other: {
                target: "municipal_documents.building_violations.conformance",
                value: "Other",
              },
            },
            fire_code_violations: {
              other: {
                target: "municipal_documents.fire_code_violations.conformance",
                value: "Other",
              },
            },
            condemnation: {
              other: {
                target: "municipal_documents.condemnation.conformance",
                value: "Other",
              },
            },
            business_license: {
              other: {
                target: "municipal_documents.business_license.conformance",
                value: "Other",
              },
            },
            historical_landmark: {
              other: {
                target: "municipal_documents.historical_landmark.conformance",
                value: "Other",
              },
            },
          },
        }}
        formik={formik}
      />
    </React.Fragment>
  );
};

AnalystMunicipalDocumentsDisplay.propTypes = {
  id: PropTypes.number,
  options: PropTypes.object,
  formik: PropTypes.object,
};

export default AnalystMunicipalDocumentsDisplay;
