import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { useLazyQuery } from "@apollo/client";
import { getJurisdictionFiles } from "../../Queries/Queries";
import DocumentDrawerDisplay from "../../Display/DataEntry/DocumentDrawerDisplay";
import { GetSet } from "../../Constants/Jurisdiction";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";

const DocumentDrawerContainer = (props) => {
  //const type = 'document';
  const { open: drawerOpen, property, jurisdiction } = props;
  const [pageSize, setPageSize] = useState(15);
  const [open, setOpen] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [filters, setFilters] = useState({
    limit: pageSize,
    offset: 0,
    operator: "CONTAINS",
  });

  const [filterJurisdictionFiles, { data: jurisdictionFilesQuery }] =
    useLazyQuery(getJurisdictionFiles(jurisdiction, pageSize), {
      variables: filters,
      fetchPolicy: "cache-and-network",
    });

  useEffect(() => {
    let newFilters = { ...filters };
    if (property) {
      newFilters.property = property;
    }
    if (jurisdiction) {
      newFilters.jurisdiction = jurisdiction;
    }
    setFilters(newFilters);
    filterJurisdictionFiles({ variables: newFilters });
  }, []);

  useEffect(() => {
    if (jurisdictionFilesQuery?.jurisdictionFiles?.total) {
      setRowCount(jurisdictionFilesQuery?.jurisdictionFiles?.total);
    }
  }, [jurisdictionFilesQuery]);

  return (
    <DocumentDrawerDisplay
      open={drawerOpen}
      media={{
        open: { get: open, set: setOpen },
        options: {
          list: jurisdictionFilesQuery?.jurisdictionFiles?.items
            ? jurisdictionFilesQuery?.jurisdictionFiles?.items
            : [],
          filter: filterJurisdictionFiles,
        },
        variables: {
          count: { get: rowCount, set: setRowCount },
          currentPage: { get: currentPage, set: setCurrentPage },
          pageSize: { get: pageSize, set: setPageSize },
          filters: { get: filters, set: setFilters },
          columns: [
            {
              field: "document",
              headerName: "Documents",
              width: 450,
              renderCell: Object.assign(
                (params) => {
                  return (
                    <Tooltip title={params?.row?.filename}>
                      <a href={params?.row?.filepath} target={"blank"}>
                        {params?.row?.filename}
                      </a>
                    </Tooltip>
                  );
                },
                { displayName: "renderCell" }
              ),
            },
          ],
        },
      }}
    />
  );
};

DocumentDrawerContainer.propTypes = {
  property: PropTypes.number,
  jurisdiction: PropTypes.number,
  open: GetSet,
};

export default DocumentDrawerContainer;
