import React from "react";
// eslint-disable-next-line no-unused-vars
import * as PropTypes from "prop-types";

const AnswerDisplay = (props) => {
  const { map, answer } = props;

  return (
    <React.Fragment>
      {answer.type === "certificate_of_occupancy" && (
        <React.Fragment>
          <div>Building Number: {answer.building_number}</div>
          <div>Number: {answer.co_number}</div>
          <div>Date: {answer.co_date}</div>
          <div>Tenant: {answer.tenant[0].name}</div>
          <div>
            Parking: {answer.parking_required === true ? " Yes" : " No"}
          </div>
        </React.Fragment>
      )}
      {answer.type === "fire_code_violation" && (
        <React.Fragment>
          <div>Permit Number: {answer.permit_number}</div>
        </React.Fragment>
      )}
      {answer.type === "condemnation" && (
        <React.Fragment>
          <div>Permit Number: {answer.permit_number}</div>
        </React.Fragment>
      )}
      {!answer.other ? (
        <div>{answer?.[map[answer.type]]?.[0]?.name}</div>
      ) : (
        <div
          dangerouslySetInnerHTML={{
            __html: answer[map[answer.type] + "_other"],
          }}
        />
      )}
      Note: <div dangerouslySetInnerHTML={{ __html: answer.notes }} />
      <div>{answer.letter_date}</div>
      <a href={answer.document[0].document[0].filepath} rel="noreferrer">
        {answer.document[0].document[0].filename}
      </a>
    </React.Fragment>
  );
};

AnswerDisplay.propTypes = {
  map: PropTypes.object,
  answer: PropTypes.object,
};

export default AnswerDisplay;
