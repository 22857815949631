import React from "react";
import * as PropTypes from "prop-types";

const ErrorDisplay = ({ errors, path }) => {
  if (!errors) {
    return <div>There is no current error.</div>;
  }

  return (
    <React.Fragment>
      {<div>{`${path} caused:`}</div>}
      {errors &&
        errors.length &&
        errors.map((error, index) => {
          return <div key={index}>{error?.message}</div>;
        })}
      {!errors.length && <div>unknown error</div>}
    </React.Fragment>
  );
};

ErrorDisplay.propTypes = {
  errors: PropTypes.object.isRequired,
  path: PropTypes.string,
};

export default ErrorDisplay;
