import React from "react";
import * as PropTypes from "prop-types";
import { Card, CardContent } from "@material-ui/core";
import WorxFormContainer from "../../../../Container/Forms/Generic/WorxFormContainer";

const RequirementsCellFormDisplay = (props) => {
  const { options, buttons, formik } = props;

  return (
    <Card raised={true}>
      <CardContent>
        <WorxFormContainer
          options={options}
          formik={formik}
          buttons={buttons}
        />
      </CardContent>
    </Card>
  );
};

RequirementsCellFormDisplay.propTypes = {
  options: PropTypes.object,
  buttons: PropTypes.array,
  formik: PropTypes.object,
};

export default RequirementsCellFormDisplay;

//for reference of old form
/*return <Card raised={true}>
    <CardContent>
      <form>
        <WorxAutocompleteFormik fieldKey={'use'} options={useOptions} formik={formik} />
        <WorxTextField fieldKey={'code_location'} formik={formik} />
        <WorxAutocompleteMultipleFormik fieldKey={'footnote'} options={footnoteOptions} formik={formik} />
        <WorxTextField fieldKey={'minimum'} type={'number'} formik={formik} />
        <WorxTextField fieldKey={'maximum'} type={'number'} formik={formik} />
        <WorxTextField fieldKey={'weight'} type={'number'} formik={formik} />
        <div className={classes.buttonWrapper}>
          <WorxDeleteButton
            callback={(response) => {
              closeForm();
              if(response?.deleteEntity?.errors?.length <= 0 ) {
                grid.filter({variables: filters.get});
              }
            }}
            requery={requery}
            type={'zone_requirement_link'}
            id={formik.values.id}
          />
          <Button variant={"contained"} color="secondary" margin="normal" onClick={() => {
            closeForm();
            formik.resetForm();
          }} classes={{root: buttonClasses.root}}>Cancel</Button>
          <Button variant={"contained"} color="primary" margin="normal" onClick={() => {
            formik.setFieldValue('button', 'normal');
            formik.handleSubmit();
            closeForm();
          }} classes={{root: buttonClasses.root}}>Submit</Button>
        </div>
      </form>
    </CardContent>
  </Card>*/
