import React, { useContext, useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLazyQuery, useMutation } from "@apollo/client";
import { getMediaDocument } from "../../../Queries/Queries";
import { createTenantList } from "../../../Queries/Mutations";
import MainContentWidthContext from "../../../Context/MainContentWidthContext";
import JuniorAnalystTenantFormContainer from "./JuniorAnalystTenantFormContainer";
import { useHistory } from "react-router";
import JuniorAnalystTenantListFormDisplay from "../../../Display/JuniorAnalyst/Forms/JuniorAnalystTenantListFormDisplay";

const JuniorAnalystTenantListFormContainer = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { documentID } = props;
  const history = useHistory();
  //const [initValues, setInitValues] = useState({});
  const [fetchItem, { data: itemQuery }] = useLazyQuery(
    getMediaDocument(documentID)
  );
  //const [initValues, setInitValues] = useState({});
  const [defaultValues, setDefaultValues] = useState({});
  const [open, setOpen] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [mainContentWidth, setMainContentWidth] = useContext(
    MainContentWidthContext
  );
  const [doCreateTenantList, { data: CreateTenantResponse }] = useMutation(
    createTenantList()
  );
  setMainContentWidth("80");

  useEffect(() => {
    fetchItem();
  }, []);

  useEffect(() => {
    if (itemQuery?.mediaDocument?.document_type?.[0]?.name) {
      //setType(itemQuery?.mediaDocument?.document_type?.[0]?.name);
      setDefaultValues({
        property: [{ id: itemQuery?.mediaDocument?.property?.[0]?.id }],
        jurisdiction: itemQuery?.mediaDocument?.jurisdiction?.[0]?.id,
        tenant_list_document: [
          {
            id: itemQuery?.mediaDocument.id,
            name: itemQuery?.mediaDocument.name,
            document: itemQuery?.mediaDocument.document,
          },
        ],
        tenants: [],
        __typename: "tenant_list",
      });
      formik.resetForm();
    }
  }, [itemQuery]);

  useEffect(() => {
    if (CreateTenantResponse) {
      history.push({
        pathname: "/junior_analyst",
      });
    }
  }, [CreateTenantResponse]);

  /*const keyMap = {
    'Requirement ID': 'id',
    'Zone': 'zone',
    'Name': 'name',
    'Label': 'label',
    '': 'min_max',
    'Requirement': 'requirement',
    'Existing': 'existing',
    'Difference': 'difference',
    'Display': 'display'
  };*/

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...defaultValues },
    validationSchema: Yup.object({}),
    // eslint-disable-next-line no-unused-vars
    onSubmit: (values, formikTools) => {
      delete values.tenant_list_document[0].document;
      delete values.jurisdiction;
      doCreateTenantList({ variables: { data: values } });
    },
  });

  return (
    <JuniorAnalystTenantListFormDisplay
      title={
        itemQuery?.mediaDocument?.property?.[0]?.name +
        " :: " +
        itemQuery?.mediaDocument?.property?.[0]?.jurisdiction?.[0]?.name
      }
      options={{
        tenants: {
          open: { get: open, set: setOpen },
          /*options: {
            list: propertyQuery?.properties?.items ? propertyQuery?.properties?.items : [],
            filter: filterProperties
          },*/
          display: (
            <JuniorAnalystTenantFormContainer
              jurisdiction={itemQuery?.mediaDocument?.jurisdiction?.[0]?.id}
              callback={(value) => {
                setOpen(false);
                formik.setFieldValue("tenants", [
                  ...formik.values["tenants"],
                  value,
                ]);
              }}
            />
          ),
        },
      }}
      disabledMap={{ property: true, tenant_list_document: true }}
      formik={formik}
    />
  );
};

JuniorAnalystTenantListFormContainer.propTypes = {
  documentID: PropTypes.number.isRequired,
};

export default JuniorAnalystTenantListFormContainer;
