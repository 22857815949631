import React, {useEffect, useState} from "react";
import * as PropTypes from "prop-types";
import {useLazyQuery, useMutation} from "@apollo/client";
import {
  getCommercialOrder,
  getCommercialReportItem,
  getJurisdictionOptions,
  getProperties, getZonesForJurisdiction
} from "../../Queries/Queries";
import {useFormik} from "formik";
import * as Yup from "yup";
import {Button} from "@mui/material";
import {
  createProperty,
  editJurisdiction,
  editOrderItem, editProperty
} from "../../Queries/Mutations";
import {useHistory} from "react-router";
import CommercialOrderItemDisplay
  from "../../Display/CommercialReport/CommercialOrderItemDisplay";

const CommercialOrderItemContainer = (props) => {
  const {id, orderId} = props;

  const history = useHistory();
  const [currentProperty, setCurrentProperty] = useState(null);
  const [showNewPropertyForm, setShowNewPropertyForm] = useState(false);
  const [showVerificationForm, setShowVerificationForm] = useState(false);
  const [verificationInitValues, setVerificationInitValues] = useState({});

  const [zoneOpen, setZoneOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [orderItem, setOrderItem] = useState({});
  const [filters, setFilters] = useState({id: +id});
  const [zoneFilters, setZoneFilters] = useState({});
  const [getOrderItem, {loading, data: orderItemQuery}] = useLazyQuery(getCommercialReportItem());
  const [jurisdictionOpen , setJurisdictionOpen] = useState(false);
  const [filterJurisdictions, {loading: jurisdictionLoading, data: jurisdictionsQuery}] = useLazyQuery(getJurisdictionOptions());
  const [propertyFilters, setPropertyFilters] = useState({});
  const [filterProperties, {loading: propertyLoading, data: propertiesQuery}] = useLazyQuery(getProperties());
  const [filterZones, {loading: zonesLoading, data: zonesQuery}] = useLazyQuery(getZonesForJurisdiction());
  const [doAddProperty, setDoAddProperty] = useState(false);

  const [doEditOrderItem] = useMutation(editOrderItem("CommercialReportOrderItem"));
  const [doCreateProperty, {data: propertyCreateResponse}] = useMutation(createProperty());
  const [doEditProperty, {data: propertyEditResponse}] = useMutation(editProperty());


  const [doEditLocality] = useMutation(editJurisdiction('Locality'), {
    refetchQueries: [
      { query: getCommercialOrder(), variables: {id: orderId} },
    ],
  });
  const [doEditSubdivision] = useMutation(editJurisdiction('Subdivision'), {
    refetchQueries: [
      { query: getCommercialOrder(), variables: {id: orderId} },
    ],
  });
  const [doEditAdministrativeArea] = useMutation(editJurisdiction('AdministrativeArea'), {
    refetchQueries: [
      { query: getCommercialOrder(), variables: {id: orderId} },
    ],
  });
  const [doEditCountry] = useMutation(editJurisdiction('Country'), {
    refetchQueries: [
      { query: getCommercialOrder(), variables: {id: orderId} },
    ],
  });


  const [columns, setColumns] = useState([
    {
      field: "id",
      headerName: "ID",
      width: 230,
      valueGetter: (value) => {
        //console.log(value)
        return value?.value;
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 230,
      valueGetter: (value) => {
        //console.log(value)
        return value?.value;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 230,
      renderCell: Object.assign(
        (value) => {
          return <Button variant="contained" color="primary" onClick={() => {
            setDoAddProperty(value.row);
          }}>{"Add Property"}</Button>;
        },
        { displayName: "renderCell" }
      ),
    },
  ])

  const jurisdictionFormik = useFormik({
    enableReinitialize: true,
    initialValues: {jurisdiction: []},
    validationSchema: Yup.object({}),
    onSubmit: (/*values, formikTools*/) => {
      // eslint-disable-next-line no-unused-vars
      /* eslint-disable no-unused-vars */
      /*const {
        jurisdiction
      } = values;

      setPropertyFilters({...propertyFilters, jurisdiction: jurisdiction?.[0]?.id});*/
    },
  });

  const propertyFormik = useFormik({
    enableReinitialize: true,
    initialValues: {__typename: 'newProperty'},
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      let {edit, ...otherValues} = values;

      console.log(otherValues);

      if(edit) {
        doEditProperty({
          variables: {
            data: otherValues
          }
        });
      } else {
        doCreateProperty({
          variables: {
            data: otherValues
          }
        });
      }

      setShowNewPropertyForm(false);
    },
  });

  const verificationFormik = useFormik({
    enableReinitialize: true,
    initialValues: {...verificationInitValues, __typename: 'verificationForm'},
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      let {type, ...otherValues} = values;
      console.log(otherValues);
      setShowVerificationForm(false);
      let orderItemWithState = {id: orderItem.id, state_transition: "work", property: [{id: currentProperty.id}]};
      let valuesWithState = {id: otherValues.id, task_description: otherValues.task_description, state_transition: "verify"}

      doEditOrderItem({variables: {data: orderItemWithState}});
      switch (type) {
        case "locality":
          doEditLocality({variables: {data: valuesWithState}});
          break;
        case "subdivision":
          doEditSubdivision({variables: {data: valuesWithState}});
          break;
        case "administrative_area":
          doEditAdministrativeArea({variables: {data: valuesWithState}});
          break;
        case "country":
          doEditCountry({variables: {data: valuesWithState}});
          break;
      }

      console.log('got here... next nav');
      history.push(`/commercial/order/${orderId}`);
      //set jur state to verify.
    },
  });

  useEffect(() => {
    getOrderItem({variables: filters});
    filterJurisdictions({variables: {limit: 25, offset: 0}});
  }, [])

  useEffect(() => {
    if(orderItemQuery) {
      jurisdictionFormik.setFieldValue('address', orderItemQuery.commercialReportItem.address);
      if(orderItemQuery?.commercialReportItem?.property?.[0]?.jurisdiction) {
        jurisdictionFormik.setFieldValue('jurisdiction', orderItemQuery.commercialReportItem.property[0].jurisdiction);
      }
      propertyFormik.setFieldValue('address', orderItemQuery.commercialReportItem.address);
      setOrderItem(orderItemQuery.commercialReportItem);
      setColumns(columns);
    }
  }, [orderItemQuery])

  useEffect(() => {
    if(jurisdictionsQuery && jurisdictionFormik.values.jurisdiction.length > 0) {
      let newFilter = {...propertyFilters, jurisdiction: jurisdictionFormik?.values?.jurisdiction?.[0]?.id};
      let newZoneFilter = {...zoneFilters, jurisdiction: jurisdictionFormik?.values?.jurisdiction?.[0]?.id};
      setPropertyFilters(newFilter);
      filterProperties({variables: newFilter});
      setZoneFilters(newZoneFilter);
      filterZones({variables: newZoneFilter});
      propertyFormik.setFieldValue('jurisdiction', [{id: jurisdictionFormik?.values?.jurisdiction?.[0]?.id}])
    }
  }, [jurisdictionsQuery])

  useEffect(() => {
    if(doAddProperty) {
      updateOrderItem(doAddProperty);
      setDoAddProperty(null);
    }
  }, [doAddProperty])

  useEffect(() => {
    if(propertyCreateResponse) {
      setDoAddProperty(propertyCreateResponse.createProperty.property);
    }
    if(propertyEditResponse) {
      setDoAddProperty(propertyEditResponse.editProperty.property);
    }
  }, [propertyCreateResponse, propertyEditResponse])

  const updateOrderItem = (property) => {
    setCurrentProperty(property);
    setVerificationInitValues(property.jurisdiction[0]);
    verificationFormik.resetForm();
    let verified_date = property?.jurisdiction?.[0]?.verified_date
    let currentDate = new Date();
    let cutoffDate = new Date(currentDate.getTime() - 14 * 24 * 60 * 60 * 1000);
    if(!verified_date || Math.floor(cutoffDate.getTime() / 1000) > verified_date) {
      setShowVerificationForm(true);
    } else {
      let orderItemWithState = {id: orderItem.id, state_transition: "work", property: [{id: property.id}]};
      doEditOrderItem({variables: {data: orderItemWithState}});
      history.push(`/commercial/order/${orderId}`);
    }
  }

  console.log(propertyFormik.values);

  return <CommercialOrderItemDisplay
    orderItem={orderItem}
    columns={columns}
    filters={{get: filters, set: setFilters}}
    currentPage={{get: currentPage, set: setCurrentPage}}
    rowTotal={propertiesQuery?.properties?.count ?? 0}
    loading={loading}

    //jurisdictionFilters={{get: jurisdictionFilters, set: setJurisdictionFilters}}
    jurisdictions={{
      open: {get: jurisdictionOpen, set: setJurisdictionOpen},
      options: {list: jurisdictionsQuery?.jurisdictions?.items ?? [], filter: filterJurisdictions}
    }}

    propertyFilters={{get: propertyFilters, set: setPropertyFilters}}
    properties={{list: propertiesQuery?.properties?.items ?? [], filter: filterProperties}}

    showNewPropertyForm={{get: showNewPropertyForm, set: setShowNewPropertyForm}}
    propertyFormOptions={{
      zone: {
        open: { get: zoneOpen, set: setZoneOpen },
        options: {
          list: zonesQuery?.zones?.items ?? [],
          filter: filterZones,
        },
        variables: {
          filters: {zoneFilters}
        }
      }
    }}

    showVerificationForm={{get: showVerificationForm, set: setShowVerificationForm}}
    verificationFormOptions={{}}

    jurisdictionFormik={jurisdictionFormik}
    propertyFormik={propertyFormik}
    verificationFormik={verificationFormik}

    propertyLoading={propertyLoading}
  />
}

CommercialOrderItemContainer.propTypes = {
  id: PropTypes.number.isRequired,
  orderId: PropTypes.number.isRequired,
}

export default CommercialOrderItemContainer;
