import React from "react";
import * as PropTypes from "prop-types";
import WorxFormContainer from "../../Container/Forms/Generic/WorxFormContainer";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import SurveyHeaderDisplay from "../JuniorAnalyst/SurveyHeaderDisplay";
import { GetSet } from "../../Constants/Jurisdiction";

const AnalystRequirementsDisplay = (props) => {
  /* eslint-disable no-unused-vars */
  const {
    id,
    options,
    footnotes,
    jurisdiction,
    property,
    documentOpen,
    abuttingZoneOpen,
    document,
    formik,
  } = props;
  /* eslint-disable no-unused-vars */
  let keys = null;
  if (footnotes) {
    keys = Object.keys(footnotes);
  }

  return (
    <React.Fragment>
      <SurveyHeaderDisplay
        jurisdiction={jurisdiction}
        property={property}
        documentOpen={documentOpen}
        abuttingZoneOpen={abuttingZoneOpen}
        documentFile={document}
      />
      <WorxFormContainer options={options} formik={formik} />
      <Button
        key={"submit"}
        variant={"contained"}
        color="primary"
        margin="normal"
        onClick={() => {
          let newList = [...formik.values.requirements_answers.requirements];
          newList.splice(newList.length, 0, {
            id: newList.length - 1,
            new: true,
          });
          formik.setFieldValue("requirements_answers.requirements", newList);
        }}
      >
        Add Requirement
      </Button>
      {footnotes &&
        keys.map((key, index) => {
          return (
            <Tooltip key={key} title={footnotes[key].description}>
              {/*<div id={'foot-' + index}><sup>{`[${index+1}]`}</sup>{`${footnotes[key].label}`}</div>*/}
              <div id={"foot-" + index}>
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  dangerouslySetInnerHTML={{
                    __html:
                      `<sup style="margin-right: 1em;">[${index + 1}]</sup>` +
                      footnotes[key].description,
                  }}
                />
              </div>
            </Tooltip>
          );
        })}
    </React.Fragment>
  );
};

AnalystRequirementsDisplay.propTypes = {
  id: PropTypes.number,
  options: PropTypes.object,
  footnotes: PropTypes.object,
  jurisdiction: PropTypes.object,
  property: PropTypes.object,
  documentOpen: GetSet,
  abuttingZoneOpen: GetSet,
  document: PropTypes.object,
  formik: PropTypes.object,
};

export default AnalystRequirementsDisplay;
