import React from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import Button from "@material-ui/core/Button";
import { PropTypes } from "prop-types";
import { Card, CardContent, StepButton } from "@material-ui/core";
import { makeStyles } from "@mui/styles";
import { GetSet } from "../../../Constants/Jurisdiction";
//import UseGridMatrixContainer from "../../../Container/DataEntry/DataEntryTwo_UseWorkflow/Forms/UseGridMatrixContainer";
import FootnoteFormContainer from "../../../Container/DataEntry/DataEntryTwo_UseWorkflow/Forms/FootnoteFormContainer";
import UseLegendFormContainer from "../../../Container/DataEntry/DataEntryTwo_UseWorkflow/Forms/UseLegendFormContainer";
import ParkingFormContainer from "../../../Container/DataEntry/DataEntryTwo_UseWorkflow/Forms/ParkingFormContainer";
import DocumentDrawerContainer from "../../../Container/DataEntry/DocumentDrawerContainer";
//import DataEntryGridWrapper from "../../../Container/DataEntry/DataEntryGrid/DataEntryGridWrapper";
import UseGridMatrixContainer from "../../../Container/DataEntry/DataEntryTwo_UseWorkflow/Forms/UseGridMatrixContainer";

const topCardUseStyles = makeStyles({
  root: {
    marginBottom: "2em",
  },
});

const buttonUseStyles = makeStyles({
  root: {
    marginLeft: "1em",
  },
  right: {
    float: "right",
  },
});

const UseWorkflowDisplay = (props) => {
  let {
    steps,
    step,
    lastStep,
    data,
    releaseJurisdiction,
    removeJurisdiction,
    setHeaderTitle,
    documentsOpen,
  } = props;
  const buttonClasses = buttonUseStyles();
  const topCardClasses = topCardUseStyles();

  setHeaderTitle(data.get.name);

  let offset = 0;
  if (step.get !== 0) {
    offset = step.get - 1;
  }
  return (
    <React.Fragment>
      {data.get && (
        <React.Fragment>
          <Card classes={{ root: topCardClasses.root }}>
            <CardContent>
              <Button
                className={buttonClasses.right}
                onClick={() => documentsOpen.set(!documentsOpen.get)}
                variant="contained"
              >
                Documents
              </Button>
              <DocumentDrawerContainer
                jurisdiction={data.get.id}
                open={documentsOpen}
              />
              {step.get === 0 && <FootnoteFormContainer data={data} />}
              {step.get === 1 && <UseLegendFormContainer data={data} />}
              {step.get === 2 && <UseGridMatrixContainer data={data} />}
              {/*step.get === 2 && <DataEntryGridWrapper step={step.get} jurisdiction={data.get.id} mode={'uses'} />*/}
              {step.get === 3 && <ParkingFormContainer data={data} />}
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              <Stepper nonLinear activeStep={step.get}>
                {steps.slice(offset, offset + 3).map((label, index) => {
                  return (
                    <Step key={label} active={index === step.get - offset}>
                      <StepButton completed={index < 1 && step.get !== 0}>
                        {label}
                      </StepButton>
                    </Step>
                  );
                })}
              </Stepper>

              <Button
                onClick={() => step.set(step.get - 1)}
                disabled={step.get === 0}
                variant="contained"
              >
                Back
              </Button>
              {step.get < lastStep && (
                <Button
                  onClick={() => step.set(step.get + 1)}
                  variant="contained"
                  color="primary"
                  classes={{ root: buttonClasses.root }}
                >
                  Next
                </Button>
              )}
              <Button
                onClick={() => releaseJurisdiction()}
                variant="contained"
                color="secondary"
                classes={{ root: buttonClasses.root }}
              >
                Release Jurisdiction
              </Button>
              {step.get === lastStep && (
                <Button
                  onClick={() => {
                    step.set(step.get + 1);
                    removeJurisdiction();
                  }}
                  variant="contained"
                  color="primary"
                  classes={{ root: buttonClasses.root }}
                >
                  Finish
                </Button>
              )}
            </CardContent>
          </Card>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

UseWorkflowDisplay.propTypes = {
  steps: PropTypes.array.isRequired,
  step: GetSet,
  lastStep: PropTypes.number.isRequired,
  data: GetSet,
  releaseJurisdiction: PropTypes.func.isRequired,
  removeJurisdiction: PropTypes.func.isRequired,
  renderKey: PropTypes.number.isRequired,
  setHeaderTitle: PropTypes.func,
  documentsOpen: GetSet,
};

export default UseWorkflowDisplay;
