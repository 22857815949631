import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import { FormLabel, makeStyles, Typography } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import { GetSet, ListFilter } from "../../../../Constants/Jurisdiction";
import * as PropTypes from "prop-types";
import WorxTextField from "../../../Forms/Inputs/WorxTextField";

const useStyles = makeStyles({
  buttonWrapper: {
    textAlign: "right",
  },
});

const buttonUseStyles = makeStyles({
  root: {
    marginLeft: "1em",
  },
});

const radioGroupUseStyles = makeStyles({
  root: {
    marginBottom: "1em",
  },
});

const topCardUseStyles = makeStyles({
  root: {
    "& .MuiDataGrid-cell": {
      whiteSpace: "normal !important",
    },
    marginBottom: "2em",
  },
});

const JurisdictionFormTwoDisplay = (props) => {
  let {
    addCategories,
    categories,
    columns,
    initValues,
    rowCount,
    pageSize,
    filters,
    currentPage,
    formik,
  } = props;

  const classes = useStyles();
  const buttonClasses = buttonUseStyles();
  const radioGroupClasses = radioGroupUseStyles();
  const topCardClasses = topCardUseStyles();

  return (
    <React.Fragment>
      <Typography variant={"h5"} gutterBottom>
        Zone Categories
      </Typography>

      <FormLabel>Create Category</FormLabel>
      {!addCategories.get && (
        <RadioGroup
          aria-label="Create Zone Categories"
          name="create-zones"
          value={addCategories.get}
          onChange={(event) => {
            addCategories.set(event.target.value);
            initValues.set({});
          }}
          classes={{ root: radioGroupClasses.root }}
        >
          <FormControlLabel value={true} control={<Radio />} label="Yes" />
          <FormControlLabel value={false} control={<Radio />} label="No" />
        </RadioGroup>
      )}
      {addCategories.get && (
        <form>
          <WorxTextField fieldKey={"name"} formik={formik} />
          <div className={classes.buttonWrapper}>
            <Button
              variant={"contained"}
              color="secondary"
              margin="normal"
              onClick={() => {
                addCategories.set(false);
                formik.resetForm();
              }}
            >
              Cancel
            </Button>
            <Button
              variant={"contained"}
              color="primary"
              margin="normal"
              onClick={() => {
                formik.setFieldValue("button", "normal");
                formik.handleSubmit();
              }}
              classes={{ root: buttonClasses.root }}
            >
              Submit
            </Button>
            <Button
              variant={"contained"}
              color="primary"
              margin="normal"
              onClick={() => {
                formik.setFieldValue("button", "repeat");
                formik.handleSubmit();
              }}
              classes={{ root: buttonClasses.root }}
            >
              Submit and Add Another
            </Button>
          </div>
        </form>
      )}
      {!addCategories.get && (
        <React.Fragment>
          <DataGrid
            classes={{ root: topCardClasses.root }}
            key={rowCount}
            page={currentPage.get}
            pagination
            paginationMode="server"
            rowCount={rowCount}
            pageSize={pageSize}
            onPageChange={(page) => {
              let nextFilter = { ...filters.get, offset: pageSize * page };
              categories.filter({ variables: nextFilter });
              filters.set(nextFilter);
              currentPage.set(page);
            }}
            loading={!categories.list}
            columns={columns}
            rows={categories.list ? categories.list : []}
            autoHeight
            getRowHeight={() => "auto"}
            checkboxSelection={false}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

JurisdictionFormTwoDisplay.propTypes = {
  addCategories: GetSet,
  categories: ListFilter,
  columns: PropTypes.array,
  initValues: GetSet,
  rowCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  filters: GetSet,
  currentPage: GetSet,
  formik: PropTypes.object.isRequired,
};

export default JurisdictionFormTwoDisplay;
