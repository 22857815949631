import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GetSet } from "../../../../Constants/Jurisdiction";
import { getCategoriesForJurisdiction } from "../../../../Queries/Queries";
import {
  addCategory,
  checkCategoryDuplicate,
  editCategory,
} from "../../../../Queries/Mutations";
import { useFormik } from "formik";
import * as Yup from "yup";
import JurisdictionFormFourDisplay from "../../../../Display/DataEntry/DataEntryOne_MainWorkflow/Forms/JurisdictionFormFourDisplay";
import Button from "@material-ui/core/Button";
import WorxDeleteButton from "../../../Forms/Inputs/WorxDeleteButton";

const JurisdictionFormFourContainer = (props) => {
  const { data } = props;
  const [addCategories, setAddCategories] = useState(false);
  const [initValues, setInitValues] = useState({});
  const [rowCount, setRowCount] = useState(0);
  const id = data.get.id;
  const pageSize = 25;
  const [currentPage, setCurrentPage] = useState(0);
  const [filters, setFilters] = useState({ limit: pageSize, offset: 0 });
  const [filterCategories, { data: categoriesQuery }] = useLazyQuery(
    getCategoriesForJurisdiction("use_list", id),
    {
      fetchPolicy: "cache-and-network",
    }
  );
  const [doCreateCategory] = useMutation(addCategory("Use"), {
    update(
      cache,
      {
        data: {
          createUseList: { category },
        },
      }
    ) {
      const { categories } = cache.readQuery({
        query: getCategoriesForJurisdiction("use_list", id),
        fetchPolicy: "cache-and-network",
      });
      cache.writeQuery({
        query: getCategoriesForJurisdiction("use_list", id),
        data: {
          categories: {
            CategoryConnection: {
              items: categories.items.concat(category),
            },
          },
        },
      });
    },
  });
  const [doEditCategory] = useMutation(editCategory("Use"), null);
  /*const [CategoryValidation, {data: validationQuery}] = useLazyQuery(getCategoriesForJurisdiction('use_list', id), {
    fetchPolicy: 'no-cache',
  });*/
  const [checkCategoryName] = useMutation(checkCategoryDuplicate());

  const validateName = async (variables) => {
    // eslint-disable-next-line no-unused-vars
    const thing = await checkCategoryName({ variables: { data: variables } });
    if (thing) {
      return thing;
    } else {
      return null;
    }
  };

  let defaultCategory = {
    name: "",
    button: null,
  };

  useEffect(() => {
    //first fetch needed in lazy case
    filterCategories();
  }, []);

  useEffect(() => {
    if (categoriesQuery) {
      setRowCount(categoriesQuery.categories.total);
    }
  }, [categoriesQuery]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...defaultCategory, ...initValues, button: null },
    validationSchema: Yup.object({
      name: Yup.string().test(
        "name",
        "This name is already in use.",
        async (value) => {
          if (initValues.id && value === initValues.name) {
            return true;
          } else {
            const rValue = await validateName({
              name: value ?? "",
              type: "use_list",
              jurisdiction: [{ id: data.get.id }],
            });
            if (rValue?.data?.checkCategoryDuplicate?.exists) {
              return false;
            } else {
              return true;
            }
          }
        }
      ),
      //description: Yup.string().required('Required'),
    }),
    onSubmit: (values, formikTools) => {
      // eslint-disable-next-line no-unused-vars
      let { button, ...category } = values;
      switch (button) {
        case "normal":
          mutateCategory(category);
          setAddCategories(false);
          break;
        case "repeat":
          mutateCategory(category);
          break;
        case "cancel":
          setAddCategories(false);
          break;
      }
      setInitValues({});
      formikTools.resetForm();
    },
  });

  const mutateCategory = async (category) => {
    // Do this to get the payload in the right shape
    // eslint-disable-next-line no-unused-vars
    let { new: isNew, edit, type, rowIndex, ...payload } = category;
    payload.jurisdiction = [
      {
        id: data.get.id,
      },
    ];
    if (payload.id) {
      await doEditCategory({ variables: { data: payload } }).then();
    } else {
      // take the temp id out of the payload
      // eslint-disable-next-line no-unused-vars
      let { id, ...createPayload } = payload;
      await doCreateCategory({ variables: { data: createPayload } });
      setRowCount(rowCount + 1);
    }
  };

  const columns = [
    { field: "name", headerName: "Name", width: 230 },
    {
      field: "Operation",
      headerName: "ID",
      width: 200,
      renderCell: Object.assign(
        (params) => {
          return (
            <React.Fragment>
              <WorxDeleteButton
                callback={(response) => {
                  if (response?.deleteEntity?.errors?.length <= 0) {
                    filterCategories({ variables: filters });
                  }
                }}
                type={"category"}
                id={params.row.id}
              />
              <Button
                onClick={() => {
                  formik.resetForm();
                  setAddCategories(true);
                  setInitValues(params.row);
                }}
              >
                edit
              </Button>
            </React.Fragment>
          );
        },
        { displayName: "renderCell" }
      ),
    },
  ];

  return (
    <JurisdictionFormFourDisplay
      addCategories={{ get: addCategories, set: setAddCategories }}
      columns={columns}
      categories={{
        list: categoriesQuery?.categories?.items,
        filter: filterCategories,
      }}
      initValues={{ get: initValues, set: setInitValues }}
      currentPage={{ get: currentPage, set: setCurrentPage }}
      filters={{ get: filters, set: setFilters }}
      rowCount={rowCount}
      pageSize={pageSize}
      formik={formik}
    />
  );
};

JurisdictionFormFourContainer.propTypes = {
  data: GetSet,
};

export default JurisdictionFormFourContainer;
