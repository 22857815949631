import React, { useContext, useEffect } from "react";
import MainContentDisplay from "../Display/MainContentDisplay";
import MainContentWidthContext from "../Context/MainContentWidthContext";
import UserContext from "../Context/UserContext";
import HeaderTitleContext from "../Context/HeaderTitleContext";
import { useQuery } from "@apollo/client";
import { getUser } from "../Queries/Queries";
import MessageContext from "../Context/MessageContext";

const MainContentContainer = (props) => {
  const [mainContentWidth] = useContext(MainContentWidthContext);

  const { loading, data } = useQuery(getUser());

  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useContext(UserContext);

  const [headerTitle] = useContext(HeaderTitleContext);
  // eslint-disable-next-line no-unused-vars
  const [message, setMessage] = useContext(MessageContext);
  //setMessage('this is a test message')

  useEffect(() => {
    if (loading === false) {
      setUser(data.currentUser);
    }
  }, [loading]);

  return (
    <MainContentDisplay
      mainContentWidth={mainContentWidth}
      headerTitle={headerTitle}
      {...props}
    />
  );
};

export default MainContentContainer;
