import React from "react";
import * as PropTypes from "prop-types";
// eslint-disable-next-line no-unused-vars
import {
  Card,
  CardContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
import WorxRadio from "../../Forms/Inputs/WorxRadio";
import { GetSet, AutoCompleteFilter } from "../../../Constants/Jurisdiction";
import WorxTextField from "../../Forms/Inputs/WorxTextField";
import WorxAddressField from "../../../Container/Forms/Inputs/WorxAddressField";
import Button from "@material-ui/core/Button";
import WorxAutocompleteFormik from "../../Forms/Inputs/WorxAutocompleteFormik";
import WorxAutocomplete from "../../Forms/Inputs/WorxAutocomplete";

const useStyles = makeStyles({
  buttonWrapper: {
    textAlign: "right",
  },
});

const radioGroupStyles = makeStyles({
  root: {
    marginBottom: "1em",
  },
});

const buttonUseStyles = makeStyles({
  root: {
    marginLeft: "1em",
  },
});

const topCardUseStyles = makeStyles({
  root: {
    marginBottom: "2em",
  },
});

const AddNewResidentialOrderDisplay = (props) => {
  /* eslint-disable no-unused-vars */
  let {
    jurisdictions,
    clients,
    client,
    contact,
    billing,
    properties,
    siteInfoOptions,
    productSelections,
    billingSelections,
    initValues,
    formik,
  } = props;


  // eslint-disable-next-line no-unused-vars
  const classes = useStyles(),
    buttonClasses = buttonUseStyles(),
    radioGroupClasses = radioGroupStyles(),
    topCardClasses = topCardUseStyles();
  /* eslint-enable no-unused-vars */

  if (!client.id) {
    return <div>Loading...</div>;
  }

  return (
    <Card classes={{ root: topCardClasses.root }}>
      <CardContent>
        <WorxTextField
          fieldKey={"report_order_items.0.name"}
          label={"Site Name"}
          formik={formik}
        />
        {/*<WorxAutocompleteFormik fieldKey={'client'} options={clients} formik={formik} />*/}
        <WorxAutocompleteFormik
          fieldKey={"report_order_items.0.jurisdiction"}
          label={"Jurisdiction"}
          options={jurisdictions}
          formik={formik}
        />
        <WorxAutocompleteFormik
          fieldKey={"report_order_items.0.property"}
          label={"Property"}
          /*nameKey={'displayName'}*/ options={properties}
          formik={formik}
        />

        <Card classes={{ root: topCardClasses.root }}>
          <CardContent>
            <Typography variant={"h5"}>Contact Information</Typography>

            <WorxAutocomplete
              fieldKey={"contact_information"}
              options={{
                open: contact,
                options: {
                  list:
                    formik?.values?.client?.[0]?.id &&
                    clients.options.list.findIndex((client) => {
                      return client.id === formik?.values?.client?.[0]?.id;
                    }) >= 0
                      ? clients.options.list[
                          clients.options.list.findIndex((client) => {
                            return (
                              client.id === formik?.values?.client?.[0]?.id
                            );
                          })
                        ]?.contact_information?.map((contact) => {
                          return {
                            id: contact.id,
                            name: contact?.address?.address_line1
                              ? contact?.address?.address_line1
                              : contact.phone,
                          };
                        })
                      : [],
                  filter: () => {},
                },
              }}
              onChange={(event, value) => {
                if (value) {
                  let clientList = clients.options.list;
                  let clientIndex = clientList.findIndex((client) => {
                    return client.id === formik?.values?.client?.[0]?.id;
                  });
                  let profileList = clientList[clientIndex].contact_information;
                  let profileIndex = profileList.findIndex((profile) => {
                    return profile.id === value.id;
                  });
                  // eslint-disable-next-line no-unused-vars
                  let { user, ...newValue } = profileList[profileIndex];
                  formik.setFieldValue("contact_information", [newValue]);
                }
              }}
            />
            <WorxAddressField
              fieldKey={"contact_information.0.address"}
              initEdit={true}
              formik={formik}
            />

            <WorxTextField
              fieldKey={"contact_information.0.phone"}
              label={"Phone"}
              type={"phone"}
              formik={formik}
            />
            <WorxTextField
              fieldKey={"contact_information.0.fax"}
              label={"Fax"}
              type={"phone"}
              formik={formik}
            />
          </CardContent>
        </Card>

        <Card classes={{ root: topCardClasses.root }}>
          <CardContent>
            <Typography variant={"h5"}>Billing Information</Typography>
            <WorxRadio
              fieldKey={"billing_selections"}
              options={billingSelections}
              formik={formik}
            />

            <WorxAutocomplete
              fieldKey={"billing_information"}
              options={{
                open: billing,
                options: {
                  list:
                    formik?.values?.client?.[0]?.id &&
                    clients.options.list.findIndex((client) => {
                      return client.id === formik?.values?.client?.[0]?.id;
                    }) >= 0
                      ? clients.options.list[
                          clients.options.list.findIndex((client) => {
                            return (
                              client.id === formik?.values?.client?.[0]?.id
                            );
                          })
                        ].billing_information.map((contact) => {
                          return {
                            id: contact.id,
                            name: contact?.address?.address_line1
                              ? contact?.address?.address_line1
                              : contact.phone,
                          };
                        })
                      : [],
                  filter: () => {},
                },
              }}
              onChange={(event, value) => {
                if (value) {
                  let clientList = clients.options.list;
                  let clientIndex = clientList.findIndex((client) => {
                    return client.id === formik?.values?.client?.[0]?.id;
                  });
                  let profileList = clientList[clientIndex].billing_information;
                  let profileIndex = profileList.findIndex((profile) => {
                    return profile.id === value.id;
                  });
                  formik.setFieldValue("billing_information", [
                    profileList[profileIndex],
                  ]);
                }
              }}
            />
            <WorxAddressField
              fieldKey={"billing_information.0.address"}
              initEdit={true}
              formik={formik}
            />

            <WorxTextField
              fieldKey={"billing_information.0.phone"}
              label={"Phone"}
              type={"phone"}
              formik={formik}
            />
            <WorxTextField
              fieldKey={"billing_information.0.fax"}
              label={"Fax"}
              type={"phone"}
              formik={formik}
            />
          </CardContent>
        </Card>

        {/*<Typography variant={"h5"}>Additional Files</Typography>
      <Typography variant={"h5"}>In Order to complete a report, you must supply Zoning Info, Inc. with a copy of an ALTA Survery and a Tenant List/Rent Roll or Unit Mix. When a Use requires seating, such as a Theater or Testurant, please provide a seat count for parking requirements. When a use is Warehouse or Industrial, please provide an employee count for parking requirements. Additional information may be required.</Typography>

      <WorxMediaUpload fieldKey={'report_order_items.0.client_documents'} label={'Upload Files'} type={'document'} entityType={'media'}
                       jurisdiction={formik?.values?.report_order_items?.[0]?.jurisdiction?.[0]?.id}
                       property={formik?.values?.report_order_items?.[0]?.property?.[0]?.id}
                       formik={formik}/>*/}

        <Button
          variant={"contained"}
          color="primary"
          margin="normal"
          onClick={() => {
            formik.handleSubmit();
          }}
          classes={{ root: buttonClasses.root }}
        >
          Submit
        </Button>
      </CardContent>
    </Card>
  );
};

AddNewResidentialOrderDisplay.propTypes = {
  clients: AutoCompleteFilter,
  client: PropTypes.object.isRequired,
  jurisdictions: AutoCompleteFilter,
  properties: AutoCompleteFilter,
  contact: GetSet,
  billing: GetSet,
  siteInfoOptions: PropTypes.array.isRequired,
  productSelections: PropTypes.array.isRequired,
  billingSelections: PropTypes.array.isRequired,
  initValues: GetSet,
  formik: PropTypes.object.isRequired,
};

export default AddNewResidentialOrderDisplay;
