/* eslint-disable */
import React, { useContext, useEffect } from "react";
import * as PropTypes from "prop-types";
import HeaderTitleContext from "../Context/HeaderTitleContext";
import { makeStyles } from "@mui/styles";
import ClientPortalOrdersListContainer from "./ClientPortal/ClientPortalOrdersListContainer";
import QueueContainer from "./Queue/QueueContainer";
import Button from "@material-ui/core/Button";
import {
  analystReleaseQueueItem,
  claimAnalystQueueItem,
  claimJuniorAnalystQueueItem,
  claimQueueItem,
  claimReportItem,
  getAnalystClaimedQueueItems,
  getAnalystQueueItems,
  getClaimedQueueItems,
  getClaimedReportItems,
  getJuniorAnalystClaimedQueueItems,
  getJuniorAnalystQueueItems,
  getOrders,
  getQueueItems,
  juniorAnalystReleaseQueueItem,
  releaseQueueItem,
} from "../Queries/Queries";
import ClientRelationsContainer from "./ClientRelations/ClientRelationsContainer";
import { DataGrid } from "@mui/x-data-grid";
import CommercialQueueContainer from "./CommercialReport/CommercialQueueContainer";
import CommercialContainer from "./CommercialReport/CommercialContainer";
import CommercialOrderItemContainer from "./CommercialReport/CommercialOrderItemContainer";

const buttonUseStyles = makeStyles({
  root: {
    marginLeft: "1em",
  },
  blue: {
    background: "#5aacee",
    color: "white",
  },
  green: {
    background: "#33a532",
    color: "white",
  },
});

const Sandbox = (props) => {
  let [header, setHeader] = useContext(HeaderTitleContext);

  setHeader("Sandbox");

  return <CommercialOrderItemContainer id={1216} />
};

Sandbox.propTypes = {
  input: PropTypes.any,
};

export default Sandbox;
