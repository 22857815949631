import React, { useContext, useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  getAnalystReport,
  getParkingConformanceOptions,
  getUsesForJurisdiction,
} from "../../Queries/Queries";
import { useFormik } from "formik";
import * as Yup from "yup";
import { editAnalystReport } from "../../Queries/Mutations";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import WorxTextField from "../../Display/Forms/Inputs/WorxTextField";
import WorxSelectString from "../../Display/Forms/Inputs/WorxSelectString";
import AnalystParkingDisplay from "../../Display/Analyst/AnalystParkingDisplay";
import MessageContext from "../../Context/MessageContext";

const AnalystParkingContainer = (props) => {
  const { id } = props;

  const [getReport, { data: report }] = useLazyQuery(getAnalystReport(id));
  const [filterConformanceOptions, { data: conformanceOptions }] = useLazyQuery(
    getParkingConformanceOptions()
  );
  const [filterUses, { data: usesOptions }] = useLazyQuery(
    getUsesForJurisdiction()
  );
  // eslint-disable-next-line no-unused-vars
  const [message, setMessage] = useContext(MessageContext);

  const [initValues, setInitValues] = useState({});

  const defaultValues = {};

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...defaultValues, ...initValues },
    validationSchema: Yup.object({}),
    // eslint-disable-next-line no-unused-vars
    onSubmit: (values, formikTools) => {
      // eslint-disable-next-line no-unused-vars
      let { conformance_report_order_item, ...val } = values;
      let payload = {
        id: val.id,
        ...(values.parking_answers && {
          parking_answers: values?.parking_answers,
        }),
        ...(values.parking_notes && { parking_notes: values?.parking_notes }),
        ...(values.parking_conformance && {
          parking_conformance: values?.parking_conformance,
        }),
      };
      doEditReport({ variables: { data: payload } });
      setMessage("✓ Parking Saved");
    },
  });

  const [doEditReport, { data: response }] = useMutation(editAnalystReport());

  const [pageSize] = useState(100);
  //const [columns, setColumns] = useState([]);
  const [openStates, setOpenStates] = useState({});
  const [openUseStates, setOpenUseStates] = useState({});

  const setOpen = (id, value) => {
    setOpenStates({ ...openStates, [id]: value });
  };

  const getOpen = (id) => {
    return openStates[id];
  };

  const setOpenUse = (id, value) => {
    setOpenUseStates({ ...openStates, [id]: value });
  };

  const getOpenUse = (id) => {
    return openUseStates[id];
  };

  let getColumns = (key) => [
    {
      field: "use",
      renderCell: Object.assign(
        (params) => {
          if (!params.row.new) {
            return (
              <Tooltip title={params?.row?.["requirement"]}>
                <div>{params?.row?.["use"]}</div>
              </Tooltip>
            );
          } else {
            return (
              <WorxSelectString
                fieldKey={`parking_answers.${key}.${formik.values.parking_answers[
                  key
                ].findIndex((item) => item.id === params.row.id)}.use`}
                label={""}
                options={{
                  open: {
                    get: getOpenUse(params.row.id),
                    set: (value) => {
                      setOpenUse(params.row.id, value);
                    },
                  },
                  options: {
                    list: usesOptions?.uses.items
                      ? usesOptions?.uses.items
                      : [],
                    filter: filterUses,
                  },
                  variables: {
                    filters: {
                      get: {
                        jurisdiction:
                          report.analystReport.conformance_report_order_item[0]
                            .jurisdiction[0].id,
                      },
                      set: {
                        /*do nothing*/
                      },
                    },
                  },
                }}
                formik={formik}
              />
            );
          }
        },
        { displayName: "renderCell" }
      ),
      headerName: "Use",
      width: 330,
    },
    {
      field: "description",
      // eslint-disable-next-line no-unused-vars
      renderCell: Object.assign(
        (params) => {
          return (
            <Tooltip title={"test"}>
              <div
                dangerouslySetInnerHTML={{ __html: params.row.description }}
              />
            </Tooltip>
          );
        },
        { displayName: "renderCell" }
      ),
      headerName: "Description",
      width: 330,
    },
    {
      field: "required_spaces",
      // eslint-disable-next-line no-unused-vars
      renderCell: Object.assign(
        (params) => {
          if (params.id !== "total" || params.id === "handicap") {
            return (
              <Tooltip title={"test"}>
                <WorxTextField
                  fieldKey={`parking_answers.${key}.${formik.values.parking_answers[
                    key
                  ].findIndex(
                    (item) => item.id === params.row.id
                  )}.required_spaces`}
                  label={""}
                  formik={formik}
                />
              </Tooltip>
            );
          } else {
            let total = formik.values.parking_answers[key].reduce(
              (inValue, newItem) => {
                if (newItem.id !== "total") {
                  return inValue + +newItem.required_spaces;
                } else {
                  return inValue;
                }
              },
              0
            );

            return (
              <Tooltip title={"test"}>
                <div>{Math.ceil(total)}</div>
              </Tooltip>
            );
          }
        },
        { displayName: "renderCell" }
      ),
      headerName: "Req Spaces",
      width: 330,
    },
    {
      field: "existing",
      // eslint-disable-next-line no-unused-vars
      renderCell: Object.assign(
        (params) => {
          if (params.id === "total" || params.id === "handicap") {
            return (
              <Tooltip title={"test"}>
                <WorxTextField
                  fieldKey={`parking_answers.${key}.${formik.values.parking_answers[
                    key
                  ].findIndex((item) => item.id === params.row.id)}.existing`}
                  label={""}
                  formik={formik}
                />
              </Tooltip>
            );
          }
        },
        { displayName: "renderCell" }
      ),
      headerName: "Existing",
      width: 330,
    },
    {
      field: "conformance",
      renderCell: Object.assign(
        (params) => {
          if (params.id === "total" || params.id === "handicap") {
            return (
              <Tooltip title={"test"}>
                <WorxSelectString
                  fieldKey={`parking_answers.${key}.${formik.values.parking_answers[
                    key
                  ].findIndex(
                    (item) => item.id === params.row.id
                  )}.conformance`}
                  label={""}
                  options={{
                    open: {
                      get: getOpen(params.row.id),
                      set: (value) => {
                        setOpen(params.row.id, value);
                      },
                    },
                    options: {
                      list: conformanceOptions?.getParkingConformanceOptions
                        ? conformanceOptions?.getParkingConformanceOptions.map(
                            (item) => {
                              return { id: item, name: item };
                            }
                          )
                        : [],
                      filter: () => {},
                    },
                  }}
                  formik={formik}
                />
              </Tooltip>
            );
          }
        },
        { displayName: "renderCell" }
      ),
      headerName: "Conformance",
      width: 330,
    },
  ];

  // setup
  useEffect(() => {
    getReport();
    filterConformanceOptions();
    setMessage(null);
  }, []);

  // report
  useEffect(() => {
    if (report) {
      setInitValues({
        ...report.analystReport,
        __typename: "AnalystParkingForm",
      });
    }
  }, [report]);

  // response

  useEffect(() => {
    if (response) {
      setInitValues({
        ...response.editAnalystReport.analyst_report,
        __typename: "AnalystParkingForm",
      });
    }
  }, [response]);

  return (
    <AnalystParkingDisplay
      options={{
        "parking_answers.regular_spaces": {
          rowTotal:
            formik?.values?.parking_answers?.regular_spaces?.length ?? 0,
          //rows: formik?.values?.parking_answers?.regular_spaces.map((item, i) => {return {id: i, ...item}}),
          columns: getColumns("regular_spaces"),
          pageSize: pageSize,
          // eslint-disable-next-line no-unused-vars
          onPageChange: (page) => {
            /* no paging in this case */
          },
          loading: formik?.values?.parking_answers?.regular_spaces,
        },
        "parking_answers.handicap_spaces": {
          rowTotal:
            formik?.values?.parking_answers?.handicap_spaces?.length ?? 0,
          //rows: [{id: 0, ...formik?.values?.parking_answers?.handicap_spaces}],
          columns: getColumns("handicap_spaces"),
          pageSize: pageSize,
          // eslint-disable-next-line no-unused-vars
          onPageChange: (page) => {
            /* no paging in this case */
          },
          loading: formik?.values?.parking_answers?.handicap_spaces,
        },
      }}
      nameOverrides={{}}
      id={id}
      formik={formik}
    />
  );
};

AnalystParkingContainer.propTypes = {
  id: PropTypes.number.isRequired,
  page: PropTypes.string,
};

export default AnalystParkingContainer;
