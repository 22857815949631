import React, { useContext, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { useHistory } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@material-ui/core";
import { getResidentialOrders } from "../../Queries/Queries";
import UserContext from "../../Context/UserContext";
import MyOrdersListDisplay from "../../Display/ResidentialOrders/MyOrdersListDisplay";
import MainContentWidthContext from "../../Context/MainContentWidthContext";
import { GetSet } from "../../Constants/Jurisdiction";
import HeaderTitleContext from "../../Context/HeaderTitleContext";

// eslint-disable-next-line no-unused-vars
const MyOrdersListContainer = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [headerTitle, setHeaderTitle] = useContext(HeaderTitleContext);
  setHeaderTitle("Orders");

  // eslint-disable-next-line no-unused-vars
  const [columns, setColumns] = useState([
    {
      field: "order_number",
      headerName: "Order Number",
      width: 230,
      renderCell: Object.assign(
        (params) => {
          return (
            <Link component={RouterLink} to={`/orders/${params.row.id}`}>
              {params.value}
            </Link>
          );
        },
        { displayName: "renderCell" }
      ),
    },
    {
      field: "jurisdiction",
      headerName: "Jurisdiction",
      width: 230,
      valueGetter: (value) => {
        return value?.row?.report_order_items?.[0]?.jurisdiction?.[0]?.name;
      },
    },
    {
      field: "property",
      headerName: "Property",
      width: 230,
      valueGetter: (value) => {
        return value?.row?.report_order_items?.[0]?.property?.[0]?.name;
      },
    },
    {
      field: "created",
      headerName: "Order Date",
      width: 230,
      valueGetter: (value) => {
        return value?.row?.created;
      },
    },
    /*{ field: 'client', headerName: 'Client', width: 230,
      renderCell: Object.assign(params => {
        delete params.row.issues;
        return renderButton(params);
      }, { displayName: 'renderCell' })
    },*/
  ]);

  const userContext = useContext(UserContext);
  const [rows, setRows] = useState([]);
  const [filters, setFilters] = useState({
    limit: 25,
    offset: 0,
    use: "",
    zone: "",
    operator: "CONTAINS",
  });
  const [rowTotal, setRowTotal] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [filterOrders, { loading: ordersLoading, data: ordersQuery }] =
    useLazyQuery(getResidentialOrders(), { variables: filters });

  const history = useHistory();
  const createNewOrder = () => {
    history.push({
      pathname: "/residential/create-order/",
    });
  };

  // eslint-disable-next-line no-unused-vars
  const [mainContentWidth, setMainContentWidth] = useContext(
    MainContentWidthContext
  );
  setMainContentWidth("80");

  useEffect(() => {
    if (userContext?.[0]?.id) {
      let newFilters = { ...filters };
      if (newFilters.client !== "" + userContext?.[0]?.id) {
        setFilters({ ...newFilters, client: "" + userContext[0].id });
      }
    }
  }, [userContext]);

  useEffect(() => {
    if (filters.client) {
      filterOrders({ variables: filters });
    }
  }, [filters]);

  useEffect(() => {
    if (ordersQuery?.residentialOrders?.total) {
      setRowTotal(ordersQuery?.orders?.total);
    }

    if (ordersQuery?.residentialOrders?.items) {
      setRows(ordersQuery?.residentialOrders?.items);
      //setRowTotal(ordersQuery?.residentialOrders?.total);
    }
  }, [ordersQuery]);

  return (
    <React.Fragment>
      <MyOrdersListDisplay
        orders={{ list: rows, filter: filterOrders, loading: ordersLoading }}
        columns={columns}
        rows={rows}
        filters={{ get: filters, set: setFilters }}
        currentPage={{ get: currentPage, set: setCurrentPage }}
        rowTotal={rowTotal}
        createNewOrder={createNewOrder}
      />
    </React.Fragment>
  );
};

MyOrdersListContainer.propTypes = {
  data: GetSet,
};

export default MyOrdersListContainer;
