import React from "react";

const useTableFragment = (type) => {
  const map = {
    Locality: "subdivision",
    Subdivision: "administrative_area",
    AdministrativeArea: "country",
    Country: null,
  };

  return [
    {
      field: type,
      headerName: "Link",
      width: 230,
      renderCell: Object.assign(
        (params) => {
          return (
            map[type] &&
            params.row[map[type]] && (
              <a href={"test"}>{params.row[map[type]][0].name}</a>
            )
          );
        },
        { displayName: "renderCell" }
      ),
    },
  ];
};

export default useTableFragment;
