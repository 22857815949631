import React from "react";
// eslint-disable-next-line no-unused-vars
import * as PropTypes from "prop-types";
import { GetSet, ListFilter } from "../../Constants/Jurisdiction";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@mui/styles";

const buttonUseStyles = makeStyles({
  root: {
    margin: "0 1em 1em 0",
  },
});

const topCardUseStyles = makeStyles({
  root: {
    "& .MuiDataGrid-cell": {
      whiteSpace: "normal !important",
    },
    marginBottom: "2em",
  },
});

// eslint-disable-next-line no-unused-vars
const AnalystConformanceReportDisplay = (props) => {
  const {
    documents,
    columns,
    currentPage,
    filters,
    rowTotal,
    doGenerateReport,
  } = props;

  const buttonClasses = buttonUseStyles();
  const topCardClasses = topCardUseStyles();

  return (
    <React.Fragment>
      <DataGrid
        classes={{ root: topCardClasses.root }}
        key={rowTotal}
        page={currentPage.get}
        pagination
        paginationMode="server"
        rowCount={rowTotal}
        pageSize={filters.get.limit}
        onPageChange={(page) => {
          let nextFilter = { ...filters.get, offset: filters.get.limit * page };
          documents.filter({ variables: nextFilter });
          filters.set(nextFilter);
          currentPage.set(page);
        }}
        loading={!documents.list}
        columns={columns}
        rows={documents.list}
        autoHeight
        getRowHeight={() => "auto"}
        checkboxSelection={false}
      />
      <div style={{ textAlign: "right", marginTop: "1em" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            doGenerateReport();
          }}
          classes={{ root: buttonClasses.root }}
        >
          Generate Report
        </Button>
      </div>
    </React.Fragment>
  );
};

AnalystConformanceReportDisplay.propTypes = {
  documents: ListFilter,
  columns: PropTypes.array,
  currentPage: GetSet,
  filters: GetSet,
  rowTotal: PropTypes.number,
  doGenerateReport: PropTypes.func,
};

export default AnalystConformanceReportDisplay;
