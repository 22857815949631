import React, { useContext, useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import WorxFormContainer from "../../Forms/Generic/WorxFormContainer";
import { useLazyQuery, useMutation } from "@apollo/client";
import { getMediaDocument } from "../../../Queries/Queries";
import { createUnitMix } from "../../../Queries/Mutations";
import MainContentWidthContext from "../../../Context/MainContentWidthContext";
import { useHistory } from "react-router";
import { Card, CardContent } from "@material-ui/core";
import { makeStyles } from "@mui/styles";

const topCardUseStyles = makeStyles({
  root: {
    marginBottom: "2em",
  },
});

const JuniorAnalystUnitMixFormContainer = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { documentID } = props;
  const topCardClasses = topCardUseStyles();
  const history = useHistory();
  //const [initValues, setInitValues] = useState({});
  const [fetchItem, { data: itemQuery }] = useLazyQuery(
    getMediaDocument(documentID)
  );
  const [initValues, setInitValues] = useState({});

  // eslint-disable-next-line no-unused-vars
  const [mainContentWidth, setMainContentWidth] = useContext(
    MainContentWidthContext
  );
  setMainContentWidth("80");

  useEffect(() => {
    fetchItem();
  }, []);

  useEffect(() => {
    if (itemQuery?.mediaDocument?.document_type?.[0]?.name) {
      setInitValues({
        property: [{ id: itemQuery?.mediaDocument?.property?.[0]?.id }],
        unit_mix_document: [
          {
            id: itemQuery?.mediaDocument.id,
            name: itemQuery?.mediaDocument.name,
            document: itemQuery?.mediaDocument.document,
          },
        ],
        __typename: "unit_mix",
      });
      formik.resetForm();
    }
  }, [itemQuery]);

  const [doCreateUnitMix, { data: CreateUnitMixResponse }] = useMutation(
    createUnitMix()
  );

  useEffect(() => {
    if (CreateUnitMixResponse) {
      history.push({
        pathname: "/junior_analyst",
      });
    }
  }, [CreateUnitMixResponse]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...initValues },
    validationSchema: Yup.object({}),
    // eslint-disable-next-line no-unused-vars
    onSubmit: (values, formikTools) => {
      delete values.unit_mix_document[0].document;
      doCreateUnitMix({ variables: { data: values } });
    },
  });

  return (
    <React.Fragment>
      <Card classes={{ root: topCardClasses.root }}>
        <CardContent>
          <h3>
            {itemQuery?.mediaDocument?.property?.[0]?.name +
              " :: " +
              itemQuery?.mediaDocument?.property?.[0]?.jurisdiction?.[0]?.name}
          </h3>
          <WorxFormContainer
            disabledMap={{ property: true, unit_mix_document: true }}
            formik={formik}
          />
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

JuniorAnalystUnitMixFormContainer.propTypes = {
  documentID: PropTypes.number.isRequired,
};

export default JuniorAnalystUnitMixFormContainer;
