import React from "react";
import * as PropTypes from "prop-types";
import { AutoCompleteFilter } from "../../../Constants/Jurisdiction";
//import WorxAutocomplete from "./WorxAutocomplete";
//import MenuItem from '@mui/material/MenuItem';
//import Select from '@mui/material/Select';
//import InputLabel from '@mui/material/InputLabel';
//import OutlinedInput from '@mui/material/OutlinedInput';
//import Chip from '@mui/material/Chip';
//import Box from '@mui/material/Box';
import { camelToTitle, underscoreToCamel } from "../../../tools/stringTools";
import WorxAutocomplete from "./WorxAutocomplete";

//const ITEM_HEIGHT = 48;
//const ITEM_PADDING_TOP = 8;

const WorxAutocompleteMultipleFormik = (props) => {
  let {
    formik,
    fieldKey,
    nameKey = "name",
    label = camelToTitle(underscoreToCamel(fieldKey)),
    disabled = false,
    options,
  } = props;

  let value = formik.values;

  for (let el of fieldKey.split(".")) {
    if (value && value?.[el]) {
      value = value?.[el];
    } else {
      value = [];
    }
  }

  /*let fixedVariables = {};
  if(options?.variables?.filters) {
    fixedVariables = options?.variables?.filters.get;
  }*/

  return (
    <fieldset>
      <legend>{camelToTitle(fieldKey) + 's'}</legend>
      {value.map((val) => {
        return (
          <div key={val.id} style={{ display: "flex" }}>
            <div>{val[nameKey]}</div>
            <div
              onClick={() => {
                let newValue = [...value];
                let index = newValue.findIndex((item) => item.id === val.id);
                newValue.splice(index, 1);
                formik.setFieldValue(fieldKey, newValue);
              }}
              style={{ marginLeft: "1em" }}
            >
              X
            </div>
          </div>
        );
      })}
      <WorxAutocomplete
        fieldKey={fieldKey}
        nameKey={nameKey}
        label={label}
        disabled={disabled}
        options={options}
        clearOnBlur
        onChange={(event, val) => {
          let newValue = [...value];
          if (val) {
            let item = newValue.find(
              (removeValue) => removeValue.id === val.id
            );
            if (!item) {
              newValue.push({ id: val.id, [nameKey]: val[nameKey] });
              formik.setFieldValue(fieldKey, newValue);
            }
          }
        }}
      />
    </fieldset>
  );
};

WorxAutocompleteMultipleFormik.propTypes = {
  options: AutoCompleteFilter,
  label: PropTypes.string,
  fieldKey: PropTypes.string.isRequired,
  nameKey: PropTypes.string,
  disabled: PropTypes.bool,
  formik: PropTypes.object.isRequired,
};

export default WorxAutocompleteMultipleFormik;
