import React, { useEffect, useState } from "react";
import UseGridMatrixDisplay from "../../../../Display/DataEntry/DataEntryTwo_UseWorkflow/Forms/UseGridMatrixDisplay";
import { useLazyQuery } from "@apollo/client";
import { getMatrix } from "../../../../Queries/Queries";
import { GetSet } from "../../../../Constants/Jurisdiction";
import UseCellDisplay from "../../../../Display/DataEntry/DataEntryTwo_UseWorkflow/Forms/UseCellDisplay";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";

const UseGridMatrixContainer = (props) => {
  const { data } = props;

  const linkTypes = ["primary", "secondary", "temporary"];
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [addLink, setAddLink] = useState(false);
  const [initValues, setInitValues] = useState({});
  const [filters, setFilters] = useState({
    limit: 25,
    offset: 0,
    use: "",
    zone: "",
  });
  const [rowTotal, setRowTotal] = useState(25);
  const [fetchMatrix, { loading: gridLoading, data: grid }] = useLazyQuery(
    getMatrix(data.get.id, 25),
    { variables: filters }
  );

  const requery = {
    refetchQueries: [{ query: getMatrix(data.get.id, 25), variables: filters }],
  };

  useEffect(() => {
    fetchMatrix();
  }, []);

  useEffect(() => {
    if (grid?.useMatrix?.count) {
      setRowTotal(grid.useMatrix.count);
    }

    if (
      grid?.useMatrix?.header &&
      columns.length !== grid?.useMatrix?.header.length
    ) {
      setColumns(
        grid.useMatrix.header.map((headerValue) => {
          if (headerValue === "Use") {
            return {
              field: headerValue,
              renderCell: Object.assign(
                (params) => {
                  return (
                    <Tooltip title={params.row[headerValue][0].name}>
                      <div>{params.row[headerValue][0].name}</div>
                    </Tooltip>
                  );
                },
                { displayName: "renderCell" }
              ),
              valueGetter: (value) => value.row[headerValue][0].name,
              headerName: headerValue,
              width: 330,
            };
          } else {
            return {
              field: headerValue,
              renderCell: Object.assign(
                (params) => {
                  return params.value;
                },
                { displayName: "renderCell" }
              ),
              headerName: headerValue,
              //width: 230
            };
          }
        })
      );
    }

    if (grid?.useMatrix?.rows) {
      setRows(
        grid.useMatrix.rows.map((row) => {
          let newRow = row.cells.reduce((outValue, newValue) => {
            const params = { ...newValue, use: row.use };
            outValue[newValue.zone[0].designation] = (
              <UseCellDisplay params={params} showForm={showForm} />
            );
            return outValue;
          }, {});
          newRow.id = row.use[0].id;
          return { ...newRow, Use: row.use };
        })
      );
    }
  }, [grid]);

  const showForm = (params) => {
    setAddLink(true);
    if (params.link[0]) {
      setInitValues(params.link[0]);
    } else {
      let link = {
        use: [{ id: params.use[0].id }],
        zone: [{ id: params.zone[0].id }],
      };
      setInitValues(link);
    }
  };

  const closeForm = () => {
    setAddLink(false);
  };

  return (
    <React.Fragment>
      <UseGridMatrixDisplay
        grid={{ list: grid, filter: fetchMatrix, loading: gridLoading }}
        initValues={{ get: initValues, set: setInitValues }}
        addLink={addLink}
        showForm={showForm}
        closeForm={closeForm}
        columns={columns}
        rows={rows}
        filters={{ get: filters, set: setFilters }}
        linkTypes={linkTypes}
        rowTotal={rowTotal}
        requery={requery}
        jurisdictionID={data.get.id}
      />
    </React.Fragment>
  );
};

UseGridMatrixContainer.propTypes = {
  data: GetSet,
};

export default UseGridMatrixContainer;
