import React from "react";
import * as PropTypes from "prop-types";
import WorxDataGridDisplay from "../WorxDataGrid/WorxDataGridDisplay";
import Card from "@material-ui/core/Card";
import {makeStyles} from "@mui/styles";
import { CardContent, Typography } from "@material-ui/core";
import {Button} from "@mui/material";


const topCardUseStyles = makeStyles({
  root: {
    "& .MuiDataGrid-cell": {
      whiteSpace: "normal !important",
    },
    marginBottom: "2em",
  },
});

const CommercialDisplay = (props) => {
  const {id, columns, rows, loading, release} = props;

  const topCardClasses = topCardUseStyles();

  return <Card classes={{root: topCardClasses.root}} raised={true}>
    <CardContent>
      <WorxDataGridDisplay columns={columns} rows={rows} loading={loading} />
      <Button variant="contained" color="primary" onClick={() => {
        release({variables: {data: {order: [{id}]}}});
      }}>{"Release"}</Button>
    </CardContent>
  </Card>
}

CommercialDisplay.propTypes = {
  id: PropTypes.number,
  columns: PropTypes.array.isRequired,
  rows: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  release: PropTypes.func,
}

export default CommercialDisplay
