import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles, Typography } from "@material-ui/core";
import * as PropTypes from "prop-types";
import { GetSet, ListFilter } from "../../../../Constants/Jurisdiction";
import TextField from "@material-ui/core/TextField/TextField";
import Dialog from "@material-ui/core/Dialog/Dialog";
import RequirementsCellFormContainer from "../../../../Container/DataEntry/DataEntryThree_RequirmentsWorkflow/Forms/RequirementsCellFormContainer";

const useStyles = makeStyles({
  worxRadioGroupStyleOverride: {
    marginBottom: "1em",
  },
  worxSpaceAround: {
    margin: "0 1em",
  },
  textfieldWrapper: {
    marginBottom: "1em",
  },
});

const topCardUseStyles = makeStyles({
  root: {
    "& .MuiDataGrid-cell": {
      whiteSpace: "normal !important",
    },
    marginBottom: "2em",
  },
});

const RequirementsGridMatrixDisplay = (props) => {
  const {
    grid,
    rows,
    columns,
    closeForm,
    addLink,
    step,
    rowTotal,
    filters,
    requery = {},
    initValues,
    jurisdictionID,
  } = props;

  let pageSize = 25;

  const classes = useStyles();
  const topCardClasses = topCardUseStyles();

  return (
    <React.Fragment>
      <Typography variant={"h5"} gutterBottom>
        Requirements Matrix {`(Step ${step + 1})`}
      </Typography>

      <Dialog
        id={"addLinkForm"}
        open={addLink}
        onClose={closeForm}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <RequirementsCellFormContainer
          initValues={initValues}
          jurisdictionID={jurisdictionID}
          filters={filters}
          requery={requery}
          closeForm={closeForm}
          grid={grid}
        />
      </Dialog>

      <div className={classes.textfieldWrapper}>
        <TextField
          margin="normal"
          id={"requirement"}
          label={"Requirement"}
          onChange={(event) => {
            let nextFilter = {
              ...filters.get,
              requirement: event.target.value,
              offset: 0,
            };
            grid.filter({ variables: nextFilter });
            filters.set(nextFilter);
          }}
          className={classes.worxSpaceAround}
        />
        <TextField
          margin="normal"
          id={"zone"}
          label={"Zone"}
          onChange={(event) => {
            let nextFilter = {
              ...filters.get,
              zone: event.target.value,
              offset: 0,
            };
            grid.filter({ variables: nextFilter });
            filters.set(nextFilter);
          }}
          className={classes.worxSpaceAround}
        />
      </div>
      <DataGrid
        classes={{ root: topCardClasses.root }}
        pagination
        checkboxSelection={false}
        rowCount={rowTotal}
        autoHeight={true}
        getRowHeight={() => "auto"}
        columns={columns}
        rows={rows}
        pageSize={pageSize}
        paginationMode="server"
        onPageChange={(page) => {
          let nextFilter = { ...filters.get, offset: pageSize * page };
          grid.filter({ variables: nextFilter });
          filters.set(nextFilter);
        }}
        loading={!grid.list}
        className={classes.worxSpaceAround}
      />
    </React.Fragment>
  );
};

RequirementsGridMatrixDisplay.propTypes = {
  grid: ListFilter,
  initValues: GetSet,
  addLink: PropTypes.bool,
  showForm: PropTypes.func,
  closeForm: PropTypes.func,
  rowTotal: PropTypes.number.isRequired,
  columns: PropTypes.array,
  rows: PropTypes.array,
  filters: GetSet,
  step: PropTypes.number.isRequired,
  requery: PropTypes.object,
  jurisdictionID: PropTypes.number,
};

export default RequirementsGridMatrixDisplay;
