import { Route, Switch, useRouteMatch } from "react-router-dom";
import JurisdictionsContainer from "../Container/JurisdictionsContainer";
import React from "react";
import JurisdictionEditContainer from "../Container/DataEntry/DataEntryOne_MainWorkflow/JurisdictionEditContainer";
import WorkflowRouter from "./WorkflowRouter";

const JurisdictionsRouter = () => {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.url}/locality`}>
        <JurisdictionsContainer type={"Locality"} />
      </Route>
      <Route path={`${match.url}/administrative-area`}>
        <JurisdictionsContainer type={"AdministrativeArea"} />
      </Route>
      <Route path={`${match.url}/subdivision`}>
        <JurisdictionsContainer type={"Subdivision"} />
      </Route>
      <Route path={`${match.url}/country`}>
        <JurisdictionsContainer type={"Country"} />
      </Route>
      <Route path={`${match.url}/create`}>
        <JurisdictionEditContainer />
      </Route>
      <Route path={`${match.url}/workflow`}>
        <WorkflowRouter />
      </Route>
      {/*<Route path={`${match.url}/`}>
      <JurisdictionsContainer type={'All'}/>
    </Route>*/}
    </Switch>
  );
};

export default JurisdictionsRouter;
