import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles, Typography } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import {
  AutoCompleteFilter,
  GetSet,
  ListFilter,
} from "../../../../Constants/Jurisdiction";
import * as PropTypes from "prop-types";
import WorxCKEditorFormik from "../../../Forms/Inputs/WorxCKEditorFormik";
import WorxTextField from "../../../Forms/Inputs/WorxTextField";
import WorxAutocompleteFormik from "../../../Forms/Inputs/WorxAutocompleteFormik";
import WorxRadio from "../../../Forms/Inputs/WorxRadio";
import TextField from "@material-ui/core/TextField/TextField";

const useStyles = makeStyles({
  worxRadioGroupStyleOverride: {
    marginBottom: "1em",
  },
  buttonWrapper: {
    marginTop: "1em",
    textAlign: "right",
  },
});

const buttonUseStyle = makeStyles({
  root: {
    marginLeft: "1em",
  },
});

const topCardUseStyles = makeStyles({
  root: {
    "& .MuiDataGrid-cell": {
      whiteSpace: "normal !important",
    },
    marginBottom: "2em",
  },
});

const ParkingFormDisplay = (props) => {
  let {
    anchorEl,
    uses,
    columns,
    rowCount,
    parkingOptions,
    zoneOptions,
    deleteParking,
    pageSize,
    filters,
    currentPage,
    formik,
  } = props;

  const classes = useStyles();
  const buttonClasses = buttonUseStyle();
  const topCardClasses = topCardUseStyles();

  return (
    <React.Fragment>
      <Typography variant={"h5"} gutterBottom>
        Parking
      </Typography>
      {anchorEl.get && (
        <form>
          <WorxTextField fieldKey={"name"} formik={formik} />
          <WorxAutocompleteFormik
            fieldKey={"zone"}
            nameKey={"designation"}
            options={zoneOptions}
            formik={formik}
          />
          <WorxAutocompleteFormik
            fieldKey={"parking"}
            options={parkingOptions}
            formik={formik}
          />
          <WorxCKEditorFormik fieldKey={"description"} formik={formik} />
          <WorxRadio
            fieldKey={"min_max"}
            options={[
              { id: "min", name: "Minimum" },
              { id: "max", name: "Maximum" },
            ]}
            formik={formik}
          />
          <WorxTextField fieldKey={"spaces"} formik={formik} />
          <WorxTextField fieldKey={"secondary_variable"} formik={formik} />
          <div className={classes.buttonWrapper}>
            <Button
              variant="contained"
              color="warning"
              onClick={() => {
                anchorEl.set(null);
                deleteParking(formik.values);
              }}
            >
              delete
            </Button>
            <Button
              variant={"contained"}
              color="secondary"
              margin="normal"
              onClick={() => {
                anchorEl.set(null);
                formik.resetForm();
              }}
              classes={{ root: buttonClasses.root }}
            >
              Cancel
            </Button>
            <Button
              variant={"contained"}
              color="primary"
              margin="normal"
              onClick={() => {
                formik.setFieldValue("button", "normal");
                formik.handleSubmit();
              }}
              classes={{ root: buttonClasses.root }}
            >
              Submit
            </Button>
            <Button
              variant={"contained"}
              color="primary"
              margin="normal"
              onClick={() => {
                formik.setFieldValue("button", "repeat");
                formik.handleSubmit();
              }}
              classes={{ root: buttonClasses.root }}
            >
              Submit Add Another
            </Button>
          </div>
        </form>
      )}

      {!anchorEl.get && (
        <div className={classes.worxDataGridWrapperStyleOverride}>
          <div>
            <TextField
              margin="normal"
              id={"name"}
              placeholder={"Name"}
              onChange={(event) => {
                let nextFilter = {
                  ...filters.get,
                  name: event.target.value,
                  operator: "CONTAINS",
                  offset: 0,
                };
                uses.filter({ variables: nextFilter });
                filters.set(nextFilter);
              }}
            />
          </div>
          <DataGrid
            classes={{ root: topCardClasses.root }}
            autoHeight={true}
            getRowHeight={() => "auto"}
            key={rowCount}
            page={currentPage.get}
            pagination
            paginationMode="server"
            rowCount={rowCount}
            pageSize={pageSize}
            onPageChange={(page) => {
              let nextFilter = { ...filters.get, offset: pageSize * page };
              uses.filter({ variables: nextFilter });
              filters.set(nextFilter);
              currentPage.set(page);
            }}
            loading={!uses.list}
            columns={columns}
            rows={uses.list ? uses.list : []}
          />
        </div>
      )}
    </React.Fragment>
  );
};

ParkingFormDisplay.propTypes = {
  anchorEl: GetSet,
  uses: ListFilter,
  columns: PropTypes.array,
  rowCount: PropTypes.number.isRequired,
  parkingOptions: AutoCompleteFilter,
  zoneOptions: AutoCompleteFilter,
  initValues: GetSet,
  deleteParking: PropTypes.func,
  pageSize: PropTypes.number.isRequired,
  filters: GetSet,
  currentPage: GetSet,
  formik: PropTypes.object.isRequired,
};

export default ParkingFormDisplay;
