import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import OrderDocumentsTabDisplay from "../../Display/Research/OrderDocumentsTabDisplay";
import Button from "@material-ui/core/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { getReportItem, researchOrderDocument } from "../../Queries/Mutations";

const OrderDocumentsTabContainer = (props) => {
  const { report_order_item, getReport } = props;

  const [workingOpen, setWorkingOpen] = useState(false);
  const [initialValues, setInitialValues] = useState({});

  const [doEditDocumentOrderItem] = useMutation(researchOrderDocument(), {
    refetchQueries: getReportItem(report_order_item.id),
  });

  const [columns, setColumns] = useState([
    {
      field: "id",
      headerName: "ID",
      width: 100,
      renderCell: Object.assign(
        (params) => {
          return (
            <Button
              onClick={() => {
                history.push({
                  pathname: "/data_entry/research/order/" + params.row.id,
                  state: { data: params.row },
                });
              }}
            >
              {params.row.id}
            </Button>
          );
        },
        { displayName: "renderCell" }
      ),
    },
    { field: "name", headerName: "Name", width: 300 },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: Object.assign(
        (params) => {
          return (
            <div>
              {!params.row.order_date && (
                <Button
                  variant={"contained"}
                  color="primary"
                  margin="normal"
                  onClick={() => {
                    setWorkingOpen(true);
                    setInitialValues({
                      doiID: params?.row?.id,
                      ...params?.row?.document_order_template?.[0],
                    });
                  }}
                >
                  Process
                </Button>
              )}
            </div>
          );
        },
        { displayName: "renderCell" }
      ),
    },
  ]);

  const defaultValues = {};

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...defaultValues, ...initialValues },
    validationSchema: Yup.object({}),
    onSubmit: (values, formikTools) => {
      /* eslint-disable no-unused-vars */
      let {
        doiID,
        email_body,
        email_subject,
        estimted_of_time,
        id,
        letter_body,
        order_date,
        ...otherStuff
      } = values;
      /* eslint-disable no-unused-vars */
      let payload = { id: doiID };
      if (email_body) {
        payload.email_body = email_body;
      }
      if (email_subject) {
        payload.email_subject = email_subject;
      }
      if (estimted_of_time) {
        payload.estimted_of_time = estimted_of_time;
      }
      if (letter_body) {
        payload.letter_body = letter_body;
      }
      if (order_date) {
        payload.order_date = order_date;
      }
      doEditDocumentOrderItem({ variables: { data: payload } });
      formikTools.resetForm();
      setWorkingOpen(false);
      getReport();
    },
  });

  const rows = report_order_item.document_order_items;

  useEffect(() => {
    //fetchTemplate();
  }, []);

  useEffect(() => {
    setColumns([
      {
        field: "id",
        headerName: "ID",
        width: 100,
        renderCell: Object.assign(
          (params) => {
            return (
              <Button
                onClick={() => {
                  history.push({
                    pathname: "/data_entry/research/order/" + params.row.id,
                    state: { data: params.row },
                  });
                }}
              >
                {params.row.id}
              </Button>
            );
          },
          { displayName: "renderCell" }
        ),
      },
      { field: "name", headerName: "Name", width: 300 },
      {
        field: "actions",
        headerName: "Actions",
        width: 200,
        renderCell: Object.assign(
          (params) => {
            return (
              <div>
                {!params.row.order_date && (
                  <Button
                    variant={"contained"}
                    color="primary"
                    margin="normal"
                    onClick={() => {
                      setWorkingOpen(true);
                      setInitialValues({
                        doiID: params?.row?.id,
                        ...params?.row?.document_order_template?.[0],
                      });
                    }}
                  >
                    Process
                  </Button>
                )}
              </div>
            );
          },
          { displayName: "renderCell" }
        ),
      },
    ]);
  }, [report_order_item]);

  return (
    <OrderDocumentsTabDisplay
      working={{ get: workingOpen, set: setWorkingOpen }}
      columns={columns}
      rows={rows}
      formik={formik}
    />
  );
};

OrderDocumentsTabContainer.propTypes = {
  report_order_item: PropTypes.object,
  getReport: PropTypes.func.isRequired,
};

export default OrderDocumentsTabContainer;
