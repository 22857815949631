import React, {
  useEffect,
  useState} from 'react';
import * as PropTypes from "prop-types";
import "./cssbaseline-overrides.css";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { ApolloLink, Observable } from "apollo-link";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "apollo-link-error";
import LoginFormContainer from "./Container/Forms/LoginFormContainer";
import { ThemeProvider } from "@material-ui/styles";
import Theme from "./theme";
import { GraphQLAPI } from "./Constants/Auth";
import { UserProvider } from "./Context/UserContext";
import useUserContextManager from "./Hooks/useUserContextManager";
import {HeaderTitleProvider} from "./Context/HeaderTitleContext";
import useHeaderTitleManager from "./Hooks/useHeaderTitleManager";
import {getRefreshToken} from "./tools/authentication";
import {MainContentWidthProvider} from "./Context/MainContentWidthContext";
import {MessageProvider} from "./Context/MessageContext";
import useMainContentWidthManager from "./Hooks/useMainContentWidthManager";
import { CssBaseline } from "@material-ui/core";
import useGridContextManager from "./Hooks/useGridContextManager";
import { GridProvider } from "./Context/GridContext";
import MainContentContainer from "./Container/MainContentContainer";
import useMessageManager from "./Hooks/useMessageManager";

const App = () => {
  const UserContext = useUserContextManager();
  const HeaderTitleContext = useHeaderTitleManager();
  const MessageContext = useMessageManager();
  const MainContentWidthContext = useMainContentWidthManager();
  const GridContext = useGridContextManager();

  //const [token] = useGetToken();
  //const [token, login] = useGetToken('admin', 'm3uaD4qUF5Ru458Q');
  //const [accessToken, setAccessToken] = useState(null);
  const [authorized, setAuthorized] = useState(false);
  const [myClient, setMyClient] = useState(null);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    let token = localStorage.getItem("access_token");
    if (token) {
      setAuthorized(true);
    }
  }, []);

  useEffect(() => {
    const cleanTypenameLink = new ApolloLink((operation, forward) => {
      if (operation.variables && !operation.variables.file) {
        // eslint-disable-next-line
        operation.variables = omitDeep(operation.variables, "__typename");
      }

      return forward(operation);
    });


    const httpLink = createUploadLink({
      uri: GraphQLAPI,
      // uri: 'http://drupal.docker.localhost/user/login?_format=json',
      //credentials: 'include',
    });

    const authLink = setContext((_, { headers }) => {
      // return the headers to the context so httpLink can read them
      let access_token = localStorage.getItem("access_token");
      return {
        headers: {
          ...headers,
          authorization: access_token ? `Bearer ${access_token}` : "",
        },
      };
    });

    const errorLink = onError(
      ({ graphQLErrors, networkError, operation, forward }) => {
        if (graphQLErrors) {
          setErrors(graphQLErrors);
          graphQLErrors.forEach(({ message, locations, path }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
          );
        }

        if (networkError) {
          setErrors(networkError);
          console.log(`[Network error]: ${networkError}`);
          console.log(networkError);
        }

        if (networkError?.statusCode && networkError.statusCode === 401) {
          return new Observable((observer) => {
            let refresh_token = localStorage.getItem("refresh_token");
            getRefreshToken(refresh_token)
              .then((refreshResponse) => {
                let access_token = refreshResponse.data.access_token;
                if (access_token) {
                  localStorage.setItem(
                    "access_token",
                    refreshResponse.data.access_token
                  );
                  localStorage.setItem(
                    "refresh_token",
                    refreshResponse.data.refresh_token
                  );

                  operation.setContext(({ headers = {} }) => ({
                    headers: {
                      // Re-add old headers
                      ...headers,
                      // Switch out old access token for new one
                      authorization: `Bearer ${access_token}` || null,
                    },
                  }));
                }
              })
              .then(() => {
                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                };

                // Retry last failed request
                forward(operation).subscribe(subscriber);
              })
              .catch((error) => {
                localStorage.removeItem("access_token");
                localStorage.removeItem("refresh_token");
                setAuthorized(false);
                observer.error(error);
              });
          });
        }
        if (networkError?.statusCode === 403) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("refresh_token");
          setAuthorized(false);
        }
      }
    );

    setMyClient(
      new ApolloClient({
        //link: authLink.concat(cleanTypenameLink).concat(httpLink),
        link: ApolloLink.from([
          cleanTypenameLink,
          errorLink,
          authLink,
          httpLink,
        ]),
        cache: new InMemoryCache(),
        connectToDevTools: true,
      })
    );
  }, [authorized]);

  return (
    <ThemeProvider theme={Theme}>
        <CssBaseline />
        <UserProvider value={UserContext}>
          <HeaderTitleProvider value={HeaderTitleContext}>
            <MessageProvider value={MessageContext}>
              <MainContentWidthProvider value={MainContentWidthContext}>
                <GridProvider value={GridContext}>
                  {myClient && (
                    <ApolloProvider client={myClient}>
                      {authorized && (
                        //<MainRouterContainer/>
                        <MainContentContainer errors={errors} />
                      )}
                      {!authorized && (
                        <LoginFormContainer
                          isAuthorized={{ get: authorized, set: setAuthorized }}
                        />
                      )}
                    </ApolloProvider>
                  )}
                </GridProvider>
              </MainContentWidthProvider>
            </MessageProvider>
          </HeaderTitleProvider>
        </UserProvider>
    </ThemeProvider>
  );
};

const omitDeepArrayWalk = (arr, key) => {
  return arr.map((val) => {
    if (Array.isArray(val)) return omitDeepArrayWalk(val, key);
    else if (typeof val === "object") return omitDeep(val, key);
    return val;
  });
};

const omitDeep = (obj, key) => {
  const keys = Object.keys(obj);
  const newObj = {};
  keys.forEach((i) => {
    if (i !== key) {
      const val = obj[i];
      if (val instanceof Date) newObj[i] = val;
      else if (Array.isArray(val)) newObj[i] = omitDeepArrayWalk(val, key);
      else if (typeof val === "object" && val !== null)
        newObj[i] = omitDeep(val, key);
      else newObj[i] = val;
    }
  });
  return newObj;
};

App.propTypes = {
  mocked: PropTypes.bool,
}

export default App;
