import React, { useContext, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { getOrdersShort } from "../../Queries/Queries";
import { GetSet } from "../../Constants/Jurisdiction";
import OrdersDisplay from "../../Display/Orders/OrdersDisplay";
import { useHistory } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@material-ui/core";
import MainContentWidthContext from "../../Context/MainContentWidthContext";
import HeaderTitleContext from "../../Context/HeaderTitleContext";

const OrdersContainer = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { data } = props;
  let [header, setHeader] = useContext(HeaderTitleContext);
  setHeader("Orders");

  // eslint-disable-next-line no-unused-vars
  const [columns, setColumns] = useState([
    {
      field: "id",
      headerName: "ID",
      width: 230,
      renderCell: Object.assign(
        (params) => {
          return (
            <Link aria-label={`row-id-${params.value}`} component={RouterLink} to={`/orders/${params.row.id}`}>
              {params.value}
            </Link>
          );
        },
        { displayName: "renderCell" }
      ),
    },
    {
      field: "order",
      headerName: "Client",
      width: 230,
      valueGetter: (value) => {
        return value.row.client[0].name;
      },
    },
    {
      field: "portfolio_name",
      headerName: "Portfolio",
      width: 230,
      valueGetter: (value) => {
        return value.value;
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 230,
      valueGetter: (value) => {
        return value?.row?.report_order_items?.[0]?.name;
      },
    },
    {
      field: "Address",
      headerName: "Address",
      width: 230,
      valueGetter: (value) => {
        return value?.row?.report_order_items?.[0]?.property?.[0]?.address
          .address_line1;
      },
    },
    {
      field: "City",
      headerName: "City",
      width: 230,
      valueGetter: (value) => {
        return value?.row?.report_order_items?.[0]?.property?.[0]?.address
          .locality;
      },
    },
    {
      field: "State",
      headerName: "State",
      width: 230,
      valueGetter: (value) => {
        return value?.row?.report_order_items?.[0]?.property?.[0]?.address
          .administrative_area;
      },
    },
    {
      field: "report_order_items",
      headerName: "Jurisdiction",
      width: 230,
      valueGetter: (value) => {
        return value?.value?.[0]?.jurisdiction?.[0]?.name;
      },
    },
    { field: "state", headerName: "Status", width: 230 },
    /*{ field: 'client', headerName: 'Client', width: 230,
      renderCell: Object.assign(params => {
        delete params.row.issues;
        return renderButton(params);
      }, { displayName: 'renderCell' })
    },*/
  ]);
  const [rows, setRows] = useState([]);
  const [filters, setFilters] = useState({
    limit: 25,
    offset: 0,
    use: "",
    zone: "",
    operator: "CONTAINS",
  });
  const [rowTotal, setRowTotal] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [filterOrders, { loading: ordersLoading, data: ordersQuery }] =
    useLazyQuery(getOrdersShort(), { variables: filters });

  const history = useHistory();
  const createNewOrder = () => {
    history.push({
      pathname: "/orders/add-new-order/",
    });
  };

  // eslint-disable-next-line no-unused-vars
  const [mainContentWidth, setMainContentWidth] = useContext(
    MainContentWidthContext
  );
  setMainContentWidth("80");

  useEffect(() => {
    filterOrders();
  }, [header]);

  useEffect(() => {
    if (ordersQuery?.orders?.count) {
      setRowTotal(ordersQuery?.orders?.count);
    }

    if (ordersQuery?.orders?.items) {
      setRows(ordersQuery?.orders?.items);
      setRowTotal(ordersQuery?.orders?.total);
    }
  }, [ordersQuery]);

  return (
    <React.Fragment>
      <OrdersDisplay
        orders={{ list: rows, filter: filterOrders, loading: ordersLoading }}
        columns={columns}
        rows={rows}
        filters={{ get: filters, set: setFilters }}
        currentPage={{ get: currentPage, set: setCurrentPage }}
        rowTotal={rowTotal}
        createNewOrder={createNewOrder}
      />
    </React.Fragment>
  );
};

OrdersContainer.propTypes = {
  data: GetSet,
};

export default OrdersContainer;
