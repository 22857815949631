import React from "react";
import Card from "@material-ui/core/Card";
import { CardContent, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { PropTypes } from "prop-types";
import { GetSet, ListFilter } from "../../Constants/Jurisdiction";
import { makeStyles } from "@mui/styles";
import ClaimedItemsContainer from "../../Container/Queue/ClaimedItemsContainer";
import WorxDataGridDisplay from "../WorxDataGrid/WorxDataGridDisplay";
//import MainLayout from "../../temp/MainLayout";

const buttonUseStyles = makeStyles({
  root: {
    margin: "0 1em 1em 0",
  },
});

const typographyUseStyles = makeStyles({
  root: {
    marginTop: "1em",
  },
});

const topCardUseStyles = makeStyles({
  root: {
    "& .MuiDataGrid-cell": {
      whiteSpace: "normal !important",
    },
    marginBottom: "2em",
  },
});

const QueueDisplay = (props) => {
  const {
    type,
    columns,
    columnsWithAction,
    rows,
    pageSize,
    rowTotal,
    currentPage,
    filters,
    claimItem,
    claimRoute,
    itemsQuery,
    claimedItemsQuery,
    claimedItemsQueryKey,
    release,
    getReleaseKey,
    paginationMode,
    fixedFilters,
    loading,
  } = props;

  const buttonClasses = buttonUseStyles();
  const typographyClasses = typographyUseStyles();
  const topCardClasses = topCardUseStyles();

  return <>
    <Card classes={{root: topCardClasses.root}} raised={true}>
      <CardContent>
        <Button variant="contained" color="primary" onClick={() => claimItem()} classes={{root: buttonClasses.root}}>Claim {type}</Button>
        <ClaimedItemsContainer columns={columns} query={claimedItemsQuery} queryKey={claimedItemsQueryKey} claimRoute={claimRoute} release={release} queueQuery={itemsQuery} getReleaseKey={getReleaseKey} fixedFilters={fixedFilters} />
        <section>
          <Typography variant="h5" gutterBottom classes={{root: typographyClasses.root}}>Queue</Typography>
          <WorxDataGridDisplay columns={columnsWithAction} rows={rows} pageSize={pageSize} filters={filters} rowTotal={rowTotal} currentPage={currentPage} paginationMode={paginationMode} loading={loading} />
        </section>
        </CardContent>
    </Card>
  </>
};

QueueDisplay.propTypes = {
  type: PropTypes.string,
  columns: PropTypes.array,
  columnsWithAction: PropTypes.array,
  rows: ListFilter,
  pageSize: PropTypes.number,
  filters: GetSet,
  currentPage: GetSet,
  rowTotal: PropTypes.number,
  claimItem: PropTypes.func,
  claimRoute: PropTypes.string,
  itemsQuery: PropTypes.func,
  claimedItemsQuery: PropTypes.func,
  claimedItemsQueryKey: PropTypes.string,
  release: PropTypes.func,
  getReleaseKey: PropTypes.func,
  paginationMode: PropTypes.string,
  fixedFilters: PropTypes.object,
  loading: PropTypes.bool,
};

export default QueueDisplay;
