import { FormLabel } from "@material-ui/core";
import {Checkbox} from '@material-ui/core';
import React from "react";
import {FormControlLabel} from "@mui/material";
import * as PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";

export const roles = ['data_entry_1', 'data_entry_2', 'data_entry_3', 'administrator'];

export default function DevTools({activeRoles, setActiveRoles}) {
    return <section style={{position: 'fixed', bottom: '0', backgroundColor: 'white', zIndex: '99999'}}>
        <FormLabel>
            Roles
        </FormLabel>
        {roles.map((role) => {
            return <FormControlLabel key={role} control={<Checkbox
              id={role}
              value={role}
              checked={activeRoles ? activeRoles.some(r => r === role) : false}
              onChange={(event) => {
                  // eslint-disable-next-line no-unused-vars
                  const selectedRoles = [...activeRoles.filter(r => r !== event.target.value)];
                  if(event.target.checked) {
                      selectedRoles.push(event.target.value);
                  }
                  setActiveRoles(selectedRoles);
                  location.reload();
              }}
              color="primary"
            />} label={role} />
        })}
        <Button variant="contained" color="primary" onClick={() => {location.reload()}}>↻</Button>
    </section>
}

DevTools.propTypes = {
    activeRoles: PropTypes.array,
    setActiveRoles: PropTypes.func,
}
