import React, { useContext, useEffect, useState } from "react";
// eslint-disable-next-line no-unused-vars
import * as PropTypes from "prop-types";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  getAnalystReport,
  getPropertyTypeValues,
  getZonesForJurisdiction,
} from "../../Queries/Queries";
import { useFormik } from "formik";
import * as Yup from "yup";
import AnalystPropertyStatusDisplay from "../../Display/Analyst/AnalystPropertyStatusDisplay";
import { editProperty } from "../../Queries/Mutations";
import MessageContext from "../../Context/MessageContext";

const AnalystPropertyStatusContainer = (props) => {
  const { id } = props;

  const [getReport, { data: report }] = useLazyQuery(getAnalystReport(id));
  const [getZones, { data: zones }] = useLazyQuery(getZonesForJurisdiction());
  const [getPropertyTypes, { data: propertyTypes }] = useLazyQuery(
    getPropertyTypeValues()
  );

  // eslint-disable-next-line no-unused-vars
  const [initValues, setInitValues] = useState({});
  const [propertyTypeOpen, setPropertyTypeOpen] = useState(false);
  const [zoneOpen, setZoneOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [message, setMessage] = useContext(MessageContext);

  const [doEditReport, { data: response }] = useMutation(editProperty());

  // setup
  useEffect(() => {
    getReport();
    getPropertyTypes();
    setMessage(null);
  }, []);

  // report
  useEffect(() => {
    if (report) {
      setInitValues(
        report.analystReport.conformance_report_order_item[0].property[0]
      );
      getZones({
        variables: {
          jurisdiction:
            report.analystReport.conformance_report_order_item[0]
              .jurisdiction[0].id,
        },
      });
    }
  }, [report]);

  // response
  useEffect(() => {
    if (response) {
      setInitValues(
        report.analystReport.conformance_report_order_item[0].property[0]
      );
    }
  }, [response]);

  const defaultValues = {};

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...initValues, ...defaultValues },
    validationSchema: Yup.object({}),
    // eslint-disable-next-line no-unused-vars
    onSubmit: (values, formikTools) => {
      doEditReport({ variables: { data: values } });
      setMessage("✓ Property Status Saved");
    },
  });

  return (
    <AnalystPropertyStatusDisplay
      options={{
        property_type: {
          open: {
            get: propertyTypeOpen,
            set: setPropertyTypeOpen,
          },
          options: {
            list: propertyTypes?.getPropertyTypeValues
              ? propertyTypes?.getPropertyTypeValues.map((item) => {
                  return { id: item.id, name: item.label };
                })
              : [],
            filter: getPropertyTypes,
          },
        },
        zone: {
          open: {
            get: zoneOpen,
            set: setZoneOpen,
          },
          options: {
            list: zones?.zones?.items ?? [],
            filter: getZones,
          },
        },
      }}
      id={id}
      formik={formik}
    />
  );
};

AnalystPropertyStatusContainer.propTypes = {
  id: PropTypes.number.isRequired,
  page: PropTypes.string,
};

export default AnalystPropertyStatusContainer;
