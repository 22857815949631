import React from "react";
// eslint-disable-next-line no-unused-vars
import * as PropTypes from "prop-types";
import { GetSet } from "../../../Constants/Jurisdiction";
import DocumentOrderItemFormContainer from "./DocumentOrderItemFormContainer";
import OrderItemFormContainer from "./OrderItemFormContainer";
import AdjustmentFormContainer from "./AdjustmentFormContainer";

const OrderItemManagementContainer = (props) => {
  // eslint-disable-next-line no-unused-vars
  let { initValues, edit } = props;
  let type = initValues.get.__typename;

  switch (type) {
    case "ConformanceReportOrderItem":
      return <OrderItemFormContainer {...props} />;
    case "DocumentOrderItem":
      return <DocumentOrderItemFormContainer {...props} />;
    case "Adjustment":
      return <AdjustmentFormContainer {...props} />;
    default:
      return (
        <React.Fragment>
          <div>{"Please select a item type."}</div>
        </React.Fragment>
      );
  }
};

OrderItemManagementContainer.propTypes = {
  referenceData: PropTypes.object,
  initValues: GetSet,
  availableReports: GetSet,
  edit: GetSet,
};

export default OrderItemManagementContainer;
