import React, { useEffect } from "react";
import * as PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import WorxFileUploadDisplay from "../../../Display/Forms/Inputs/WorxFileUploadDisplay";
import { uploadFile } from "../../../Queries/Mutations";

const WorxFileUpload = (props) => {
  let {
    formik,
    mediaField,
    disabled = false,
    entityType,
    fieldKey,
    type = "document",
  } = props;

  const [doUploadFile, { data: uploadedFileQuery }] = useMutation(uploadFile());

  useEffect(() => {
    if (uploadedFileQuery?.uploadFile?.file) {
      formik.setFieldValue(fieldKey, [uploadedFileQuery.uploadFile.file]);
    }

    if (uploadedFileQuery?.uploadFile?.errors[0]?.message) {
      formik.setErrors({
        fieldKey: uploadedFileQuery.uploadFile.errors[0]["message"],
      });
    }
  }, [uploadedFileQuery]);

  return (
    <WorxFileUploadDisplay
      fieldKey={fieldKey}
      disabled={disabled}
      entityType={entityType}
      mediaField={mediaField}
      doUploadFile={doUploadFile}
      type={type}
      formik={formik}
    />
  );
};

WorxFileUpload.propTypes = {
  mediaField: PropTypes.string,
  fieldKey: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  entityType: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
};

export default WorxFileUpload;
