import React from "react";
import * as PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@mui/styles";

const buttonUseStyles = makeStyles({
  root: {
    marginLeft: "1em",
  },
  blue: {
    background: "#5aacee",
    color: "white",
  },
  green: {
    background: "#33a532",
    color: "white",
  },
});

const UseCellDisplay = (props) => {
  const { params, showForm } = props;
  const buttonClasses = buttonUseStyles();

  return (
    <React.Fragment>
      {params.link?.[0]?.status?.[0]?.name ? (
        <Button
          variant="contained"
          className={
            params?.link?.[0]?.footnote.length > 0
              ? buttonClasses.green
              : buttonClasses.blue
          }
          onClick={() => showForm(params)}
        >
          {params.link[0].status[0].name}
        </Button>
      ) : (
        <Button onClick={() => showForm(params)}>+</Button>
      )}
    </React.Fragment>
  );
};

UseCellDisplay.propTypes = {
  params: PropTypes.object.isRequired,
  showForm: PropTypes.func.isRequired,
};

export default UseCellDisplay;
