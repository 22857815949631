import React, { useContext, useEffect, useState } from "react";
import JurisdictionEditDisplay from "../../../Display/DataEntry/DataEntryOne_MainWorkflow/JurisdictionEditDisplay";
import * as PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {getClaimedQueueItems, getCodeSectionTypes, getJurisdiction} from "../../../Queries/Queries";
import HeaderTitleContext from "../../../Context/HeaderTitleContext";
import MainContentWidthContext from "../../../Context/MainContentWidthContext";
import { deleteQueueItem, releaseQueueItem } from "../../../Queries/Mutations";

const JurisdictionEditContainer = (props) => {
  const { id } = props;

  //let location = useLocation();
  const [steps, setSteps] = useState([
    "Jurisdiction Info",
    "Zoning District Categories",
    "Zoning Districts / Overlays / PUD’s",
    "Use Categories",
    "Uses",
  ]);
  const [step, setStep] = useState(0);
  const [renderKey, setRenderKey] = useState(0);
  const history = useHistory();

  if (step > steps.length) {
    setStep(steps.length);
  }

  const [filterJurisdiction, { data: jurisdictionQuery }] = useLazyQuery(
    getJurisdiction()
  );

  //release queue item
  const [data, setData] = useState(null);
  const [releaseJurisdiction, { data: releasedJurisdiction }] = useMutation(
    releaseQueueItem("main_workflow", id), {refetchQueries: [{query: getClaimedQueueItems(), variables: {queue: 'main_workflow'}}]}
  );
  const [removeJurisdiction, { data: removedJurisdiction }] = useMutation(
    deleteQueueItem("main_workflow", id), {refetchQueries: [{query: getClaimedQueueItems(), variables: {queue: 'main_workflow'}}]}
  );

  const codeStart = 5;
  const lastStep = 18;
  const { data: codeSectionTypes } = useQuery(getCodeSectionTypes());
  const [codeTypes, setCodeTypes] = useState([]);

  useEffect(() => {
    filterJurisdiction({ variables: { id } });
  }, []);

  useEffect(() => {
    if (jurisdictionQuery) {
      setData(jurisdictionQuery.jurisdiction);
    }
  }, [jurisdictionQuery]);

  useEffect(() => {
    if (codeSectionTypes?.codeSectionTypeOptions) {
      setCodeTypes(codeSectionTypes.codeSectionTypeOptions);
      setSteps([
        ...steps,
        ...codeSectionTypes.codeSectionTypeOptions.map((type) => type.name),
      ]);
    }
  }, [codeSectionTypes]);

  useEffect(() => {
    if (releasedJurisdiction) {
      history.push({
        pathname: "/data_entry/data_entry_1",
      });
    }
  }, [releasedJurisdiction]);

  useEffect(() => {
    if (removedJurisdiction) {
      history.push({
        pathname: "/data_entry/data_entry_1",
      });
    }
  }, [removedJurisdiction]);

  // use this to make sure the render key never repeats when the step changes
  const setActiveStep = (step) => {
    setStep(step);
    setRenderKey(renderKey + 1);
  };

  // eslint-disable-next-line no-unused-vars
  const [mainContentWidth, setMainContentWidth] = useContext(
    MainContentWidthContext
  );

  switch (step) {
    case 2:
      setMainContentWidth("80");
      break;
    case 4:
      setMainContentWidth("80");
      break;
  }

  // eslint-disable-next-line no-unused-vars
  const [headerTitle, setHeaderTitle] = useContext(HeaderTitleContext);

  return (
    data && (
      <JurisdictionEditDisplay
        data={{ get: data, set: setData }}
        steps={steps}
        step={{ get: step, set: setActiveStep }}
        releaseJurisdiction={releaseJurisdiction}
        removeJurisdiction={removeJurisdiction}
        codeTypes={codeTypes}
        codeSectionTypes={codeSectionTypes}
        codeStart={codeStart}
        lastStep={lastStep}
        renderKey={renderKey}
        setHeaderTitle={setHeaderTitle}
      />
    )
  );
};

JurisdictionEditContainer.propTypes = {
  id: PropTypes.number,
  data: PropTypes.any,
};

export default JurisdictionEditContainer;
