import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { format, parseISO } from "date-fns";
import React from "react";
import * as PropTypes from "prop-types";
import { camelToTitle, underscoreToCamel } from "../../../tools/stringTools";

const WorxDatePicker = (props) => {
  const {
    fieldKey,
    label = camelToTitle(underscoreToCamel(fieldKey)),
    disabled = false,
    formik,
  } = props;

  let value = formik.values;

  for (let el of fieldKey.split(".")) {
    if (value && value?.[el]) {
      value = value?.[el];
    } else {
      value = null;
    }
  }

  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          margin="normal"
          id={fieldKey}
          disabled={disabled}
          format="yyyy-MM-dd"
          value={value ? parseISO(value) : null}
          label={label}
          onChange={(newValue) => {
            formik.setFieldValue(fieldKey, format(newValue, "yyyy-MM-dd"));
          }}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

WorxDatePicker.propTypes = {
  fieldKey: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  formik: PropTypes.object.isRequired,
};

export default WorxDatePicker;
