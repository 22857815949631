import React, { useContext, useEffect } from "react";
import * as PropTypes from "prop-types";
import { useLazyQuery } from "@apollo/client";
import { getOrder } from "../../../Queries/Queries";
import ClientPortalOrderDisplay from "../../../Display/ClientPortal/Forms/ClientPortalOrderDisplay";
import HeaderTitleContext from "../../../Context/HeaderTitleContext";
import MainContentWidthContext from "../../../Context/MainContentWidthContext";

const ClientPortalOrderContainer = (props) => {
  const { id } = props;

  // eslint-disable-next-line no-unused-vars
  const [headerTitle, setHeaderTitle] = useContext(HeaderTitleContext);
  setHeaderTitle("Order Details");

  // eslint-disable-next-line no-unused-vars
  const [mainContentWidth, setMainContentWidth] = useContext(
    MainContentWidthContext
  );
  setMainContentWidth("50");

  const [fetchOrder, { data: orderQuery }] = useLazyQuery(getOrder(id));

  useEffect(() => {
    fetchOrder();
  }, []);

  return <ClientPortalOrderDisplay order={orderQuery?.order} />;
};

ClientPortalOrderContainer.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ClientPortalOrderContainer;
