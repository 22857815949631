import React from "react";
import * as PropTypes from "prop-types";
import WorxAutocomplete from "../Forms/Inputs/WorxAutocomplete";
import { GetSet } from "../../Constants/Jurisdiction";
import { camelToTitle } from "../../tools/stringTools";

const DisplayCellDisplay = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { index, openDisplay, params, formikRemote, formik } = props;

  let id = formik?.values?.display;
  let name = formik?.values?.display;

  if (!id || id.length === 0) {
    id = "no";
  }

  if (!name || name.length === 0) {
    name = "No";
  }

  return (
    <WorxAutocomplete
      fieldKey={""}
      value={{ id: id.toLowerCase(), name: camelToTitle(name).trim() }}
      onChange={(event, value) => {
        if (value) {
          formik.setFieldValue(`display`, value.id);
          formikRemote.setFieldValue(`${index}.display`, value.id);
        } else {
          formik.setFieldValue(`display`, null);
          formik.setFieldValue(`${index}.display`, null);
        }
      }}
      options={{
        open: openDisplay,
        options: {
          list: [
            { id: "yes", name: "Yes" },
            { id: "no", name: "No" },
          ],
          filter: () => {},
        },
      }}
      width={120}
    />
  );
};

DisplayCellDisplay.propTypes = {
  index: PropTypes.number.isRequired,
  openDisplay: GetSet,
  params: PropTypes.object.isRequired,
  formikRemote: PropTypes.object.isRequired,
  formik: PropTypes.object.isRequired,
};

export default DisplayCellDisplay;
