import React, { useContext } from "react";
// eslint-disable-next-line no-unused-vars
import * as PropTypes from "prop-types";
import MessageDisplay from "../Display/MessageDisplay";
import MessageContext from "../Context/MessageContext";

// eslint-disable-next-line no-unused-vars
const MessageContainer = (props) => {
  const [message] = useContext(MessageContext);

  return <MessageDisplay message={message} />;
};

MessageContainer.propTypes = {};

export default MessageContainer;
