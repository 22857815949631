import React, { useContext, useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  getTenantFieldsToDisplay,
  getUsesForJurisdiction,
} from "../../../Queries/Queries";
import MainContentWidthContext from "../../../Context/MainContentWidthContext";
import JuniorAnalystTenantFormDisplay from "../../../Display/JuniorAnalyst/Forms/JuniorAnalystTenantFormDisplay";
import { createTenant, editTenant } from "../../../Queries/Mutations";

const JuniorAnalystTenantFormContainer = (props) => {
  /* eslint-disable no-unused-vars */
  const {
    documentID,
    jurisdiction,
    callback = () => {
      /*do nothing*/
    },
    initValues = {},
  } = props;
  /* eslint-disable no-unused-vars */
  const [openUse, setOpenUse] = useState(false);
  //const [initValues, setInitValues] = useState(false);
  const [use, setUse] = useState();
  const [fetchUse, { data: useQuery }] = useLazyQuery(
    getUsesForJurisdiction(jurisdiction)
  );
  const [fetchFields, { data: fieldsQuery }] = useLazyQuery(
    getTenantFieldsToDisplay()
  );
  const [doCreateTenant, { data: tenantResponse }] = useMutation(
    createTenant()
  );
  const [doEditTenant, { data: tenantEditResponse }] = useMutation(
    editTenant()
  );

  const defaultValues = {
    __typename: "tenant",
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...defaultValues, ...initValues },
    validationSchema: Yup.object({}),
    // eslint-disable-next-line no-unused-vars
    onSubmit: (values, formikTools) => {
      if (values.id) {
        doEditTenant({ variables: { data: values } });
      } else {
        doCreateTenant({ variables: { data: values } });
      }
      //callback(values);
    },
  });

  // eslint-disable-next-line no-unused-vars
  const [mainContentWidth, setMainContentWidth] = useContext(
    MainContentWidthContext
  );
  setMainContentWidth("80");

  useEffect(() => {
    fetchUse();
  }, []);

  /*const keyMap = {
    'Requirement ID': 'id',
    'Zone': 'zone',
    'Name': 'name',
    'Label': 'label',
    '': 'min_max',
    'Requirement': 'requirement',
    'Existing': 'existing',
    'Difference': 'difference',
    'Display': 'display'
  };*/

  useEffect(() => {
    if (formik?.values?.use?.[0]?.id && formik?.values?.use?.[0]?.id !== use) {
      setUse(formik?.values?.use?.[0]?.id);
      fetchFields({ variables: { use: formik.values.use[0].id } });
    }
  }, [formik]);

  useEffect(() => {
    if (tenantResponse) {
      callback({ ...formik.values, ...tenantResponse.createTenant.tenant });
    }
  }, [tenantResponse]);

  useEffect(() => {
    if (tenantEditResponse) {
      callback({ ...formik.values, ...tenantEditResponse.editTenant.tenant });
    }
  }, [tenantEditResponse]);

  return (
    <JuniorAnalystTenantFormDisplay
      options={{
        use: {
          open: {
            get: openUse,
            set: setOpenUse,
          },
          options: {
            list: useQuery?.uses?.items ? useQuery?.uses?.items : [],
            filter: fetchUse,
          },
        },
      }}
      fields={
        fieldsQuery?.getTenantFieldsToDisplay
          ? fieldsQuery?.getTenantFieldsToDisplay
          : []
      }
      formik={formik}
    />
  );
};

JuniorAnalystTenantFormContainer.propTypes = {
  documentID: PropTypes.number.isRequired,
  jurisdiction: PropTypes.number.isRequired,
  initValues: PropTypes.object,
  callback: PropTypes.func,
};

export default JuniorAnalystTenantFormContainer;
