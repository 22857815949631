export const worxAddress = `
  address_line1
  address_line2
  administrative_area
  country_code
  locality
  postal_code
`;

export const worxFile = `
  filename
  filename
  filepath
  id
`;

export const worxReference = `
  id
  name
`;

//Sub Queries for fragments
export const Locality = `
  ... on Locality {
    subdivision {
      id
      name
    }
    locality_type {
      id
      name
    }
    code_definition {
      url
      link_text
    }
    gis_map {
      link_text
      url
    }
    parcel_map {
      link_text
      url
    }
  }
`;

export const Subdivision = `
  ... on Subdivision {
    administrative_area {
      id
      name
    }
    subdivision_type {
      id
      name
    }
    code_definition {
      url
      link_text
    }
    gis_map {
      link_text
      url
    }
    parcel_map {
      link_text
      url
    }
  }
`;

export const AdministrativeArea = `
  ... on AdministrativeArea {
    country {
      id
      name
    }
    administrative_area_type {
      id
      name
    }
    code_definition {
      url
      link_text
    }
  }
`;

export const Country = `
  ... on Country {
    name,
    id
    code_definition {
      url
      link_text
    }
  }`;

export const Zone = `
  ... on Zone {
    id
    name
    category {
      id
      name
    }
    description
    designation
    type
    code_file {
      id
      filename
      filepath
    }
    code_date
  }`;

export const Overlay = ` 
  ... on Overlay {
    id
    name
    category {
      id
      name
    }
    description
    designation
    type
    code_file {
      id
      filename
      filepath
    }
    code_date
  }`;

export const PD = `
  ... on PD {
    id
    name
    category {
      id
      name
    }
    description
    designation
    type
    code_file {
      id
      filename
      filepath
    }
    code_date
  }`;

export const ZoneUseLinkStatuses = `
  ... on ZoneUseLinkStatuses {
    id
    name
    description
    jurisdiction {
      id
      name
    }
    type
    parent {
      id
      name
    }
  }`;

export const EmailTemplate = `
  ... on EmailTemplate {
    ${worxReference}
    cost_per
    email_body
    email_subject
    estimate_of_time
    check_payable {${worxAddress}}
    contact {${worxReference}}
    instructions
    jurisdiction {${worxReference}}
    research_documents {${worxReference}}
    type
  }`;

export const FaxTemplate = `
  ... on FaxTemplate {
    ${worxReference}
    contact {${worxReference}}
    cost_per
    estimate_of_time
    instructions
    jurisdiction {${worxReference}}
  }`;

export const OnlineTemplate = `
  ... on OnlineTemplate {
    ${worxReference}
    cost_per
    estimate_of_time
    instructions
    jurisdiction {${worxReference}}
    research_documents {${worxReference}}
    type
  }`;

export const PhoneTemplate = `
  ... on PhoneTemplate {
    ${worxReference}
    contact {${worxReference}}
    cost_per
    estimate_of_time
    instructions
    jurisdiction {${worxReference}}
    research_documents {${worxReference}}
    type
  }`;

export const RunnerTemplate = `
  ... on RunnerTemplate {
    ${worxReference}
    check_payable {${worxAddress}}
    contact {${worxReference}}
    cost_per
    estimate_of_time
    instructions
    jurisdiction {${worxReference}}
    research_documents {${worxReference}}
    runner_contact {${worxReference}}
    runner_instructions
    runner_payable {${worxAddress}}
    type
  }`;

export const USPSTemplate = `
  ... on USPSTemplate {
    ${worxReference}
    check_payable {${worxAddress}}
    contact {${worxReference}}
    cost_per
    estimate_of_time
    instructions
    jurisdiction {${worxReference}}
    letter_body
    research_documents {${worxReference}}
    type
  }`;

export const Order = `
  id
  state
  client {
    ${worxReference}
    mail
    billing_information {
      id
      address {${worxAddress}}
      user {
        ${worxReference}
        mail
      }
    }
    contact_information {
      address {${worxAddress}}
      id
      user {
        ${worxReference}
        mail
      }
    }
    prepared_for {
      address {${worxAddress}}
      id
      user {
        ${worxReference}
        mail
      }
    }
  }
  prepared_for {
    id
    address {${worxAddress}}
  }
  adjustments {
    id
  }
  portfolio_name
`;

export const ZoningLetter = `
... on ZoningLetter {
  ${worxReference}
  letter_date
  notes
  document {
    document {${worxFile}}
    ${worxReference}
    document_type {${worxReference}}
  }
  property {${worxReference}}
  provider {${worxReference}}
  type
}`;

export const ZoningCodeViolation = `
  ... on ZoningCodeViolation {
    ${worxReference}
    letter_date
    notes
    document {
      document {${worxFile}}
      ${worxReference}
      document_type {${worxReference}}
    }
    property {${worxReference}}
    provider {${worxReference}}
    type
    zv_violations {${worxReference}}
    zv_violations_other
  }
`;

export const BuildingCodeViolation = `
  ... on BuildingCodeViolation {
    ${worxReference}
    notes
    bv_violations {${worxReference}}
    bv_violations_other
    letter_date
    document {
      document {${worxFile}}
      ${worxReference}
      document_type {${worxReference}}
    }
    property {${worxReference}}
    provider {${worxReference}}
    type
  }
`;

export const BusinessLicense = `
  ... on BusinessLicense {
    document {
      document {${worxFile}}
      ${worxReference}
      document_type {${worxReference}}
    }
    ${worxReference}
    letter_date
    notes
    property {${worxReference}}
    provider {${worxReference}}
    type
  }
`;

export const CertificateOfOccupancy = `
  ... on CertificateOfOccupancy {
    ${worxReference}
    building_number
    co_date
    co_number
    document {
      document {${worxFile}}
      ${worxReference}
      document_type {${worxReference}}
    }
    notes
    parking_required
    property {${worxReference}}
    provider {${worxReference}}
    square_footage
    tenant {${worxReference}}
    type
    use {${worxReference}}
  }
`;

export const Condemnation = `
  ... on Condemnation {
    ${worxReference}
    cn_plans {${worxReference}}
    cn_plans_other
    document {
      document {${worxFile}}
      ${worxReference}
      document_type {${worxReference}}
    }
    letter_date
    notes
    property {${worxReference}}
    provider {${worxReference}}
    type
  }
`;

export const ConditionalUsePermit = `
  ... on ConditionalUsePermit {
    ${worxReference}
    document {
      document {${worxFile}}
      ${worxReference}
      document_type {${worxReference}}
    }
    letter_date
    notes
    permit_number
    property {${worxReference}}
    provider {${worxReference}}
    type
  }
`;

export const FireCodeViolation = `
  ... on FireCodeViolation {
    ${worxReference}
    fv_violations {${worxReference}}
    fv_violations_other
    document {
      document {${worxFile}}
      ${worxReference}
      document_type {${worxReference}}
    }
    letter_date
    notes
    permit_number
    property {${worxReference}}
    provider {${worxReference}}
    type
  }
`;

export const HistoricalLandmark = `
  ... on HistoricalLandmark {
    ${worxReference}
    historical_landmark {${worxReference}}
    document {
      document {${worxFile}}
      ${worxReference}
      document_type {${worxReference}}
    }
    letter_date
    notes
    property {${worxReference}}
    provider {${worxReference}}
    type
  }
`;

export const SpecialUsePermit = `
  ... on SpecialUsePermit {
    ${worxReference}
    document {
      document {${worxFile}}
      ${worxReference}
      document_type {${worxReference}}
    }
    letter_date
    notes
    permit_number
    property {${worxReference}}
    provider {${worxReference}}
    type
  }
`;

export const Variance = `
  ... on Variance {
    ${worxReference}
    document {
      document {${worxFile}}
      ${worxReference}
      document_type {${worxReference}}
    }
    letter_date
    notes
    variance_number
    property {${worxReference}}
    provider {${worxReference}}
    type
  }
`;

export const ConformanceReportOrderItem = `
  ... on ConformanceReportOrderItem {
    ${worxReference}
    client_documents {
      ${worxReference}
      document {${worxFile}}
      type
      jurisdiction {${worxReference}}
      document_type {
        description
        ${worxReference}
        vid
      }
      property {${worxReference}}
    }
    document_order_items {
      document {
        ${worxReference}
        property {${worxReference}}
        jurisdiction {${worxReference}}
        document {${worxFile}}
        document_type {
          description
          ${worxReference}
          vid
        }
      }
      document_entities
      document_order_template {
        ${EmailTemplate}
        ${FaxTemplate}
        ${OnlineTemplate}
        ${PhoneTemplate}
        ${RunnerTemplate}
        ${USPSTemplate}
      }
      ${worxReference}
      order_date
      report_parent {
        id
      }
      quantity
      total_price
      type
      unit_price
    }
    jurisdiction {${worxReference}}
    order {${Order}}
    order_documents {${worxReference}}
    property {
      ${worxReference}
      address {${worxAddress}}
      jurisdiction {${worxReference}}
      notes
      parcel_map {
        ${worxReference}
        image {${worxFile}}
        property {${worxReference}}
      }
      google_map {
        ${worxReference}
        image {${worxFile}}
        property {${worxReference}}
      }
      parcel_tax_id
      use {${worxReference}}
      zone {${worxReference}}
      property_type {${worxReference}}
      zoning_map {
        ${worxReference}
        image {${worxFile}}
        jurisdiction {${worxReference}}
        property {${worxReference}}
        type
      }
      abutting_zoning {${worxReference}}
    }
    status
    quantity
    total_price
    type
    unit_price
  }
`;
