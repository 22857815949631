import React from "react";
import * as PropTypes from "prop-types";
import WorxFormContainer from "../../Container/Forms/Generic/WorxFormContainer";

const AnalystReportStatusDisplay = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { id, options, formik } = props;

  return (
    <WorxFormContainer
      options={options}
      disabledMap={{
        "property.0.address": true,
        "order.0.prepared_for": true,
        "order.0.client.0.name": true,
      }}
      formik={formik}
    />
  );
};

AnalystReportStatusDisplay.propTypes = {
  id: PropTypes.number,
  options: PropTypes.object,
  formik: PropTypes.object,
};

export default AnalystReportStatusDisplay;
