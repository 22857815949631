import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/core";
import * as PropTypes from "prop-types";
import { GetSet, ListFilter } from "../../Constants/Jurisdiction";
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
  worxRadioGroupStyleOverride: {
    marginBottom: "1em",
  },
  worxSpaceAround: {
    margin: "0 1em",
  },
  textfieldWrapper: {
    marginBottom: "1em",
  },
  buttonWrapper: {
    marginTop: "1em",
    textAlign: "right",
  },
});

// eslint-disable-next-line no-unused-vars
const buttonUseStyles = makeStyles({
  root: {
    marginLeft: "1em",
  },
  blue: {
    background: "#5aacee",
    color: "white",
  },
  green: {
    background: "#33a532",
    color: "white",
  },
});

const topCardUseStyles = makeStyles({
  root: {
    "& .MuiDataGrid-cell": {
      whiteSpace: "normal !important",
    },
    marginBottom: "2em",
  },
});

const MyOrdersListDisplay = (props) => {
  const {
    orders,
    rows,
    columns,
    rowTotal,
    filters,
    currentPage,
    createNewOrder,
  } = props;

  let pageSize = 25;

  const classes = useStyles();
  const buttonClasses = buttonUseStyles();
  const topCardClasses = topCardUseStyles();

  return (
    <React.Fragment>
      <div>
        <Button
          variant={"contained"}
          color="primary"
          margin="normal"
          onClick={createNewOrder}
          classes={{ root: buttonClasses.root }}
        >
          Add New Order
        </Button>
      </div>

      <div className={classes.textfieldWrapper}>
        <TextField
          margin="normal"
          id={"order"}
          label={"ID"}
          onChange={(event) => {
            let nextFilter = {
              ...filters.get,
              id: event.target.value ? +event.target.value : null,
              offset: 0,
            };
            orders.filter({ variables: nextFilter });
            filters.set(nextFilter);
          }}
          className={classes.worxSpaceAround}
        />
        <TextField
          margin="normal"
          id={"client"}
          label={"Client"}
          onChange={(event) => {
            let nextFilter = {
              ...filters.get,
              client: event.target.value ? event.target.value : "",
              offset: 0,
            };
            orders.filter({ variables: nextFilter });
            filters.set(nextFilter);
          }}
          className={classes.worxSpaceAround}
        />
      </div>

      <DataGrid
        classes={{ root: topCardClasses.root }}
        key={rowTotal}
        pagination
        rowCount={rowTotal}
        page={currentPage.get}
        autoHeight={true}
        getRowHeight={() => "auto"}
        columns={columns}
        rows={rows}
        pageSize={pageSize}
        paginationMode="server"
        onPageChange={(page) => {
          let nextFilter = { ...filters.get, offset: pageSize * page };
          orders.filter({ variables: nextFilter });
          filters.set(nextFilter);
          currentPage.set(page);
        }}
        loading={!orders.list}
        className={classes.worxSpaceAround}
      />
    </React.Fragment>
  );
};

MyOrdersListDisplay.propTypes = {
  orders: ListFilter,
  initValues: GetSet,
  rowTotal: PropTypes.number.isRequired,
  columns: PropTypes.array,
  rows: PropTypes.array,
  filters: GetSet,
  currentPage: GetSet,
  createNewOrder: PropTypes.func.isRequired,
};

export default MyOrdersListDisplay;
