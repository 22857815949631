import React, { useContext, useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";
import UseWorkflowDisplay from "../../../Display/DataEntry/DataEntryTwo_UseWorkflow/UseWorkflowDisplay";
import HeaderTitleContext from "../../../Context/HeaderTitleContext";
import MainContentWidthContext from "../../../Context/MainContentWidthContext";
import { deleteQueueItem, releaseQueueItem } from "../../../Queries/Mutations";
import {getClaimedQueueItems} from "../../../Queries/Queries";

const UseWorkflowContainer = () => {
  let location = useLocation();
  const [data, setData] = useState(
    location.state.data ? location.state.data : {}
  );
  // eslint-disable-next-line no-unused-vars
  const [steps, setSteps] = useState([
    "Footnotes",
    "Legends",
    "Use Matrix",
    "Parking",
  ]);
  const [documentsOpen, setDocumentsOpen] = useState(false);
  const lastStep = 3;

  const [step, setStep] = useState(0);
  const [renderKey, setRenderKey] = useState(0);
  const history = useHistory();

  // eslint-disable-next-line no-unused-vars
  const [headerTitle, setHeaderTitle] = useContext(HeaderTitleContext);

  if (step > steps.length) {
    setStep(steps.length);
  }

  // eslint-disable-next-line no-unused-vars
  const [releaseJurisdiction, { data: releasedJurisdiction }] = useMutation(
    releaseQueueItem("use_workflow", data.id), {refetchQueries: [{query: getClaimedQueueItems(), variables: {queue: 'use_workflow'}}]}
  );
  const [removeJurisdiction, { data: removedJurisdiction }] = useMutation(
    deleteQueueItem("use_workflow", data.id), {refetchQueries: [{query: getClaimedQueueItems(), variables: {queue: 'use_workflow'}}]}
  );

  useEffect(() => {
    if (releasedJurisdiction || removedJurisdiction) {
      history.push({
        pathname: "/data_entry/use_workflow",
      });
    }
  }, [releasedJurisdiction, removedJurisdiction]);

  // use this to make sure the render key never repeats when the step changes
  const setActiveStep = (step) => {
    setStep(step);
    setRenderKey(renderKey + 1);
  };

  // eslint-disable-next-line no-unused-vars
  const [mainContentWidth, setMainContentWidth] = useContext(
    MainContentWidthContext
  );

  switch (step) {
    case 2:
      setMainContentWidth("80");
      break;
  }

  return (
    <UseWorkflowDisplay
      data={{ get: data, set: setData }}
      steps={steps}
      step={{ get: step, set: setActiveStep }}
      lastStep={lastStep}
      releaseJurisdiction={releaseJurisdiction}
      removeJurisdiction={removeJurisdiction}
      renderKey={renderKey}
      setHeaderTitle={setHeaderTitle}
      documentsOpen={{ get: documentsOpen, set: setDocumentsOpen }}
    />
  );
};

UseWorkflowContainer.propTypes = {
  data: PropTypes.any,
};

export default UseWorkflowContainer;
