import { Redirect, Route, Switch /*useRouteMatch*/ } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import JurisdictionsRouter from "./JurisdictionsRouter";
import WorkflowRouter from "./WorkflowRouter";
import LoginFormContainer from "../Container/Forms/LoginFormContainer";
import OrdersRouter from "./OrdersRouter";
import Sandbox from "../Container/Sandbox";
import ResearchRouter from "./ResearchRouter";
import JuniorAnalystRouter from "./JuniorAnalystRouter";
import ResidentialRouter from "./ResidentialRouter";
import AnalystRouter from "./AnalystRouter";
import ErrorContainer from "../Container/ErrorContainer";
import * as PropTypes from "prop-types";
import { useHistory } from "react-router";
import PortalRouter from "./PortalRouter";
import ClientRelationsRouter from "./ClientRelations";
import MessageContext from "../Context/MessageContext";
import CommercialRouter from "./CommercialRouter";

const MainRouter = (props) => {
  //const match = useRouteMatch();
  const history = useHistory();
  const { errors } = props;
  const [path, setPath] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [message, setMessage] = useContext(MessageContext);

  useEffect(() => {
    if (errors !== null) {
      setPath(history.location.pathname);
      history.push({
        pathname: "/error",
      });
    }
  }, [errors]);

  if (history.location.pathname.split("/")?.[1] !== "analyst") {
    setMessage(null);
  }

  return (
    <Switch>
      <Route path="/jurisdictions">
        <JurisdictionsRouter />
      </Route>
      <Route path="/data_entry">
        <WorkflowRouter />
      </Route>
      <Route path="/login">
        <LoginFormContainer />
      </Route>
      <Route path="/orders">
        <OrdersRouter />
      </Route>
      <Route path="/research">
        <ResearchRouter />
      </Route>
      <Route path="/junior_analyst">
        <JuniorAnalystRouter />
      </Route>
      <Route path="/residential">
        <ResidentialRouter />
      </Route>
      <Route path="/portal">
        <PortalRouter />
      </Route>
      <Route exact path="/client-relations">
        <ClientRelationsRouter />
      </Route>
      <Route path="/sandbox">
        <Sandbox input={"test"} />
      </Route>
      <Route path="/analyst">
        <AnalystRouter />
      </Route>
      <Route path="/commercial">
        <CommercialRouter />
      </Route>
      <Route path="/error">
        <ErrorContainer errors={errors} path={path} />
      </Route>
      <Route exact path="/">
        <Redirect to="/data_entry" />
      </Route>
    </Switch>
  );
};

MainRouter.propTypes = {
  errors: PropTypes.object,
};

export default MainRouter;
