/* OLD for reference
export const addressToDisplay = addr => {
  let addrArray = addr.split(/#/g);
  return addrArray.reduce((out, char, index) => {
    if (index === 0) {
      return '';
    }
    if (index === 1) {
      return char;
    }
    if (index === 3 || index === 2) {
      return char + ', ' + out;
    }
    if (index === 5) {
      return out + ' ' + char;
    }
    return char + ' ' + out;
  }, '');
};*/

export const addressToDisplay = (addressObject) => {
  let address = `${addressObject?.address_line1}${
    addressObject?.address_line2.length > 0
      ? `, ${addressObject?.address_line2}`
      : ""
  }, ${addressObject?.locality}, ${addressObject?.administrative_area}`;
  return address;
};
