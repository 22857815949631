import React, { useEffect, useState } from "react";
// eslint-disable-next-line no-unused-vars
import * as PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import { GetSet } from "../../../Constants/Jurisdiction";
import { useLazyQuery, useMutation } from "@apollo/client";
import { getDocumentOrderTemplates, getOrder } from "../../../Queries/Queries";
import DocumentOrderItemFormDisplay from "../../../Display/Orders/Forms/DocumentOrderItemFormDisplay";
import { editOrderItem, createOrderItem } from "../../../Queries/Mutations";

const DocumentOrderItemFormContainer = (props) => {
  let { referenceData, initValues, availableReports, edit } = props;
  let type = initValues.get.__typename;

  // eslint-disable-next-line no-unused-vars
  const [doEditOrderDocumentItem] = useMutation(editOrderItem(type), {
    refetchQueries: [{ query: getOrder(referenceData.id) }],
  });
  const [doCreateOrderDocumentItem] = useMutation(createOrderItem(type), {
    refetchQueries: [{ query: getOrder(referenceData.id) }],
  });

  const [reportsOpen, setReportsOpen] = useState(false);

  const [fixedTemplateFilters, setFixedTemplateFilters] = useState({});
  const [templateOpen, setTemplateOpen] = useState(false);
  const [filterTemplates, { data: templateQuery }] = useLazyQuery(
    getDocumentOrderTemplates()
  );

  let defaultOrderItemValues = {};

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...defaultOrderItemValues, ...initValues.get },
    validationSchema: Yup.object({}),
    // eslint-disable-next-line no-unused-vars
    onSubmit: (values, formikTools) => {
      edit.set(false);

      if (values.id) {
        // eslint-disable-next-line no-unused-vars
        /* eslint-disable no-unused-vars */
        const {
          document,
          document_entities,
          document_order_template,
          order_date,
          report_parent,
          upload_data,
          total_price,
          ...payload
        } = values;
        /* eslint-disable no-unused-vars */
        doEditOrderDocumentItem({ variables: { data: payload } });
      } else {
        // eslint-disable-next-line no-unused-vars
        const { total_price, ...payload } = values;
        doCreateOrderDocumentItem({ variables: { data: payload } });
      }
      formikTools.resetForm();
    },
  });

  useEffect(() => {
    if (initValues.report_parent) {
      let list = referenceData.report_order_items;
      let index = list.findIndex(
        (item) => item.id === initValues.report_parent?.[0]?.id
      );
      if (index) {
        setFixedTemplateFilters({
          jurisdiction: list[index].jurisdiction[0].id,
        });
        filterTemplates({
          variables: { jurisdiction: list[index].jurisdiction[0].id },
        });
      }
    }
  }, []);

  useEffect(() => {
    if (formik.values.report_parent) {
      let list = referenceData.report_order_items;
      let index = list.findIndex(
        (item) => item.id === formik.values.report_parent?.[0]?.id
      );
      if (index >= 0) {
        setFixedTemplateFilters({
          jurisdiction: list[index].property[0].jurisdiction[0].id,
        });
        filterTemplates({
          variables: {
            jurisdiction: list[index].property[0].jurisdiction[0].id,
          },
        });
      }
    }
  }, [formik.values]);

  return (
    <DocumentOrderItemFormDisplay
      availableReports={{
        open: { get: reportsOpen, set: setReportsOpen },
        options: {
          list: availableReports.get,
          filter: () => {},
        },
      }}
      templateOptions={{
        open: { get: templateOpen, set: setTemplateOpen },
        options: {
          list: templateQuery?.documentOrderTemplates
            ? templateQuery?.documentOrderTemplates
            : [],
          filter: filterTemplates,
        },
        variables: {
          filters: { get: fixedTemplateFilters, set: setFixedTemplateFilters },
        },
      }}
      formik={formik}
    />
  );
};

DocumentOrderItemFormContainer.propTypes = {
  referenceData: PropTypes.object,
  initValues: GetSet,
  availableReports: GetSet,
  edit: GetSet,
};

export default DocumentOrderItemFormContainer;
