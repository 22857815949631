import React, { useContext, useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { useLazyQuery, useMutation } from "@apollo/client";
import { getAnalystReport, getConformanceOptions } from "../../Queries/Queries";
import { useFormik } from "formik";
import * as Yup from "yup";
import { editAnalystReport } from "../../Queries/Mutations";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import WorxTextField from "../../Display/Forms/Inputs/WorxTextField";
import WorxSelectString from "../../Display/Forms/Inputs/WorxSelectString";
import AnalystRequirementsDisplay from "../../Display/Analyst/AnalystRequirementsDisplay";
import MessageContext from "../../Context/MessageContext";

const AnalystRequirementsContainer = (props) => {
  const { id } = props;

  const [getReport, { data: report }] = useLazyQuery(getAnalystReport(id));
  const [documentOpen, setDocumentOpen] = useState(false);
  const [document, setDocument] = useState(false);
  const [abuttingZoneOpen, setAbuttingZoneOpen] = useState(false);
  const [filterConformanceOptions, { data: conformanceOptions }] = useLazyQuery(
    getConformanceOptions()
  );
  const [initValues, setInitValues] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [message, setMessage] = useContext(MessageContext);

  const defaultValues = {};

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...defaultValues, ...initValues },
    validationSchema: Yup.object({}),
    // eslint-disable-next-line no-unused-vars
    onSubmit: (values, formikTools) => {
      // eslint-disable-next-line no-unused-vars
      let { conformance_report_order_item, ...val } = values;
      let payload = {
        id: val.id,
        ...(values.requirements_answers && {
          requirements_answers: values?.requirements_answers,
        }),
        ...(values.requirements_notes && {
          requirements_notes: values?.requirements_notes,
        }),
      };
      doEditReport({ variables: { data: payload } });
      setMessage("✓ Requirements Saved");
    },
  });

  const [doEditReport, { data: response }] = useMutation(editAnalystReport());

  const [pageSize] = useState(10);
  const [columns, setColumns] = useState([]);
  const [openStates, setOpenStates] = useState({});
  const setOpen = (id, value) => {
    setOpenStates({ ...openStates, [id]: value });
  };

  const getOpen = (id) => {
    return openStates[id];
  };

  const footnotes = formik?.values?.requirements_answers?.footnotes;

  useEffect(() => {
    setColumns([
      {
        field: "item",
        renderCell: Object.assign(
          (params) => {
            if (params.row.new !== true) {
              const index = Object.keys(footnotes).findIndex(
                (noteID) => noteID === params?.row?.footnote?.[0]
              );
              return (
                <Tooltip title={params?.row?.["requirement"]}>
                  <div>
                    {index >= 0 && (
                      <sup>
                        [<a href={"#foot-" + index}>{index + 1}</a>]
                      </sup>
                    )}
                    {`${params?.row?.["requirement"]}`}
                  </div>
                </Tooltip>
              );
            } else {
              return (
                <WorxTextField
                  fieldKey={`requirements_answers.requirements.${formik.values.requirements_answers.requirements.findIndex(
                    (item) => item.id === params.row.id
                  )}.requirement`}
                  label={""}
                  formik={formik}
                />
              );
            }
          },
          { displayName: "renderCell" }
        ),
        headerName: "Item",
        width: 330,
      },
      {
        field: "label",
        // eslint-disable-next-line no-unused-vars
        renderCell: Object.assign(
          (params) => {
            return (
              <Tooltip title={params.row.label}>
                <div dangerouslySetInnerHTML={{ __html: params.row.label }} />
              </Tooltip>
            );
          },
          { displayName: "renderCell" }
        ),
        headerName: "Description",
        width: 330,
      },
      {
        field: "min_max",
        // eslint-disable-next-line no-unused-vars
        renderCell: Object.assign(
          (params) => {
            return (
              <Tooltip title={params.row.label}>
                <div>{params.row.min_max}</div>
              </Tooltip>
            );
          },
          { displayName: "renderCell" }
        ),
        headerName: " ",
        width: 50,
      },
      {
        field: "requirement",
        // eslint-disable-next-line no-unused-vars
        renderCell: Object.assign(
          (params) => {
            return (
              <Tooltip title={"test"}>
                <WorxTextField
                  key={
                    formik.values.requirements_answers.requirements[
                      formik.values.requirements_answers.requirements.findIndex(
                        (item) => item.id === params.row.id
                      )
                    ].required
                  }
                  fieldKey={`requirements_answers.requirements.${formik.values.requirements_answers.requirements.findIndex(
                    (item) => item.id === params.row.id
                  )}.required`}
                  label={""}
                  formik={formik}
                />
              </Tooltip>
            );
          },
          { displayName: "renderCell" }
        ),
        headerName: "Requirement",
        width: 330,
      },
      {
        field: "existing",
        renderCell: Object.assign(
          (params) => {
            return (
              <Tooltip title={"test"}>
                <WorxTextField
                  key={
                    formik.values.requirements_answers.requirements[
                      formik.values.requirements_answers.requirements.findIndex(
                        (item) => item.id === params.row.id
                      )
                    ].existing
                  }
                  fieldKey={`requirements_answers.requirements.${formik.values.requirements_answers.requirements.findIndex(
                    (item) => item.id === params.row.id
                  )}.existing`}
                  label={""}
                  formik={formik}
                />
              </Tooltip>
            );
          },
          { displayName: "renderCell" }
        ),
        headerName: "Existing",
        width: 330,
      },
      {
        field: "conformance",
        renderCell: Object.assign(
          (params) => {
            return (
              <Tooltip title={"test"}>
                <WorxSelectString
                  key={
                    formik.values.requirements_answers.requirements[
                      formik.values.requirements_answers.requirements.findIndex(
                        (item) => item.id === params.row.id
                      )
                    ].conformance
                  }
                  fieldKey={`requirements_answers.requirements.${formik.values.requirements_answers.requirements.findIndex(
                    (item) => item.id === params.row.id
                  )}.conformance`}
                  label={""}
                  options={{
                    open: {
                      get: getOpen(params.row.id),
                      set: (value) => {
                        setOpen(params.row.id, value);
                      },
                    },
                    options: {
                      list: conformanceOptions?.getConformanceOptions
                        ? conformanceOptions?.getConformanceOptions.map(
                            (item) => {
                              return { id: item, name: item };
                            }
                          )
                        : [],
                      filter: () => {},
                    },
                  }}
                  formik={formik}
                />
              </Tooltip>
            );
          },
          { displayName: "renderCell" }
        ),
        headerName: "Conformance",
        width: 330,
      },
      {
        field: "display",
        // eslint-disable-next-line no-unused-vars
        renderCell: Object.assign(
          (params) => {
            return (
              <Tooltip title={"test"}>
                <WorxSelectString
                  key={
                    formik.values.requirements_answers.requirements[
                      formik.values.requirements_answers.requirements.findIndex(
                        (item) => item.id === params.row.id
                      )
                    ].display
                  }
                  fieldKey={`requirements_answers.requirements.${formik.values.requirements_answers.requirements.findIndex(
                    (item) => item.id === params.row.id
                  )}.display`}
                  label={""}
                  options={{
                    open: {
                      get: getOpen(params.row.id),
                      set: (value) => {
                        setOpen(params.row.id, value);
                      },
                    },
                    options: {
                      list: [
                        { id: "yes", name: "yes" },
                        { id: "no", name: "no" },
                      ],
                      filter: () => {},
                    },
                  }}
                  formik={formik}
                />
              </Tooltip>
            );
          },
          { displayName: "renderCell" }
        ),
        headerName: "Display",
        width: 330,
      },
    ]);
  }, [formik.values, conformanceOptions]);

  // setup
  useEffect(() => {
    getReport();
    filterConformanceOptions();
    setMessage(null);
  }, []);

  // report
  useEffect(() => {
    if (report) {
      setInitValues({
        ...report.analystReport,
        __typename: "AnalystRequirementsForm",
      });
      let docs =
        report?.analystReport?.conformance_report_order_item?.[0]
          ?.client_documents;
      if (docs) {
        let index = docs.findIndex(
          (doc) => doc?.document_type?.[0]?.name === "Survey"
        );
        setDocument(docs[index].document[0]);
      }
    }
  }, [report]);

  // response

  useEffect(() => {
    if (response) {
      setInitValues({
        ...response.editAnalystReport.analyst_report,
        __typename: "AnalystRequirementsForm",
      });
    }
  }, [response]);

  return (
    <AnalystRequirementsDisplay
      options={{
        "requirements_answers.requirements": {
          rowTotal:
            formik?.values?.requirements_answers?.requirements?.length ?? 0,
          columns: columns,
          pageSize: pageSize,
          // eslint-disable-next-line no-unused-vars
          onPageChange: (page) => {
            /* no paging in this case */
          },
          paginationMode: "client",
          loading: formik?.values?.requirements_answers?.requirements,
        },
      }}
      jurisdiction={
        report?.analystReport?.conformance_report_order_item?.[0]
          ?.jurisdiction?.[0].id ?? null
      }
      property={
        report?.analystReport?.conformance_report_order_item?.[0]
          ?.property?.[0] ?? null
      }
      documentOpen={{ get: documentOpen, set: setDocumentOpen }}
      abuttingZoneOpen={{ get: abuttingZoneOpen, set: setAbuttingZoneOpen }}
      document={document}
      footnotes={formik?.values?.requirements_answers?.footnotes}
      nameOverrides={{}}
      id={id}
      formik={formik}
    />
  );
};

AnalystRequirementsContainer.propTypes = {
  id: PropTypes.number.isRequired,
  page: PropTypes.string,
};

export default AnalystRequirementsContainer;
