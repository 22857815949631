import React from "react";
import * as PropTypes from "prop-types";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import { FormLabel } from "@material-ui/core";
import { makeStyles } from "@mui/styles";
import { camelToTitle } from "../../../tools/stringTools";

const useStyles = makeStyles({
  fileWrapper: {
    marginBottom: "1em",
  },
});

const WorxFileUploadDisplay = (props) => {
  let {
    formik,
    mediaField,
    fieldKey,
    entityType,
    disabled = false,
    doUploadFile,
    type,
  } = props;

  const classes = useStyles();

  let accept = "";
  if (type === "image") {
    accept = `image/*`;
  }
  if (type === "document") {
    accept = `.pdf`;
  }

  return (
    <React.Fragment>
      <FormLabel>{`File Upload (${camelToTitle(type)})`}</FormLabel>
      <div className={classes.fileWrapper}>
        {!formik?.values?.[fieldKey]?.[0] && (
          <React.Fragment>
            <Input
              inputProps={{ accept: accept }}
              margin="none"
              disabled={disabled}
              id={fieldKey}
              type="file"
              placeholder={""}
              onChange={(event) => {
                let payload = {
                  file: event.target.files,
                  entity: entityType,
                  field: mediaField ? mediaField : fieldKey,
                };
                doUploadFile({ variables: payload });
              }}
            />
          </React.Fragment>
        )}
        {formik?.values?.[fieldKey]?.[0] && (
          <React.Fragment>
            <a
              href={formik.values[fieldKey][0].filepath}
              rel="noreferrer"
              target={"_blank"}
            >
              {formik.values[fieldKey][0].filename}
            </a>
            {!disabled && (
              <Button
                color="secondary"
                margin="none"
                onClick={() => {
                  formik.setFieldValue(fieldKey, null);
                }}
              >
                Remove File
              </Button>
            )}
          </React.Fragment>
        )}
        {formik?.errors?.[fieldKey]?.[0] ? (
          <div style={{ color: "red" }}>{formik.errors[fieldKey]}</div>
        ) : null}
      </div>
    </React.Fragment>
  );
};

WorxFileUploadDisplay.propTypes = {
  mediaField: PropTypes.string.isRequired,
  fieldKey: PropTypes.string.isRequired,
  entityType: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  doUploadFile: PropTypes.func,
  type: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
};

export default WorxFileUploadDisplay;
