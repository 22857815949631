import React from "react";
import * as PropTypes from "prop-types";
import { Card, CardContent } from "@material-ui/core";
import { makeStyles } from "@mui/styles";
import WorxFormContainer from "../../../Container/Forms/Generic/WorxFormContainer";

const topCardUseStyles = makeStyles({
  root: {
    marginBottom: "2em",
  },
});

const JuniorAnalystTenantListFormDisplay = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { title, options, jurisdiction, disabledMap, formik } = props;

  const topCardClasses = topCardUseStyles();

  return (
    <React.Fragment>
      <Card classes={{ root: topCardClasses.root }}>
        <CardContent>
          <h3>{title}</h3>
          <WorxFormContainer
            options={options}
            disabledMap={disabledMap}
            formik={formik}
          />
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

JuniorAnalystTenantListFormDisplay.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,
  jurisdiction: PropTypes.number.isRequired,
  disabledMap: PropTypes.object.isRequired,
  formik: PropTypes.object.isRequired,
};

export default JuniorAnalystTenantListFormDisplay;
