import React from "react";
import * as PropTypes from "prop-types";
import { GetSet } from "../../Constants/Jurisdiction";
import { DataGrid } from "@mui/x-data-grid";
import Dialog from "@material-ui/core/Dialog/Dialog";
import { makeStyles } from "@mui/styles";
import WorxFormContainer from "../../Container/Forms/Generic/WorxFormContainer";

const buttonUseStyles = makeStyles({
  alignHead: {
    display: "tableHeaderGroup",
  },
});

const cardUseStyles = makeStyles({
  worxCardStyleOverride: {
    width: (props) => (props.width > "80" ? "80%" : props.width + "%"),
    padding: "2em",
    marginBottom: "2em",
  },
});

const useStyles = makeStyles({
  worxRadioGroupStyleOverride: {
    marginBottom: "1em",
  },
  worxSpaceAround: {
    margin: "0 1em",
  },
  textfieldWrapper: {
    marginBottom: "1em",
  },
  buttonWrapper: {
    marginTop: "1em",
    textAlign: "right",
  },
});

const topCardUseStyles = makeStyles({
  root: {
    "& .MuiDataGrid-cell": {
      whiteSpace: "normal !important",
    },
    marginBottom: "2em",
  },
});

const OrderDocumentsTabDisplay = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { working, rowTotal, pageSize, columns, rows, formik } = props;
  // eslint-disable-next-line no-unused-vars
  const buttonClasses = buttonUseStyles();
  // eslint-disable-next-line no-unused-vars
  const cardClasses = cardUseStyles();
  const classes = useStyles();
  const topCardClasses = topCardUseStyles();

  return (
    <React.Fragment>
      <DataGrid
        classes={{ root: topCardClasses.root }}
        key={rows.length}
        pagination
        rowCount={rowTotal}
        autoHeight={true}
        getRowHeight={() => "auto"}
        columns={columns}
        rows={rows}
        pageSize={pageSize}
        loading={!rows}
        className={classes.worxSpaceAround}
      />

      <Dialog
        id={"addLinkForm"}
        open={working.get}
        onClose={() => working.set(false)}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <WorxFormContainer disabled={true} formik={formik} />
      </Dialog>
    </React.Fragment>
  );
};

OrderDocumentsTabDisplay.propTypes = {
  working: GetSet,
  rowTotal: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  formik: PropTypes.object.isRequired,
};

export default OrderDocumentsTabDisplay;
