import React, { useContext, useEffect, useState } from "react";
// eslint-disable-next-line no-unused-vars
import * as PropTypes from "prop-types";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  getAnalystReport,
  getCOfOAttachedOptions,
  getCofOConformanceOptions,
  getUsesForJurisdiction,
} from "../../Queries/Queries";
import { useFormik } from "formik";
import * as Yup from "yup";
import { editAnalystReport } from "../../Queries/Mutations";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import WorxTextField from "../../Display/Forms/Inputs/WorxTextField";
import WorxSelectString from "../../Display/Forms/Inputs/WorxSelectString";
import AnalystCOfODisplay from "../../Display/Analyst/AnalystCOfODisplay";
import { titleToUnderscore } from "../../tools/stringTools";
import MessageContext from "../../Context/MessageContext";

const AnalystCOfOContainer = (props) => {
  const { id } = props;

  const [getReport, { data: report }] = useLazyQuery(getAnalystReport(id));
  // eslint-disable-next-line no-unused-vars
  const [filterConformanceOptions, { data: conformanceOptions }] = useLazyQuery(
    getCofOConformanceOptions()
  );
  const [filterAttachedOptions, { data: attachedOptions }] = useLazyQuery(
    getCOfOAttachedOptions()
  );
  const [usesFilters, setUsesFilters] = useState({ limit: 50, offset: 0 });
  const [filterUsesOptions, { data: usesOptions }] = useLazyQuery(
    getUsesForJurisdiction(),
    { variables: usesFilters }
  );
  // eslint-disable-next-line no-unused-vars
  const [message, setMessage] = useContext(MessageContext);

  const [initValues, setInitValues] = useState({});

  const defaultValues = {};

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...defaultValues, ...initValues },
    validationSchema: Yup.object({}),
    // eslint-disable-next-line no-unused-vars
    onSubmit: (values, formikTools) => {
      // eslint-disable-next-line no-unused-vars
      let { conformance_report_order_item, reset, ...val } = values;
      if (!reset) {
        let payload = {
          id: val.id,
          ...(values.cofo_answers && { cofo_answers: values?.cofo_answers }),
          ...(values.cofo_notes && { cofo_notes: values?.cofo_notes }),
        };
        doEditReport({ variables: { data: payload } });
      } else {
        let payload = {
          id: val.id,
          cofo_answers: "",
          ...(values.cofo_notes && { cofo_notes: values?.cofo_notes }),
        };
        doEditReport({ variables: { data: payload } });
      }
      setMessage("✓ COfO Saved");
    },
  });

  const [doEditReport, { data: response }] = useMutation(editAnalystReport());

  const [pageSize] = useState(100);
  const [columns, setColumns] = useState([]);
  const [openConformanceStates, setOpenConformanceStates] = useState({});
  const [openUseStates, setOpenUseStates] = useState({});
  const [openAttachedStates, setOpenAttachedStates] = useState({});

  const setOpenConformance = (id, value) => {
    setOpenConformanceStates({ ...openConformanceStates, [id]: value });
  };

  const getOpenConformance = (id) => {
    return openConformanceStates[id];
  };

  const setOpenUses = (id, value) => {
    setOpenUseStates({ ...openUseStates, [id]: value });
  };

  const getOpenUses = (id) => {
    return openUseStates[id];
  };

  const setOpenAttached = (id, value) => {
    setOpenAttachedStates({ ...openAttachedStates, [id]: value });
  };

  const getOpenAttached = (id) => {
    return openAttachedStates[id];
  };

  useEffect(() => {
    setColumns([
      {
        field: "name",
        renderCell: Object.assign(
          (params) => {
            return (
              <Tooltip title={params?.row?.["name"]}>
                <div>{params?.row?.["name"]}</div>
              </Tooltip>
            );
          },
          { displayName: "renderCell" }
        ),
        headerName: "Tenant/Building",
        width: 330,
      },
      {
        field: "use",
        // eslint-disable-next-line no-unused-vars
        renderCell: Object.assign(
          (params) => {
            return (
              <Tooltip title={"test"}>
                <WorxSelectString
                  fieldKey={`cofo_answers.${formik.values.cofo_answers.findIndex(
                    (item) => item.id === params.row.id
                  )}.use`}
                  label={""}
                  formik={formik}
                  options={{
                    open: {
                      get: getOpenUses(params.row.id),
                      set: (value) => {
                        setOpenUses(params.row.id, value);
                      },
                    },
                    options: {
                      list: usesOptions?.uses.items ?? [],
                      filter: filterUsesOptions,
                    },
                  }}
                />
              </Tooltip>
            );
          },
          { displayName: "renderCell" }
        ),
        headerName: "Use",
        width: 330,
      },
      {
        field: "conformance",
        renderCell: Object.assign(
          (params) => {
            return (
              <Tooltip title={"test"}>
                <WorxSelectString
                  fieldKey={`cofo_answers.${formik.values.cofo_answers.findIndex(
                    (item) => item.id === params.row.id
                  )}.conformance`}
                  label={""}
                  options={{
                    open: {
                      get: getOpenConformance(params.row.id),
                      set: (value) => {
                        setOpenConformance(params.row.id, value);
                      },
                    },
                    options: {
                      list: conformanceOptions?.getCofOConformanceOptions
                        ? conformanceOptions?.getCofOConformanceOptions.map(
                            (item) => {
                              return { id: item, name: item };
                            }
                          )
                        : [],
                      filter: () => {},
                    },
                  }}
                  formik={formik}
                />
              </Tooltip>
            );
          },
          { displayName: "renderCell" }
        ),
        headerName: "Conformance",
        width: 330,
      },
      {
        field: "count",
        // eslint-disable-next-line no-unused-vars
        renderCell: Object.assign(
          (params) => {
            return (
              <Tooltip title={"test"}>
                <WorxTextField
                  fieldKey={`cofo_answers.${formik.values.cofo_answers.findIndex(
                    (item) => item.id === params.row.id
                  )}.count`}
                  label={""}
                  formik={formik}
                />
              </Tooltip>
            );
          },
          { displayName: "renderCell" }
        ),
        headerName: "Count",
        width: 330,
      },
      {
        field: "cofo_attached",
        renderCell: Object.assign(
          (params) => {
            return (
              <Tooltip title={"test"}>
                <WorxSelectString
                  fieldKey={`cofo_answers.${formik.values.cofo_answers.findIndex(
                    (item) => item.id === params.row.id
                  )}.cofo_attached`}
                  label={""}
                  options={{
                    open: {
                      get: getOpenAttached(params.row.id),
                      set: (value) => {
                        setOpenAttached(params.row.id, value);
                      },
                    },
                    options: {
                      list: attachedOptions?.getCofOAttachedOptions
                        ? attachedOptions?.getCofOAttachedOptions.map(
                            (item) => {
                              return { id: item, name: item };
                            }
                          )
                        : [],
                      filter: () => {},
                    },
                  }}
                  formik={formik}
                />
              </Tooltip>
            );
          },
          { displayName: "renderCell" }
        ),
        headerName: "C of O Attached",
        width: 330,
      },
    ]);
  }, [formik.values, conformanceOptions]);

  // setup
  useEffect(() => {
    getReport();
    filterConformanceOptions();
    filterAttachedOptions();
    setMessage(null);
  }, []);

  // report
  useEffect(() => {
    if (report) {
      let newFilter = {
        ...usesFilters,
        jurisdiction:
          report.analystReport.conformance_report_order_item[0].jurisdiction[0]
            .id,
      };
      setUsesFilters(newFilter);
      getUsesForJurisdiction({ variables: newFilter });
      setInitValues({ ...report.analystReport, __typename: "AnalystCOfOForm" });
    }
  }, [report]);

  // response

  useEffect(() => {
    if (response) {
      let newFilter = {
        ...usesFilters,
        jurisdiction:
          response.editAnalystReport.analyst_report
            .conformance_report_order_item[0].jurisdiction[0].id,
      };
      setUsesFilters(newFilter);
      getUsesForJurisdiction({ variables: newFilter });
      setInitValues({
        ...response.editAnalystReport.analyst_report,
        __typename: "AnalystCOfOForm",
      });
    }
  }, [response]);

  return (
    <AnalystCOfODisplay
      options={{
        cofo_answers: {
          rowTotal: formik?.values?.cofo_answers?.length ?? 0,
          columns: columns,
          pageSize: pageSize,
          // eslint-disable-next-line no-unused-vars
          onPageChange: (page) => {
            /* no paging in this case */
          },
          loading: formik?.values?.cofo_answers,
        },
        "cofo_answers.0.unit_mix_aux.rows": {
          rowTotal: formik?.values?.cofo_answers?.[0]?.unit_mix_aux?.rows
            ? formik?.values?.cofo_answers?.[0]?.unit_mix_aux?.rows.length
            : 0,
          columns: formik?.values?.cofo_answers?.[0]?.unit_mix_aux?.header
            ? formik?.values?.cofo_answers?.[0]?.unit_mix_aux?.header.map(
                (headerValue) => {
                  return {
                    field: titleToUnderscore(headerValue),
                    renderCell: Object.assign(
                      (params) => {
                        return params.value;
                      },
                      { displayName: "renderCell" }
                    ),
                    headerName: headerValue,
                    width: 330,
                  };
                }
              )
            : [],
          rows: formik?.values?.cofo_answers?.[0]?.unit_mix_aux?.rows
            ? formik?.values?.cofo_answers?.[0]?.unit_mix_aux?.rows.map(
                (row, index) => ({ ...row, id: index })
              )
            : [],
          pageSize: pageSize,
          // eslint-disable-next-line no-unused-vars
          onPageChange: (page) => {
            /* no paging in this case */
          },
          loading: formik?.values?.cofo_answers,
          label: "Unit Mix",
          hide: !formik?.values?.cofo_answers?.[0]?.unit_mix_aux,
        },
      }}
      nameOverrides={{}}
      id={id}
      formik={formik}
    />
  );
};

AnalystCOfOContainer.propTypes = {
  id: PropTypes.number.isRequired,
  page: PropTypes.string,
};

export default AnalystCOfOContainer;
