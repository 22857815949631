import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import React from "react";
import MyOrdersListContainer from "../Container/ResidentialOrders/MyOrdersListContainer";
import AddNewResidentialOrderContainer from "../Container/ResidentialOrders/Forms/AddNewResidentialOrderContainer";
import AuthorizeNetFormContainer from "../Container/ResidentialOrders/AuthorizeNet/AuthorizeNetFormContainer";

const ResidentialRouter = () => {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.url}/my-orders`}>
        <MyOrdersListContainer />
      </Route>
      <Route path={`${match.url}/create-order`}>
        <AddNewResidentialOrderContainer />
      </Route>
      <Route
        path={`${match.url}/checkout/:id`}
        render={(props) => {
          // eslint-disable-next-line react/prop-types
          return <AuthorizeNetFormContainer orderID={props.match.params.id} />;
        }}
      />
      <Route path={`${match.url}/`}>
        <Redirect to={`${match.url}/my-orders`} />
      </Route>
    </Switch>
  );
};

export default ResidentialRouter;
