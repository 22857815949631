import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles, Typography } from "@material-ui/core";
import * as PropTypes from "prop-types";
import { GetSet, ListFilter } from "../../Constants/Jurisdiction";
import TextField from "@material-ui/core/TextField/TextField";
//import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card/Card";
import { CardContent } from "@material-ui/core";
import WorxMediaUpload from "../../Container/Forms/Inputs/WorxMediaUpload";
import WorxDialogContainer from "../../Container/WorxDialogContainer";
import Button from "@material-ui/core/Button";
import AnswerContainer from "../../Container/Reaserch/AnswerContainer";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";

const useStyles = makeStyles({
  worxRadioGroupStyleOverride: {
    marginBottom: "1em",
  },
  worxSpaceAround: {
    margin: "0 1em",
  },
  textfieldWrapper: {
    marginBottom: "1em",
  },
  buttonWrapper: {
    marginTop: "1em",
    textAlign: "right",
  },
});

// eslint-disable-next-line no-unused-vars
const buttonUseStyles = makeStyles({
  root: {
    marginLeft: "1em",
  },
  blue: {
    background: "#5aacee",
    color: "white",
  },
  green: {
    background: "#33a532",
    color: "white",
  },
});

const topCardUseStyles = makeStyles({
  root: {
    "& .MuiDataGrid-cell": {
      whiteSpace: "normal !important",
    },
    marginBottom: "2em",
  },
});

const OrdersDisplay = (props) => {
  const {
    orders,
    rows,
    columns,
    rowTotal,
    filters,
    currentPage,
    /*createNewOrder,*/ order,
    report,
    document,
    researchDocument,
    documentTypes,
    editCR,
    answers,
    answersOpen,
    analystReportQuery,
    formik,
  } = props;

  let pageSize = 25;

  const classes = useStyles();
  const topCardClasses = topCardUseStyles();

  return (
    <Card raised={true}>
      <CardContent>
        <div className={classes.textfieldWrapper}>
          <TextField
            margin="normal"
            id={"order"}
            label={"ID"}
            onChange={(event) => {
              let nextFilter = {
                ...filters.order.get,
                id: event.target.value ? +event.target.value : null,
                offset: 0,
              };
              orders.filter({ variables: nextFilter });
              filters.set.order(nextFilter);
            }}
            className={classes.worxSpaceAround}
          />
          <TextField
            margin="normal"
            id={"client"}
            label={"Client"}
            onChange={(event) => {
              let nextFilter = {
                ...filters.order.get,
                client: event.target.value ? event.target.value : "",
                offset: 0,
              };
              orders.filter({ variables: nextFilter });
              filters.set.order(nextFilter);
            }}
            className={classes.worxSpaceAround}
          />
        </div>

        <DataGrid
          classes={{ root: topCardClasses.root }}
          key={rowTotal}
          pagination
          rowCount={rowTotal.order.get}
          page={currentPage.order.get}
          autoHeight={true}
          getRowHeight={() => "auto"}
          columns={columns}
          rows={rows}
          pageSize={pageSize}
          paginationMode="server"
          onPageChange={(page) => {
            let nextFilter = { ...filters.order.get, offset: pageSize * page };
            orders.filter({ variables: nextFilter });
            filters.order.set(nextFilter);
            currentPage.order.set(page);
          }}
          onSelectionModelChange={(selectionModel) => {
            let index = rows.findIndex(
              (item) => item.id === selectionModel.selectionModel[0]
            );
            order.set(rows[index]);
            report.set(null);
            document.set(null);
          }}
          loading={!orders.list}
          className={classes.worxSpaceAround}
        />

        {order.get && (
          <React.Fragment>
            <Typography variant={"h5"}>Reports</Typography>
            <DataGrid
              key={rowTotal}
              pagination
              autoHeight={true}
              getRowHeight={() => "auto"}
              columns={[
                {
                  field: "id",
                  headerName: "ID",
                  width: 100,
                  valueGetter: (value) => {
                    return value?.value;
                  },
                },
                {
                  field: "name",
                  headerName: "Name",
                  width: 430,
                  valueGetter: (value) => {
                    return value?.value;
                  },
                },
              ]}
              rows={order.get.report_order_items}
              pageSize={pageSize}
              paginationMode="client"
              onPageChange={(page) => {
                let nextFilter = {
                  ...filters.report.get,
                  offset: pageSize * page,
                };
                orders.filter({ variables: nextFilter });
                filters.report.set(nextFilter);
                currentPage.report.set(page);
              }}
              onSelectionModelChange={(selectionModel) => {
                let index = order.get.report_order_items.findIndex(
                  (item) => item.id === selectionModel.selectionModel[0]
                );
                report.set(order.get.report_order_items[index]);
                document.set(null);
              }}
              loading={!orders.list}
              className={classes.worxSpaceAround}
            />
          </React.Fragment>
        )}

        {report.get && (
          <WorxMediaUpload
            type={"document"}
            entityType={"media"}
            fieldKey={"document"}
            label={"Client Documents"}
            property={report.get?.property?.[0].id ?? null}
            formik={formik}
            onComplete={(value) => {
              editCR({
                variables: {
                  data: { id: report.get.id, client_documents: value.document },
                },
              });
            }}
          />
        )}

        {report.get && (
          <React.Fragment>
            <Typography variant={"h5"}>Research Documents</Typography>
            <DataGrid
              key={rowTotal.document.get}
              pagination
              autoHeight={true}
              columns={[
                {
                  field: "id",
                  headerName: "ID",
                  width: 100,
                  valueGetter: (value) => {
                    return value?.value;
                  },
                },
                {
                  field: "name",
                  headerName: "Name",
                  width: 230,
                  valueGetter: (value) => {
                    return documentTypes[
                      documentTypes.findIndex(
                        (item) => item.id === +value.row?.id
                      )
                    ].name;
                  },
                },
                {
                  field: "answers",
                  headerName: "Answers",
                  width: 230,
                  // eslint-disable-next-line no-unused-vars
                  renderCell: Object.assign(
                    (params) => {
                      const index = answers.findIndex((item) => {
                        return (
                          +item.id ===
                          +params?.row?.document_entities?.[params.id]?.[0]?.[0]
                        );
                      });
                      if (index >= 0) {
                        return (
                          <Button
                            key={
                              params?.row?.document_entities?.[
                                params.id
                              ]?.[0]?.[0]
                            }
                            variant={"contained"}
                            color="primary"
                            margin="normal"
                            onClick={() => {
                              researchDocument.set(answers[index]);
                              answersOpen.set(true);
                            }}
                          >
                            View Answers
                          </Button>
                        );
                      }
                      return <div>no answers</div>;
                    },
                    { displayName: "renderCell" }
                  ),
                },
                {
                  field: "notes",
                  headerName: "Notes",
                  width: 230,
                  // eslint-disable-next-line no-unused-vars
                  renderCell: Object.assign(
                    (params) => {
                      const index = answers.findIndex((item) => {
                        return (
                          +item.id ===
                          +params?.row?.document_entities?.[params.id]?.[0]?.[0]
                        );
                      });
                      if (index >= 0) {
                        return (
                          <Tooltip title={answers[index].notes}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: answers[index].notes,
                              }}
                            />
                          </Tooltip>
                        );
                      }
                      return <div>no notes</div>;
                    },
                    { displayName: "renderCell" }
                  ),
                },
              ]}
              rows={
                report.get?.document_order_items
                  ? report.get.document_order_items.reduce(
                      (outData, newData) => {
                        return [
                          ...outData,
                          ...newData.document_order_template[0].research_documents.map(
                            (item) => ({
                              document_entities: newData.document_entities,
                              ...item,
                            })
                          ),
                        ];
                      },
                      []
                    )
                  : []
              }
              pageSize={pageSize}
              paginationMode="client"
              onPageChange={(page) => {
                let nextFilter = {
                  ...filters.document.get,
                  offset: pageSize * page,
                };
                orders.filter({ variables: nextFilter });
                filters.document.set(nextFilter);
                currentPage.set(page);
              }}
              /*onSelectionModelChange={(selectionModel) => {
            document.set(order.get.report_order_items[selectionModel.selectionModel[0] - 1])
          }}*/
              loading={!orders.list}
              getRowHeight={() => "auto"}
              className={classes.worxSpaceAround}
            />
          </React.Fragment>
        )}

        {report.get && (
          <React.Fragment>
            <Typography variant={"h5"}>Property Notes</Typography>
            <div
              dangerouslySetInnerHTML={{
                __html: report.get.property?.[0]?.notes ?? "<div>Empty</div>",
              }}
            />
          </React.Fragment>
        )}

        {report.get && (
          <React.Fragment>
            <Typography variant={"h5"}>Analyst Notes</Typography>
            <Typography variant={"body1"}>CoFO Notes</Typography>
            <div
              dangerouslySetInnerHTML={{
                __html: analystReportQuery?.cofo_notes ?? "<div>Empty</div>",
              }}
            />
            <Typography variant={"body1"}>Conformance Notes</Typography>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  analystReportQuery?.conformance_notes ?? "<div>Empty</div>",
              }}
            />
            <Typography variant={"body1"}>Parking Notes</Typography>
            <div
              dangerouslySetInnerHTML={{
                __html: analystReportQuery?.parking_notes ?? "<div>Empty</div>",
              }}
            />
            <Typography variant={"body1"}>Requirement Notes</Typography>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  analystReportQuery?.requirements_notes ?? "<div>Empty</div>",
              }}
            />
          </React.Fragment>
        )}

        <WorxDialogContainer
          id={"addLinkForm"}
          open={answersOpen.get}
          onClose={() => answersOpen.set(false)}
          onKeyDown={(e) => e.stopPropagation()}
          content={<AnswerContainer answer={researchDocument.get} />}
        />
      </CardContent>
    </Card>
  );
};

OrdersDisplay.propTypes = {
  orders: ListFilter,
  initValues: GetSet,
  rowTotal: PropTypes.object,
  columns: PropTypes.array,
  rows: PropTypes.array,
  filters: PropTypes.object,
  currentPage: PropTypes.object,
  createNewOrder: PropTypes.func.isRequired,

  order: GetSet,
  report: GetSet,
  document: GetSet,
  researchDocument: GetSet,
  documentTypes: PropTypes.array,
  editCR: PropTypes.func,

  answers: PropTypes.object,
  answersOpen: PropTypes.getSet,

  analystReportQuery: PropTypes.object,

  formik: PropTypes.object,
};

export default OrdersDisplay;
