import React from "react";
import * as PropTypes from "prop-types";
import Drawer from "@material-ui/core/Drawer";
import { GetSet } from "../Constants/Jurisdiction";
import {
  Card,
  CardContent,
  // FormLabel,
  makeStyles,
  //Typography
} from "@material-ui/core";

const topCardUseStyles = makeStyles({
  root: {
    marginBottom: "2em",
  },
});

const AbuttingZonesDisplay = (props) => {
  const { open, abuttingZones } = props;

  const topCardClasses = topCardUseStyles();

  return (
    <React.Fragment>
      {" "}
      {
        <Drawer
          id={"drawer-test"}
          anchor={"right"}
          open={open.get}
          onClose={() => {
            open.set(false);
          }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <Card classes={{ root: topCardClasses.root }}>
            <CardContent>
              {abuttingZones &&
                abuttingZones.map((zone) => {
                  return (
                    <div key={zone.id} style={{ marginBottom: ".25em" }}>
                      <span style={{ fontWeight: "bold", marginRight: "1em" }}>
                        {zone.name}:
                      </span>
                      {zone.zoning?.[0]?.name}
                    </div>
                  );
                })}
            </CardContent>
          </Card>
        </Drawer>
      }
    </React.Fragment>
  );
};

AbuttingZonesDisplay.propTypes = {
  open: GetSet,
  abuttingZones: PropTypes.array,
};

export default AbuttingZonesDisplay;
