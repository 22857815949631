import React from "react";
import * as PropTypes from "prop-types";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { InputLabel } from '@mui/material';
import { camelToTitle, underscoreToCamel } from "../../../tools/stringTools";

const WorxCKEditorFormik = (props) => {
  let {
    fieldKey,
    label = camelToTitle(underscoreToCamel(fieldKey)),
    disabled = false,
    formik,
  } = props;

  let value = formik.values;
  let error = formik.errors;
  let touched = formik.touched;

  for (let el of fieldKey.split(".")) {
    if (value && value?.[el]) {
      value = value?.[el];
    } else {
      value = null;
    }

    if (error && error?.[el]) {
      error = error?.[el];
    } else {
      error = null;
    }

    if (touched && touched?.[el]) {
      touched = touched?.[el];
    } else {
      touched = null;
    }
  }

  return (
    <section key={formik.values[fieldKey]} aria-label={label}>
      <InputLabel style={{ marginTop: "1em" }}>{label}</InputLabel>
      <CKEditor
        aria-label={label}
        editor={ClassicEditor}
        disabled={disabled}
        data={value ?? ''}
        /*onReady={ editor => {
        // You can store the "editor" and use when it is needed.
      } }*/
        onBlur={(event, editor) => {
          const data = editor.getData();
          if (data) {
            formik.setFieldValue(fieldKey, data);
          }
        }}
        /*onFocus={ ( event, editor ) => {
      } }*/
      />
      {touched && error ? <div style={{ color: "red" }}>{error}</div> : null}
    </section>
  );
};

WorxCKEditorFormik.propTypes = {
  fieldKey: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  formik: PropTypes.object,
};

export default WorxCKEditorFormik;
