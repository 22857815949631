import React, { useContext } from "react";
import { List } from "@material-ui/core";
import UserContext from "../../Context/UserContext";
import SidebarMenuItemContainer from "../../Container/Menu/SidebarMenuItemContainer";

const MainMenuDisplay = () => {
  const userContext = useContext(UserContext);

  return (
    <nav aria-label="Main Navigation">
      <List disablePadding={true}>
        {userContext[0].roles && userContext[0].roles.includes('data_entry_1') &&
          <SidebarMenuItemContainer path={"/data_entry/main_workflow"} value={"Data Entry 1"} />
        }
        {userContext[0].roles && userContext[0].roles.includes('data_entry_2') &&
          <SidebarMenuItemContainer path={"/data_entry/use_workflow"} value={"Data Entry 2"} />
        }
        {userContext[0].roles && userContext[0].roles.includes('data_entry_3') &&
          <SidebarMenuItemContainer path={"/data_entry/requirements_workflow"} value={"Data Entry 3"} />
        }
        {userContext[0].roles && userContext[0].roles.includes('administrator') &&
          <SidebarMenuItemContainer path={"/orders"} value={"Orders"}/>
        }
        {userContext[0].roles && userContext[0].roles.includes('administrator') &&
            <SidebarMenuItemContainer path={"/research"} value={"Research"} />
        }
        {userContext[0].roles && userContext[0].roles.includes('administrator') &&
            <SidebarMenuItemContainer path={"/junior_analyst"} value={"Junior Analyst"} />
        }
        {userContext[0].roles && userContext[0].roles.includes('administrator') &&
          <SidebarMenuItemContainer path={"/analyst"} value={"Analyst"} />
        }
        {userContext[0].roles && userContext[0].roles.includes('administrator') &&
          <SidebarMenuItemContainer path={"/residential"} value={"Residential"} />
        }
        {userContext[0].roles && userContext[0].roles.includes('administrator') &&
          <SidebarMenuItemContainer path={"/portal"} value={"Client Portal"} />
        }
        {userContext[0].roles && userContext[0].roles.includes('administrator') &&
          <SidebarMenuItemContainer path={"/client-relations"} value={"Client Relations"} />
        }
        {userContext[0].roles && userContext[0].roles.includes('administrator') &&
          <SidebarMenuItemContainer path={"/commercial"} value={"Commercial Reports"} />
        }
      </List>
    </nav>
  );
};

export default MainMenuDisplay;
