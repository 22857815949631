import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GetSet } from "../../../../Constants/Jurisdiction";
import {
  getCalculations,
  getRequirementsForJurisdiction,
} from "../../../../Queries/Queries";
import {
  createRequirement,
  editRequirement,
} from "../../../../Queries/Mutations";
import { useFormik } from "formik";
import * as Yup from "yup";
import RequirementsFormDisplay from "../../../../Display/DataEntry/DataEntryThree_RequirmentsWorkflow/Forms/RequirementsFormDisplay";
import Button from "@material-ui/core/Button";
import WorxDeleteButton from "../../../Forms/Inputs/WorxDeleteButton";

const RequirementsFormContainer = (props) => {
  const { data, step } = props;
  const [initValues, setInitValues] = useState({});
  const [rowTotal, setRowTotal] = useState(null);
  const id = data.get.id;
  const pageSize = 25;
  const [currentPage, setCurrentPage] = useState(0);
  const [filters, setFilters] = useState({ limit: pageSize, offset: 0 });
  const [filterRequirements, { data: requirementsQuery }] = useLazyQuery(
    getRequirementsForJurisdiction(data.get.id, pageSize),
    {
      fetchPolicy: "cache-and-network",
    }
  );
  const [addRequirement, setAddRequirement] = useState(false);

  // Pull first set of uses only on first pass.
  useEffect(() => {
    filterRequirements({ offset: 0, limit: 50 });
  }, []);

  useEffect(() => {
    if (requirementsQuery?.requirements?.total) {
      if (requirementsQuery?.requirements?.total != rowTotal) {
        setRowTotal(requirementsQuery?.requirements?.total);
      }
    }
  }, [requirementsQuery]);

  // options
  const [calculationOpen, setCalculationOpen] = useState(false);
  const [filterCalculationOptions, { data: calculationOptionsQuery }] =
    useLazyQuery(getCalculations());

  // mutations
  const [doCreateRequirement] = useMutation(createRequirement(), {
    refetchQueries: [
      { query: getRequirementsForJurisdiction(id, 50), variables: filters },
    ],
  });
  const [doEditRequirement] = useMutation(editRequirement(), {
    refetchQueries: [
      { query: getRequirementsForJurisdiction(id, 50), variables: filters },
    ],
  });

  // formic
  let defaultRequirement = {
    name: "",
    calculation: [{ id: 0, name: "- select -" }],
    code_location: "",
    description: "",
    jurisdiction: [{ id: data.get.id }],
    weight: 0,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...defaultRequirement, ...initValues, button: null },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
    }),
    onSubmit: (values, formikTools) => {
      // eslint-disable-next-line no-unused-vars
      let { button, ...requirement } = values;
      switch (button) {
        case "normal":
          mutateRequirement(requirement);
          setAddRequirement(false);
          break;
        case "repeat":
          mutateRequirement(requirement);
          break;
        case "cancel":
          setAddRequirement(false);
          break;
      }
      setInitValues({});
      formikTools.resetForm();
    },
  });

  const mutateRequirement = async (footnote) => {
    // Do this to get the payload in the right shape
    // eslint-disable-next-line no-unused-vars
    let { new: isNew, edit, type, rowIndex, ...payload } = footnote;
    if (payload.id) {
      await doEditRequirement({ variables: { data: payload } }).then();
    } else {
      // take the temp id out of the payload
      // eslint-disable-next-line no-unused-vars
      let { id, ...createPayload } = payload;
      await doCreateRequirement({ variables: { data: createPayload } });
    }
  };

  const columns = [
    { field: "name", headerName: "Name", width: 500 },
    {
      field: "operations",
      headerName: "Edit",
      width: 150,
      renderCell: Object.assign(
        (params) => {
          return (
            <React.Fragment>
              <WorxDeleteButton
                callback={(response) => {
                  if (response?.deleteEntity?.errors?.length <= 0) {
                    filterRequirements({ variables: filters });
                  }
                }}
                type={"jur_requirement"}
                id={params.row.id}
              />
              <Button
                onClick={() => {
                  setAddRequirement(true);
                  setInitValues(params.row);
                }}
                color="primary"
              >
                Edit
              </Button>
            </React.Fragment>
          );
        },
        { displayName: "renderCell" }
      ),
    },
  ];

  return (
    <RequirementsFormDisplay
      addRequirement={{ get: addRequirement, set: setAddRequirement }}
      requirements={{
        list: requirementsQuery?.requirements?.items,
        filter: filterRequirements,
      }}
      columns={columns}
      rowTotal={rowTotal}
      calculationOptions={{
        open: { get: calculationOpen, set: setCalculationOpen },
        options: {
          list: calculationOptionsQuery?.calculations?.items
            ? calculationOptionsQuery?.calculations?.items
            : [],
          filter: filterCalculationOptions,
        },
      }}
      initValues={{ get: initValues, set: setInitValues }}
      currentPage={{ get: currentPage, set: setCurrentPage }}
      filters={{ get: filters, set: setFilters }}
      step={step}
      pageSize={pageSize}
      formik={formik}
    />
  );
};

RequirementsFormContainer.propTypes = {
  data: GetSet,
  step: PropTypes.number.isRequired,
};

export default RequirementsFormContainer;
