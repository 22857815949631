import React from "react";
import { PropTypes } from "prop-types";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles, Typography } from "@material-ui/core";

const typographyUseStyles = makeStyles({
  root: {
    marginTop: "1em",
  },
});

const topCardUseStyles = makeStyles({
  root: {
    "& .MuiDataGrid-cell": {
      whiteSpace: "normal !important",
    },
    marginBottom: "2em",
  },
});

const ClaimedItemsDisplay = (props) => {
  const { rows, columns, loading } = props;

  const typographyClasses = typographyUseStyles();
  const topCardUseClasses = topCardUseStyles();


  return <section>
    <Typography variant="h5" gutterBottom classes={{root: typographyClasses.root}}>My Items</Typography>
    <DataGrid
      classes={{root: topCardUseClasses.root}}
      autoHeight={true}
      columns={columns}
      rows={rows}
      pageSize={10}
      loading={loading}
      checkboxSelection={false}
      getRowHeight={() => 'auto'}
    />
  </section>
};

ClaimedItemsDisplay.propTypes = {
  rows: PropTypes.any.isRequired,
  columns: PropTypes.array.isRequired,
  loading: PropTypes.bool,
};

export default ClaimedItemsDisplay;
