import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import React from "react";
import { useContext } from "react";
import JurisdictionEditContainer from "../Container/DataEntry/DataEntryOne_MainWorkflow/JurisdictionEditContainer";
import UseWorkflowContainer from "../Container/DataEntry/DataEntryTwo_UseWorkflow/UseWorkflowContainer";
import RequirementsWorkflowContainer from "../Container/DataEntry/DataEntryThree_RequirmentsWorkflow/RequirementsWorkflowContainer";
import UserContext from "../Context/UserContext";
import DataEntryContainer from "../Container/DataEntry/DataEntryContainer";

const WorkflowRouter = () => {
  const userContext = useContext(UserContext);

  //match roles to default screen after login
  let default_route = "";
  //let default_queue = '';
  if (userContext[0].roles && userContext[0].roles.includes("data_entry_1")) {
    //default_route = 'data_entry_1';
    default_route = "main_workflow";
  } else if (
    userContext[0].roles &&
    userContext[0].roles.includes("data_entry_2") &&
    !userContext[0].roles.includes("data_entry_1")
  ) {
    //default_route = 'data_entry_2';
    default_route = "use_workflow";
  } else if (
    userContext[0].roles &&
    userContext[0].roles.includes("data_entry_3") &&
    !userContext[0].roles.includes("data_entry_1")
  ) {
    //default_route = 'data_entry_3';
    default_route = "requirements_workflow";
  } else {
    default_route = "";
  }

  let match = useRouteMatch();
  return (
    <Switch>
      <Route
        path={`${match.url}/main_workflow/jurisdiction/:id`}
        render={(props) => {
          // eslint-disable-next-line react/prop-types
          return <JurisdictionEditContainer id={+props.match.params.id} />;
        }}
      />
      <Route path={`${match.url}/main_workflow/jurisdiction`}>
        <JurisdictionEditContainer />
      </Route>
      <Route path={`${match.url}/use_workflow/jurisdiction`}>
        <UseWorkflowContainer />
      </Route>
      <Route path={`${match.url}/requirements_workflow/jurisdiction`}>
        <RequirementsWorkflowContainer />
      </Route>
      <Route path={`${match.url}/main_workflow`}>
        <DataEntryContainer queue="main_workflow" />
      </Route>
      <Route path={`${match.url}/use_workflow`}>
        <DataEntryContainer queue="use_workflow" />
      </Route>
      <Route path={`${match.url}/requirements_workflow`}>
        <DataEntryContainer queue="requirements_workflow" />
      </Route>
      <Route path={match.url}>
        <Redirect
          to={
            default_route
              ? match.url + "/" + default_route
              : match.url + "/main_workflow"
          }
        />
      </Route>
    </Switch>
  );
};

export default WorkflowRouter;
