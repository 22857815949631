import { Route, Switch, useRouteMatch } from "react-router-dom";
import React from "react";
import JuniorAnalystQueueContainer from "../Container/JuniorAnalyst/JuniorAnalystQueueContainer";
import Sandbox from "../Container/Sandbox";
import JuniorAnalystSwitchContainer from "../Container/JuniorAnalyst/JuniorAnalystSwitchContainer";
import JuniorAnalystSurveyFormContainer from "../Container/JuniorAnalyst/Forms/JuniorAnalystSurveyFormContainer";

/* eslint-disable react/prop-types */
const JuniorAnalystRouter = () => {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.url}/survey_temp_form`}>
        <Sandbox />
      </Route>
      <Route
        path={`${match.url}/junior_analyst_workflow/survey_temp_form/:id`}
        render={(props) => {
          return (
            <JuniorAnalystSurveyFormContainer
              documentID={props.match.params.id}
            />
          );
        }}
      />
      <Route
        path={`${match.url}/junior_analyst_workflow/:id`}
        render={(props) => {
          return (
            <JuniorAnalystSwitchContainer claimID={+props.match.params.id} />
          );
        }}
      />
      <Route path={`${match.url}`}>
        <JuniorAnalystQueueContainer />
      </Route>
    </Switch>
  );
};

export default JuniorAnalystRouter;
