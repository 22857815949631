import React from "react";
import * as PropTypes from "prop-types";
import { GetSet } from "../../Constants/Jurisdiction";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import WorxFormContainer from "../../Container/Forms/Generic/WorxFormContainer";
import DocumentUploadSubContainer from "../../Container/Reaserch/DocumentUploadSubContainer";
import WorxDialogContainer from "../../Container/WorxDialogContainer";
import AnswerContainer from "../../Container/Reaserch/AnswerContainer";

const useStyles = makeStyles({
  worxRadioGroupStyleOverride: {
    marginBottom: "1em",
  },
  worxSpaceAround: {
    margin: "1em 0em",
  },
  textfieldWrapper: {
    marginBottom: "1em",
  },
  buttonWrapper: {
    marginTop: "1em",
    textAlign: "right",
  },
});

const topCardUseStyles = makeStyles({
  root: {
    "& .MuiDataGrid-cell": {
      whiteSpace: "normal !important",
    },
    marginBottom: "2em",
  },
});

const ReceivedTabDisplay = (props) => {
  const {
    working,
    viewAnswersOpen,
    answerID,
    answers,
    updateDocuments,
    pageSize,
    orderItems,
    columns,
    jurisdiction,
    property,
    options,
    formik,
  } = props;
  const classes = useStyles();
  const topCardClasses = topCardUseStyles();

  return (
    <React.Fragment>
      {orderItems.map((data) => {
        let localRows = data.document.map((doc) => ({
          ...doc,
          answer_key:
            +data?.document_entities[
              Object.keys(data.document_entities)?.[0]
            ]?.[0]?.[0],
          __parent_id: data.id,
        }));

        return (
          <React.Fragment key={data.id + "-" + data?.document?.length}>
            {data?.document_order_template?.[0]?.name}
            <DocumentUploadSubContainer
              jurisdiction={jurisdiction}
              property={property}
              hideOnDone={false}
              callback={updateDocuments}
              initValues={data}
            />
            <DataGrid
              classes={{ root: topCardClasses.root }}
              pagination
              autoHeight={true}
              getRowHeight={() => "auto"}
              columns={columns}
              rows={localRows}
              pageSize={pageSize}
              loading={!data.document && data.document !== null}
              className={classes.worxSpaceAround}
            />
          </React.Fragment>
        );
      })}
      <WorxDialogContainer
        id={"addLinkForm"}
        open={working.get}
        onClose={() => working.set(false)}
        onKeyDown={(e) => e.stopPropagation()}
        content={
          <WorxFormContainer
            disabledMap={{ property: true, document: true }}
            dependencyMap={{
              bv_violations_other: {
                target: "bv_violations",
                value: [{ id: "Other" }],
              },
              cn_plans_other: {
                target: "cn_plans",
                value: [{ id: "Other" }],
              },
              fv_violations_other: {
                target: "fv_violations",
                value: [{ id: "Other" }],
              },
              zv_violations_other: {
                target: "zv_violations",
                value: [{ id: "Other" }],
              },
            }}
            options={options}
            formik={formik}
          />
        }
      />
      <WorxDialogContainer
        id={"addLinkForm"}
        open={viewAnswersOpen.get}
        onClose={() => viewAnswersOpen.set(false)}
        onKeyDown={(e) => e.stopPropagation()}
        content={
          <React.Fragment>
            <AnswerContainer answer={answers?.[answerID.get]} />
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
};

ReceivedTabDisplay.propTypes = {
  working: GetSet,
  viewAnswersOpen: GetSet,
  answerID: GetSet,
  answers: PropTypes.array,
  documents: PropTypes.object,
  rowTotal: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  columns: PropTypes.array.isRequired,
  orderItems: PropTypes.array.isRequired,
  updateDocuments: PropTypes.func.isRequired,
  jurisdiction: PropTypes.object,
  property: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  formik: PropTypes.object.isRequired,
};

export default ReceivedTabDisplay;
