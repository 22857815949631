import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import {
  TextareaAutosize,
  Checkbox,
  makeStyles,
  Typography,
  FormLabel,
} from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import {
  GetSet,
  AutoCompleteFilter,
  ListFilter,
} from "../../../../Constants/Jurisdiction";
import * as PropTypes from "prop-types";
import WorxAutocompleteFormik from "../../../Forms/Inputs/WorxAutocompleteFormik";
import WorxTextField from "../../../Forms/Inputs/WorxTextField";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles({
  worxRadioGroupStyleOverride: {
    marginBottom: "1em",
  },
  buttonWrapper: {
    textAlign: "right",
  },
  textareaWrapper: {
    display: "flex",
    flexDirection: "column",
  },
});

const buttonUseStyles = makeStyles({
  root: {
    marginLeft: "1em",
  },
});

const topCardUseStyles = makeStyles({
  root: {
    "& .MuiDataGrid-cell": {
      whiteSpace: "normal !important",
    },
    marginBottom: "2em",
  },
});

const JurisdictionFormFiveDisplay = (props) => {
  let {
    addUses,
    uses,
    columns,
    initValues,
    categoryOptions,
    pageSize,
    rowCount,
    filters,
    currentPage,
    formik,
  } = props;

  const classes = useStyles();
  const buttonClasses = buttonUseStyles();
  const topCardClasses = topCardUseStyles();

  return (
    <React.Fragment>
      <Typography variant={"h5"} gutterBottom>
        Uses
      </Typography>
      <FormLabel>Create Use</FormLabel>
      {!addUses.get && (
        <RadioGroup
          aria-label="Uses"
          name="create-uses"
          value={addUses.get}
          onChange={(event) => {
            addUses.set(event.target.value);
            initValues.set({});
          }}
          className={classes.worxRadioGroupStyleOverride}
        >
          <FormControlLabel value={true} control={<Radio />} label="Yes" />
          <FormControlLabel value={false} control={<Radio />} label="No" />
        </RadioGroup>
      )}
      {addUses.get && (
        <form>
          <WorxTextField fieldKey={"name"} formik={formik} />
          <div className={classes.textareaWrapper}>
            <FormLabel>Description</FormLabel>
            <TextareaAutosize
              margin="normal"
              id={"description"}
              value={formik.values.description}
              onChange={formik.handleChange}
              rowsMin={"5"}
              rowsMax={"10"}
            />
          </div>
          <WorxAutocompleteFormik
            fieldKey={"category"}
            options={categoryOptions}
            formik={formik}
          />
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  id={"certificate_required"}
                  checked={formik.values.certificate_required}
                  onChange={(event) => {
                    formik.setFieldValue(
                      "certificate_required",
                      event.target.checked
                    );
                  }}
                  name="Certificate of Occupancy Required"
                  color="primary"
                />
              }
              label="Certificate Required"
            />
          </div>
          <WorxTextField fieldKey={"code_location"} formik={formik} />
          <WorxTextField fieldKey={"weight"} type={"number"} formik={formik} />
          <div className={classes.buttonWrapper}>
            <Button
              variant={"contained"}
              color="secondary"
              margin="normal"
              onClick={() => {
                addUses.set(false);
                formik.resetForm();
              }}
            >
              Cancel
            </Button>
            <Button
              variant={"contained"}
              color="primary"
              margin="normal"
              onClick={() => {
                formik.setFieldValue("button", "normal");
                formik.handleSubmit();
              }}
              classes={{ root: buttonClasses.root }}
            >
              Submit
            </Button>
            <Button
              variant={"contained"}
              color="primary"
              margin="normal"
              onClick={() => {
                formik.setFieldValue("button", "repeat");
                formik.handleSubmit();
              }}
              classes={{ root: buttonClasses.root }}
            >
              Submit and Add Another
            </Button>
          </div>
        </form>
      )}

      {!addUses.get && (
        <React.Fragment>
          <div>
            <TextField
              margin="normal"
              id={"name"}
              placeholder={"Name"}
              onChange={(event) => {
                let nextFilter = {
                  ...filters.get,
                  name: event.target.value,
                  offset: 0,
                };
                uses.filter({ variables: nextFilter });
                filters.set(nextFilter);
                currentPage.set(0);
              }}
            />
          </div>
          <DataGrid
            classes={{ root: topCardClasses.root }}
            key={rowCount}
            page={currentPage.get}
            pagination
            paginationMode="server"
            rowCount={rowCount}
            pageSize={pageSize}
            onPageChange={(page) => {
              let nextFilter = { ...filters.get, offset: pageSize * page };
              uses.filter({ variables: nextFilter });
              currentPage.set(page);
              filters.set(nextFilter);
            }}
            loading={!uses.list}
            columns={columns}
            rows={uses.list ? uses.list : []}
            autoHeight
            getRowHeight={() => "auto"}
            checkboxSelection={false}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

JurisdictionFormFiveDisplay.propTypes = {
  addUses: GetSet,
  uses: ListFilter,
  columns: PropTypes.array,
  initValues: GetSet,
  categoryOptions: AutoCompleteFilter,
  pageSize: PropTypes.number.isRequired,
  rowCount: PropTypes.number,
  filters: GetSet,
  currentPage: GetSet,
  formik: PropTypes.object.isRequired,
};

export default JurisdictionFormFiveDisplay;
